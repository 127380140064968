<div class="page-container">
  <div class="audit-tool-cards-container">
    <div class="question-options-row">
      <div class="filter-chips">
        <div class="chipDrop">
          <mat-chip-list class="modified-chip" class="question-options-column" aria-label="Filters">
            <mat-chip [routerLink]="['/admin-landing/reports']" color="primary" selected>
              <fa-icon class="chip-left-icon" [icon]="faChartPie"></fa-icon>
              <span>Charts</span>
            </mat-chip>
          </mat-chip-list>
        </div>
        <div class="chipDrop">
          <mat-chip-list aria-label="Filters">
            <mat-chip color="primary" selected (click)="goToReports()">
              <fa-icon class="chip-left-icon" [icon]="faTable"></fa-icon>
              <span>Reports & Extracts</span>
            </mat-chip>
          </mat-chip-list>
        </div>
        <div class="chipDrop">
          <mat-chip-list aria-label="Filters" *ngIf=" user['division'] !== 'Supermarkets' && (('adhocAssessments.create' | accessCheck) || ('recurringAssessments.create' | accessCheck) || ('onceOffAssessments.create' | accessCheck))">
            <mat-chip (click)="user['division'] === 'Supermarkets' ? createAssessment.open() : createSurvey()"
              color="primary" selected>
              <fa-icon class="chip-left-icon" [icon]="faPlus"></fa-icon>
              <span>Create New Assessment</span>
            </mat-chip>
          </mat-chip-list>
          <mat-select #createAssessment class="optionsDropDown">
            <mat-option (click)="createSurvey()" *ngIf="('adhocAssessments.create' | accessCheck) || ('recurringAssessments.create' | accessCheck) || ('onceOffAssessments.create' | accessCheck)">
              Standard Assessment
            </mat-option>
            <mat-option (click)="createSurveyMRR()">
              Material Risk Review
            </mat-option>
          </mat-select>
        </div>
        <div class="chipDrop">
          <mat-chip-list aria-label="Filters">
            <mat-chip [routerLink]="['/admin-landing/more-templates']" color="primary" selected>
              <fa-icon class="chip-left-icon" [icon]="faEdit"></fa-icon>
              <span>Manage Templates</span>
            </mat-chip>
          </mat-chip-list>
        </div>
        <div class="chipDrop">
          <mat-chip-list aria-label="Filters">
            <mat-chip [routerLink]="['/access-management']" color="primary" selected>
              <fa-icon class="chip-left-icon" [icon]="faKey"></fa-icon>
              <span>Manage Access</span>
            </mat-chip>
          </mat-chip-list>
        </div>
        <div class="chipDrop" *ngIf="user['domain'] === 'WoolworthsNZ'">
          <mat-chip-list aria-label="Filters">
            <mat-chip [routerLink]="['/store-dashboard']" color="primary" selected>
              <span>
                <fa-icon class="chip-left-icon" [icon]="faStore"></fa-icon>
                <span>Manage Stores</span>
              </span>
            </mat-chip>
          </mat-chip-list>
        </div>
        <div class="chipDrop">
          <mat-chip-list aria-label="Filters">
            <mat-chip  (click)="user['division'] === 'Supermarkets' ? Evidence.open() : goToStandradAssessment()"
            color="primary" selected>
              <fa-icon class="chip-left-icon" [icon]="faPaperclip"></fa-icon>
              <span>Evidence</span>
            </mat-chip>
          </mat-chip-list>
          <mat-select #Evidence class="optionsDropDown">
            <mat-option (click)="goToStandradAssessment()">
              Standard Assessment
            </mat-option>
            <mat-option (click)="goToMrrAssessment()">
              Material Risk Review 
            </mat-option>
          </mat-select>
        </div>
      </div>
    </div>
  </div>

  <div class="list-screen-container audit-tool-cards-container">

    <ng-container *ngFor="let panel of panels">
      <ng-container *ngTemplateOutlet="getTemplateRefByName(panel)"></ng-container>
    </ng-container>


    <ng-template #onceOffAssessments>
      <div class="audit-tool-card list-screen-card">
        <h1>Once Off Assessments</h1>
        <mat-tab-group #tabGroup dynamicHeight="false" mat-align-tabs="center"
          (selectedTabChange)="onceOffTabChanged($event)">
          <mat-tab label="{{surveyGroup}}" *ngFor="let surveyGroup of onceOffAssessmentGroups">
            <div *ngIf="surveyGroup === 'Upcoming'">
              <div class="survey-line survey-heading-line no-ripple">
                <div class="survey-stat template-stat">
                  <h4>Assessment</h4>
                </div>
                <div class="survey-stat template-stat">
                  <h4>Publish Date</h4>
                </div>
                <div class="survey-stat template-stat">
                  <h4>Template Used</h4>
                </div>
                <div class="survey-stat template-stat hide-on-mobile">
                  <h4>No. Questions</h4>
                </div>
              </div>
            </div>

            <div *ngIf="surveyGroup === 'Active'">
              <div class="survey-line survey-heading-line no-ripple">
                <div class="survey-stat template-stat">
                  <h4>Assessment</h4>
                </div>
                <div class="survey-stat template-stat">
                  <h4>Due Date</h4>
                </div>
                <div class="survey-stat template-stat">
                  <h4>% Complete</h4>
                </div>
                <div class="survey-stat template-stat hide-on-mobile">
                  <h4>Open/Created Actions</h4>
                </div>
              </div>
            </div>

            <div *ngIf="!isLoadingOnceOff">
              <div *ngFor="let survey of onceOffSurveys">
                <a class="survey-details-link" [routerLink]="['/once-off-assessment-overview', survey.id]">
                  <div *ngIf="surveyGroup === 'Upcoming'" class="survey-line">
                    <div class="survey-stat template-stat">
                      <h3>
                        <span *ngIf="survey['targetStores']" class="infoTag">Targeted</span>
                        &nbsp;{{survey['title'].slice(0, 50) | unescape}}
                        <span *ngIf="survey.title.length > 50">...</span>
                      </h3>
                    </div>
                    <div class="survey-stat template-stat">
                      <h4>{{getDate(survey.publishDate)}}</h4>
                    </div>
                    <div class="survey-stat template-stat">
                      <h4>{{survey['templateTitle']}}</h4>
                    </div>
                    <div class="survey-stat template-stat hide-on-mobile">
                      <h4>{{survey['questions']?.length}}</h4>
                    </div>
                  </div>

                  <div *ngIf="surveyGroup === 'Active'" class="survey-line">
                    <div class="survey-stat template-stat">
                      <h3>
                        <span *ngIf="survey['targetStores']" class="infoTag">Targeted</span>
                        &nbsp;{{survey['title'].slice(0, 50) | unescape}}
                        <span *ngIf="survey.title.length > 50">...</span>
                      </h3>
                    </div>
                    <div class="survey-stat template-stat">
                      <h4>{{getDate(survey.dueDate)}}</h4>
                    </div>
                    <div class="survey-stat template-stat">
                      <h1>{{survey['avgCompletionRate'] | percent}}</h1>
                    </div>
                    <div class="survey-stat template-stat hide-on-mobile">
                      <h3 *ngIf="!survey['actionsOutstanding'] && !survey['actionsCreated']">0 / 0 </h3>
                      <h3 *ngIf="!survey['actionsOutstanding'] && survey['actionsCreated']">0 /
                        {{survey['actionsCreated']}}
                      </h3>
                      <h3 *ngIf="survey['actionsOutstanding'] && survey['actionsCreated']">
                        {{survey['actionsOutstanding']}} / {{survey['actionsCreated']}}
                      </h3>
                    </div>
                  </div>
                </a>
              </div>

              <h3 class="none-found-message" *ngIf="onceOffSurveys.length == 0">
                You currently have no {{surveyGroup}} Once-Off Assessments.
              </h3>
            </div>

            <div *ngIf="isLoadingOnceOff" class="loader-container">
              <div class="loading-wapple"></div>
            </div>

            <a
              [routerLink]="['/admin-landing/more-surveys', {status: surveyGroup === 'Active' ? 'active' : 'upcoming'}]">
              <button class="csa-button-solid">More</button>
            </a>
          </mat-tab>
        </mat-tab-group>
      </div>
    </ng-template>

    <ng-template #adhocAssessments>
      <div class="audit-tool-card list-screen-card">
        <h1>Adhoc Assessments</h1>
        <mat-tab-group #tabGroup dynamicHeight="false" mat-align-tabs="center"
          (selectedTabChange)="adhocTabChanged($event)">
          <mat-tab label="{{surveyGroup}}" *ngFor="let surveyGroup of adhocSurveyGroups">
            <div class="survey-line survey-heading-line no-ripple">
              <div class="survey-stat template-stat">
                <h4>Assessment</h4>
              </div>
              <div class="survey-stat template-stat hide-on-mobile">
                <h4>Active Responses</h4>
              </div>
              <div class="survey-stat template-stat">
                <h4>Create New</h4>
              </div>
            </div>

            <div *ngIf="!isLoadingAdhoc">
              <div *ngFor="let survey of adhocSurveys; let i=index">
                <div class="survey-line">
                  <div class="survey-details-link" [routerLink]="['/onthefly-survey-details', survey.id]">
                    <h3 class="grey-text center-vertical">
                      <span *ngIf="survey['filterName']" class="infoTag">{{survey['filterName'] | uppercase}}</span>
                      &nbsp;{{survey['title'].slice(0, 50) | unescape}}
                      <span *ngIf="survey['title']?.length > 50">...</span>
                    </h3>
                  </div>

                  <div class="survey-details-link hide-on-mobile center-vertical"
                    [routerLink]="['/onthefly-survey-details', survey.id]">
                    <h1>{{survey['activeResponseCount']}}</h1>
                  </div>

                  <div class="survey-details-link survey-button"
                    *ngIf="survey['targetRoles']?.indexOf(user['role']) > -1 && survey['status'] === 'published'">
                    <button class="csa-button-solid" (click)="createOnTheFlySurvey(survey.id)">
                      <span>
                        <fa-icon [icon]="faPlus"></fa-icon> Create
                      </span>
                    </button>
                  </div>

                  <div class="survey-details-link survey-button"
                    *ngIf="survey['status'] === 'closed' && survey['targetRoles']?.indexOf(user['role']) > -1">
                    <div class="light-grey medium-text" *ngIf="survey['daysToEdit'] == 1">
                      Last day to Edit Active Responses
                    </div>
                    <div class="light-grey medium-text" *ngIf="survey['daysToEdit'] > 1">
                      {{survey['daysToEdit']}} Days to Edit Active Responses
                    </div>
                  </div>

                  <div class="survey-details-link survey-button"
                    *ngIf="survey['targetRoles']?.indexOf(user['role']) == -1 || survey['status'] === 'active'"
                    [routerLink]="['/onthefly-survey-details', survey.id]">
                    Available to <span
                      *ngFor="let role of survey['targetRoles']; let j=index">{{splitRoleByCaps(role)}}<span
                        *ngIf="survey['targetRoles']?.length - 1 > j">,&nbsp;</span></span>
                  </div>
                </div>
              </div>

              <h3 class="none-found-message" *ngIf="adhocSurveys.length == 0">
                You currently have no {{surveyGroup}} Adhoc Assessments.
              </h3>
            </div>

            <div *ngIf="isLoadingAdhoc" class="loader-container">
              <div class="loading-wapple"></div>
            </div>

            <a
              [routerLink]="['/more-onthefly-surveys', {status: surveyGroup === 'Available' ? 'published' : 'active', type: 'adhoc'}]">
              <button class="csa-button-solid">More</button>
            </a>
          </mat-tab>
        </mat-tab-group>
      </div>
    </ng-template>

    <ng-template #recurringAssessments>
      <div class="audit-tool-card list-screen-card">
        <h1>Recurring Assessments</h1>
        <div class="filter-chips">
          <div class="chipDrop">
            <mat-chip-list aria-label="Filters">
              <mat-chip (click)=frequencySelect.open() color="primary" selected>
                {{selectedFrequency | titlecase}} <fa-icon [icon]="faSortDown"></fa-icon>
              </mat-chip>
            </mat-chip-list>
            <mat-select #frequencySelect class="optionsDropDown" [(value)]="selectedFrequency">
              <mat-option (click)="getRecurringSurveys()">All Frequencies</mat-option>
              <mat-option *ngFor="let frequency of recurringSelections" (click)="getRecurringSurveys(frequency)">
                {{frequency | titlecase}}
              </mat-option>
            </mat-select>
          </div>
        </div>

        <mat-tab-group #recurringTabGroup dynamicHeight="false" mat-align-tabs="center"
          (selectedTabChange)="recurringTabChanged($event)">
          <mat-tab label="{{surveyGroup}}" *ngFor="let surveyGroup of recurringSurveyGroups">
            <div class="survey-line survey-heading-line no-ripple">
              <div class="survey-stat template-stat">
                <h4>Assessment</h4>
              </div>
              <div class="survey-stat template-stat hide-on-mobile" *ngIf="surveyGroup === 'Available'">
                <h4>Active Assessment Completion</h4>
              </div>
              <div class="survey-stat template-stat hide-on-mobile">
                <h4>Next Publish Date</h4>
              </div>
            </div>

            <div *ngIf="!isLoadingRecurring">
              <div *ngFor="let survey of recurringSurveys; let i=index">
                <a class="survey-details-link" [routerLink]="['/recurring-survey-details', survey.id]">
                  <div class="survey-line">
                    <div class="survey-stat template-stat">
                      <h3>
                        {{survey['title'].slice(0, 50) | unescape}}
                        <span *ngIf="survey['title']?.length > 50">...</span>
                      </h3>
                    </div>
                    <div class="survey-stat template-stat hide-on-mobile" *ngIf="surveyGroup === 'Available'">
                      <h1 *ngIf="survey['avgCompletionRate'] || survey['avgCompletionRate'] == 0">
                        {{survey['avgCompletionRate'] | percent}}
                      </h1>
                      <p *ngIf="!survey['avgCompletionRate'] && survey['avgCompletionRate'] != 0">
                        No Active Assessment
                      </p>
                    </div>
                    <div class="survey-stat template-stat hide-on-mobile">
                      {{ getDate(survey['nextPublishDate'])}}
                    </div>
                  </div>
                </a>
              </div>

              <h3 class="none-found-message" *ngIf="recurringSurveys.length == 0">
                You currently have no {{surveyGroup}} Recurring Assessments.
              </h3>
            </div>

            <div *ngIf="isLoadingRecurring" class="loader-container">
              <div class="loading-wapple"></div>
            </div>

            <a
              [routerLink]="['/more-recurring-surveys', {status: surveyGroup === 'Available' ? 'published' : 'active'}]">
              <button class="csa-button-solid">More</button>
            </a>
          </mat-tab>
        </mat-tab-group>
      </div>
    </ng-template>

    <ng-template #openActions>
      <div class="audit-tool-card list-screen-card">
        <h1>Open Actions</h1>
        <div class="survey-line survey-heading-line no-ripple">
          <div class="survey-stat template-stat">
            <h4>Action</h4>
          </div>
          <div class="survey-stat template-stat">
            <h4>Store</h4>
          </div>
          <div class="survey-stat template-stat">
            <h4>Assessment</h4>
          </div>
          <div class="survey-stat template-stat hide-on-mobile">
            <h4>Description</h4>
          </div>
        </div>

        <div *ngIf="!isLoadingActions">
          <div *ngFor="let action of actions">
            <a class="survey-details-link" [routerLink]="['/action-details', action.id]">
              <div class="survey-line">
                <div class="survey-stat template-stat center-align">
                  <h4> {{action['title'].slice(0, 35) | unescape}}
                    <span *ngIf="(action.title.length > 35)">...</span>
                  </h4>
                  <p [ngClass]="{
                              'orange-text' : isDueInWeek(getDate(action.dueDate)) == true && action['status'] === 'pending',
                              'red-text' : isOverdue(getDate(action.dueDate)) == true && action['status'] === 'pending'
                              }">
                    Due: {{getDate(action.dueDate).slice(0, -4)}}
                  </p>
                </div>
                <div class="survey-stat template-stat">
                  <h4>{{action['storeID']}}</h4>
                </div>
                <div class="survey-stat template-stat">
                  <div>{{action['surveyTitle'] | unescape}}</div>
                </div>
                <div class="survey-stat template-stat hide-on-mobile">
                  <p>
                    {{action['description'].slice(0, 100) | unescape}}
                    <span *ngIf="(action.title.length > 100)">...</span>
                  </p>
                </div>
              </div>
            </a>
          </div>

          <h3 class="none-found-message" *ngIf="actions.length == 0">
            There are currently no Open Actions.
          </h3>
        </div>

        <div *ngIf="isLoadingActions" class="loader-container">
          <div class="loading-wapple"></div>
        </div>

        <a [routerLink]="['/actions-drill-down']">
          <button class="csa-button-solid">More</button>
        </a>
      </div>
    </ng-template>

    <ng-template #templates>
      <div class="audit-tool-card list-screen-card">
        <h1>Templates</h1>

        <div class="survey-line survey-heading-line no-ripple">
          <div class="survey-stat template-stat">
            <h4>Template</h4>
          </div>
          <div class="survey-stat template-stat">
            <h4>Division</h4>
          </div>
          <div class="survey-stat template-stat">
            <h4># Questions</h4>
          </div>
          <div class="survey-stat template-stat hide-on-mobile">
            <h4># Active Assessments</h4>
          </div>
        </div>

        <div *ngIf="!isLoadingTemplates">
          <div *ngFor="let template of allTemplates; let i=index">
            <a class="survey-details-link" [routerLink]="['/template-details', template.id]">
              <div class="survey-line">
                <div class="survey-stat template-stat">
                  <h3>
                    <span *ngIf="template['filterName']" class="infoTag">{{template['filterName'] | uppercase}}</span>
                    &nbsp;{{template['title'].slice(0, 90) | unescape}}
                    <span *ngIf="template.title.length > 50">...</span>
                  </h3>
                </div>
                <div class="survey-stat template-stat">
                  <h4>{{template['division'] | unescape}}</h4>
                </div>
                <div class="survey-stat template-stat">
                  <h1>{{template['questions'].length}}</h1>
                </div>
                <div class="survey-stat template-stat hide-on-mobile">
                  <h2>{{template['surveysPublished']}}</h2>
                </div>
              </div>
            </a>
          </div>

          <h3 class="none-found-message" *ngIf="allTemplates.length == 0">
            There are currently no Templates available. Please talk to the Dev Team to load Assessments.
          </h3>
        </div>

        <div *ngIf="isLoadingTemplates" class="loader-container">
          <div class="loading-wapple"></div>
        </div>

        <a [routerLink]="['/admin-landing/more-templates']">
          <button class="csa-button-solid">More</button>
        </a>
      </div>
    </ng-template>


    <!-- MRR Survey Assessment -->
    <ng-template #MRRAssessments>
      <div class="audit-tool-card list-screen-card" *ngIf="(user['division'] === 'Supermarkets')">
        <h1>Material Risk Review Assessments</h1>
        <mat-tab-group #tabGroup dynamicHeight="false" mat-align-tabs="center"
          (selectedTabChange)="mrrTabChanged($event)">
          <mat-tab label="{{surveyGroup}}" *ngFor="let surveyGroup of mrrSurveyGroups">
            <div class="survey-line survey-heading-line no-ripple">
              <div class="survey-stat template-stat">
                <h4>Assessment</h4>
              </div>
              <div class="survey-stat template-stat">
                <h4>Sections</h4>
              </div>
              <div *ngIf="surveyGroup !== 'Upcoming'" class="survey-stat template-stat hide-on-mobile">
                <h4>Responses</h4>
              </div>
              <div *ngIf="surveyGroup === 'Available'" class="survey-stat template-stat">
                <h4>Create Draft</h4>
              </div>
              <div *ngIf="surveyGroup === 'Upcoming'" class="survey-stat template-stat">
                <h4>Publish Date</h4>
              </div>
              <div *ngIf="surveyGroup === 'Closed'" class="survey-stat template-stat">
                <h4>End Date</h4>
              </div>
            </div>

            <div *ngIf="!isLoadingMRR">
              <div *ngFor="let survey of mrrSurveys">
                <div class="survey-line">
                  <div class="survey-details-link" [routerLink]="['/mrr-survey-details', survey.id]">
                    <h3 class="grey-text center-vertical">
                      <!-- <span *ngIf="survey['filterName']" class="infoTag">{{survey['filterName'] | uppercase}}</span>
                    &nbsp;{{survey['title'].slice(0, 50) | unescape}} -->
                      {{survey['title'].slice(0, 50) | unescape}}
                      <span *ngIf="survey['title']?.length > 50">...</span>
                    </h3>
                  </div>

                  <div class="survey-details-link center-vertical" [routerLink]="['/mrr-survey-details', survey.id]">
                    <h1>{{survey['sectionHeading']?.length || '0' }}</h1>
                  </div>

                  <div *ngIf="surveyGroup !== 'Upcoming'" class="survey-details-link hide-on-mobile center-vertical"
                    [routerLink]="['/mrr-survey-details', survey.id]">
                    <h1>{{survey['activeResponseCount']}}</h1>
                  </div>

                  <div *ngIf="surveyGroup === 'Upcoming'" class="survey-details-link center-vertical"
                    [routerLink]="['/mrr-survey-details', survey.id]">
                    <h3>{{getDate(survey.startDate)}}</h3>
                  </div>

                  <div *ngIf="surveyGroup === 'Closed'" class="survey-details-link center-vertical"
                    [routerLink]="['/mrr-survey-details', survey.id]">
                    <h3>{{getDate(survey.endDate)}}</h3>
                  </div>

                  <div class="survey-details-link survey-button"
                    *ngIf="surveyGroup === 'Available' && survey['status'] === 'published'">
                    <button class="csa-button-solid" (click)="createMRR(survey)">
                      <span>
                        <fa-icon [icon]="faPlus"></fa-icon> Create
                      </span>
                    </button>
                  </div>
                </div>
              </div>

              <h3 class="none-found-message" *ngIf="mrrSurveys.length == 0">
                You currently have no {{surveyGroup}} Material Risk Review Assessments.
              </h3>
            </div>

            <div *ngIf="isLoadingMRR" class="loader-container">
              <div class="loading-wapple"></div>
            </div>

            <a
              [routerLink]="['/more-mrr-surveys', {status: surveyGroup === 'Available' ? 'published' : 'active', type: 'adhoc'}]">
              <button class="csa-button-solid">More</button>
            </a>
          </mat-tab>
        </mat-tab-group>
      </div>
    </ng-template>

    <!-- MRR Survey Assessment -->

    <!-- MRR Actions -->
    <ng-template #MRRActions>
      <div class="audit-tool-card list-screen-card" *ngIf="(user['division'] === 'Supermarkets')">
        <h1>Material Risk Review Actions</h1>
        <div class="survey-line survey-heading-line no-ripple">
          <div class="survey-stat template-stat">
            <h4>Action</h4>
          </div>
          <div class="survey-stat template-stat">
            <h4>Store</h4>
          </div>
          <div class="survey-stat template-stat">
            <h4>Assessment</h4>
          </div>
          <div class="survey-stat template-stat hide-on-mobile">
            <h4>Description</h4>
          </div>
        </div>

        <div *ngIf="!isLoadingActions">
          <div *ngFor="let action of mrrActions">
            <a class="survey-details-link" [routerLink]="['/mrr-action-details', action.id]">
              <div class="survey-line">
                <div class="survey-stat template-stat center-align">
                  <h4> {{action['title'].slice(0, 35) | unescape}}
                    <span *ngIf="(action.title.length > 35)">...</span>
                  </h4>
                  <p [ngClass]="{
                              'orange-text' : isDueInWeek(getDate(action.dueDate)) == true && action['status'] === 'pending',
                              'red-text' : isOverdue(getDate(action.dueDate)) == true && action['status'] === 'pending'
                              }">
                    Due: {{getDate(action.dueDate).slice(0, -4)}}
                  </p>
                </div>
                <div class="survey-stat template-stat">
                  <h4>{{action['storeID']}}</h4>
                </div>
                <div class="survey-stat template-stat">
                  <div>{{action['surveyTitle'] | unescape}}</div>
                </div>
                <div class="survey-stat template-stat hide-on-mobile">
                  <p>
                    {{action['description'].slice(0, 100) | unescape}}
                    <span *ngIf="(action.title.length > 100)">...</span>
                  </p>
                </div>
              </div>
            </a>
          </div>

          <h3 class="none-found-message" *ngIf="actions.length == 0">
            There are currently no Open Actions.
          </h3>
        </div>

        <div *ngIf="isLoadingActions" class="loader-container">
          <div class="loading-wapple"></div>
        </div>

        <a [routerLink]="['/mrr-actions-drill-down']">
          <button class="csa-button-solid">More</button>
        </a>
      </div>
    </ng-template>

    <!-- MRR Actions -->
  </div>
  <div class="app-version">{{dateFormatted}}</div>
</div>