/**
 * Checks if a given role name exists in the targetRoles array (case-sensitive)..
 *
 * @param {string[]} targetRoleNames - The array of role name strings to search within.
 * @param {string} roleName - The role name to check for in the array.
 * @returns {boolean} - Returns true if the role name is found, otherwise false.
 * @example
 * isTargetRole(["Admin", "MultiUser", "Super"], "Super"); // true
 * @example
 * isTargetRole(["Admin", "MultiUser", "Super"], "super"); // false
 * @example
 * isTargetRole(["Admin", "MultiUser", "Super"], "Store"); // false
 * @example
 * isTargetRole([], "Admin"); // false
 */
export function isTargetRoleName(targetRoleNames: string[], roleName: string): boolean {
  return targetRoleNames?.indexOf(roleName) > -1;
}
