import {
  Component,
  OnInit,
  ViewChildren,
  ElementRef,
  QueryList,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import {
  faChartPie, faPlus, faTable, faKey 
} from '@fortawesome/free-solid-svg-icons';
import { MatDialog } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import * as moment from 'moment';
import { Subject, takeUntil } from 'rxjs';
import { SurveysService } from 'src/app/surveys/surveys.service';
import { AdhocSurveyService } from 'src/app/adhoc-surveys/adhoc-survey.service';
import { RecurringSurveyService } from 'src/app/recurring-survey/recurring-survey.service';
import { MrrSurveysService } from 'src/app/mrr-surveys/mrr-surveys.service';
import { CsaBackEndService } from 'src/app/common-services/csa-back-end.service';
import { CsaAuthService } from 'src/app/auth/csa-auth.service';
import { reassignArrayPropertyValues } from 'src/app/utils/data';
import { getUserLocationsQueryString } from 'src/app/utils/user-roles/get-location-query-string';
import { splitRoleByCaps } from 'src/app/utils/user-roles/split-role-by-caps';
import { User } from '../../models/user';
import { StateService } from '../../common-services/state.service';
import { StoreSelectionDialogComponent } from '../../surveys/store-selection-dialog/store-selection-dialog.component';
import { MessagesComponent } from '../../surveys/messages/messages.component';
import { LoaderService } from '../../common-services/loader.service';

@Component({
  selector: 'app-above-store-landing',
  templateUrl: './above-store-landing.component.html',
  styleUrls: ['./above-store-landing.component.scss'],
})
export class AboveStoreLandingComponent implements OnInit {
  @ViewChild('recurringTabGroup') recurringTabGroup;

  @ViewChild('tabGroup') tabGroup;

  @ViewChildren('chartContainer') chartContainers: QueryList<ElementRef>;

  faPlus = faPlus;

  faChartPie = faChartPie;

  faTable = faTable;

  faKey = faKey;

  actions = [];

  onceOffSurveys = [];

  mrrActions = [];

  allTemplates = [];
  
  closedSurveys = [];

  date = moment();

  daysAvailable = '';

  filter = '';

  nextLevel = '';

  recurringSelectedGroup = 0;

  recurringSurveyGroups = ['Daily', 'Weekly', 'Monthly'];

  recurringSurveys = [];

  regularAssessmentGroups = ['Active', 'Upcoming'];

  regularSelectedGroup = 0;

  reportsQuery = '';

  surveyCompletion = '';

  adhocSurveyGroups = ['My Assessments', "Other's Assessments"];

  adhocSelectedGroup = 0;

  adhocSurveys = [];

  todaysDay = '0';

  upcomingSurveys = [];

  user: User;

  daysMap = new Map([
    ['0', 'Mondays'],
    ['1', 'Tuesdays'],
    ['2', 'Wednesdays'],
    ['3', 'Thursdays'],
    ['4', 'Fridays'],
    ['5', 'Saturdays'],
    ['6', 'Sundays'],
  ]);

  isLoadingOnceOff: boolean = false;

  isLoadingAdhoc: boolean = false;

  isLoadingRecurring: boolean = false;

  isLoadingActions: boolean = false;

  mrrSurveyGroups = ['Available', 'Upcoming', 'Closed'];

  mrrSelectedGroup = 0;

  mrrSurveys = [];

  isLoadingMRR: boolean = false;

  private onDestroy$ = new Subject<void>(); // used to unsubscribe from observables on component destroy

  constructor(
    private surveyCompletionData: LoaderService,
    private csaAuth: CsaAuthService,
    private csaBackEnd: CsaBackEndService,
    private actRoute: ActivatedRoute,
    private dialog: MatDialog,
    private router: Router,
    private state: StateService,
    private surveyService : SurveysService,
    private adhocService : AdhocSurveyService,
    private recurringService : RecurringSurveyService,
    private mrrService : MrrSurveysService

  ) {}

  ngOnInit(): void {
    this.surveyCompletionData.currentMessage.subscribe(
      (message) => (this.surveyCompletion = message)
    );
    this.user = this.csaAuth.user;
    if (this.user['role'] == 'StoreLossPartner') {
      this.adhocSurveyGroups = ['Current Assessments'];
    }

    this.csaAuth.userChanges$().pipe(takeUntil(this.onDestroy$)).subscribe((user) => {
      this.user = user;
    });

    this.getInitialFilters();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.state.origin = undefined;
    this.state.state = undefined;
  }

  goToReports() {
    switch (this.user['division']) {
      case 'Supermarkets':
        window.open(
          'https://datastudio.google.com/reporting/57f5fafb-e776-4ae1-8880-77f1a9a6b91d',
          '_blank'
        );
        break;
      case 'Metro':
        window.open(
          'https://datastudio.google.com/reporting/408f1c5c-da33-4a91-b75d-2a9c55d4ce78',
          '_blank'
        );
        break;
      case 'DC':
        window.open(
          'https://datastudio.google.com/reporting/3ce18dd9-a183-4e86-ac4f-f40dee5d8dd1',
          '_blank'
        );
        break;
      case 'CFC':
        window.open(
          'https://datastudio.google.com/reporting/74bbdae1-4592-466b-9a53-c8afb58cacc6',
          '_blank'
        );
        break;
      case 'Bigw':
        window.open(
          'https://datastudio.google.com/reporting/25e6c115-e482-479f-b9cd-5fb632d69436',
          '_blank'
        );
        break;
      case 'Woolworths NZ Stores':
        window.open(
          'https://datastudio.google.com/reporting/d99e6dd2-1316-4ace-a497-f691673205ce',
          '_blank'
        );
        break;
      case 'Woolworths NZ eStores':
        window.open(
          'https://datastudio.google.com/reporting/3308b33e-fb4f-497f-9325-a192606175d5',
          '_blank'
        );
        break;
      case 'Woolworths NZ Primary Connect':
        window.open(
          'https://datastudio.google.com/reporting/4cbb12f6-a497-49d5-a8b6-2f40f2c8ac88',
          '_blank'
        );
        break;
      default:
        break;
    }
  }

  getInitialFilters() {
    this.filter = getUserLocationsQueryString(this.csaAuth.user);
    if (
      this.user['role'] == 'StoreLossPartner'
        || this.user['role'] == 'StoreLossManager'
    ) {
      this.getStockLossSurveys();
      this.getActions();
      this.getMrrActions();
    } else {
      this.getOnceOffSurveys();
      this.getRecurringSurveys();
      this.getAdhocSurveys();
      this.getActions();
      this.getMrrActions();
      this.getMRRassessments();
    }
  }

  getOnceOffSurveys() {
    setTimeout(() => {
      this.isLoadingOnceOff = true;
      this.onceOffSurveys = [];
    }, 0);

    this.surveyService
      .getSurveys(
        '/above-store',
        this.user['division'] + this.filter,
        'published',
        '/0',
        '/5'
      )
      .subscribe(
        (surveysData) => {
          this.onceOffSurveys = surveysData['surveys'];
          this.isLoadingOnceOff = false;
        },
        (error) => {
          this.isLoadingOnceOff = false;
        }
      );
  }

  getAdhocSurveys() {
    setTimeout(() => {
      this.isLoadingAdhoc = true;
      this.adhocSurveys = [];
    }, 0);

    let targetRoles;
    if (this.adhocSelectedGroup == 1) targetRoles = 'other';
    else if (this.adhocSelectedGroup == 0) targetRoles == 'self';

    this.adhocService
      .getOnTheFlySurveytemplates(
        '',
        this.user['division'],
        'published',
        '/0',
        '/5',
        `adhoc&targetRoles=${targetRoles}${this.filter}`
      )
      .subscribe(
        (surveysData) => {
          // Reassign filterName property value from 'eitb' to 'gps'
          this.adhocSurveys = reassignArrayPropertyValues(surveysData['surveys'], 'filterName', 'eitb', 'GPS');               
          this.getAdhocUsers();
          this.isLoadingAdhoc = false;
        },
        (error) => {
          this.isLoadingAdhoc = false;
        }
      );
  }

  getStockLossSurveys() {
    setTimeout(() => {
      this.isLoadingAdhoc = true;
      this.adhocSurveys = [];
    }, 0);

    if (this.adhocSelectedGroup == 0) {
      this.adhocService
        .getOnTheFlySurveytemplates(
          '',
          this.user['division'],
          'published',
          '/0',
          '/5',
          `adhoc&targetRoles=self${this.filter}`
        )
        .subscribe(
          (surveysData) => {
            // Reassign filterName property value from 'eitb' to 'gps'
            this.adhocSurveys = reassignArrayPropertyValues(surveysData['surveys'], 'filterName', 'eitb', 'GPS');                 
            this.getAdhocUsers();
            this.isLoadingAdhoc = false;
          },
          (error) => {
            this.isLoadingAdhoc = false;
          }
        );
    }
  }

  getRecurringSurveys() {
    setTimeout(() => {
      this.isLoadingRecurring = true;
      this.recurringSurveys = [];
    }, 0);

    let frequency;
    if (this.recurringSelectedGroup == 2) frequency = 'monthly';
    else if (this.recurringSelectedGroup == 1) frequency = 'weekly';
    else if (this.recurringSelectedGroup == 0) frequency = 'daily';

    this.recurringService
      .getRecurringSurveys(
        this.user['division'] + this.filter,
        'published',
        '/0',
        '/5',
        `recurring&frequency=${frequency}`
      )
      .subscribe(
        (surveysData) => {
          this.recurringSurveys = surveysData['surveys'];
          this.isLoadingRecurring = false;
        },
        (error) => {
          this.isLoadingRecurring = false;
        }
      );
  }

  adhocTabChanged(tabChangeEvent: MatTabChangeEvent) {
    this.adhocSelectedGroup = tabChangeEvent.index;
    this.getAdhocSurveys();
  }

  recurringTabChanged(tabChangeEvent: MatTabChangeEvent) {
    this.recurringSelectedGroup = tabChangeEvent.index;
    this.getRecurringSurveys();
  }

  mrrTabChanged(tabChangeEvent: MatTabChangeEvent) {
    this.mrrSelectedGroup = tabChangeEvent.index;
    this.getMRRassessments();
  }

  getAdhocUsers() {
    this.adhocSurveys.forEach((adhocSurvey) => {
      // convert to UTC and then a specific format
      if (
        adhocSurvey['status'] == 'closed'
        && moment().isAfter(
          moment(adhocSurvey['endDate'], [
            'DD/MM/YYYY',
            'DD MMM YYYY',
            moment.ISO_8601,
          ]).format('YYYY-MM-DD')
        )
      ) {
        const maxEndDate = moment(adhocSurvey['maxEndDate'], [
          'DD/MM/YYYY',
          'DD MMM YYYY',
          moment.ISO_8601,
        ]).format('YYYY-MM-DD');
        adhocSurvey['daysToEdit'] = moment(maxEndDate).diff(moment(), 'days') + 1;
      }
      // adhocSurvey["nextTargetRoles"] = [];
      // adhocSurvey["targetRoles"].forEach((targetRole) => {
      //   switch (targetRole) {
      //     case "Store":
      //       if (this.user["division"] == "DC") {
      //         adhocSurvey["nextTargetRoles"].push("DCs");
      //       } else {
      //         adhocSurvey["nextTargetRoles"].push("Stores");
      //       }
      //       break;
      //     case "MultiStore":
      //       if (this.user["division"] == "DC") {
      //         adhocSurvey["nextTargetRoles"].push("Multi DC Users");
      //       } else {
      //         adhocSurvey["nextTargetRoles"].push("Multi Store Users");
      //       }
      //       break;
      //     case "GroupManager":
      //       adhocSurvey["nextTargetRoles"].push("Group Managers");
      //       break;
      //     case "OperationManager":
      //       adhocSurvey["nextTargetRoles"].push("Operations Managers");
      //       break;
      //     case "StateManager":
      //       adhocSurvey["nextTargetRoles"].push("State Managers");
      //       break;
      //     case "Admin":
      //       adhocSurvey["nextTargetRoles"].push("Admin Users");
      //       break;
      //     case "RegionManager":
      //       adhocSurvey["nextTargetRoles"].push("Region Managers");
      //       break;
      //     case "AreaManager":
      //       adhocSurvey["nextTargetRoles"].push("Area Managers");
      //       break;
      //     case "StoreLossPartner":
      //       adhocSurvey["nextTargetRoles"].push("Store Loss Partners");
      //       break;
      //     case "StoreLossManager":
      //       adhocSurvey["nextTargetRoles"].push("Store Loss Managers");
      //       break;
      //     case "NationalView":
      //       adhocSurvey["nextTargetRoles"].push("National View User");
      //       break;
      //     default:
      //       break;
      //   }
      // });
    });
  }

  getMRRassessments() {
    setTimeout(() => {
      this.isLoadingMRR = true;
      this.mrrSurveys = [];
    }, 0);

    let status;
    if (this.mrrSelectedGroup == 1) {
      status = 'active';
    } else if (this.mrrSelectedGroup == 2) {
      status = 'closed';
    } else if (this.mrrSelectedGroup == 0) {
      status = 'published';
    }

    this.mrrService
      .getmrrSurveys(
        '',
        this.user['division'] + this.filter,
        status,
        '/0',
        '/5',
        'adhoc',
        'mrr'
      )
      .subscribe(
        (surveysData) => {
          this.mrrSurveys = surveysData['surveys'];
          console.log('mrr Surveys');
          console.log(this.mrrSurveys);
          this.isLoadingMRR = false;
        },
        (error) => {
          this.isLoadingMRR = false;
        }
      );
  }

  // Exposes global function splitRoleByCaps to template
  splitRoleByCaps(value): string {
    return splitRoleByCaps(value, this.user['division']);
  }

  getActions() {
    setTimeout(() => {
      this.isLoadingActions = true;
      this.actions = [];
    });

    this.surveyService
      .getActionList(
        '/0',
        '/5',
        `?status=pending&division=${this.user['division']}${this.filter}`
      )
      .subscribe(
        (actionsData) => {
          if (actionsData['actions']) {
            this.actions = actionsData['actions'];
          }
          this.isLoadingActions = false;
        },
        (error) => {
          this.isLoadingActions = false;
        }
      );
  }

  getMrrActions() {
    setTimeout(() => {
      this.isLoadingActions = true;
      this.actions = [];
    }, 0);
    this.surveyService
      .getActionList(
        '/0',
        '/5',
        `?status=pending&division=${this.user['division']}&filterName=mrr${this.filter}`
      )
      .subscribe(
        (actionsData) => {
          if (actionsData['actions']) {
            this.mrrActions = actionsData['actions'];
            console.log('test mrr actions');
            console.log(this.mrrActions);
          }
          this.isLoadingActions = false;
        },
        (error) => {
          this.isLoadingActions = false;
        }
      );
  }

  surveyDetails(message: string) {
    this.surveyCompletionData.changeMessage(message);
  }

  createOnTheFlySurvey(surveyID) {
    const storeSelectDialog = this.dialog.open(StoreSelectionDialogComponent, {
      backdropClass: 'dialog-backdrop',
      minWidth: '70%',
      maxWidth: '90vw',
      height: 'auto',
      data: {
        allowClose: true,
        message:
          'Please select the store you are completing this Adhoc Assessment for.',
      },
    });
    storeSelectDialog.afterClosed().subscribe((result) => {
      if (result['storeNo']) {
        const adhocAssessment = {};
        adhocAssessment['storeID'] = result['storeNo'];
        adhocAssessment['survey_id'] = surveyID;
        this.surveyService
          .getAboveStoreSurveyID(
            surveyID,
            `?storeID=${adhocAssessment['storeID']}`
          )
          .subscribe((surveyDetails) => {
            if (surveyDetails['activeResponseCount'] > 0) {
              const confirmMultipleDialog = this.dialog.open(MessagesComponent, {
                data: {
                  heading: 'Active Responses',
                  message:
                    `You currently have ${ 
                      surveyDetails['activeResponseCount']
                    } Active Responses for this Store & Assessment. Are you sure you would like to create a new one?`,
                  closeText: 'Create',
                  cancelText: 'See Active Responses',
                },
                backdropClass: 'dialog-backdrop',
              });
              confirmMultipleDialog.afterClosed().subscribe((result) => {
                if (result == 'logout') {
                  this.adhocService
                    .createOnTheFlySurvey(adhocAssessment)
                    .subscribe((reply) => {
                      if (reply['status'] == 200) {
                        const dialogSuccess = this.dialog.open(
                          MessagesComponent,
                          {
                            data: {
                              heading: 'Adhoc Assessment Created',
                              closeText: 'OK',
                              message:
                                'Adhoc Assessment has been created. Please click ok to go to the Adhoc Assessment, and begin completing it',
                            },
                            disableClose: true,
                            backdropClass: 'dialog-backdrop',
                          }
                        );
                        dialogSuccess.afterClosed().subscribe((result) => {
                          // window.location.reload();
                          this.router.navigate([
                            '/assessment-response-edit',
                            reply['response_id'],
                          ]);
                        });
                      } else {
                        this.dialog.open(MessagesComponent, {
                          data: {
                            heading: "Couldn't Schedule Adhoc Assessment",
                            message:
                              `Please email ${ 
                                this.user['supportEmail']
                              } for support`,
                          },
                          disableClose: true,
                          backdropClass: 'dialog-backdrop',
                        });
                      }
                    });
                } else {
                  this.router.navigate(['/onthefly-survey-details', surveyID]);
                }
              });
            } else {
              this.adhocService
                .createOnTheFlySurvey(adhocAssessment)
                .subscribe((reply) => {
                  if (reply['status'] == 200) {
                    const dialogSuccess = this.dialog.open(MessagesComponent, {
                      data: {
                        heading: 'Adhoc Assessment Created',
                        closeText: 'OK',
                        message:
                          'Adhoc Assessment has been created. Please click ok to go to the Adhoc Assessment, and begin completing it',
                      },
                      disableClose: true,
                      backdropClass: 'dialog-backdrop',
                    });
                    dialogSuccess.afterClosed().subscribe((result) => {
                      // window.location.reload();
                      this.router.navigate([
                        '/assessment-response-edit',
                        reply['response_id'],
                      ]);
                    });
                  } else {
                    this.dialog.open(MessagesComponent, {
                      data: {
                        heading: "Couldn't Schedule Adhoc Assessment",
                        message:
                          `Please email ${ 
                            this.user['supportEmail']
                          } for support`,
                      },
                      disableClose: true,
                      backdropClass: 'dialog-backdrop',
                    });
                  }
                });
            }
          });
      } else {
        this.dialog.open(MessagesComponent, {
          data: {
            heading: 'Adhoc Assessment Cancelled',
            message:
              'You need to select a store for your Adhoc Assessment before starting.',
          },
          disableClose: true,
          backdropClass: 'dialog-backdrop',
        });
      }
    });
  }

  isDueInWeek(date) {
    const m = moment(date, ['DD/MM/YYYY', 'DD MMM YYYY', moment.ISO_8601]);
    const currentDate = moment();
    const isDueInWeek = currentDate.diff(m, 'days') > -7 && currentDate.diff(m, 'days') <= 0;
    return isDueInWeek;
  }

  isOverdue(date) {
    const m = moment(date, ['DD/MM/YYYY', 'DD MMM YYYY', moment.ISO_8601]);
    const currentDate = moment();
    return m < currentDate;
  }

  getDate(date) {
    date = moment(date, ['DD/MM/YYYY', 'DD MMM YYYY', moment.ISO_8601]).format(
      'DD MMM YYYY'
    );
    return date;
  }
}
