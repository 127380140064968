export enum OnceOffTabType {
  ActiveForAdmin,
  UpcomingForAdmin,
  ClosedForAdmin,
  AllForAdmin,
  ActiveForAboveStore,
  ClosedForAboveStore,
  UnOpenedForStore,
  StartedForStore,
  SubmittedForStore,
  ClosedForStore,
  AllForStore,
}
