<div class="page-container">
  <h1>Once Off Assessments</h1>
  
  <div class="audit-tool-cards-container">
    <div class="audit-tool-card more-list-screen-card">
      <mat-tab-group #tabGroup dynamicHeight="false" mat-align-tabs="center" [(selectedIndex)]="selectedGroup" (selectedTabChange)="tabChanged($event)">
        <mat-tab label="{{surveyGroup}}" *ngFor="let surveyGroup of surveyGroups">
          <table mat-table [dataSource]="surveysToShow">
            <ng-container matColumnDef="survey">
              <th mat-header-cell *matHeaderCellDef class="survey-details-link center-align"> 
                <h4>Assessment</h4> 
              </th>
              <td mat-cell *matCellDef="let survey" class="survey-details-link center-align">
                <h4>{{survey['title'].slice(0, 50) | unescape}}<span *ngIf='survey.title.length > 50'>...</span></h4>
                <p [ngClass]="{'red-text' : survey['isDueInWeek'] == true}">Due: {{getDate(survey.dueDate).slice(0, -4)}}</p>
              </td>
            </ng-container>

            <ng-container matColumnDef="percentComplete">
              <th mat-header-cell *matHeaderCellDef class="survey-details-link center-align"> 
                <h4>% Complete</h4> 
              </th>
              <td mat-cell *matCellDef="let survey" class="survey-details-link center-align">
                <div class="float-up">
                  <h1 [ngClass]="{
                    'light-green': survey['surveyState'] === 'Complete',
                    'light-blue' : survey['surveyState'] === 'Continue'
                  }">{{ survey['completion'] | percent}}</h1>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef class="survey-details-link center-align">
                <h4>Status</h4>  
              </th>
              <td mat-cell *matCellDef="let survey" class="survey-details-link center-align">
                <div class="survey-button">
                  <a [ngClass]="{'disabled-link' : survey['status'] === 'submitted' || survey['surveyState'] === 'Closed'}">
                    <button class="csa-button-solid" [ngClass]="{'disabled-btn' : survey['status'] === 'submitted' || survey['surveyState'] === 'Closed'}" (click)="startSurvey(survey.id, $event)">
                      <fa-icon class="chip-left-icon" *ngIf="!(survey['surveyState'] === 'Submitted' || survey['surveyState'] === 'Closed')" [icon]="faPlayCircle"></fa-icon>
                      <span>{{survey['surveyState']}}</span>
                    </button>
                  </a>
                </div>
              </td>
            </ng-container>

            <mat-header-row *matHeaderRowDef="tableColumns"  class="survey-heading-line"></mat-header-row>
            <mat-row *matRowDef="let survey; columns: tableColumns;" [routerLink]="['/assessment-response-view', survey.id]"
              (click)="saveState = true;" [ngClass]="{'highlighted' : survey['status'] === 'active'}"
              class="survey-line survey-line-link"></mat-row>
          </table>

          <div *ngIf="isLoading" class="loader-container">
            <div class="loading-wapple"></div>
          </div>
    
          <div *ngIf="surveysToShow.length == 0 && !isLoading">            
            <h3 class="none-found-message">
              You currently have no {{surveyGroup}} Assessments.
            </h3>
          </div>
        </mat-tab>
      </mat-tab-group>
      <mat-paginator #paginator 
          [pageIndex]=pageIndex 
          [pageSize]=pageSize 
          [length]="totalCount"
          [pageSizeOptions]="[5, 10, 25, 100]" 
          [showFirstLastButtons]=false 
          (page)="getNext($event)">
      </mat-paginator>
    </div>
  </div>
</div>


