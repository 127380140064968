import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';

import {
  faCheckSquare, faPlus, faPlayCircle, faSortDown 
} from '@fortawesome/free-solid-svg-icons';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import * as moment from 'moment';
import { Subject, takeUntil } from 'rxjs';
import { SurveysService } from 'src/app/surveys/surveys.service';
import { AdhocSurveyService } from 'src/app/adhoc-surveys/adhoc-survey.service';
import { getUserLocationsQueryString } from 'src/app/utils/user-roles/get-location-query-string';
import { FeatureAccessService } from 'src/app/common-services/feature-access.service';
import { createTagList } from 'src/app/utils/create-tag-list/create-tag-list';
import { MessagesComponent } from '../../surveys/messages/messages.component';
import { StateService } from '../../common-services/state.service';
import { StoreSelectionDialogComponent } from '../../surveys/store-selection-dialog/store-selection-dialog.component';
import { User } from '../../models/user';
import { MrrSurveysService } from '../mrr-surveys.service';
import { CsaAuthService } from '../../auth/csa-auth.service';

@Component({
  selector: 'app-more-mrr-surveys',
  templateUrl: './more-mrr-surveys.component.html',
  styleUrls: ['./more-mrr-surveys.component.scss']
})
export class MoreMrrSurveysComponent implements OnInit, OnDestroy {
  @ViewChild(MatTabGroup) tabGroup: MatTabGroup;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  faCheckSquare = faCheckSquare;

  faPlus = faPlus;

  faSortDown = faSortDown;

  faPlayCircle = faPlayCircle;

  filter: string = '';

  hasNext: string;

  index: number = 0;

  pageIndex: number = 0;

  pageSize: number = 10;

  saveState: boolean = false;

  selectedGroup: number = 0;

  surveyGroups: string[] = [];

  surveysToShow: any[] = [];

  tableColumns: string[];

  totalCount: number = 0;

  user: User;

  isLoading: boolean = false;

  public viewAllAvailable = false;

  public viewAllUpcoming = false;

  public viewAllClosed = false;
  
  public viewAvailableResponsesForAssignedStore = false;

  public viewClosedResponsesForAssignedStore = false;

  public createDraftResponse = false;

  public showNotAssignedToStoreMessage = false;

  public assignedStoreResponses = false;
  
  public viewTags = false;

  private onDestroy$ = new Subject<void>(); // used to unsubscribe from observables on component destroy

  constructor(
    private csaAuth: CsaAuthService,
    private dialog: MatDialog,
    private elementRef: ElementRef,
    private router: Router,
    private state: StateService,
    private surveyService : SurveysService,
    private mrrService : MrrSurveysService,
    private adhocService : AdhocSurveyService,
    private featureAccessService: FeatureAccessService,

  ) { }

  ngOnInit(): void {
    this.getPermissions();
    this.user = this.csaAuth.user;

    this.csaAuth.userChanges$().pipe(takeUntil(this.onDestroy$)).subscribe((user) => {
      this.user = user;
    });

    if (this.state.state && this.state.origin == 'more-mrr-surveys') {
      for (const key in this.state.state) {
        this[key] = this.state.state[key];
      }
    }

    if (this.viewAllAvailable) {
      this.surveyGroups.push('Available');
    }
    if (this.viewAvailableResponsesForAssignedStore) {
      this.surveyGroups.push('Available Responses');
    }
    if (this.viewAllUpcoming) {
      this.surveyGroups.push('Upcoming');
    }
    if (this.viewAllClosed) {
      this.surveyGroups.push('Closed');
    }
    if (this.viewClosedResponsesForAssignedStore) {
      this.surveyGroups.push('Closed Responses');
    }

    this.getMRRAssessments();
  }

  getPermissions() {
    this.viewAllAvailable = this.featureAccessService.hasAccess('mrrAssessments.viewAllAvailable');
    this.viewAllUpcoming = this.featureAccessService.hasAccess('mrrAssessments.viewAllUpcoming');    
    this.viewAllClosed = this.featureAccessService.hasAccess('mrrAssessments.viewAllClosed');
    this.viewAvailableResponsesForAssignedStore = this.featureAccessService.hasAccess('mrrAssessments.viewAvailableResponsesForAssignedStore');
    this.viewClosedResponsesForAssignedStore = this.featureAccessService.hasAccess('mrrAssessments.viewClosedResponsesForAssignedStore');
    this.createDraftResponse = this.featureAccessService.hasAccess('mrrAssessments.createDraftResponse');
    this.viewTags = this.featureAccessService.hasAccess('tags.view');
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.setState();
  }

  setState() {
    if (this.saveState) {
      this.state.origin = 'more-mrr-surveys';
      this.state.state = {
        filter: this.filter,
        hasNext: this.hasNext,
        index: this.index,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        selectedGroup: this.selectedGroup,
        surveyGroups: this.surveyGroups,
        surveysToShow: this.surveysToShow,
        tableColumns: this.tableColumns,
        totalCount: this.totalCount,
      };
    } else {
      this.state.origin = undefined;
      this.state.state = undefined;
    }
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent) {
    this.paginator.pageIndex = 0;
    this.pageIndex = 0;
    this.index = 0;
    this.pageSize = this.paginator.pageSize;
    this.selectedGroup = tabChangeEvent.index;

    this.getMRRAssessments();
  }

  getNext(event: PageEvent) {
    this.index = event.pageIndex * event.pageSize;
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;

    this.getMRRAssessments();
  }

  getMRRAssessments() {
    setTimeout(() => {
      this.showNotAssignedToStoreMessage = false;
      this.isLoading = true;
      this.surveysToShow = [];
    }, 0);

    let status;
    this.assignedStoreResponses = false;
    if (this.surveyGroups[this.selectedGroup] == 'Available') {
      this.filter = getUserLocationsQueryString(this.csaAuth.user);
      status = 'published';
    } else if (this.surveyGroups[this.selectedGroup] == 'Available Responses') {
      status = 'active&status=started';
      this.assignedStoreResponses = true;
    } else if (this.surveyGroups[this.selectedGroup] == 'Upcoming') {
      this.filter = getUserLocationsQueryString(this.csaAuth.user);
      status = 'active';
    } else if (this.surveyGroups[this.selectedGroup] == 'Closed') {
      this.filter = getUserLocationsQueryString(this.csaAuth.user);
      status = 'closed';
    } else if (this.surveyGroups[this.selectedGroup] == 'Closed Responses') {
      status = 'closed';
      this.assignedStoreResponses = true;
    }

    if (!this.assignedStoreResponses) {
      this.mrrService
        .getmrrSurveys(
          '',
          this.user['division'],
          status,
          `/${this.index}`,
          `/${this.pageSize}`,
          'adhoc',
          `mrr${this.filter}`
        )
        .subscribe(
          (surveysData) => {
            this.surveysToShow = createTagList(surveysData['surveys'], this.viewTags);
            this.hasNext = surveysData['has_next'];
            this.getAdhocUsers();
            this.getPagination();
            this.isLoading = false;
          },
          (error) => {
            this.isLoading = false;
          }
        );
    } else if (this.user['storeID']) {
      this.surveyService
        .getStoreSurveys(
          `?status=${status
          }&division=${
            this.user['division']
          }&storeID=${
            this.user['storeID']}&type=adhoc&filterName=mrr`,
          `/${this.index}`,
          `/${this.pageSize}`
        )
        .subscribe(
          (surveysData) => {
            this.surveysToShow = surveysData['surveys'];
            this.hasNext = surveysData['has_next'];
            this.getAdhocUsers();
            this.getPagination();
            this.getMrrStatus();
            this.isLoading = false;
          },
          (error) => {
            this.isLoading = false;
          }
        );
    } else {
      setTimeout(() => {
        this.showNotAssignedToStoreMessage = true;
        this.isLoading = false;
        this.surveysToShow = [];
        this.hasNext = 'N';      
      }, 0);
    }
  }

  getPagination() {
    const label = this.elementRef.nativeElement.querySelector(
      '.mat-paginator-range-label'
    );
    if (label) {
      if (this.surveysToShow.length == 0) {
        label.innerHTML = 'No results to show';
        this.totalCount = 0;
      } else {
        const upToCount = this.index + this.surveysToShow.length;

        if (this.hasNext == 'Y') {
          label.innerHTML = `${String(this.index + 1)} to ${upToCount} of Many`;
          this.totalCount = upToCount + 1;
        } else {
          label.innerHTML = `${String(this.index + 1)} to ${upToCount} of ${upToCount}`;
          this.totalCount = upToCount;
        }
      }
    }
  }

  getAdhocUsers() {
    this.surveysToShow.forEach((adhocSurvey) => {
      // convert to UTC and then a specific format
      if (
        adhocSurvey['status'] == 'closed'
        && moment().isAfter(
          moment(adhocSurvey['endDate'], [
            'DD/MM/YYYY',
            'DD MMM YYYY',
            moment.ISO_8601,
          ]).format('YYYY-MM-DD')
        )
      ) {
        const maxEndDate = moment(adhocSurvey['maxEndDate'], [
          'DD/MM/YYYY',
          'DD MMM YYYY',
          moment.ISO_8601,
        ]).format('YYYY-MM-DD');
        adhocSurvey['daysToEdit'] = moment(maxEndDate).diff(moment(), 'days') + 1;
      }
      adhocSurvey['final'] = moment()
        > moment(adhocSurvey['endDate'], [
          'DD/MM/YYYY',
          'DD MMM YYYY',
          moment.ISO_8601,
        ]).add(adhocSurvey['surveyValidity'], 'days');
      // adhocSurvey['final'] = adhocSurvey['daysToEdit']<=0;
      // adhocSurvey["nextTargetRoles"] = [];
      //   adhocSurvey["targetRoles"].forEach((targetRole) => {
      //     switch (targetRole) {
      //       case "Store":
      //         if (this.user["division"] == "DC") {
      //           adhocSurvey["nextTargetRoles"].push("DCs");
      //         } else {
      //           adhocSurvey["nextTargetRoles"].push("Stores");
      //         }
      //         break;
      //       case "MultiStore":
      //         if (this.user["division"] == "DC") {
      //           adhocSurvey["nextTargetRoles"].push("Multi DC Users");
      //         } else {
      //           adhocSurvey["nextTargetRoles"].push("Multi Store Users");
      //         }
      //         break;
      //       case "GroupManager":
      //         adhocSurvey["nextTargetRoles"].push("Group Managers");
      //         break;
      //       case "OperationManager":
      //         adhocSurvey["nextTargetRoles"].push("Operations Managers");
      //         break;
      //       case "StateManager":
      //         adhocSurvey["nextTargetRoles"].push("State Managers");
      //         break;
      //       case "Admin":
      //         adhocSurvey["nextTargetRoles"].push("Admin Users");
      //         break;
      //       case "RegionManager":
      //         adhocSurvey["nextTargetRoles"].push("Region Managers");
      //         break;
      //       case "AreaManager":
      //         adhocSurvey["nextTargetRoles"].push("Area Managers");
      //         break;
      //       case "StoreLossPartner":
      //         adhocSurvey["nextTargetRoles"].push("Store Loss Partners");
      //         break;
      //       case "StoreLossManager":
      //         adhocSurvey["nextTargetRoles"].push("Store Loss Managers");
      //         break;
      //       case "StockLossPartner":
      //         adhocSurvey["nextTargetRoles"].push("Store Loss Partners");
      //         break;
      //       case "NationalView":
      //         adhocSurvey['nextTargetRoles'].push("National View Users");
      //         break;
      //       default:
      //         break;
      //     }
      //   });
    });
  }

  createMRR(survey) {
    console.log('create MRR');
    const storeSelectDialog = this.dialog.open(StoreSelectionDialogComponent, {
      backdropClass: 'dialog-backdrop',
      minWidth: '70%',
      maxWidth: '90vw',
      height: 'auto',
      data: {
        allowClose: true,
        message:
          'Please select the store you are completing this Material Risk Review Assessment for.',
        surveyTitle: survey['title'],
        sections: survey['sectionHeading'],
        startDate: survey['startDate'],
        filterName: 'mrr',
      },
    });
    storeSelectDialog.afterClosed().subscribe((result) => {
      if (result['storeNo']) {
        const mrrAssessment = {};
        mrrAssessment['storeID'] = result['storeNo'];
        mrrAssessment['survey_id'] = survey['id'];
        this.surveyService
          .getAboveStoreSurveyID(
            survey['id'],
            `?storeID=${mrrAssessment['storeID']}`
          )
          .subscribe((surveyDetails) => {
            if (surveyDetails['activeResponseCount'] > 0) {
              const confirmMultipleDialog = this.dialog.open(MessagesComponent, {
                data: {
                  heading: 'Active Responses',
                  message:
                    `You currently have ${ 
                      surveyDetails['activeResponseCount']
                    } Active Responses for this Store & Assessment.`,
                  closeText: 'See Active Responses',
                  // cancelText: "See Active Responses",
                },
                backdropClass: 'dialog-backdrop',
              });
              confirmMultipleDialog.afterClosed().subscribe((result) => {
                if (result == 'logout') {
                  this.router.navigate(['/mrr-survey-details', survey['id']]);
                }
              });
            } else {
              this.adhocService
                .createOnTheFlySurvey(mrrAssessment)
                .subscribe((reply) => {
                  if (reply['status_message'] == 'Already created') {
                    this.dialog.open(MessagesComponent, {
                      data: {
                        heading: 'You Have Already Responded',
                        closeText: 'OK',
                        message:
                        'You have already created response for this store. No another response can create for this particular store.',
                      },
                      disableClose: true,
                      backdropClass: 'dialog-backdrop',
                    });
                  } else if (reply['status'] == 200) {
                    const dialogSuccess = this.dialog.open(MessagesComponent, {
                      data: {
                        heading: 'Material Risk Review Assessment Created',
                        closeText: 'OK',
                        message:
                          'Material Risk Review Assessment has been created. Please click ok to go to the Material Risk Review Assessment, and begin completing it',
                      },
                      disableClose: true,
                      backdropClass: 'dialog-backdrop',
                    });
                    dialogSuccess.afterClosed().subscribe((result) => {
                      // window.location.reload();
                      this.router.navigate([
                        '/mrr-response',
                        reply['response_id'],
                      ]);
                    });
                  } else {
                    this.dialog.open(MessagesComponent, {
                      data: {
                        heading: "Couldn't Schedule Material Risk Review Assessment",
                        message:
                          `Please email ${ 
                            this.user['supportEmail']
                          } for support`,
                      },
                      disableClose: true,
                      backdropClass: 'dialog-backdrop',
                    });
                  }
                });
            }
          });
      } else {
        this.dialog.open(MessagesComponent, {
          data: {
            heading: 'Material Risk Review Assessment Cancelled',
            message:
              'You need to select a store for your Material Risk Review Assessment before starting.',
          },
          disableClose: true,
          backdropClass: 'dialog-backdrop',
        });
      }
    });
  }

  getMrrStatus() {
    for (let i = 0; i < this.surveysToShow.length; i++) {
      if (this.surveysToShow[i]['finalReportDate']) {
        this.surveysToShow[i].mrrStatus = 'Final';
      } else {
        this.surveysToShow[i].mrrStatus = 'Draft';
      }
    }

    for (let i = 0; i < this.surveysToShow.length; i++) {
      const status = this.surveysToShow[i]['status'];
      if (status == 'started') {
        this.surveysToShow[i].mrrStatusBtn = 'Continue';
      } else if (status == 'active') {
        this.surveysToShow[i].mrrStatusBtn = 'Start';
      } else {
        this.surveysToShow[i].mrrStatusBtn = 'Closed';
      }
    }
  }
}
