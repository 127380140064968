import {
  Component, Inject, OnInit,
  AfterViewInit,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  faAngleDown,
  faAngleUp,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import {
  FormControl, FormGroup, Validators
} from '@angular/forms';
import { SharedFunctionService } from 'src/app/shared/shared-service/shared-function.service';
import { FeatureAccessService } from 'src/app/common-services/feature-access.service';
import { MrrVisitNote } from '../../models/mrr-visit-note';
import { CsaAuthService } from '../../auth/csa-auth.service';

@Component({
  selector: 'app-visit-notes-dialog',
  templateUrl: './visit-notes-dialog.component.html',
  styleUrls: ['./visit-notes-dialog.component.scss']
})
export class VisitNotesDialogComponent implements OnInit, AfterViewInit {
  faAngleDown = faAngleDown;

  faAngleUp = faAngleUp;

  mrrForm: FormGroup;

  confirmText = 'Close';

  idleState = '';

  notes: any;

  faTimes = faTimes;

  surveyDetails: any;

  status: any;

  user: object;

  isVisitNotesExpanded: boolean = false;

  allExpandState: boolean = true; // expansion panel open and close param

  viewNotesAvailable: boolean = false;

  canUserAddVisitNotes: boolean = false;

  visitNotesState: object[] = [];

  readonly MAX_NUMBER_OF_LINES = 5;

  constructor(
    private csaAuthService: CsaAuthService,
    private sharedFunction: SharedFunctionService,
    private dialogRef: MatDialogRef<VisitNotesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public featureAccessService: FeatureAccessService
  ) {
    if (data['closeText']) {
      this.confirmText = data['closeText'];
    }

    if (data.message.indexOf('@') == -1) {
      data.message = data.message.replace('https://', '').replace('email', 'visit');
    }
  }

  ngOnInit(): void {
    this.user = this.csaAuthService.user;
    this.status = this.data.surveyDetails['status'];
    this.surveyDetails = this.data.surveyDetails['visitNotes'];
    this.checkVisitNotesVisibility();
    this.mrrForm = new FormGroup({
      notes: new FormControl('', [
        Validators.required,
        this.sharedFunction.emptyStringValidator.bind(this)
      ]),

    });
    this.checkIfUserCanAddVisitNotes();
    this.initialiseVisitNotesState();
  }
  
  ngAfterViewInit(): void {
    setTimeout(() => { // This avoids the ExpressionChangedAfterItHasBeenCheckedError error when toggling exceededMaxNumberOfLines
      for (let i = 0; i < this.surveyDetails?.length; i++) {
        this.checkClipping(i);
      }
    });
  }
  
  initialiseVisitNotesState() {
    for (let i = 0; i < this.surveyDetails?.length; i++) {
      const state = {};
      state['showMore'] = true;
      state['isClipped'] = false;
      state['numberOfLines'] = 0;
      state['exceededMaxNumberOfLines'] = false;
      this.visitNotesState.push(state);
    }
  }

  toggleShowMoreLess(index: number) {
    this.visitNotesState[index]['showMore'] = !this.visitNotesState[index]['showMore'];
  }

  checkNumberOfLines(index: number): void {
    const container = document.getElementById(`textContainer${index}`);
    if (this.visitNotesState[index]['isClipped']) {
      this.visitNotesState[index]['numberOfLines'] = 100;
      this.visitNotesState[index]['exceededMaxNumberOfLines'] = true;
      return;
    }

    // Get the computed style of the container
    const computedStyle = window.getComputedStyle(container);
    const lineHeight = this.getLineHeight(computedStyle);
    const containerHeight = container.clientHeight;

    // Calculate the number of lines
    this.visitNotesState[index]['numberOfLines'] = Math.floor(containerHeight / lineHeight);
    this.visitNotesState[index]['exceededMaxNumberOfLines'] = this.visitNotesState[index]['numberOfLines'] > this.MAX_NUMBER_OF_LINES;
  }

  getLineHeight(style: CSSStyleDeclaration): number {
    const lineHeight = parseFloat(style.lineHeight);

    // If 'line-height' is 'normal', fallback to 1.2 * font size
    if (isNaN(lineHeight)) {
      const fontSize = parseFloat(style.fontSize);
      return fontSize * 1.2; // Default line-height multiplier
    }

    return lineHeight;
  }

  checkClipping(index: number): void {
    const container = document.getElementById(`textContainer${index}`);
    this.visitNotesState[index]['isClipped'] = container.scrollHeight > container.clientHeight;
    this.checkNumberOfLines(index);
  }

  private checkIfUserCanAddVisitNotes() {
    // At this point we have only converted mrr to use subfeature checks, so if the assessment type isn't MRR - use the old role based check
    if (this.data.assessmentType === 'mrr') {
      if (this.featureAccessService.hasAccess('mrrAssessments.viewAndCreateVisitNotes')) {
        this.canUserAddVisitNotes = true;
      } else {
        this.canUserAddVisitNotes = false;
      }
    } else {
      this.canUserAddVisitNotes = this.user['role'] === 'Admin' || this.user['role'] === 'StateManager' || this.user['role'] === 'OperationManager' || this.user['role'] === 'GroupManager';
    }
  }

  checkVisitNotesVisibility() {
    if (this.sharedFunction.isVisitNoteAdded(this.data.surveyDetails)) {
      this.viewNotesAvailable = true;
    }
  }

  closeMe() {
    this.dialogRef.close();
  }

  /**
   * Saves notes.
   * 
   * @param value - Having the details of visit notes
   * auto updating visit notes.
   */
  saveNotes(value: MrrVisitNote) {
    if (value.notes) {
      this.notes = value.notes;
    }
  }

  /**
   * Sharing visit note details to parent component
   * closing dialog box after collecting visit note.
   *
   * @param value - The note.
   */
  sendVisitNote(value: MrrVisitNote) {
    this.notes = value.notes;
    this.dialogRef.close({ type: 'logout', notes: this.notes });
  }
}
