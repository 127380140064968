import { User } from 'src/app/models/user';
import { getLocationsOrdered } from './get-locations-ordered';

/**
 * Checks if the user has a position higher than the store level in the org structure.
 * 
 * @param {User | undefined} user  - User - The user object containing org structure information.
 * @param {object} user.orgStruct - The org structure of the user.
 * @param {string} [user.storeID] - The store ID associated with the user, if any.
 * @returns {boolean} - Returns `true` if the user is higher than store level, otherwise `false`.
 * @example
 * const user = {
 *   orgStruct: {...},
 *   storeID: 'store123'
 * };
 * const isHigher = isHigherThanStoreLevelUser(user);
 * console.log(isHigher); // Output: false
 */
export function isHigherThanStoreLevelUser(user: User): boolean {
  if (!user || !user.orgStruct) {
    return false; // false when no user or org structure information provided
  }
  const locationsOrdered = getLocationsOrdered(user.orgStruct);
  const storeIndex = locationsOrdered.indexOf('storeID');
  if (storeIndex === -1) {
    return true; // Assume higher than store level user when no store node exists
  }
  if (storeIndex === 0) {
    return false; // Store node should not be the first org node. This is defensive programming.
  }
  // Store is not the top of the org structure here
  return !user.storeID; // true when user is not associated with a store
}
