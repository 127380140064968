import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import * as moment from 'moment';
import { createTagList } from 'src/app/utils/create-tag-list/create-tag-list';
import { addTargetedToAddToTagList } from 'src/app/utils/add-targeted-to-add-to-target-list/add-targeted-to-add-to-target-list';
import { addStatusToAddToTagList } from 'src/app/utils/add-status-to-add-to-target-list/add-status-to-add-to-target-list';
import { FeatureAccessService } from 'src/app/common-services/feature-access.service';
import { CreateSurveyDialogComponent } from '../create-survey-dialog/create-survey-dialog.component';
import { CsaAuthService } from '../../auth/csa-auth.service';
import { LoaderService } from '../../common-services/loader.service';
import { StateService } from '../../common-services/state.service';
import { User } from '../../models/user';
import { SurveysService } from '../surveys.service';

@Component({
  selector: 'app-more-surveys-admin',
  templateUrl: './more-surveys-admin.component.html',
  styleUrls: ['./more-surveys-admin.component.scss'],
})
export class MoreSurveysAdminComponent implements OnInit, OnDestroy {
  @ViewChild(MatTabGroup) tabGroup: MatTabGroup;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  faPlus = faPlus;

  allTemplates: any[] = [];

  date = moment();

  hasNext: string;

  index: number = 0;

  pageIndex: number = 0;

  pageSize: number = 10;

  saveState: boolean = false;

  selectedGroup: number = 0;

  surveyGroups: string[] = ['Active', 'Upcoming', 'Closed', 'All'];

  surveysToShow: any[] = [];

  tableColumns: string[];

  totalCount: number = 0;

  user: User;

  isLoading: boolean = false;
  
  public viewTags = false;

  constructor(
    private actRoute: ActivatedRoute,
    private csaAuth: CsaAuthService,
    private elementRef: ElementRef,
    private state: StateService,
    private surveyCompletionData: LoaderService,
    private surveyService:SurveysService,
    private featureAccessService: FeatureAccessService,
  ) {}

  ngOnInit() {
    this.getPermissions(); 
    this.getSelectedGroup(this.actRoute.snapshot.paramMap.get('status'));
    this.user = this.csaAuth.user;

    if (this.state.state && this.state.origin == 'more-surveys-admin') {
      for (const key in this.state.state) {
        this[key] = this.state.state[key];
      }
    }

    this.surveyService
      .getTemplates(
        `/list/${this.user['division']}?status=archived&status=active`
      )
      .subscribe((templateData) => {
        this.allTemplates = templateData['templates'];
        this.getSurveysToShow();
      });
  }

  getPermissions() {
    this.viewTags = this.featureAccessService.hasAccess('tags.view');
  }

  ngOnDestroy() {
    this.setState();
  }

  setState() {
    if (this.saveState) {
      this.state.origin = 'more-surveys-admin';
      this.state.state = {
        hasNext: this.hasNext,
        index: this.index,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        selectedGroup: this.selectedGroup,
        surveysToShow: this.surveysToShow,
        tableColumns: this.tableColumns,
        totalCount: this.totalCount,
      };
    } else {
      this.state.origin = undefined;
      this.state.state = undefined;
    }
  }

  getSelectedGroup(group) {
    if (group == 'active') {
      this.selectedGroup = 0;
    } else if (group == 'upcoming') {
      this.selectedGroup = 1;
    } else if (group == 'closed') {
      this.selectedGroup = 2;
    } else {
      this.selectedGroup = 3;
    }
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent) {
    this.paginator.pageIndex = 0;
    this.pageIndex = 0;
    this.index = 0;
    this.pageSize = this.paginator.pageSize;
    this.selectedGroup = tabChangeEvent.index;
    this.getSurveysToShow();
  }

  getNext(event: PageEvent) {
    this.index = event.pageIndex * event.pageSize;
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.getSurveysToShow();
  }

  getSurveysToShow() {
    setTimeout(() => {
      this.isLoading = true;
      this.surveysToShow = [];
    }, 0);

    let status = '';

    if (this.selectedGroup == 3) {
      this.tableColumns = ['tags', 'survey', 'publishDate', 'dueDate', 'templateUsed'];
    } else if (this.selectedGroup == 2) {
      this.tableColumns = ['tags', 'survey', 'dueDate', '%Complete', 'actions'];
      status = 'closed';
    } else if (this.selectedGroup == 1) {
      this.tableColumns = ['tags', 'survey', 'publishDate', 'templateUsed', 'questions'];
      status = 'active';
    } else if (this.selectedGroup == 0) {
      this.tableColumns = ['tags', 'survey', 'dueDate', '%Complete', 'actions'];
      status = 'published';
    }

    this.surveyService
      .getSurveys(
        '/admin',
        this.user['division'],
        status,
        `/${this.index}`,
        `/${this.pageSize}`
      )
      .subscribe((surveysData) => {
        this.surveysToShow = surveysData['surveys'];
        this.hasNext = surveysData['has_next'];
        for (let i = 0; i < this.surveysToShow.length; i++) {
          this.surveysToShow[i].template = '';
          for (let j = 0; j < this.allTemplates.length; j++) {
            if (this.allTemplates[j].id == this.surveysToShow[i].template_id) {
              this.surveysToShow[i].template = this.allTemplates[j].title;
            }
          }
        }
        this.surveysToShow = addTargetedToAddToTagList(this.surveysToShow);
        this.surveysToShow = addStatusToAddToTagList(this.surveysToShow, ['Cancelled']);
        this.surveysToShow = createTagList(this.surveysToShow, this.viewTags, ['addToTagList']);        
        this.getPagination();
        this.isLoading = false;
      }, (error) => {
        this.isLoading = false;
      });
  }

  getPagination() {
    const label = this.elementRef.nativeElement.querySelector(
      '.mat-paginator-range-label'
    );
    if (label) {
      if (this.surveysToShow.length == 0) {
        label.innerHTML = 'No results to show';
        this.totalCount = 0;
      } else {
        const upToCount = this.index + this.surveysToShow.length;

        if (this.hasNext == 'Y') {
          label.innerHTML = `${String(this.index + 1)} to ${upToCount} of Many`;
          this.totalCount = upToCount + 1;
        } else {
          label.innerHTML = `${String(this.index + 1)} to ${upToCount} of ${upToCount}`;
          this.totalCount = upToCount;
        }
      }
    }
  }

  surveyDetails(message: string) {
    this.surveyCompletionData.changeMessage(message);
  }
}
