import {
  Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, QueryList, SimpleChanges, ViewChild, ViewChildren 
} from '@angular/core';
import { debounceTime, Subject, takeUntil } from 'rxjs';
import { DomainService } from 'src/app/domain/domain.service';

@Component({
  selector: 'app-autocomplete-dropdown',
  templateUrl: './autocomplete-dropdown.component.html',
  styleUrls: ['./autocomplete-dropdown.component.scss']
})
export class AutocompleteDropdownComponent implements OnChanges, OnInit, OnDestroy {
  @Input() searchTerm = '';

  @Input() searchTermBufferInMs = 0;
  
  @Input() searchResults: object[] = [];

  @Input() searchResultsPropertyName = '';

  @Input() errorMessage = '';

  @Input() showErrorMessage = false;

  @Input() placeholder = '';

  @Input() accessibilityLabel = '';

  @Input() selectSearchTextOnEnter = false;

  @Output() searchTermChanged = new EventEmitter<string>();

  @Output() selectedItem = new EventEmitter<object>();

  @ViewChild('textSearchTerm') textSearchTerm: ElementRef;
  
  @ViewChildren('searchResultListItem') searchResultListItem: QueryList<ElementRef>;

  openOverlay = false;

  containerWidth = 0;

  readonly enabledBigWColor = '#0046c8';

  readonly enabledWoolworthsColor = '#178841';

  addToListColorEnabled = this.enabledWoolworthsColor;

  addToListColorDisabled = '#BABFC1';

  searchTerm$ = new Subject<string>();

  private onDestroy$ = new Subject<void>(); // used to unsubscribe from observables on component destroy

  constructor(private elementRef: ElementRef, private domainService: DomainService) { }

  ngOnInit(): void {
    this.addToListColorEnabled = this.domainService.isBigW() ? this.enabledBigWColor : this.enabledWoolworthsColor;
    if (this.hasSearchTermTimeBuffer()) {
      this.searchTerm$
        .pipe(
          debounceTime(this.searchTermBufferInMs),
          takeUntil(this.onDestroy$)
        )
        .subscribe((searchTerm) => {
          this.searchTermChanged.emit(searchTerm);
        }); 
    }
    setTimeout(() => {
      // HACK: Ensures container width has time to adjust to provide an accurate initial value.
      // Other lifecycle hooks did not provide a single event to make this possible.
      // Content width was stable during AfterViewChecked, but it fired too many times.
      this.updateWidth();
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.searchResults) {
      this.openOverlay = this.searchResults.length > 0;
    }
  }

  private updateWidth(): void {
    this.containerWidth = this.elementRef.nativeElement.offsetWidth;
  }

  // Use the ResizeObserver to detect container width changes when the window does not resize. 
  // Otherwise, use this simpler approach to detect container width changes.
  @HostListener('window:resize')
  onWindowResize(): void {
    this.updateWidth();
  }  

  onSearchTermInput(event) {
    const value = event.target.value;
    this.updateSearchTerm(value.trim());
  }

  onEnter() {
    if (!this.selectSearchTextOnEnter) {
      return;
    }
    this.selectItem(this.searchTerm);
  }

  addSearchTerm() {
    if (!this.selectSearchTextOnEnter || this.searchTerm.trim().length === 0) {
      return;
    }
    this.selectItem(this.searchTerm);
  }

  onSearchTermBlur(event) {
    event.preventDefault();
    if (this.searchResultListItem?.length > 0) {
      this.searchResultListItem.toArray()[0].nativeElement.focus();
    }
  }

  onSearchTermFocus() {
    this.openOverlay = this.searchResults.length > 0;
  }

  hasSearchTermTimeBuffer(): boolean {
    return (this.searchTermBufferInMs ?? 0) > 0;
  }

  clearSearchTerm() {
    this.updateSearchTerm('');
  }

  keydown(event: KeyboardEvent, currentIndex: number) {
    event.preventDefault();
    const key = event.key.toLowerCase();
    if (key === 'arrowup') {
      this.focusPreviousListItem(currentIndex);
    } else if (key === 'arrowdown') {
      this.focusNextListItem(currentIndex);
    } else if (key === 'tab') {
      if (event.shiftKey) {
        this.focusPreviousListItem(currentIndex);
      } else {
        this.focusNextListItem(currentIndex);
      } 
    }    
  }

  backdropClick(event) {
    this.openOverlay = false;
    // This is required to give the overlay time to close itself. 
    // Otherwise, it will be seen as the underlying element and receive the click event causing and infinite loop of click events.
    setTimeout(() => {
      this.propagateClick(event);
    });
  }

  propagateClick(event: MouseEvent): void {
    // Create and dispatch a click event to the underlying element 
    const clickEvent = new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window
    });
    const underlyingElement = document.elementFromPoint(event.clientX, event.clientY);
    underlyingElement.dispatchEvent(clickEvent);
  }

  selectItem(value) {
    this.selectedItem.emit(value);
    this.clearSearchTerm();
  }

  private updateSearchTerm(searchTerm: string) {
    const newSearchTerm = searchTerm.trim();
    this.searchTerm = newSearchTerm;
    if (this.hasSearchTermTimeBuffer()) {
      this.searchTerm$.next(newSearchTerm);
    } else {
      this.searchTermChanged.emit(newSearchTerm);
    }
  }

  private focusPreviousListItem(currentIndex: number) {
    const listItems = this.searchResultListItem.toArray();
    const previousListItem = currentIndex - 1;
    if (previousListItem < 0) {
      this.textSearchTerm.nativeElement.focus();
      return;
    }
    listItems[previousListItem].nativeElement.focus();
  }

  private focusNextListItem(currentIndex: number) {
    const listItems = this.searchResultListItem.toArray();
    const nextListItem = currentIndex + 1;
    if (listItems.length - 1 < nextListItem) {
      return;
    }
    listItems[nextListItem].nativeElement.focus();
  }
}
