import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, catchError, map } from 'rxjs';
import { environment } from '../../../environments/environment';
import { handleError } from '../../utils/handle-error/handle-error';
import { EntitiesMapper } from './entities.mapper';
import { RolesMapper } from './roles.mapper';

@Injectable({
  providedIn: 'root'
})
export class RoleService {
  private baseUrl = `${environment.backEndUrl}/role`;

  private listUrl = `${environment.backEndUrl}/role/list`;

  private entitiesListUrl = `${environment.backEndUrl}/level/list`;

  constructor(
    private httpClient: HttpClient,
    private entitiesMapper: EntitiesMapper,
    private rolesMapper: RolesMapper
  ) { }

  fetch(id: number): Observable<object> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache'
    });
    const options = {
      headers: httpHeaders,
    };
    return this.httpClient.get<object>(`${this.baseUrl}/${id}`, options).pipe(
      map((data: object) => this.rolesMapper.mapRoleResponseToRole(data)),      
      catchError(handleError)
    );
  }

  fetchForDivision(id: number, division: string): Observable<object> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',      
      'Cache-Control': 'no-cache',
      'Do-Not-Override-Division': 'true',
      division
    });
    const options = {
      headers: httpHeaders,
    };
    return this.httpClient.get<object>(`${this.baseUrl}/${id}`, options).pipe(
      map((data: object) => this.rolesMapper.mapRoleResponseToRole(data)),      
      catchError(handleError)
    );
  }

  getEntityList(): Observable<object> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache'
    });
    const options = {
      headers: httpHeaders
    };
    return this.httpClient.get<object>(this.entitiesListUrl, options).pipe(
      map((data: object) => ({
        entityList: this.entitiesMapper.mapEntitiesResponseToEntities(data['levelsList']),
        status: data['status']
      })),
      catchError(handleError)
    );
  }

  create(data: object): Observable<object> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache'
    });
    const options = {
      headers: httpHeaders,
    };
    return this.httpClient.post<object>(this.baseUrl, data, options).pipe(
      catchError(handleError)
    );
  }

  update(data: object): Observable<object> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache'
    });
    const options = {
      headers: httpHeaders,
    };
    return this.httpClient.put<object>(this.baseUrl, data, options).pipe(
      catchError(handleError)
    );
  }

  delete(id: number): Observable<object> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache'
    });
    const options = {
      headers: httpHeaders
    };
    return this.httpClient.delete<object>(`${this.baseUrl}/${id}`, options).pipe(
      catchError(handleError)
    );
  }

  getList(): Observable<object> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',      
      'Cache-Control': 'no-cache'
    });
    const options = {
      headers: httpHeaders,
    };
    const fullUrl = `${this.listUrl}`;
    return this.httpClient.get<object>(fullUrl, options).pipe(
      map((data: object) => ({
        roleList: this.rolesMapper.mapRolesResponseToRoles(data['roleList']),
        status: data['status']
      })),
      catchError(handleError)
    );
  }

  getListForDivision(division: string): Observable<object> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',      
      'Cache-Control': 'no-cache',
      'Do-Not-Override-Division': 'true',
      division
    });
    const options = {
      headers: httpHeaders,
    };
    const fullUrl = `${this.listUrl}`;
    return this.httpClient.get<object>(fullUrl, options).pipe(
      map((data: object) => ({
        roleList: this.rolesMapper.mapRolesResponseToRoles(data['roleList']),
        status: data['status']
      })),
      catchError(handleError)
    );
  }

  getListForDivisionAndRole(division: string, role: string): Observable<object> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',      
      'Cache-Control': 'no-cache',
      'Do-Not-Override-Division': 'true',
      'Do-Not-Override-Role': 'true',
      division,
      role
    });
    const options = {
      headers: httpHeaders,
    };
    const fullUrl = `${this.listUrl}`;
    return this.httpClient.get<object>(fullUrl, options).pipe(
      map((data: object) => ({
        roleList: this.rolesMapper.mapRolesResponseToRoles(data['roleList']),
        status: data['status']
      })),
      catchError(handleError)
    );
  }

  getListContainingFeatureSubFeatureKey(featureSubFeatureKey: string): Observable<object> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',      
      'Cache-Control': 'no-cache'
    });
    const options = {
      headers: httpHeaders,
    };
    const fullUrl = `${this.listUrl}?subfeature=${featureSubFeatureKey}`;
    return this.httpClient.get<object>(fullUrl, options).pipe(
      map((data: object) => ({
        roleList: this.rolesMapper.mapRolesResponseToRoles(data['roleList']),
        status: data['status']
      })),
      catchError(handleError)
    );
  }

  search(searchTerm: string, startIndex: number, itemsPerPage: number): Observable<object> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache'
    });
    const options = {
      headers: httpHeaders,
      body: { search: searchTerm }
    };
    const fullUrl = `${this.listUrl}/${startIndex}/${itemsPerPage}`;
    return this.httpClient.get<object>(fullUrl, options).pipe(
      map((data: object) => ({
        roleList: this.rolesMapper.mapRolesResponseToRoles(data['roleList']),
        status: data['status']
      })),
      catchError(handleError)
    );
  }
}
