import { Injectable } from '@angular/core';
import { getDomainExtension } from '../utils/get-domain-extension/get-domain-extension';

@Injectable({
  providedIn: 'root'
})
export class DomainService {
  constructor() { }

  getDomain() {
    if (window.location.hostname == 'localhost') {
      return 'Woolworths';
      // return 'WoolworthsNZ';
      // return 'Bigw';
      // return environment.domain
    } 
    const domain = window.location.hostname.substring(window.location.hostname.lastIndexOf('iverify.') + 8, window.location.hostname.lastIndexOf('.', window.location.hostname.lastIndexOf('.'))).split('.')[0].charAt(0).toUpperCase() + window.location.hostname.substring(window.location.hostname.lastIndexOf('iverify.') + 8, window.location.hostname.lastIndexOf('.', window.location.hostname.lastIndexOf('.'))).split('.')[0].slice(1);
    if (domain == 'Woolworths' && this.getDomainExtension() == 'nz') {
      return 'WoolworthsNZ';
    }
    return domain;
  }

  isBigW(): boolean {
    const domain = this.getDomain();
    return domain.toLowerCase().includes('bigw');
  }

  getDomainExtension(): string {
    return getDomainExtension(window.location.href);
  }
}
