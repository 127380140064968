<div class="page-container">
  <!-- <h2 *ngIf="!user['storeName'] && user['role'] == 'Store' && user['domain'] != 'Bigw'">Hi {{user['storeID']}} Woolies!</h2>
  <h2 *ngIf="user['storeName'] && user['role'] == 'Store'  && user['domain'] != 'Bigw'">Hi {{user['storeName']}} Woolies!</h2>
  <h2 *ngIf="!user['storeName'] && user['role'] == 'Store' && user['domain'] == 'Bigw'">Hi {{user['storeID']}} BIG W!</h2>
  <h2 *ngIf="user['storeName'] && user['role'] == 'Store'  && user['domain'] == 'Bigw'">Hi {{user['storeName']}} BIG W!</h2> -->

  <div *ngIf="user['role'] === 'Store'">
    <h2 *ngIf="user['domain'] === 'Woolworths'">
      Hi {{user['storeName'] ? user['storeName'] : user['storeID']}} Woolies!
    </h2>
    <h2 *ngIf="user['domain'] === 'WoolworthsNZ'">
      Hi {{user['storeName'] ? user['storeName'] : user['storeID']}} Countdown!
    </h2>
    <h2 *ngIf="user['domain'] === 'Bigw'">
      Hi {{user['storeName'] ? user['storeName'] : user['storeID']}} Big W!
    </h2>
  </div>

  <h2 *ngIf="user['role'] !== 'Store'"><span class="padding-top">Welcome to {{user['storeID']}} - {{user['storeName']}}</span><a (click)="changeStore(true)" class="link-icon"><fa-icon [icon]="faPencilAlt"></fa-icon></a></h2>

  <div class="audit-tool-cards-container">
    <div class="audit-tool-card-interum">
      <h1>Assessments</h1>
      <div class="audit-tool-card-outer more-list-screen-card">
        <table mat-table [dataSource]="allSurveys">
          <ng-container matColumnDef="survey">
            <th mat-header-cell *matHeaderCellDef class="survey-details-link center-align survey-info"> <h4>Assessment</h4> </th>
            <td mat-cell *matCellDef="let survey" class="survey-details-link center-align survey-info" [routerLink]="['/assessment-response-view', survey.id]">
              <div class="left-align">
                  <h4>{{survey['title'].slice(0, 50) | unescape}}<span *ngIf="survey.title.length > 50">...</span></h4>
                  <p [ngClass]="{'red-text' : survey['isDueInWeek'] == true}">Due: {{getDate(survey.dueDate).slice(0, -4)}}</p>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="percentComplete">
            <th mat-header-cell *matHeaderCellDef class="survey-details-link center-align center-align"> <h4>% Complete</h4> </th>
            <td mat-cell *matCellDef="let survey" class="survey-details-link center-align" [routerLink]="['/assessment-response-view', survey.id]">
              <div class="float-up">
                <h1 [ngClass]="{
                        'light-green': survey['surveyState'] === 'Complete',
                        'light-blue' : survey['surveyState'] === 'Continue'
                      }">{{ survey['completion'] | percent}}</h1>

              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef class="survey-details-link center-align center-align"><h4>Status</h4>  </th>
            <td mat-cell *matCellDef="let survey" class="survey-details-link center-align">
              <div class="survey-button">
                  <a [ngClass]="{'disabled-link' : survey['status'] === 'submitted' || survey['surveyState'] === 'Closed'}"
                      [routerLink]="['/assessment-response-edit', survey.id]">
                      <button class="csa-button-solid" [ngClass]="{'disabled-btn' : survey['status'] === 'submitted' || survey['surveyState'] === 'Closed'}">
                        <fa-icon class="chip-left-icon" *ngIf="!(survey['surveyState'] === 'Submitted' || survey['surveyState'] === 'Closed')" [icon]="faPlayCircle"></fa-icon>
                        <span>{{survey['surveyState']}}</span>
                      </button>
                  </a>
              </div>
            </td>
          </ng-container>
          <mat-header-row *matHeaderRowDef="tableColumns"  class="survey-heading-line"></mat-header-row>
          <mat-row *matRowDef="let survey; columns: tableColumns;" [ngClass]="{
                                        'highlighted' : survey['status'] === 'active'
                                      }" class="survey-line survey-line-link"></mat-row>

        </table>

        <div *ngIf="isLoadingOnceOff" class="loader-container">
          <div class="loading-wapple"></div>
        </div>
  
        <div class="none-found-message" *ngIf="onceOffSurveys.length == 0 && !isLoadingOnceOff">
          <h3 class="padded-icon">
            All Done!
          </h3>
          <div>
            You have completed all of your assigned Assessments. Watch this space to receive Assessments once they are published.
          </div>
        </div>

        <a [routerLink]="['/store-landing/more-surveys']"><button class="csa-button-solid">More Assessments</button></a>

      </div>
    </div>

    <div class="audit-tool-card-interum">
      <h1>AdHoc Assessments</h1>
      <div class="audit-tool-card-outer more-list-screen-card">
        <mat-tab-group #tabGroup dynamicHeight="false" mat-align-tabs="center" (selectedTabChange)="tabChanged($event)">
          <mat-tab label="{{surveyGroup}}" *ngFor="let surveyGroup of surveyGroups">
            <table mat-table multiTemplateDataRows [dataSource]="adhocSurveys">
              <ng-container matColumnDef="survey">
                <th mat-header-cell *matHeaderCellDef class="survey-details-link center-align survey-info"> <h4>Assessment</h4> </th>
                <td mat-cell *matCellDef="let survey" class="survey-details-link center-align survey-info" [routerLink]="['/onthefly-survey-details', survey.id]">
                  <div class="left-align">
                      <h4 class="grey-text center-vertical">
                        <span *ngIf="survey['filterName']" class="infoTag">{{survey['filterName'] | uppercase}}</span> 
                        &nbsp;{{survey['title'].slice(0, 90) | unescape}}
                        <span *ngIf="survey.title.length > 90">...</span>
                      </h4>                        
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="percentComplete">
                <th mat-header-cell *matHeaderCellDef class="survey-details-link center-align center-align"> <h4>Active Responses</h4> </th>
                <td mat-cell *matCellDef="let survey" class="survey-details-link center-align" [routerLink]="['/onthefly-survey-details', survey.id]">
                  <div class="float-up">
                    <h1>{{ survey['activeResponseCount']}}</h1>

                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef class="survey-details-link center-align center-align"><h4>Create New</h4>  </th>
                <td mat-cell *matCellDef="let survey" class="survey-details-link center-align">
                  <div class="survey-details-link center-align survey-button" *ngIf="survey['status'] !== 'closed' && survey['targetRoles']?.indexOf(user['role']) > -1">
                      <button class="csa-button-solid" (click)="createOnTheFlySurvey(survey['id'])">
                        <span><fa-icon [icon]="faPlus"></fa-icon> Create</span>
                      </button>
                  </div>
                  <div class="survey-details-link center-align survey-button" 
                    *ngIf="survey['status'] === 'closed' && survey['targetRoles']?.indexOf(user['role']) > -1">
                    <div class="light-grey medium-text" *ngIf="survey['daysToEdit'] == 1">
                      Last Day to Edit Active Responses
                    </div>
                    <div class="light-grey medium-text" *ngIf="survey['daysToEdit'] > 1">
                      {{survey['daysToEdit']}} Days to Edit Active Responses
                    </div>
                  </div>
                  <div class="survey-details-link center-align survey-button" *ngIf="survey['targetRoles']?.indexOf(user['role']) == -1">
                    Available To: <span *ngFor="let role of survey['targetRoles']; let j=index">{{splitRoleByCaps(role)}}<span *ngIf="survey['targetRoles']?.length - 1 > j">,&nbsp;</span></span>
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="hintText">
                <td class="todo-text hint-line-text" mat-cell *matCellDef="let survey"  [routerLink]="['/onthefly-survey-details', survey.id]">
                  Click For History, Details & Active Responses
                </td>
              </ng-container>
              <mat-header-row *matHeaderRowDef="tableColumns"  class="survey-heading-line"></mat-header-row>
              <mat-row *matRowDef="let survey; columns: tableColumns;" [ngClass]="{
                                            'highlighted' : survey['status'] === 'active'
                                          }" class="survey-line survey-line-link"></mat-row>
              <mat-row *matRowDef="let row; columns: ['hintText']" class="hint-line"></mat-row>
            </table>

            <div *ngIf="isLoadingAdhoc" class="loader-container">
              <div class="loading-wapple"></div>
            </div>
      
            <div class="none-found-message" *ngIf="adhocSurveys.length == 0 && !isLoadingAdhoc">
              <h3 class="padded-icon">
                No Available Adhoc Assessments.
              </h3>
              <div>Watch this space to see Adhoc Assessments once they are available.</div>
            </div>

            <a [routerLink]="['/more-onthefly-surveys']"><button class="csa-button-solid">More Assessments</button></a>

          </mat-tab>
        </mat-tab-group>
      </div>
    </div>



    <div class="audit-tool-card-interum">
      <h1>Actions</h1>
      <div class="audit-tool-card-outer more-list-screen-card">
        <table mat-table [dataSource]="actions">
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef class="survey-details-link center-align"> <h4>Action</h4> </th>
            <td mat-cell *matCellDef="let action" class="survey-details-link center-align">
              <div class="left-align">
                  <h4> {{action['title'].slice(0, 35) | unescape}}<span *ngIf="(action.title.length > 35)">...</span></h4>
                  <p [ngClass]="{
                  'orange-text' : action['isDueInWeek'] == true,
                  'red-text' : action['overDue'] == true
                  }">Due: {{action.dueDate.slice(0, -4)}}</p>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef class="survey-details-link center-align"> <h4>Description</h4> </th>
            <td mat-cell *matCellDef="let action" class="survey-details-link center-align" [routerLink]="['/action-details', action.id]">
              <div class="left-align">
                  <p> {{action['description'].slice(0, 100) | unescape}}<span *ngIf="(action['description'].length > 100)">...</span></p>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="updates">
            <th mat-header-cell *matHeaderCellDef class=" survey-details-link center-align center-align"> <h4>Updates</h4> </th>
            <td mat-cell *matCellDef="let action" class=" survey-details-link center-align" [routerLink]="['/action-details', action.id]">
              <div class="survey-stat">
                  <h1>{{getActionUpdates(action['id'])}}</h1>
              </div>
            </td>
          </ng-container>
          <mat-header-row *matHeaderRowDef="actionsTableColumns"  class="survey-heading-line"></mat-header-row>
          <mat-row *matRowDef="let action; columns: actionsTableColumns;" [routerLink]="['/action-details', action.id]" class="survey-line survey-line-link"></mat-row>
        </table>

        <div *ngIf="isLoadingActions" class="loader-container">
          <div class="loading-wapple"></div>
        </div>
  
        <div class="none-found-message" *ngIf="actions.length == 0 && !isLoadingActions">
          <h3 class="padded-icon">
            All Done!
          </h3>
          <div>
            You have completed all of your actions. New actions will appear here once they are created in any Assessment.
          </div>
        </div>

        <a [routerLink]="['/more-actions']"><button class="csa-button-solid">More Actions</button></a>

      </div>
    </div>
  
      <!-- MRR Assessment -->
 
     
      <div class="audit-tool-card-interum" *ngIf="(user['division'] === 'Supermarkets')">
        <h1>Material Risk Review Assessments</h1>
        <div class="audit-tool-card-outer more-list-screen-card">
        <mat-tab-group #tabGroup dynamicHeight="false" mat-align-tabs="center"
          (selectedTabChange)="mrrTabChanged($event)">
          <mat-tab label="{{surveyGroup}}" *ngFor="let surveyGroup of mrrSurveyGroups">
            <div class="survey-line MRR survey-heading-line no-ripple ">
              <div class="survey-stat template-stat">
                <h4>Assessment</h4>
              </div>
              <div class="survey-stat template-stat">
                <h4>Rating</h4>
              </div>
              <div *ngIf="surveyGroup === 'Available'" class="survey-stat template-stat hide-on-mobile">
                <h4>Status</h4>
              </div>
              <div *ngIf="surveyGroup === 'Closed'" class="survey-stat template-stat">
                <h4>Status</h4>
              </div>
              <div *ngIf="surveyGroup === 'Available'" class="survey-stat template-stat">
                <h4>Create New</h4>
              </div>
            </div>
  
            <div *ngIf="!isLoadingMRR">
              <div *ngFor="let survey of mrrSurveys" [ngClass]="{
                'highlighted' : survey['status'] === 'active'
              }" class="highlighted">
                <div class="survey-line">
                  <div class="survey-details-link" [routerLink]="['/mrr-response', survey['id']]">
                    <h4 class="grey-text center-vertical">
                      &nbsp;{{survey['title'].slice(0, 90) | unescape}}
                      <span *ngIf="survey.title.length > 90">...</span>
                    </h4>
                  </div>

                  <div class="survey-details-link center-vertical"
                    [routerLink]="['/mrr-response', survey['id']]">
                    <h4>{{survey['rating']}}</h4>
                  </div>

                  <div *ngIf="surveyGroup === 'Available'" class="survey-details-link hide-on-mobile center-vertical"
                    [routerLink]="['/mrr-response', survey['id']]">
                    <h3>{{survey['mrrStatus']}}</h3>
                  </div>



                  <div class="survey-details-link survey-button" >
                    <div class="survey-button">
                      <a [ngClass]="{'disabled-link' :  survey['mrrStatusBtn'] === 'Closed'}"
                          [routerLink]="['/mrr-response', survey.id]">
                          <button class="csa-button-solid" [ngClass]="{'disabled-btn' :  survey['mrrStatusBtn'] === 'Closed'}">
                            <fa-icon class="chip-left-icon" *ngIf="!( survey['mrrStatusBtn'] === 'Closed')" [icon]="faPlayCircle"></fa-icon>
                            <span>{{survey['mrrStatusBtn']}}</span>
                          </button>
                      </a>
                  </div>
                    <!-- <div class="survey-button">
                      <a [ngClass]="{'disabled-link' :  survey['mrrStatusBtn'] == 'Closed'}"
                        [routerLink]="['/mrr-response', survey['id']]">
                        <button class="csa-button-solid"
                          [ngClass]="{'disabled-btn' :  survey['mrrStatusBtn'] == 'Closed'}">
                          <fa-icon class="chip-left-icon"
                            *ngIf="(survey['mrrStatusBtn'] == 'Continue')"
                            [icon]="faPlayCircle"></fa-icon>
                          <fa-icon class="chip-left-icon" *ngIf="(survey['mrrStatusBtn'] == 'Start')" [icon]="faPlayCircle">
                          </fa-icon>
                          <span>{{survey['mrrStatusBtn']}}</span>
                        </button>
                      </a>
                    </div> -->
                  </div>
                </div>
              </div>
  
              <h3 class="none-found-message" *ngIf="mrrSurveys.length == 0">
                You currently have no {{surveyGroup}} Material Risk Review Assessments.
              </h3>
            </div>
  
            <div *ngIf="isLoadingMRR" class="loader-container">
              <div class="loading-wapple"></div>
            </div>
  
            <a
            [routerLink]="['/more-mrr-surveys', {status: surveyGroup === 'Available' ? 'active' : 'active', type: 'adhoc'}]">
            <button class="csa-button-solid">More Assessments </button>
          </a>
          </mat-tab>
        </mat-tab-group>
      </div>
      </div>
  

  
      <!-- MRR Assessment -->
    
    <!-- MRR Actions -->

    <div class="audit-tool-card-interum" *ngIf="(user['division'] === 'Supermarkets')" >
      <h1>Material Risk Review Actions</h1>
      <div class="audit-tool-card-outer more-list-screen-card">
        <table mat-table [dataSource]="mrrActions">
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef class="survey-details-link center-align"> <h4>Action</h4> </th>
            <td mat-cell *matCellDef="let action" class="survey-details-link center-align">
              <div class="left-align">
                  <h4> {{action['title'].slice(0, 35) | unescape}}<span *ngIf="(action.title.length > 35)">...</span></h4>
                  <p [ngClass]="{
                  'orange-text' : action['isDueInWeek'] == true,
                  'red-text' : action['overDue'] == true
                  }">Due: {{action.dueDate.slice(0, -4)}}</p>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef class="survey-details-link center-align"> <h4>Description</h4> </th>
            <td mat-cell *matCellDef="let action" class="survey-details-link center-align" [routerLink]="['/mrr-action-details', action.id]">
              <div class="left-align">
                  <p> {{action['description'].slice(0, 100) | unescape}}<span *ngIf="(action['description'].length > 100)">...</span></p>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="updates">
            <th mat-header-cell *matHeaderCellDef class=" survey-details-link center-align center-align"> <h4>Updates</h4> </th>
            <td mat-cell *matCellDef="let action" class=" survey-details-link center-align" [routerLink]="['/mrr-action-details', action.id]">
              <div class="survey-stat">
                  <h1>{{getMrrActionUpdates(action['id'])}}</h1>
              </div>
            </td>
          </ng-container>
          <mat-header-row *matHeaderRowDef="actionsTableColumns"  class="survey-heading-line"></mat-header-row>
          <mat-row *matRowDef="let action; columns: actionsTableColumns;" [routerLink]="['/mrr-action-details', action.id]" class="survey-line survey-line-link"></mat-row>
        </table>

        <div *ngIf="isLoadingMrrActions" class="loader-container">
          <div class="loading-wapple"></div>
        </div>

        <div class="none-found-message" *ngIf="mrrActions.length == 0 && !isLoadingMrrActions">
          <h3 class="padded-icon">
            All Done!
          </h3>
          <div>
            You have completed all of your actions. New actions will appear here once they are created in any Assessment.
          </div>
        </div>

        <a [routerLink]="['/mrr-more-actions']"><button class="csa-button-solid">More Actions</button></a>

      </div>
      </div>

    <!---MRR Actions--->
   </div>
  <div class="app-version">{{date}}</div>
</div>
