import {
  Component, EventEmitter, Input, OnDestroy, 
  Output
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject, take } from 'rxjs';
import { SurveysService } from 'src/app/surveys/surveys.service';
import { isNullOrEmpty } from 'src/app/utils/is-empty-string/is-empty-string';
import { getUserMessageFromHttpErrorResponse } from 'src/app/utils/handle-error/handle-error';
import { isSpecialCharacter } from 'src/app/utils/is-special-character/is-special-character';
import { DialogComponent, DialogData, DialogMode } from '../dialog/dialog.component';

@Component({
  selector: 'app-tags-autocomplete-dropdown',
  templateUrl: './tags-autocomplete-dropdown.component.html',
  styleUrls: ['./tags-autocomplete-dropdown.component.scss']
})
export class TagsAutocompleteDropdownComponent implements OnDestroy {
  @Input() tagList: string[] = [];
  
  @Output() tagListChanged = new EventEmitter<string[]>();

  searchTagTerm = '';

  searchTagResults = [];

  showSearchTagMessage = false;

  searchTagMessage = '';

  readonly searchTagsTitle = 'Search tags error';

  readonly MIN_TAG_SEARCH_LENGTH = 2;

  readonly MIN_TAG_LENGTH = 3;

  readonly MAX_TAG_LENGTH = 30;

  ignoreSearchResults = false;

  private onDestroy$ = new Subject<void>(); // used to unsubscribe from observables on component destroy

  constructor(
    private dialog: MatDialog,
    private surveyService: SurveysService,
  ) { }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  // Delegates search terms input changes to search term observable. 
  searchTermChanged(value: string) {
    if (isNullOrEmpty(value)) {
      this.searchTagResults = [];
      return;
    }
    // Validate to not allow special characters
    if (isSpecialCharacter(value)) {
      this.displaySearchTagMessage('Tag name should only contain alphanumeric characters');
      return;
    }
    this.getSearchResults(value);
  }

  getSearchResults(value) {
    if (isNullOrEmpty(value)) {
      this.ignoreSearchResults = true;
      this.clearSearchTerm();
      this.searchTagResults = [];
    } else {
      this.searchTagTerm = value.trim();
      if (this.searchTagTerm && (this.searchTagTerm.length < this.MIN_TAG_SEARCH_LENGTH || this.searchTagTerm.length > this.MAX_TAG_LENGTH)) {
        this.ignoreSearchResults = true;
        this.clearSearchTagMessage();
        this.searchTagResults = [];
        return;
      }

      this.ignoreSearchResults = false;
      this.surveyService.searchTags(this.searchTagTerm).pipe(take(1)).subscribe( 
        {
          next: (data) => {
            if (this.ignoreSearchResults) {
              console.log('ignore search results', data);
              return;
            }
            this.clearSearchTagMessage();
            this.searchTagResults = (this.filterOutAlreadySelectedOptions(data['searchResults'], this.tagList));
          },
          error: (error) => {
            this.showSearchTagMessage = false;
            this.searchTagMessage = '';
            this.dialog.open(DialogComponent, {
              data: <DialogData>{
                title: this.searchTagsTitle,
                message: getUserMessageFromHttpErrorResponse(error),
                mode: DialogMode.Error,
              },
              disableClose: true,
              backdropClass: 'dialog-backdrop',
            });   
          }
        }
      );
    }
  }

  removeTag(tag: string) {
    this.tagList = this.tagList.filter((item: string) => item !== tag);
    this.clearSearchTerm();
    this.tagListChanged.emit(this.tagList);
  }

  clearSearchTagMessage() {
    this.showSearchTagMessage = false;
    this.searchTagMessage = '';    
  }

  displaySearchTagMessage(message: string) {
    this.ignoreSearchResults = true;
    this.showSearchTagMessage = true;
    this.searchTagMessage = message;
    this.searchTagResults = [];
  }
  
  /**
   * Filters out options that are already selected from the provided list.
   *
   * @param {string[]} options - The array of available options to filter.
   * @param {string[]} optionsToRemove - The array of options that should be removed if present in the options array.
   * @returns {string[]} - A new array of options excluding those that are in the optionsToRemove array.
   */
  private filterOutAlreadySelectedOptions(options: string[], optionsToRemove: string[]): string[] {
    return options?.filter((option) => optionsToRemove.find((optionToRemove) => optionToRemove == option) == null);
  }

  private doesTagExist(tag: string, tagList: string[]): boolean {
    return !!tagList?.find((item: string) => item === tag);
  }

  clearSearchTerm() {
    this.searchTagTerm = '';
    this.clearSearchTagMessage();
  }

  addItem(tag: string) {
    this.ignoreSearchResults = true;
    // Validate tag length
    if (tag.length < this.MIN_TAG_LENGTH || tag.length > this.MAX_TAG_LENGTH) {
      this.displaySearchTagMessage(`Tag length must be between ${this.MIN_TAG_LENGTH} and  ${this.MAX_TAG_LENGTH} characters long`);
      return;
    }
    // Validate to not allow special characters
    if (isSpecialCharacter(tag)) {
      this.displaySearchTagMessage('Tag name should only contain alphanumeric characters');
      return;
    }
    // Add to tag list if it does not already exist
    if (this.doesTagExist(tag, this.tagList)) {
      this.displaySearchTagMessage(`Tag name '${tag}' already exists`);
    } else {
      this.clearSearchTagMessage();
      this.tagList.push(tag);  
    }
    this.tagListChanged.emit(this.tagList);
  }  
}
