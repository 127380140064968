import {
  Component,
  Inject,
  OnInit,
  KeyValueDiffer,
  KeyValueDiffers,
} from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';

import { faPaperPlane, faInfoCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import * as moment from 'moment';
import { SurveysService } from 'src/app/surveys/surveys.service';
import { FeatureAccessService } from 'src/app/common-services/feature-access.service';
import { CsaAuthService } from '../../auth/csa-auth.service';
import { User } from '../../models/user';
import { CsaBackEndService } from '../../common-services/csa-back-end.service';
import { MessagesComponent } from '../../surveys/messages/messages.component';

@Component({
  selector: 'app-create-mrr-action-dialog',
  templateUrl: './create-mrr-action-dialog.component.html',
  styleUrls: ['./create-mrr-action-dialog.component.scss']
})
export class CreateMrrActionDialogComponent implements OnInit {
  actionData = {};

  actions: any[];

  faInfoCircle = faInfoCircle;

  faPaperPlane = faPaperPlane;

  faTimes = faTimes;

  maxDate = new Date();

  minDate = new Date();

  noEntries = '';

  objDiffer = {};

  searching = false;

  showHelp = true;

  surveyData = {};

  surveyNameValidation = "[A-Za-z0-9\\&\\.\\,\\'\\?\\-\\n ]*";

  uploading = false;

  user: User;

  private differ: KeyValueDiffer<string, any>;

  saveActionTimer;

  saveActionAttemptsWithoutAutoSavedActionID = 0;
  
  autoSaveTimer;

  constructor(
    private differs: KeyValueDiffers,
    private csaAuth: CsaAuthService,
    private dialog: MatDialog,
    private csaBackEnd: CsaBackEndService,
    private dialogRef: MatDialogRef<CreateMrrActionDialogComponent>,
    private surveyService: SurveysService,
    private featureAccessService: FeatureAccessService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    if (!this.featureAccessService.hasAccess('mrrActions.create')) {
      console.error('User does not have rights to use this dialog. Feature.Subfeature `mrrActions.create` is required.');
      setTimeout(() => this.dialogRef.close({ type: 'error', action: [] }), 0);
      return;
    }
    this.user = this.csaAuth.user;
    this.maxDate.setFullYear(this.maxDate.getFullYear() + 1);
    this.maxDate.setDate(this.maxDate.getDate() - 1);

    this.surveyService
      .getSurveyById(this.data.surveyId)
      .subscribe((surveyResponseData) => {
        this.surveyData = surveyResponseData;
        for (let i = 0; i < surveyResponseData['responses'].length; i++) {
          if (
            surveyResponseData['responses'][i]['question_id']
            == this.data.questionId
          ) {
            if (this.data['autoSavedActionID'] != 'new') {
              const actionID = this.data['autoSavedActionID'];
              const actionArray = surveyResponseData['responses'][i]['autoSavedAction']
                .filter((e) => e['autoSavedActionID'] == actionID);

              if (actionArray[0]) {
                this.actionData = actionArray[0];
                clearTimeout(this.autoSaveTimer);
              } else {
                const dialog = this.dialog.open(MessagesComponent, {
                  data: {
                    heading: 'Action Removed',
                    message:
                      'This Action has been deleted by another user. Please Refresh your page to see up to date Assessment Status',
                    closeText: 'Refresh Page',
                  },
                  disableClose: true,
                  backdropClass: 'dialog-backdrop',
                });
                // eslint-disable-next-line no-loop-func
                dialog.afterClosed().subscribe((result) => {
                  window.location.reload();
                });
              }
            }
          }
        }
        this.initChanges();
      });
  }

  public closeMe() {
    if (!this.actionData['autoSavedActionID']) {
      if (
        !this.actionData['title']
        && !this.actionData['description']
        && !this.actionData['stepsToResolve']
        && !this.actionData['issueTracking']
        && !this.actionData['personResponsible']
        && !this.actionData['dueDate']
      ) {
        this.dialogRef.close({ type: 'auto', action: this.actionData });
      } else {
        this.awaitClose();
      }
    } else {
      clearTimeout(this.autoSaveTimer);
      this.dialogRef.close({ type: 'auto', action: this.actionData });
    }
  }

  awaitClose() {
    const dialog = this.dialogRef;
    setTimeout(() => this.closeMe(), 500);
  }

  toggleHelp() {
    this.showHelp = !this.showHelp;
  }

  initChanges() {
    if (!this.actionData['autoSavedActionID']) {
      this.actionData['createdBy'] = this.user['email'];
      this.actionData['status'] = 'PENDING';
      this.actionData['questionTitle'] = this.data.question;
      this.actionData['questionShortTitle'] = this.data.questionShortTitle;
      this.actionData['questionID'] = this.data.questionId;
      this.actionData['surveyID'] = this.surveyData['survey_id'];
      this.actionData['responseID'] = this.surveyData['id'];
      this.actionData['surveyTitle'] = this.data.survey;
      this.actionData['storeID'] = this.surveyData['storeID'];
      this.actionData['storeName'] = this.surveyData['storeName'];

      if (this.surveyData['brand']) {
        this.actionData['brand'] = this.surveyData['brand'];
      }
      if (this.surveyData['groupID']) {
        this.actionData['groupID'] = this.surveyData['groupID'];
      }
      if (this.surveyData['state']) {
        this.actionData['state'] = this.surveyData['state'];
      }
      if (this.surveyData['assessmentType']) {
        this.actionData['assessmentType'] = this.surveyData['assessmentType'];
      }
      if (this.surveyData['division']) {
        this.actionData['division'] = this.surveyData['division'];
      }
      if (this.surveyData['zone']) {
        this.actionData['zone'] = this.surveyData['zone'];
      }
      if (this.surveyData['region']) {
        this.actionData['region'] = this.surveyData['region'];
      }
      if (this.surveyData['area']) {
        this.actionData['area'] = this.surveyData['area'];
      }
      if (this.surveyData['filterName']) {
        this.actionData['filterName'] = this.surveyData['filterName'];
      }
      if (this.surveyData['stateGeo']) {
        this.actionData['stateGeo'] = this.surveyData['stateGeo'];
      } else if (this.surveyData['state']) {
        this.actionData['stateGeo'] = this.surveyData['state'];
      }
      if (this.surveyData['type'] == 'adhoc') {
        this.actionData['multiSurvey'] = this.surveyData['multiSurvey'];
        this.actionData['targetRoles'] = this.surveyData['targetRoles'];
        this.actionData['type'] = this.surveyData['type'];
      }
    }

    this.differ = this.differs.find(this.actionData).create();
    const initial = this.differ.diff(this.actionData);
  }

  saveAction(values) {
    clearTimeout(this.saveActionTimer);
    if (this.actionData['autoSavedActionID']) {
      clearTimeout(this.autoSaveTimer);
    }    

    if (values.actionDueDate) {
      this.actionData['dueDate'] = moment(values.actionDueDate, [
        'DD/MM/YYYY',
        'DD MMM YYYY',
        moment.ISO_8601,
      ]).format();
    }
    // Wait for the auto saved action id to be generated before creating the action. 
    // Otherwise, there is a possibility of a race condition where the auto save occurs after the save action operation.
    // As the save action is responsible for cleaning up auto saved actions to will lead to duplicate unsaved or auto saved actions.
    // Note: The auto save will eventually save (after 2000ms).
    if (!this.actionData['autoSavedActionID']) {
      if (this.saveActionAttemptsWithoutAutoSavedActionID >= 5) { // Avoid infinite loops occurring. Max of 5 attempts allowed.
        console.log('saveAction - maximum save attempts of 5 reached. Required autoSavedActionID is not defined.');
        this.dialog.open(MessagesComponent, {
          data: {
            heading: 'Cannot Save Action',
            message:
              `Please try again or email ${this.user['supportEmail']} for support`,
          },
          disableClose: true,
          backdropClass: 'dialog-backdrop',
        });
        this.saveActionAttemptsWithoutAutoSavedActionID = 0;
        return;
      }
      this.saveActionAttemptsWithoutAutoSavedActionID++;
      console.log('saveAction - wait for autoSavedActionID to be defined. Poll saveAction in 1,000ms');
      this.saveActionTimer = setTimeout(
        () => this.saveAction(values),
        1000
      );
      return;
    }

    const requestJSONPayload = this.csaBackEnd.attachFirewallBypassPrefixes(this.actionData, ['title', 'description']);
    this.surveyService.createAction(requestJSONPayload).subscribe(
      (reply) => {
        if (reply['status'] == 200) {
          this.dialog.open(MessagesComponent, {
            data: {
              heading: 'Action Saved',
              message:
                'Your action has been saved and can now be seen on your iVerify Home page.',
            },
            disableClose: true,
            backdropClass: 'dialog-backdrop',
          });
          this.actionData['actionId'] = reply['id'];
          // questionAction = {title: action['title'], actionId: action['id'], surveyID: action['surveyID'], questionID: action['questionID']};
          this.dialogRef.close({ type: 'final', action: this.actionData });
        } else {
          this.dialog.open(MessagesComponent, {
            data: {
              heading: 'Cannot Save Action',
              message:
                `Please email ${this.user['supportEmail']} for support`,
            },
            disableClose: true,
            backdropClass: 'dialog-backdrop',
          });
          this.dialogRef.close({ type: 'error', action: [] });
        }
      },
      (err) => {
        this.dialog.open(MessagesComponent, {
          data: {
            heading: 'Cannot Save Action',
            message:
              `Please email ${this.user['supportEmail']} for support`,
          },
          disableClose: true,
          backdropClass: 'dialog-backdrop',
        });
        console.log(err);
      }
    );
  }

  deleteAction() {
    clearTimeout(this.autoSaveTimer);

    const message = this.dialog.open(MessagesComponent, {
      data: {
        heading: 'Are You Sure?',
        message:
          'Are you sure you want to delete this Action? This cannot be un-done.',
        closeText: 'Delete',
        cancelText: 'Cancel',
        maxWidth: '90vw',
      },
      backdropClass: 'dialog-backdrop',
    });

    message.afterClosed().subscribe((result) => {
      if (result == 'logout') {
        const data = {};
        data['id'] = this.data.surveyId;

        data['question_id'] = this.data.questionId;
        if (this.actionData['autoSavedActionID']) {
          data['autoSavedActionID'] = this.actionData['autoSavedActionID'];

          const requestJSONPayload = this.csaBackEnd.attachFirewallBypassPrefixes(data, ['autoSavedAction']);

          this.surveyService.autoSaveAction(requestJSONPayload, 'delete').subscribe(
            (response) => {
              if (response['status'] == 400 || response['status'] == 500) {
                this.dialog.open(MessagesComponent, {
                  data: {
                    heading: "Couldn't Delete Action",
                    message:
                      `Please email ${this.user['supportEmail']
                      } for support`,
                    closeText: 'Ok',
                  },
                  backdropClass: 'dialog-backdrop',
                });
              } else {
                this.dialogRef.close({
                  type: 'delete',
                  autoSavedActionID: this.actionData['autoSavedActionID'],
                });
              }
            },
            (error) => {
              console.log(error);
              this.dialog.open(MessagesComponent, {
                data: {
                  heading: "Couldn't Delete Action",
                  message:
                    `Please email ${this.user['supportEmail']
                    } for support`,
                  closeText: 'Ok',
                },
                backdropClass: 'dialog-backdrop',
              });
            }
          );
        } else {
          this.dialogRef.close({ type: 'delete', autoSavedActionID: '' });
        }
      }
    });
  }

  saveNotes(values) {
    clearTimeout(this.autoSaveTimer);
    this.autoSaveTimer = setTimeout(
      () => this.autoSaveAction(values),
      2000
    );
  }

  autoSaveAction(values) {
    clearTimeout(this.autoSaveTimer);

    if (values.actionDueDate) {
      this.actionData['dueDate'] = moment(values.actionDueDate, [
        'DD/MM/YYYY',
        'DD MMM YYYY',
        moment.ISO_8601,
      ]).format();
    }

    const data = {};
    data['id'] = this.data.surveyId;
    let status = '';

    if (!this.actionData['autoSavedActionID']) {
      status = 'new';
      data['autoSavedAction'] = this.actionData;
      data['question_id'] = this.data.questionId;
    } else {
      data['question_id'] = this.data.questionId;

      status = 'update';
      data['autoSavedAction'] = {};
      const changes = this.differ.diff(this.actionData);
      if (changes) {
        changes.forEachChangedItem((r) => {
          data['autoSavedAction'][r.key] = r.currentValue;
        });
        changes.forEachAddedItem((r) => {
          data['autoSavedAction'][r.key] = r.currentValue;
        });
        changes.forEachRemovedItem((r) => {
          data['autoSavedAction'][r.key] = r.currentValue;
        });
      }
      data['autoSavedAction']['autoSavedActionID'] = this.actionData['autoSavedActionID'];
    }

    const requestJSONPayload = this.csaBackEnd.attachFirewallBypassPrefixes(data, ['autoSavedAction']);
    this.surveyService.autoSaveAction(requestJSONPayload, status).subscribe(
      (response) => {
        if (response['status'] == 400 || response['status'] == 500) {
          this.dialog.open(MessagesComponent, {
            data: {
              heading: 'Action Auto Save Failed',
              message:
                `Please email ${this.user['supportEmail']} for support`,
              closeText: 'Ok',
            },
            backdropClass: 'dialog-backdrop',
          });
        } else if (status == 'new') {
          this.actionData['autoSavedActionID'] = response['autoSavedActionID'];
        }
      },
      (error) => {
        console.log(error);
        this.dialog.open(MessagesComponent, {
          data: {
            heading: 'Action Auto Save Failed',
            message:
              `Please email ${this.user['supportEmail']} for support`,
            closeText: 'Ok',
          },
          backdropClass: 'dialog-backdrop',
        });
      }
    );
  }
}
