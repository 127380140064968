<div mat-dialog-title>
	<div class="close-container">
		<span class="info-icon med-grey" (click)="closeMe()">
			<fa-icon [icon]="faTimes"></fa-icon>
		</span>
		<h2 *ngIf="data['type'] === 'update'">Update Access</h2>
		<h2 *ngIf="data['type'] === 'create'">Create New User</h2>
	</div>
</div>

<div mat-dialog-content>
	<form #accessManagementForm="ngForm">
		<div class="access-management-form">
			<h1 class="access-management-headings left-align">Email:</h1>
			<mat-form-field appearance="outline" color="primary">
				<mat-label>Email</mat-label>
				<input [disabled]="data['type'] === 'update'" (ngModelChange)="userData['EMAIL_ADDRESS']=$event"
					[ngModel]="userData['EMAIL_ADDRESS'] | unescape" ngModel maxLength="100" #email matInput
					class="custom-control-input" required type="email" name="email" [email]="true">
				<mat-hint align="start">This field can not be changed once the user is created.</mat-hint>
				<mat-hint *ngIf="userData['EMAIL_ADDRESS']" align="end">{{userData['EMAIL_ADDRESS'].length || 0}}/100</mat-hint>
				<mat-error>Please enter the user's email address</mat-error>
			</mat-form-field>

			<h1 class="access-management-headings left-align">Name:</h1>
			<div class="centered-fields">
				<div class="multiple-field" [style.--numShown]="2">
					<mat-form-field appearance="outline" color="primary">
						<mat-label>First Name</mat-label>
						<input (ngModelChange)="userData['FIRST_NAME']=$event" [ngModel]="userData['FIRST_NAME'] | unescape" ngModel
							maxLength="100" #firstName matInput class="custom-control-input" name="firstName" required>
						<mat-hint *ngIf="userData['FIRST_NAME']" align="end">{{userData['FIRST_NAME'].length || 0}}/100</mat-hint>
						<mat-error>Please enter the user's first name</mat-error>
					</mat-form-field>
				</div>
				<div class="multiple-field" [style.--numShown]="2">
					<mat-form-field appearance="outline" color="primary">
						<mat-label>Last Name</mat-label>
						<input (ngModelChange)="userData['LAST_NAME']=$event" [ngModel]="userData['LAST_NAME'] | unescape" ngModel
							maxLength="100" #lastName matInput class="custom-control-input" name="lastName" required>
						<mat-hint *ngIf="userData['LAST_NAME']" align="end">{{userData['LAST_NAME'].length || 0}}/100</mat-hint>
						<mat-error>Please enter the user's last name</mat-error>
					</mat-form-field>
				</div>
			</div>

			<h1 class="access-management-headings left-align">Role:</h1>
			<mat-form-field appearance="outline" color="primary">
				<mat-label class="" for="role">Role</mat-label>
				<mat-select (ngModelChange)="userData['ROLE']=$event; initRole()" [ngModel]="userData['ROLE'] | unescape" color="primary"
					name="role" class="custom-control-input" required>
					<mat-option *ngFor="let role of availableRoles" [value]="role">
						{{splitRoleByCaps(role) | unescape}}
					</mat-option>
				</mat-select>
				<mat-error>Please select the user's role</mat-error>
			</mat-form-field>
      
			<ng-container *ngIf="showLocation">
				<h1 class="access-management-headings left-align">Location:</h1>
				<div class="centered-fields">
					<ng-container *ngFor="let key of locationsOrdered">
						<div class="multiple-field" [style.--numShown]="numShown()" *ngIf="showLocations[key]">
							<mat-form-field appearance="outline" color="primary">
								<mat-label class="" for="key" *ngIf="!isLoading[key]">
									{{(key === 'groupID' ? 'group' : key) | titlecase}}
								</mat-label>
                <mat-label *ngIf="isLoading[key]">
                  <div class="select-placeholder-container">
                    Finding {{(key === 'groupID' ? 'group' : key) | titlecase}}s
                    <mat-spinner class="spinner" diameter="20"></mat-spinner>
                  </div>
                </mat-label>
								<mat-select [disabled]="!availableLocations[key] || availableLocations[key]?.length == 0"
									[(value)]="userData[(key == 'groupID' ? 'GROUP_NO' : key.toUpperCase())]"
									name="key" class="custom-control-input"
									[required]="showLocations[key]">
									<mat-option 
									*ngFor="let location of availableLocations[key]" 
									(click)="getFiltersForLevel(key); accessManagementForm.form.markAsDirty(); isValid();"
									[value]="location">
										{{location}}
									</mat-option>
								</mat-select>
								<mat-error>Please select the user's {{key === 'groupID' ? 'group' : key}}</mat-error>
							</mat-form-field>
						</div>
					</ng-container>
				</div>
			</ng-container>

			<h1 class="access-management-headings left-align">Description:</h1>
			<mat-form-field appearance="outline" color="primary">
				<mat-label>Description</mat-label>
				<input (ngModelChange)="userData['POSITION_TITLE']=$event" [ngModel]="userData['POSITION_TITLE'] | unescape"
					ngModel maxLength="100" #positiontitle matInput class="custom-control-input" name="positionTitle">
				<mat-hint *ngIf="userData['POSITION_TITLE']" align="end">{{userData['POSITION_TITLE'].length || 0}}/100</mat-hint>
			</mat-form-field>
		</div>
	</form>
</div>

<div mat-dialog-actions>
	<div *ngIf="!accessManagementForm.valid || !formIsValid" class="subtle-centered-text">Please complete all fields correctly to {{data['type'] === 'update' ? 'update' : 'create new'}} user</div>

	<div *ngIf="!accessManagementForm.dirty && data['type'] === 'update'" class="subtle-centered-text">Change user information to update the user</div>

	<div layout="row" layout-align="space-between center" flex>
		<button [disabled]="!accessManagementForm.valid || !accessManagementForm.dirty || !formIsValid" class="csa-button-solid survey-submit"
			(click)="saveForm()">
			<span *ngIf="data['type'] === 'update'">
				<fa-icon [icon]="faCheck"></fa-icon>
				<span> Save User</span>
			</span>
			<span *ngIf="data['type'] === 'create'">
				<fa-icon [icon]="faPlus"></fa-icon>
				<span> Create User</span>
			</span>
		</button>
		<button *ngIf="data['type'] === 'update'" class="csa-button-outline survey-submit delete-button"
			(click)="removeUser()">
			<span>
				<fa-icon [icon]="faTrash"></fa-icon>
				<span>&nbsp;Remove User</span>
			</span>
		</button>
	</div>
</div>