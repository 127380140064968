import {
  Component, OnDestroy, OnInit, TemplateRef, ViewChild 
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import * as moment from 'moment';
import {
  faPaperclip,
  faPlus,
  faChartPie,
  faTable,
  faSortDown,
  faKey,
  faEdit,
  faStore,
} from '@fortawesome/free-solid-svg-icons';
import { Subject, takeUntil } from 'rxjs';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { SurveysService } from 'src/app/surveys/surveys.service';
import { AdhocSurveyService } from 'src/app/adhoc-surveys/adhoc-survey.service';
import { RecurringSurveyService } from 'src/app/recurring-survey/recurring-survey.service';
import { MrrSurveysService } from 'src/app/mrr-surveys/mrr-surveys.service';
import { reassignArrayPropertyValues } from 'src/app/utils/data';
import { splitRoleByCaps } from 'src/app/utils/user-roles/split-role-by-caps';
import { CreateSurveyDialogComponent } from '../../surveys/create-survey-dialog/create-survey-dialog.component';
import { StoreSelectionDialogComponent } from '../../surveys/store-selection-dialog/store-selection-dialog.component';
import { MessagesComponent } from '../../surveys/messages/messages.component';
import { LoaderService } from '../../common-services/loader.service';
import { CsaAuthService } from '../../auth/csa-auth.service';
import { CreateMRRDialogComponent } from '../../mrr-surveys/create-mrr-dialog/create-mrr-dialog.component';
import { User } from '../../models/user';
import { adminLandingPanels } from './admin-landing-panels.enum';
import { LandingPageService } from '../landing-page.service';

@Component({
  selector: 'app-admin-landing',
  templateUrl: './admin-landing.component.html',
  styleUrls: ['./admin-landing.component.scss'],
})

export class AdminLandingComponent implements OnInit, OnDestroy {
  @ViewChild('recurringTabGroup') recurringTabGroup;

  @ViewChild('tabGroup') tabGroup;

  faPlus = faPlus;

  faChartPie = faChartPie;

  faSortDown = faSortDown;

  faTable = faTable;

  faEdit = faEdit;

  faKey = faKey;

  faStore = faStore;

  faPaperclip = faPaperclip;

  actions = [];

  mrrActions = [];

  allTemplates = [];

  closedSurveys = [];

  date = moment();

  dateFormatted = moment().format();

  daysAvailable = '';

  onceOffAssessmentGroups = ['Active', 'Upcoming'];

  onceOffSelectedGroup = 0;

  onceOffSurveys = [];

  recurringSelectedGroup = 0;

  recurringSelections = ['daily', 'weekly', 'monthly'];

  recurringSurveyGroups = ['Available', 'Upcoming'];

  recurringSurveys = [];

  selectedFrequency = 'All Frequencies';

  surveyCompletion = '';

  adhocSurveyGroups = ['Available', 'Upcoming'];

  adhocSelectedGroup = 0;
 
  adhocSurveys = [];

  adhocSurveyStates = [];

  todaysDay = '0';

  user: User;

  userDetails = {};

  panels: adminLandingPanels[] = [];

  daysMap = new Map([
    ['0', 'Mondays'],
    ['1', 'Tuesdays'],
    ['2', 'Wednesdays'],
    ['3', 'Thursdays'],
    ['4', 'Fridays'],
    ['5', 'Saturdays'],
    ['6', 'Sundays'],
  ]);

  isLoadingOnceOff: boolean = false;

  isLoadingAdhoc: boolean = false;

  isLoadingRecurring: boolean = false;

  isLoadingActions: boolean = false;

  isLoadingTemplates: boolean = false;

  mrrSurveyGroups = ['Available', 'Upcoming', 'Closed'];

  mrrSelectedGroup = 0;

  mrrSurveys = [];

  isLoadingMRR: boolean = false;

  private onDestroy$ = new Subject<void>(); // used to unsubscribe from observables on component destroy

  public adminLandingPanelsEnum = adminLandingPanels; // allows us to use the enum in the template

  // template ref for dashboard panels - if you add another panel to the admin landing, you need to add a template ref for it here and update setDashboardByPositionTitle()
  @ViewChild('onceOffAssessments') onceOffAssessmentsPanel: TemplateRef<any>;

  @ViewChild('adhocAssessments') adhocAssessmentsPanel: TemplateRef<any>;

  @ViewChild('recurringAssessments') recurringAssessmentsPanel: TemplateRef<any>;

  @ViewChild('openActions') openActionsPanel: TemplateRef<any>;

  @ViewChild('templates') templatesPanel: TemplateRef<any>;

  @ViewChild('MRRAssessments') MRRAssessmentsPanel: TemplateRef<any>;

  @ViewChild('MRRActions') MRRActionsPanel: TemplateRef<any>;

  constructor(
    private surveyCompletionData: LoaderService,
    private csaAuth: CsaAuthService,
    private dialog: MatDialog,
    private router: Router,
    private surveyService : SurveysService,
    private adhocService : AdhocSurveyService,
    private recurringService : RecurringSurveyService,
    private mrrService : MrrSurveysService,
    private landingPageService : LandingPageService,
  ) { }

  ngOnInit() {
    this.surveyCompletionData.currentMessage.subscribe(
      (message) => (this.surveyCompletion = message)
    );
    this.user = this.csaAuth.user;

    this.csaAuth.userChanges$().pipe(takeUntil(this.onDestroy$)).subscribe((user) => {
      this.user = user;
      this.setDashboardOrderBasedOnPositionTitle(user.positionTitle);
    });

    this.getAdhocSurveys();
    this.getRecurringSurveys();
    this.getOnceOffAssessments();
    this.getTemplates();
    this.getActions();
    this.getMrrActions();
    this.getMRRassessments();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  /**
   * Some position titles require the dashboard tiles in a different order.
   * For example, state admin managers require MRR panels at the top.
   *
   * @param positiontitle - Position title of the currently signed in user.
   */
  private setDashboardOrderBasedOnPositionTitle(positiontitle: string) {
    const userIsStateAdminManager = positiontitle.includes('SAM'); // State admin managers have SAM in their position title

    if (userIsStateAdminManager) {
      // state admin managers require MRR panels at the top of the admin landing
      this.panels = [adminLandingPanels.MRRAssessments, adminLandingPanels.MRRActions, adminLandingPanels.onceOffAssessments, adminLandingPanels.adhocAssessments, adminLandingPanels.recurringAssessments, adminLandingPanels.openActions, adminLandingPanels.templates];
    } else {
      this.panels = [adminLandingPanels.onceOffAssessments, adminLandingPanels.adhocAssessments, adminLandingPanels.recurringAssessments, adminLandingPanels.openActions, adminLandingPanels.templates, adminLandingPanels.MRRAssessments, adminLandingPanels.MRRActions];
    }
  }

  /**
   * We order our admin landing panels dynamically, to assist with this, this function returns
   * the template ref for a given panel by the name of the panel.
   *
   * @param panel - Name of the panel to retrieve a template reference for.
   * @returns Template ref for requested panel.
   */
  public getTemplateRefByName(panel: adminLandingPanels) {
    switch (panel) {
      case adminLandingPanels.MRRActions:
        return this.MRRActionsPanel;
      case adminLandingPanels.MRRAssessments:
        return this.MRRAssessmentsPanel;
      case adminLandingPanels.adhocAssessments:
        return this.adhocAssessmentsPanel;
      case adminLandingPanels.onceOffAssessments:
        return this.onceOffAssessmentsPanel;
      case adminLandingPanels.openActions:
        return this.openActionsPanel;
      case adminLandingPanels.recurringAssessments:
        return this.recurringAssessmentsPanel;
      case adminLandingPanels.templates:
        return this.templatesPanel;
    }
  }

  goToReports() {
    switch (this.user['division']) {
      case 'Supermarkets':
        window.open(
          'https://datastudio.google.com/reporting/57f5fafb-e776-4ae1-8880-77f1a9a6b91d',
          '_blank'
        );
        break;
      case 'Metro':
        window.open(
          'https://datastudio.google.com/reporting/408f1c5c-da33-4a91-b75d-2a9c55d4ce78',
          '_blank'
        );
        break;
      case 'DC':
        window.open(
          'https://datastudio.google.com/reporting/3ce18dd9-a183-4e86-ac4f-f40dee5d8dd1',
          '_blank'
        );
        break;
      case 'CFC':
        window.open(
          'https://datastudio.google.com/reporting/74bbdae1-4592-466b-9a53-c8afb58cacc6',
          '_blank'
        );
        break;
      case 'Bigw':
        window.open(
          'https://datastudio.google.com/reporting/25e6c115-e482-479f-b9cd-5fb632d69436',
          '_blank'
        );
        break;
      case 'Woolworths NZ Stores':
        window.open(
          'https://datastudio.google.com/reporting/d99e6dd2-1316-4ace-a497-f691673205ce',
          '_blank'
        );
        break;
      case 'Woolworths NZ eStores':
        window.open(
          'https://datastudio.google.com/reporting/3308b33e-fb4f-497f-9325-a192606175d5',
          '_blank'
        );
        break;
      case 'Woolworths NZ Primary Connect':
        window.open(
          'https://datastudio.google.com/reporting/4cbb12f6-a497-49d5-a8b6-2f40f2c8ac88',
          '_blank'
        );
        break;
      default:
        break;
    }
  }

  getActions() {
    setTimeout(() => {
      this.isLoadingActions = true;
      this.actions = [];
    }, 0);
    this.surveyService
      .getActionList(
        '/0',
        '/5',
        `?status=pending&division=${this.user['division']}`
      )
      .subscribe(
        (actionsData) => {
          if (actionsData['actions']) {
            this.actions = actionsData['actions'];
          }
          this.isLoadingActions = false;
        },
        (error) => {
          this.isLoadingActions = false;
        }
      );
  }

  getMrrActions() {
    setTimeout(() => {
      this.isLoadingActions = true;
      this.actions = [];
    }, 0);
    this.surveyService
      .getActionList(
        '/0',
        '/5',
        `?status=pending&division=${this.user['division']}&filterName=mrr`
      )
      .subscribe(
        (actionsData) => {
          if (actionsData['actions']) {
            this.mrrActions = actionsData['actions'];
          }
          this.isLoadingActions = false;
        },
        (error) => {
          this.isLoadingActions = false;
        }
      );
  }

  getTemplates() {
    setTimeout(() => {
      this.isLoadingTemplates = true;
      this.allTemplates = [];
    }, 0);
    this.surveyService
      .getTemplates(`/list/${this.user['division']}/0/5`)
      .subscribe(
        (templateData) => {
          // Convert all templateData['templates'] filterName property values to 'gps' when 'found to be 'eitb' before
          // assigning templateData['templates'] to allTemplates.        
          this.allTemplates = reassignArrayPropertyValues(templateData['templates'], 'filterName', 'eitb', 'GPS');
          this.isLoadingTemplates = false;
        },
        (error) => {
          this.isLoadingTemplates = false;
        }
      );
  }

  getOnceOffAssessments() {
    setTimeout(() => {
      this.isLoadingOnceOff = true;
      this.onceOffSurveys = [];
    }, 0);

    let status;
    if (this.onceOffSelectedGroup == 1) {
      status = 'active';
    } else if (this.onceOffSelectedGroup == 0) {
      status = 'published';
    }

    this.surveyService
      .getSurveys('/admin', this.user['division'], status, '/0', '/5')
      .subscribe(
        (surveysData) => {
          this.onceOffSurveys = surveysData['surveys'];
          this.onceOffSurveys.forEach((survey) => {
            if (!survey.templateTitle) {
              this.landingPageService
                .getTemplateDetails(survey.template_id)
                .subscribe((templateData) => {
                  survey.templateTitle = templateData['title'];
                });
            }
          });
          this.isLoadingOnceOff = false;
        },
        (error) => {
          this.isLoadingOnceOff = false;
        }
      );
  }

  getAdhocSurveys() {
    setTimeout(() => {
      this.isLoadingAdhoc = true;
      this.adhocSurveys = [];
    }, 0);

    let status;
    if (this.adhocSelectedGroup == 1) {
      status = 'active';
    } else if (this.adhocSelectedGroup == 0) {
      status = 'published';
    }

    this.adhocService
      .getOnTheFlySurveytemplates(
        '',
        this.user['division'],
        status,
        '/0',
        '/5',
        'adhoc'
      )
      .subscribe(
        (surveysData) => {
          // Reassign filterName property value from 'eitb' to 'gps'
          this.adhocSurveys = reassignArrayPropertyValues(surveysData['surveys'], 'filterName', 'eitb', 'GPS');               
          this.getAdhocUsers();
          this.isLoadingAdhoc = false;
        },
        (error) => {
          this.isLoadingAdhoc = false;
        }
      );
  }

  getRecurringSurveys(frequency = undefined) {
    setTimeout(() => {
      this.isLoadingRecurring = true;
      this.recurringSurveys = [];
    }, 0);

    let status;
    if (this.recurringSelectedGroup == 1) {
      status = 'active';
    } else if (this.recurringSelectedGroup == 0) {
      status = 'published';
    }

    let query;
    if (!frequency || frequency == 'All Frequencies') {
      this.selectedFrequency = 'All Frequencies';
      query = 'recurring';
    } else {
      this.selectedFrequency = frequency;
      query = `recurring&frequency=${frequency}`;
    }

    this.recurringService
      .getRecurringSurveys(this.user['division'], status, '/0', '/5', query)
      .subscribe(
        (surveysData) => {
          this.recurringSurveys = surveysData['surveys'];
          this.isLoadingRecurring = false;
        },
        (error) => {
          this.isLoadingRecurring = false;
        }
      );
  }

  getAdhocUsers() {
    this.adhocSurveys.forEach((adhocSurvey) => {
      // convert to UTC and then a specific format
      if (
        adhocSurvey['status'] == 'closed'
        && moment().isAfter(
          moment(adhocSurvey['endDate'], [
            'DD/MM/YYYY',
            'DD MMM YYYY',
            moment.ISO_8601,
          ]).format('YYYY-MM-DD')
        )
      ) {
        const maxEndDate = moment(adhocSurvey['maxEndDate'], [
          'DD/MM/YYYY',
          'DD MMM YYYY',
          moment.ISO_8601,
        ]).format('YYYY-MM-DD');
        adhocSurvey['daysToEdit'] = moment(maxEndDate).diff(moment(), 'days') + 1;
      }
      // adhocSurvey["nextTargetRoles"] = [];
      // adhocSurvey["targetRoles"].forEach((targetRole) => {
      //   switch (targetRole) {
      //     case "Store":
      //       if (this.user["division"] == "DC") {
      //         adhocSurvey["nextTargetRoles"].push("DCs");
      //       } else {
      //         adhocSurvey["nextTargetRoles"].push("Stores");
      //       }
      //       break;
      //     case "MultiStore":
      //       if (this.user["division"] == "DC") {
      //         adhocSurvey["nextTargetRoles"].push("Multi DC Users");
      //       } else {
      //         adhocSurvey["nextTargetRoles"].push("Multi Store Users");
      //       }
      //       break;
      //     case "GroupManager":
      //       adhocSurvey["nextTargetRoles"].push("Group Managers");
      //       break;
      //     case "OperationManager":
      //       adhocSurvey["nextTargetRoles"].push("Operations Managers");
      //       break;
      //     case "StateManager":
      //       adhocSurvey["nextTargetRoles"].push("State Managers");
      //       break;
      //     case "Admin":
      //       adhocSurvey["nextTargetRoles"].push("Admin Users");
      //       break;
      //     case "RegionManager":
      //       adhocSurvey["nextTargetRoles"].push("Region Managers");
      //       break;
      //     case "AreaManager":
      //       adhocSurvey["nextTargetRoles"].push("Area Managers");
      //       break;
      //     case "StoreLossManager":
      //       adhocSurvey["nextTargetRoles"].push("Store Loss Managers");
      //       break;
      //     case "StoreLossPartner":
      //       adhocSurvey["nextTargetRoles"].push("Store Loss Partners");
      //       break;
      //     case "NationalView":
      //       adhocSurvey["nextTargetRoles"].push("National View Users");
      //       break;
      //     default:
      //       break;
      //   }
      // });
    });
  }

  getMRRassessments() {
    setTimeout(() => {
      this.isLoadingMRR = true;
      this.mrrSurveys = [];
    }, 0);

    let status;
    if (this.mrrSurveyGroups[this.mrrSelectedGroup] == 'Available') {
      status = 'published';
    } else if (this.mrrSurveyGroups[this.mrrSelectedGroup] == 'Closed') {
      status = 'closed';
    } else if (this.mrrSurveyGroups[this.mrrSelectedGroup] == 'Upcoming') {
      status = 'active';
    }

    this.mrrService
      .getmrrSurveys(
        '',
        this.user['division'],
        status,
        '/0',
        '/5',
        'adhoc',
        'mrr'
      )
      .subscribe(
        (surveysData) => {
          this.mrrSurveys = surveysData['surveys'];
          this.isLoadingMRR = false;
        },
        (error) => {
          this.isLoadingMRR = false;
        }
      );
  }

  // Exposes global function splitRoleByCaps to template
  splitRoleByCaps(value): string {
    return splitRoleByCaps(value, this.user['division']);
  }

  adhocTabChanged(tabChangeEvent: MatTabChangeEvent) {
    this.adhocSelectedGroup = tabChangeEvent.index;
    this.getAdhocSurveys();
  }

  recurringTabChanged(tabChangeEvent: MatTabChangeEvent) {
    this.recurringSelectedGroup = tabChangeEvent.index;
    this.getRecurringSurveys(this.selectedFrequency);
  }

  onceOffTabChanged(tabChangeEvent: MatTabChangeEvent) {
    this.onceOffSelectedGroup = tabChangeEvent.index;
    this.getOnceOffAssessments();
  }

  mrrTabChanged(tabChangeEvent: MatTabChangeEvent) {
    this.mrrSelectedGroup = tabChangeEvent.index;
    this.getMRRassessments();
  }

  isDueInWeek(date) {
    const m = moment(date, ['DD/MM/YYYY', 'DD MMM YYYY', moment.ISO_8601]);
    const currentDate = moment();
    const isDueInWeek = currentDate.diff(m, 'days') > -7 && currentDate.diff(m, 'days') <= 0;
    return isDueInWeek;
  }

  isOverdue(date) {
    const m = moment(date, ['DD/MM/YYYY', 'DD MMM YYYY', moment.ISO_8601]);
    const currentDate = moment();
    return m < currentDate;
  }

  getDate(date) {
    date = moment(date, ['DD/MM/YYYY', 'DD MMM YYYY', moment.ISO_8601]).format(
      'DD MMM YYYY'
    );
    return date;
  }

  createSurvey() {
    const dialogRef = this.dialog.open(CreateSurveyDialogComponent, {
      backdropClass: 'dialog-backdrop',
      minWidth: '90vw',
      minHeight: 'calc(100vh - 90px)',
      height: 'auto',
      data: { templateid: '', templateDivision: this.user['division'] },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.ngOnInit();
    });
  }

  createSurveyMRR() {
    const dialogRef = this.dialog.open(CreateMRRDialogComponent, {
      backdropClass: 'dialog-backdrop',
      minWidth: '90vw',
      minHeight: 'calc(100vh - 90px)',
      height: 'auto',
      data: { templateid: '', templateDivision: this.user['division'] },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.ngOnInit();
    });
  }

  createOnTheFlySurvey(surveyID) {
    const storeSelectDialog = this.dialog.open(StoreSelectionDialogComponent, {
      backdropClass: 'dialog-backdrop',
      minWidth: '70%',
      maxWidth: '90vw',
      height: 'auto',
      data: {
        allowClose: true,
        message:
          'Please select the store you are completing this Adhoc Assessment for.',
      },
    });
    storeSelectDialog.afterClosed().subscribe((result) => {
      if (result['storeNo']) {
        const adhocAssessment = {};
        adhocAssessment['storeID'] = result['storeNo'];
        adhocAssessment['survey_id'] = surveyID;
        this.surveyService
          .getAboveStoreSurveyID(
            surveyID,
            `?storeID=${adhocAssessment['storeID']}`
          )
          .subscribe((surveyDetails) => {
            if (surveyDetails['activeResponseCount'] > 0) {
              const confirmMultipleDialog = this.dialog.open(MessagesComponent, {
                data: {
                  heading: 'Active Responses',
                  message:
                    `You currently have ${ 
                      surveyDetails['activeResponseCount']
                    } Active Responses for this Store & Assessment. Are you sure you would like to create a new one?`,
                  closeText: 'Create',
                  cancelText: 'See Active Responses',
                },
                backdropClass: 'dialog-backdrop',
              });
              confirmMultipleDialog.afterClosed().subscribe((result) => {
                if (result == 'logout') {
                  this.adhocService
                    .createOnTheFlySurvey(adhocAssessment)
                    .subscribe((reply) => {
                      if (reply['status'] == 200) {
                        const dialogSuccess = this.dialog.open(
                          MessagesComponent,
                          {
                            data: {
                              heading: 'Adhoc Assessment Created',
                              closeText: 'OK',
                              message:
                                'Adhoc Assessment has been created. Please click ok to go to the Adhoc Assessment, and begin completing it',
                            },
                            disableClose: true,
                            backdropClass: 'dialog-backdrop',
                          }
                        );
                        dialogSuccess.afterClosed().subscribe((result) => {
                          // window.location.reload();
                          this.router.navigate([
                            '/assessment-response-edit',
                            reply['response_id'],
                          ]);
                        });
                      } else {
                        this.dialog.open(MessagesComponent, {
                          data: {
                            heading: "Couldn't Schedule Adhoc Assessment",
                            message:
                              `Please email ${ 
                                this.user['supportEmail']
                              } for support`,
                          },
                          disableClose: true,
                          backdropClass: 'dialog-backdrop',
                        });
                      }
                    });
                } else {
                  this.router.navigate([
                    '/onthefly-survey-details',
                    surveyID,
                  ]);
                }
              });
            } else {
              this.adhocService
                .createOnTheFlySurvey(adhocAssessment)
                .subscribe((reply) => {
                  if (reply['status'] == 200) {
                    const dialogSuccess = this.dialog.open(MessagesComponent, {
                      data: {
                        heading: 'Adhoc Assessment Created',
                        closeText: 'OK',
                        message:
                          'Adhoc Assessment has been created. Please click ok to go to the Adhoc Assessment, and begin completing it',
                      },
                      disableClose: true,
                      backdropClass: 'dialog-backdrop',
                    });
                    dialogSuccess.afterClosed().subscribe((result) => {
                      // window.location.reload();
                      this.router.navigate([
                        '/assessment-response-edit',
                        reply['response_id'],
                      ]);
                    });
                  } else {
                    this.dialog.open(MessagesComponent, {
                      data: {
                        heading: "Couldn't Schedule Adhoc Assessment",
                        message:
                          `Please email ${ 
                            this.user['supportEmail']
                          } for support`,
                      },
                      disableClose: true,
                      backdropClass: 'dialog-backdrop',
                    });
                  }
                });
            }
          });
      } else {
        this.dialog.open(MessagesComponent, {
          data: {
            heading: 'Adhoc Assessment Cancelled',
            message:
              'You need to select a store for your Adhoc Assessment before starting.',
          },
          disableClose: true,
          backdropClass: 'dialog-backdrop',
        });
      }
    });
  }

  createMRR(survey) {
    const storeSelectDialog = this.dialog.open(StoreSelectionDialogComponent, {
      backdropClass: 'dialog-backdrop',
      minWidth: '70%',
      maxWidth: '90vw',
      height: 'auto',
      data: {
        allowClose: true,
        message:
          'Please select the store you are completing this Material Risk Review Assessment for.',
        surveyTitle: survey['title'],
        sections: survey['sectionHeading'],
        startDate: survey['startDate'],
        filterName: 'mrr',
      },
    });
    storeSelectDialog.afterClosed().subscribe((result) => {
      if (result['storeNo']) {
        const mrrAssessment = {};
        mrrAssessment['storeID'] = result['storeNo'];
        mrrAssessment['survey_id'] = survey['id'];
        this.surveyService
          .getAboveStoreSurveyID(
            survey['id'],
            `?storeID=${mrrAssessment['storeID']}`
          )
          .subscribe((surveyDetails) => {
            if (surveyDetails['activeResponseCount'] > 0) {
              const confirmMultipleDialog = this.dialog.open(MessagesComponent, {
                data: {
                  heading: 'Active Responses',
                  message:
                    `You currently have ${ 
                      surveyDetails['activeResponseCount']
                    } Active Responses for this Store & Assessment.`,
                  closeText: 'See Active Responses',
                  // cancelText: "See Active Responses",
                },
                backdropClass: 'dialog-backdrop',
              });
              confirmMultipleDialog.afterClosed().subscribe((result) => {
                if (result == 'logout') {
                  this.router.navigate(['/mrr-survey-details', survey['id']]);
                }
              });
            } else {
              this.adhocService
                .createOnTheFlySurvey(mrrAssessment)
                .subscribe((reply) => {
                  if (reply['status_message'] == 'Already created') {
                    this.dialog.open(MessagesComponent, {
                      data: {
                        heading: 'You Have Already Responded',
                        closeText: 'OK',
                        message:
                          'You have already created response for this store. No another response can create for this particular store.',
                      },
                      disableClose: true,
                      backdropClass: 'dialog-backdrop',
                    });
                  } else if (reply['status'] == 200) {
                    const dialogSuccess = this.dialog.open(MessagesComponent, {
                      data: {
                        heading: 'Material Risk Review Assessment Created',
                        closeText: 'OK',
                        message:
                          'Material Risk Review Assessment has been created. Please click ok to go to the Material Risk Review Assessment, and begin completing it',
                      },
                      disableClose: true,
                      backdropClass: 'dialog-backdrop',
                    });
                    dialogSuccess.afterClosed().subscribe((result) => {
                      // window.location.reload();
                      this.router.navigate([
                        '/mrr-response',
                        reply['response_id'],
                      ]);
                    });
                  } else {
                    this.dialog.open(MessagesComponent, {
                      data: {
                        heading: "Couldn't Schedule Material Risk Review Assessment",
                        message:
                          `Please email ${ 
                            this.user['supportEmail']
                          } for support`,
                      },
                      disableClose: true,
                      backdropClass: 'dialog-backdrop',
                    });
                  }
                });
            }
          });
      } else {
        this.dialog.open(MessagesComponent, {
          data: {
            heading: 'Material Risk Review Assessment Cancelled',
            message:
              'You need to select a store for your Material Risk Review Assessment before starting.',
          },
          disableClose: true,
          backdropClass: 'dialog-backdrop',
        });
      }
    });
  }

  surveyDetails(message: string) {
    this.surveyCompletionData.changeMessage(message);
  }

  goToStandradAssessment() {
    this.router.navigate([
      '/more-evidence',
      'standard',
    ]);
  }

  goToMrrAssessment() {
    this.router.navigate([
      '/more-evidence',
      'mrr',
    ]);
  }
}
