import * as moment from 'moment';

/**
 * Determines whether the "Mark as Complete" checkbox should be enabled for a given action.
 *
 * @param {object} action - The action object to evaluate.
 * @param {string} action.modifiedOn - The timestamp of the last modification of the action (ISO 8601 format).
 * @param {string} action.status - The current status of the action. Expected to be `'complete'`.
 * @returns {boolean | undefined} 
 * - `true` if the checkbox should be enabled.
 * - `false` if the checkbox should be disabled.
 * - `undefined` if the action does not meet the conditions for either enabling or disabling the checkbox.
 *
 * The checkbox is enabled if:
 * - The action's status is `'complete'`.
 * - The time difference between the current moment and the `modifiedOn` timestamp is less than 24 hours.
 *
 * The checkbox is disabled if:
 * - The action's status is `'complete'`.
 * - The time difference between the current moment and the `modifiedOn` timestamp is greater than 24 hours.
 *
 * The output is `undefined` if:
 * - The action does not have a status of `'complete'`.
 * - The `modifiedOn` timestamp is missing or invalid.
 * - The input action is not valid (e.g., `null` or `undefined`).
 */
export function isActionMarkAsCompleteCheckboxEnabled(action): boolean | undefined {
  if (!action) {
    return false;
  }
  if (
    moment().diff(moment(action['modifiedOn']), 'hours') > 24
    && action['status'] == 'complete'
  ) {
    return false;
  } if (
    moment().diff(moment(action['modifiedOn']), 'hours') < 24
    && action['status'] == 'complete'
  ) {
    return true;
  }
  return undefined;
}
