<div class="search-tags-content">
  <div class="search-tags-search-bar">
    <app-autocomplete-dropdown
        [searchTerm]="searchTagTerm"
        [searchTermBufferInMs]="500"
        [searchResults]="searchTagResults"
        [selectSearchTextOnEnter]="true"
        [placeholder]="'Enter tag text to be added'"
        [accessibilityLabel]="'Enter tag text to be added'"
        [errorMessage]="searchTagMessage" 
        [showErrorMessage]="showSearchTagMessage"
        (searchTermChanged)="searchTermChanged($event)"
        (selectedItem)="addItem($event)"
    >
    </app-autocomplete-dropdown>
  </div>      
  <div class="search-tags">
    <button 
      type="button"
      *ngFor="let tag of tagList"
      tabindex="-1"
      class="chip-input"
    >
      {{ tag }}
      <app-icon iconName="roundXMark" (click)="removeTag(tag)" class="delete-icon"></app-icon>
    </button>
  </div>
</div>
