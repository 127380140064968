<div class="page-container">
  <h1>{{thisTemplate['title'] | unescape}}</h1>

  <div class="question-options-row" *ngIf="thisTemplate['status'] === 'active' && (('adhocAssessments.create' | accessCheck) || ('recurringAssessments.create' | accessCheck) || ('onceOffAssessments.create' | accessCheck))">
    <div class="filter-chips">
      <div class="chipDrop">
        <mat-chip-list class="modified-chip" aria-label="Filters">
          <mat-chip (click)="createSurvey()" color="primary" selected>
            <fa-icon class="chip-left-icon" [icon]="faPlus"></fa-icon>
            <span>
              Create New Assessment With This Template
            </span>
          </mat-chip>
        </mat-chip-list>
      </div>
    </div>
  </div>

  <h2 class="left-align">Template Details:</h2>
  <div class="audit-tool-cards-container">
    <div class="audit-tool-card list-screen-card p2 remove-x-margins">
      <div class="question-options-row">
        <div class="survey-detail-block">
          <h4>Division:</h4>
          <p>{{thisTemplate['division'] === 'Bigw' ? 'Big W' : thisTemplate['division']}}</p>
        </div>
        <div class="survey-detail-block">
          <h4>No. of Questions:</h4>
          <p>{{thisTemplate['questions']?.length}}</p>
        </div>
        <div class="survey-detail-block" *ngIf="thisTemplate['lastModified']">
          <h4>Last Modified:</h4>
          <p>  {{ thisTemplate['lastModified'] | date :'dd MMM y'  }} </p>
        </div>
        <div class="survey-detail-block" *ngIf="thisTemplate['surveysPublished']">
          <h4>Active Assessments:</h4>
          <p>{{ thisTemplate['surveysPublished'] }}</p>
        </div>
        <div class="survey-detail-block" *ngIf="thisTemplate['filterName']">
          <h4>Template Type:</h4>
          <p>{{ thisTemplate['filterName'] | uppercase }}</p>
        </div>
      </div>
      <div *ngIf="thisTemplate['status'] === 'archived'" class="question-options-row">
        <div class="orange-text medium-text">
          <h4>This Template has been Archived</h4>
        </div>
      </div>
    </div>
  </div>

  <div class="survey-container">
    <h2 class="left-align">Template Questions:</h2>
    <div class="question-options-row no-bottom-margin">
      <h3 class="light-green medium-text expand-collapse" (click)="allExpandState = !allExpandState">
        <span *ngIf="allExpandState == false">
          &nbsp;&nbsp;Show All
          <fa-icon [icon]="faAngleDown"></fa-icon>
        </span>
        <span *ngIf="allExpandState == true">
          &nbsp;&nbsp;Collapse All
          <fa-icon [icon]="faAngleUp"></fa-icon>
        </span>
        &nbsp;&nbsp;
      </h3>
    </div>

    <mat-accordion class="questions-accordion" multi>
      <mat-expansion-panel [expanded]="allExpandState" #panel hideToggle
        *ngFor="let heading of thisTemplate['sectionHeading']; let i = index;" (opened)="setOpened(i)"
        (closed)="setClosed(i)">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <span class="neg-5-margin hide-on-mobile">
              <svg height="3" width="200">
                <line x1="0" y1="0" x2="200" y2="0" style="stroke-width:4" />
              </svg>
            </span>
            &nbsp;&nbsp;<span>{{heading}}</span>&nbsp;&nbsp;<fa-icon *ngIf="openItems.indexOf(i) === -1"
              [icon]="faAngleDown">
            </fa-icon>
            <fa-icon *ngIf="openItems.indexOf(i) > -1" [icon]="faAngleUp"></fa-icon>&nbsp;&nbsp;
            <span class="neg-5-margin hide-on-mobile">
              <svg height="3" width="200">
                <line x1="0" y1="0" x2="200" y2="0" style="stroke-width:4" />
              </svg>
            </span>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <div>
          <div
            *ngFor="let question of (thisTemplate['questions'] | groupQuestions:{sectionHeading: heading}); let j = index;">
            <div class="audit-tool-card question-card">
              <div class="p2">
                <div class="question-count">
                  <span class="large-count">Question {{j+1}}</span> of
                  {{(thisTemplate['questions'] | groupQuestions:{sectionHeading: heading}).length}}
                </div>
                <div class="question-title" [innerHTML]="question.title"></div>
                <div *ngIf="question.type === 'boolean' && !question['radioOptions']" class="question-options-row">
                  <span class="csa-button-outline small no-effect">Yes</span>
                  <span class="csa-button-outline small no-effect">No</span>
                </div>
                <div *ngIf="question.type === 'boolean' && question['radioOptions']" class="question-options-row">
                  <span class="csa-button-outline small no-effect">{{question.radioOptions[0].answer}} <span
                      class="red-text action-alert" *ngIf="question.radioOptions[0].enforceAction == true">
                      <fa-icon [icon]="faExclamationCircle"></fa-icon>
                    </span></span>
                  <span class="csa-button-outline small no-effect">{{question.radioOptions[1].answer}} <span
                      class="red-text action-alert" *ngIf="question.radioOptions[1].enforceAction == true">
                      <fa-icon [icon]="faExclamationCircle"></fa-icon>
                    </span></span>
                </div>
                <div *ngIf="question.type === 'boolean-na' && !question['radioOptions']" class="question-options-row">
                  <span class="csa-button-outline small no-effect">Yes</span>
                  <span class="csa-button-outline small no-effect">No</span>
                  <span class="csa-button-outline small no-effect">N/A</span>
                </div>
                <div *ngIf="question.type === 'boolean-na' && question['radioOptions']" class="question-options-row">
                  <span class="csa-button-outline small no-effect">{{question.radioOptions[0].answer}} <span
                      class="red-text action-alert" *ngIf="question.radioOptions[0].enforceAction == true">
                      <fa-icon [icon]="faExclamationCircle"></fa-icon>
                    </span></span>
                  <span class="csa-button-outline small no-effect">{{question.radioOptions[1].answer}} <span
                      class="red-text action-alert" *ngIf="question.radioOptions[1].enforceAction == true">
                      <fa-icon [icon]="faExclamationCircle"></fa-icon>
                    </span></span>
                  <span class="csa-button-outline small no-effect">{{question.radioOptions[2].answer}} <span
                      class="red-text action-alert" *ngIf="question.radioOptions[2].enforceAction == true">
                      <fa-icon [icon]="faExclamationCircle"></fa-icon>
                    </span></span>
                </div>
                <div *ngIf="question.type === 'radio'" class="question-options-column">
                  <span class="csa-button-outline no-effect">{{question.radioOptions[0].answer}} <span
                      class="red-text action-alert" *ngIf="question.radioOptions[0].enforceAction == true">
                      <fa-icon [icon]="faExclamationCircle"></fa-icon>
                    </span></span>
                  <span class="csa-button-outline no-effect">{{question.radioOptions[1].answer}} <span
                      class="red-text action-alert" *ngIf="question.radioOptions[1].enforceAction == true">
                      <fa-icon [icon]="faExclamationCircle"></fa-icon>
                    </span></span>
                  <span class="csa-button-outline no-effect"
                    *ngIf="question.radioOptions[2]">{{question.radioOptions[2].answer}} <span
                      class="red-text action-alert" *ngIf="question.radioOptions[2].enforceAction == true">
                      <fa-icon [icon]="faExclamationCircle"></fa-icon>
                    </span></span>

                  <!-- <span class="csa-button-outline no-effect">Red (Non Compliant)</span>
                                                                                                                              <span class="csa-button-outline no-effect">Amber (Partial Break Down)</span>
                                                                                                                              <span class="csa-button-outline no-effect">Green (Compliant)</span>
                                                                                                                              <span class="csa-button-outline small no-effect">{{question.radioOptions[0]}}</span>
                                                                                                                              <span class="csa-button-outline small no-effect">{{question.radioOptions[1]}}</span>
                                                                                                                              <span class="csa-button-outline small no-effect">{{question.radioOptions[2]}}</span>  -->
                </div>
                <div *ngIf="question.type === 'freeText' " class="question-options-column">
                  <!-- <span class="csa-button-outline big no-effect">Genenral info Free text box Field</span> -->
                  <mat-form-field appearance="outline" color="primary">
                    <mat-label class="">General Text Field</mat-label>
                    <textarea cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" matInput
                      #noteSection (ngModelChange)="question['notes']=$event" [ngModel]="question['notes'] | unescape"
                      class="custom-control-input" name="{{question['id'] + 'note'}}" disabled></textarea>
                    <mat-error>Please Enter a Text</mat-error>
                  </mat-form-field>
                </div>
                <div class="med-green medium-text" *ngIf="question['stateGeo']">
                  Restricted To:
                  <span *ngFor="let stateGeo of question['stateGeo']; let k = index;">
                    {{stateGeo}}
                    <span *ngIf="k < question['stateGeo'].length - 1">, </span>
                  </span>
                </div>
                <div class="med-green medium-text" *ngIf="question['region']">
                  Restricted To:
                  <span *ngFor="let region of question['region']; let k = index;">
                    {{region}}
                    <span *ngIf="k < question['region'].length - 1">, </span>
                  </span>
                </div>
                <div class="med-green medium-text" *ngIf="question['zone']">
                  Restricted To:
                  <span *ngFor="let region of question['zone']; let k = index;">
                    {{region}}
                    <span *ngIf="k < question['zone'].length - 1">, </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
