import {
  Component, EventEmitter, Input, OnInit, Output 
} from '@angular/core';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
})
export class AccordionComponent implements OnInit {
  @Input() accordionSize: 'large' | 'medium' | 'small' = 'medium';

  @Input() headingLevel!: 1 | 2 | 3 | 4 | 5 | 6 | '1' | '2' | '3' | '4' | '5' | '6';

  @Input() showHeadingIcon = false;

  @Input() noMargin = false;

  @Input() expanded: boolean = false;

  @Output() toggleClick = new EventEmitter<boolean>();

  /**
   * Expands or collapses the accordion panel.
   */
  toggleState() {
    this.expanded = !this.expanded;
    this.toggleClick.emit(this.expanded);
  }

  ngOnInit() {
    this.checkMissingInputs();
  }

  /**
   * Throw errors if any required inputs are missing.
   */
  checkMissingInputs() {
    if (!this.headingLevel) {
      throw ('Please specify a heading level for the accordion component');
    }
  }
}
