import {
  Component,
  EventEmitter,
  OnInit,
  ChangeDetectorRef,
  NgZone,
} from '@angular/core';
import {
  MatDialog,
} from '@angular/material/dialog';

import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import {
  faPencilAlt, faPaperPlane, faCircleNotch, faCamera, faPlus, faArrowLeft 
} from '@fortawesome/free-solid-svg-icons';
import { Location } from '@angular/common';
import Compressor from 'compressorjs';
import { isActionMarkAsCompleteCheckboxEnabled } from 'src/app/utils/is-action-mark-as-complete-checkbox-enabled/is-action-mark-as-complete-checkbox-enabled';
import { CsaAuthService } from '../../auth/csa-auth.service';
import { PhotoPreviewComponent } from '../photo-preview/photo-preview.component';
import { PhotoCarouselComponent } from '../photo-carousel/photo-carousel.component';
import { CsaBackEndService } from '../../common-services/csa-back-end.service';
import { MessagesComponent } from '../messages/messages.component';
import { LoaderService } from '../../common-services/loader.service';
import { User } from '../../models/user';
import { SurveysService } from '../surveys.service';

@Component({
  selector: 'app-action-details',
  templateUrl: './action-details.component.html',
  styleUrls: ['./action-details.component.scss'],
})
export class ActionDetailsComponent implements OnInit {
  actionID = '';

  actions: any[];

  dateChangeDetails = {};

  dateChanges = [];

  dateToday = new Date();

  dueDate;

  editDueDate = false;

  evidenceCount = 0;

  faArrowLeft = faArrowLeft;

  faCamera = faCamera;

  faCircleNotch = faCircleNotch;

  faPaperPlane = faPaperPlane;

  faPencilAlt = faPencilAlt;

  faPlus = faPlus;

  maxDate;

  minDate = new Date();

  photos = [];

  action = {};

  uploading = false;

  user: User;

  worknotes = [];

  constructor(
    private _location: Location,
    private csaAuth: CsaAuthService,
    private dialog: MatDialog,
    private csaBackEnd: CsaBackEndService,
    private actRoute: ActivatedRoute,
    private router: Router,
    private cd: ChangeDetectorRef,
    private zone: NgZone,
    private loader: LoaderService,
    private surveyService : SurveysService,
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
  }

  ngOnInit() {
    this.user = this.csaAuth.user;
    this.actionID = this.actRoute.snapshot.paramMap.get('id');
    this.action['questionTitle'] = '';

    this.surveyService.getAction(this.actionID).subscribe((message) => {
      this.action = message['action'];

      if (this.action['workNotes']) {
        for (let i = 0; i < this.action['workNotes'].length; i++) {
          if (this.action['workNotes'][i].notes == 'Due date updated.') {
            this.addDate(this.action['workNotes'][i]);
          } else if (
            this.action['workNotes'][i].notes != 'Status updated.'
          ) {
            this.worknotes.push(this.action['workNotes'][i]);
          }
        }
      } else {
        this.worknotes = [];
      }
      this.action['checkEnabled'] = isActionMarkAsCompleteCheckboxEnabled(this.action);
      this.dueDate = new Date(this.action['dueDate']);
      this.maxDate = new Date(this.action['createdOn']);
      this.maxDate.setFullYear(this.maxDate.getFullYear() + 1);
      this.maxDate.setDate(this.maxDate.getDate() - 1);  
    });    
  }

  backClicked() {
    this._location.back();
  }

  checkToggle() {
    if (this.action['status'] == 'pending') {
      this.action['status'] = 'complete';
      this.action['checkEnabled'] = true;

      const updates = {};
      updates['status'] = 'complete';
      updates['modifiedBy'] = this.user.email;
      const updateParams = `/${this.actionID}/status`;

      const requestJSONPayload = this.csaBackEnd.attachFirewallBypassPrefixes(updates, ['notes']);
      this.surveyService.updateAction(updateParams, requestJSONPayload).subscribe(
        (reply) => {
          console.log(reply);
        },
        (err) => {
          this.dialog.open(MessagesComponent, {
            data: {
              heading: 'Cannot Complete Action',
              message:
                `Please email ${this.user['supportEmail']} for support`,
            },
            disableClose: true,
            backdropClass: 'dialog-backdrop',
          });
          console.log(err);
        }
      );
    } else {
      this.action['status'] = 'pending';
      this.action['checkEnabled'] = null;

      const updates = {};
      updates['status'] = 'pending';
      updates['modifiedBy'] = this.user.email;
      const updateParams = `/${this.actionID}/status`;
     
      const requestJSONPayload = this.csaBackEnd.attachFirewallBypassPrefixes(updates, ['notes']);
      this.surveyService.updateAction(updateParams, requestJSONPayload).subscribe(
        (reply) => {
          console.log(reply);
        },
        (err) => {
          this.dialog.open(MessagesComponent, {
            data: {
              heading: 'Cannot Open Action',
              message:
                `Please email ${this.user['supportEmail']} for support`,
            },
            disableClose: true,
            backdropClass: 'dialog-backdrop',
          });
          console.log(err);
        }
      );
    }
  }

  getDate(date) {
    date = moment(date, ['DD/MM/YYYY', 'DD MMM YYYY', moment.ISO_8601]).format(
      'DD MMM YYYY'
    );
    return date;
  }

  addDate(dateUpdateObject) {
    const note = `Due Date updated from <span class='light-green'>${
      this.getDate(dateUpdateObject['fromDate'])
    }</span> to <span class='light-green'>${
      this.getDate(dateUpdateObject['toDate'])
    }</span>`;
    this.dateChangeDetails = {
      note,
      addedOn: this.getDate(dateUpdateObject['addedOn']),
      addedBy: dateUpdateObject['addedBy'],
    };
    this.dateChanges.push(this.dateChangeDetails);
  }

  submitForm1(survey, value) {
    if (!value.noteSection || this.uploading) {
      return;
    }
    const updates = {};

    updates['notes'] = value.noteSection;
    updates['addedBy'] = this.user.email;
    updates['addedOn'] = moment().format('DD MMM YYYY');
    updates['photos'] = this.photos;
    this.photos = [];

    const updateParams = `/${this.actionID}/work_notes`;
 
    const requestJSONPayload = this.csaBackEnd.attachFirewallBypassPrefixes(updates, ['notes']);
    this.surveyService.updateAction(updateParams, requestJSONPayload).subscribe(
      (reply) => {
        this.worknotes.push({
          notes: updates['notes'],
          addedOn: updates['addedOn'],
          addedBy: updates['addedBy'],
          photos: updates['photos'],
        });
        console.log(reply);
      },
      (err) => {
        this.dialog.open(MessagesComponent, {
          data: {
            heading: 'Cannot Add Updates',
            message:
              `Please email ${this.user['supportEmail']} for support`,
          },
          disableClose: true,
          backdropClass: 'dialog-backdrop',
        });
        console.log(err);
      }
    );
    survey.reset();
  }

  submitForm2(survey, value) {
    const dateChange = {};
    dateChange['dueDate'] = moment(value.dueDate, [
      'DD/MM/YYYY',
      'DD MMM YYYY',
      moment.ISO_8601,
    ]).format();
    dateChange['modifiedBy'] = this.user.email;

    const updateParams = `/${this.actionID}/due_date`;
    
    const requestJSONPayload = this.csaBackEnd.attachFirewallBypassPrefixes(dateChange, ['notes']);
    this.surveyService.updateAction(updateParams, requestJSONPayload).subscribe(
      (reply) => {
        this.addDate({
          fromDate: this.action['dueDate'],
          toDate: dateChange['dueDate'],
          addedOn: moment(),
          addedBy: dateChange['modifiedBy'],
        });
        this.action['dueDate'] = dateChange['dueDate'];
      },
      (err) => {
        this.dialog.open(MessagesComponent, {
          data: {
            heading: 'Cannot Update Due Date',
            message:
              `Please email ${this.user['supportEmail']} for support`,
          },
          disableClose: true,
          backdropClass: 'dialog-backdrop',
        });
        console.log(err);
      }
    );
    this.editDueDate = false;
  }

  compressPhoto(file) {
    const emitter = new EventEmitter<Blob>();
    const compression = new Compressor(file, {
      maxWidth: 1000,
      maxHeight: 1000,
      convertSize: 1000000,
      success(result) {
        emitter.emit(result);
      },
      error(err) {
        emitter.emit();
      },
    });
    return emitter.asObservable();
  }

  getPhotosThisQuestion(photo) {
    setTimeout(() => {
      this.photos.push(photo);
      this.uploading = false;
    }, 10);
    this.cd.detectChanges();
  }

  checkName(name) {
    const check = name.toLowerCase().match(/\.(pdf)/g);
    if (check == null) return true;
    return false;
  }

  removePhoto(src, question, photo) {
    this.surveyService
      .removePhoto(photo, src, this.action['surveyID'], question)
      .subscribe(
        (response) => {
          this.removePhotoFromQ(photo);
          this.cd.detectChanges();
        },
        (error) => {
          this.dialog.open(MessagesComponent, {
            data: {
              heading: "Couldn't Remove File",
              message:
                `Please email ${this.user['supportEmail']} for support`,
              closeText: 'Ok',
            },
            backdropClass: 'dialog-backdrop',
          });
        }
      );
  }

  removePhotoFromQ(fileName) {
    for (let j = 0; j < this.photos.length; j++) {
      if (
        this.photos[j]['photoName'] == fileName
        || this.photos[j]['fileName'] == fileName
      ) {
        this.photos.splice(j, 1);
        setTimeout(() => {
          this.uploading = false;
        }, 10);
      }
    }
  }

  // eslint-disable-next-line default-param-last
  getAllPhotos(updateIndex = 0, photoIndex = 0, draft?) {
    let evidence = [];
    let showRemove = false;
    let evidenceIndex = 0;

    if (draft) {
      const update = {
        notes: draft.noteSection,
        addedBy: this.user.email,
        addedOn: moment().format('DD MMM YYYY'),
        photos: this.photos
      };
      evidence = [update];
      showRemove = true;
    } else {
      const updates = [];
      for (let i = 0; i < this.worknotes.length; i++) {      
        const update = this.worknotes[i];
        if (updateIndex == i) {
          evidenceIndex = updates.length;
        }
        if (update['photos'] && update['photos'].length > 0) {
          for (let j = 0; j < update['photos'].length; j++) {
            if (!update['photos'][j]['fileName']) {
              update['photos'][j]['fileName'] = `${this.action['surveyID'] 
              }-${  
                this.action['questionID']  
              }-${  
                this.action['storeID'] 
              }-${  
                update['photos'][j]['photoName']}`;
            }
          }

          update['totalIndex'] = i + 1;
          updates.push(update);
        }
      }
      evidence = updates;
    }
    
    const data = {
      action: {
        title: this.action['title'],
        status: this.action['status'],
      },
      evidence,
      evidenceIndex,
      photoIndex,
      totalNumber: this.worknotes.length,
      showRemove,
      documentType: 'Evidence',
      type: 'action'
    };

    console.log(data);
    const photoDialog = this.dialog.open(PhotoCarouselComponent, {
      data,
      backdropClass: 'dialog-backdrop',
      minWidth: '70%',
      maxWidth: '90vw',
      height: 'auto'
    });

    photoDialog.afterClosed().subscribe((result) => {
      if (result.status == 'delete') this.removePhoto(result.src, this.action['questionID'], result.fileName);
    });
  }

  viewPhoto(photoName, question, fileName, showRemove) {
    let photo = '';

    if (fileName) {
      photo = fileName;
    } else {
      photo = `${this.action['surveyID']
      }-${
        question
      }-${
        this.action['storeID']
      }-${
        photoName}`;
    }

    const query = `?fileNames=${photo}`;
    this.loader.show('');

    this.surveyService.getPhotos(query).subscribe(
      (photoData) => {
        console.log(photoData);
        if (Object.keys(photoData).length == 0) {
          this.loader.hide('');
          const messageDialog = this.dialog.open(MessagesComponent, {
            data: {
              heading: 'File cannot be viewed, please retry',
              message:
              `Please email ${this.user['supportEmail']} for support`,
              closeText: 'Ok',
              retryText: 'Retry'
            },
            backdropClass: 'dialog-backdrop',
          });
          messageDialog.afterClosed().subscribe((result) => {
            if (result == 'retry') {
              this.viewPhoto(photoName, question, fileName, showRemove);
            }
          });
        } else if (Object.keys(photoData).length > 0) {
          this.zone.run(() => {
            const photoDialog = this.dialog.open(PhotoPreviewComponent, {
              data: {
                photoURL: photoData[photo],
                photoName,
                showRemove: false,
                documentType: 'Evidence'
              },
              backdropClass: 'dialog-backdrop',
              minWidth: '70%',
              maxWidth: '90vw',
              height: 'auto',
            });

            photoDialog.afterClosed().subscribe((result) => {
              if (result == 'delete') this.removePhoto(photoData, question, photo);
            });
          });
        }
      },
      (error) => {
        this.loader.hide('');
        const messageDialog = this.dialog.open(MessagesComponent, {
          data: {
            heading: 'File cannot be viewed, please retry',
            message:
              `Please email ${this.user['supportEmail']} for support`,
            closeText: 'Ok',
            retryText: 'Retry'
          },
          backdropClass: 'dialog-backdrop',
        });
        messageDialog.afterClosed().subscribe((result) => {
          if (result == 'retry') {
            this.viewPhoto(photoName, question, fileName, showRemove);
          }
        });
      }
    );
  }

  uploadPhoto($event, question, survey) {
    this.uploading = true;
    const photoName = $event.target.files[0].name;

    const goodPhotoType = $event.target.files[0].name
      .toLowerCase()
      .match(/\.(pdf|jpg|png|gif|tiff|jpeg|bmp|raw)/g);

    const PDFTest = $event.target.files[0].name.toLowerCase().match(/\.(pdf)/g);
    if (goodPhotoType == null) {
      this.dialog.open(MessagesComponent, {
        data: {
          heading: 'This file is not an image or PDF.',
          message: 'Please select an image or PDF file to attach.',
          closeText: 'Ok',
        },
        backdropClass: 'dialog-backdrop',
      });
      setTimeout(() => {
        this.uploading = false;
      }, 10);
    } else if (PDFTest == null) {
      this.compressPhoto($event.target.files[0]).subscribe(
        (compressedPhoto) => {
          if (typeof compressedPhoto === 'undefined') {
            this.dialog.open(MessagesComponent, {
              data: {
                heading: 'Error Compressing File',
                message:
                    `Please try a different file or email ${ 
                      this.user['supportEmail']
                    } for support`,
                closeText: 'Ok',
              },
              backdropClass: 'dialog-backdrop',
            });
            setTimeout(() => {
              this.uploading = false;
            }, 10);
          } else if (compressedPhoto['size'] > 10000000) {
            this.dialog.open(MessagesComponent, {
              data: {
                heading: 'This File Is To Big',
                message: 'Please upload a smaller file',
                closeText: 'Ok',
              },
              backdropClass: 'dialog-backdrop',
            });
            setTimeout(() => {
              this.uploading = false;
            }, 10);
          } else {
            const action = this.surveyService
              .uploadPhoto(
                compressedPhoto,
                survey,
                question,
                this.action['storeID'],
                `${compressedPhoto['size']}`
              )
              .subscribe(
                (response) => {
                  if (response[0]['status'] != 200) {
                    this.dialog.open(MessagesComponent, {
                      data: {
                        heading: "Couldn't Add File",
                        message:
                              `There was an error when sending your file. Please email ${ 
                                this.user['supportEmail']
                              } for support`,
                        closeText: 'Ok',
                      },
                      backdropClass: 'dialog-backdrop',
                    });
                    setTimeout(() => {
                      this.uploading = false;
                    }, 10);
                  } else if (response[0]['message'] == 'OK') {
                    setTimeout(() => {
                      this.uploading = false;
                    }, 10);
                    this.getPhotosThisQuestion({
                      photoName: response[0]['name'],
                      fileName: response[0]['fileID'],
                      fileSize: compressedPhoto['size'],
                    });
                  } else {
                    this.dialog.open(MessagesComponent, {
                      data: {
                        heading: "Couldn't Add File",
                        message:
                              `No response from server. Please email ${ 
                                this.user['supportEmail']
                              } for support`,
                        closeText: 'Ok',
                      },
                      backdropClass: 'dialog-backdrop',
                    });
                    setTimeout(() => {
                      this.uploading = false;
                      this.cd.detectChanges();
                    }, 10);
                  }
                },
                (error) => {
                  this.dialog.open(MessagesComponent, {
                    data: {
                      heading: "Couldn't Add File",
                      message:
                            `Please email ${ 
                              this.user['supportEmail']
                            } for support`,
                      closeText: 'Ok',
                    },
                    backdropClass: 'dialog-backdrop',
                  });
                  setTimeout(() => {
                    this.uploading = false;
                  }, 10);
                }
              );
          }
        }
      );
    } else if ($event.target.files[0]['size'] > 10000000) {
      this.dialog.open(MessagesComponent, {
        data: {
          heading: 'This File Is To Big',
          message: 'Please upload a smaller file',
          closeText: 'Ok',
        },
        backdropClass: 'dialog-backdrop',
      });
      setTimeout(() => {
        this.uploading = false;
      }, 10);
    } else {
      const action = this.surveyService
        .uploadPhoto(
          $event.target.files[0],
          survey,
          question,
          this.action['storeID'],
          $event.target.files[0]['size']
        )
        .subscribe(
          (response) => {
            if (response[0]['status'] != 200) {
              this.dialog.open(MessagesComponent, {
                data: {
                  heading: "Couldn't Add File",
                  message:
                        `There was an error when sending your file. Please email ${ 
                          this.user['supportEmail']
                        } for support`,
                  closeText: 'Ok',
                },
                backdropClass: 'dialog-backdrop',
              });
              setTimeout(() => {
                this.uploading = false;
              }, 10);
            } else if (response[0]) {
              this.getPhotosThisQuestion({
                photoName: response[0]['name'],
                fileName: response[0]['fileID'],
                fileSize: $event.target.files[0]['size'],
              });
              this.cd.detectChanges();
            } else {
              this.dialog.open(MessagesComponent, {
                data: {
                  heading: "Couldn't Add File",
                  message:
                        `No response from server. Please email ${ 
                          this.user['supportEmail']
                        } for support`,
                  closeText: 'Ok',
                },
                backdropClass: 'dialog-backdrop',
              });
              setTimeout(() => {
                this.uploading = false;
              }, 10);
            }
          },
          (error) => {
            this.dialog.open(MessagesComponent, {
              data: {
                heading: "Couldn't Add File",
                message:
                      `Please email ${ 
                        this.user['supportEmail']
                      } for support`,
                closeText: 'Ok',
              },
              backdropClass: 'dialog-backdrop',
            });
            setTimeout(() => {
              this.uploading = false;
            }, 10);
          }
        );
    }
  }
}
