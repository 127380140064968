import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';

import { faPlus, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import * as moment from 'moment';
import { SurveysService } from 'src/app/surveys/surveys.service';
import { getUserLocationsQueryString } from 'src/app/utils/user-roles/get-location-query-string';
import { createTagList } from 'src/app/utils/create-tag-list/create-tag-list';
import { FeatureAccessService } from 'src/app/common-services/feature-access.service';
import { Router } from '@angular/router';
import { LoaderService } from '../../common-services/loader.service';
import { StateService } from '../../common-services/state.service';
import { CsaAuthService } from '../../auth/csa-auth.service';
import { User } from '../../models/user';
import { RecurringSurveyService } from '../recurring-survey.service';

@Component({
  selector: 'app-more-recurring-surveys',
  templateUrl: './more-recurring-surveys.component.html',
  styleUrls: ['./more-recurring-surveys.component.scss'],
})
export class MoreRecurringSurveysComponent implements OnInit, OnDestroy {
  @ViewChild(MatTabGroup) tabGroup: MatTabGroup;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  faPlus = faPlus;

  faSortDown = faSortDown;

  allTemplates: any[] = [];

  date = moment();

  filter: string = '';

  frequencies: string[] = ['daily', 'weekly', 'monthly'];

  hasNext: string;

  index: number = 0;

  pageIndex: number = 0;

  pageSize: number = 10;

  saveState: boolean = false;

  selectedFrequency: string = 'All Frequencies';

  selectedGroup: number = 0;

  surveyGroups: string[];

  surveysToShow: any[] = [];

  tableColumns: string[];

  totalCount: number = 0;

  user: User;

  isStoreUser = false;

  isLoading: boolean = false;

  // permissions
  viewFrequencyPermissions = false;
 
  public viewTags = false;

  constructor(
    private csaAuth: CsaAuthService,
    private router: Router,
    private elementRef: ElementRef,
    private state: StateService,
    private surveyCompletionData: LoaderService,
    private recurringService : RecurringSurveyService,
    private surveyService: SurveysService,
    private featureAccessService: FeatureAccessService,
  ) {}

  ngOnInit(): void {
    this.getPermissions();
    this.user = this.csaAuth.user;
    this.isStoreUser = !!this.user['storeID'];

    if (this.state.state && this.state.origin == 'more-recurring-surveys') {
      for (const key in this.state.state) {
        this[key] = this.state.state[key];
      }
    }
    this.getPermissions();
    if (this.viewFrequencyPermissions) {
      this.getSurveysToShow(this.selectedFrequency);
    } else {
      this.getInitialFilters();
    }
  }

  ngOnDestroy() {
    this.setState();
  }

  getPermissions() {
    this.viewFrequencyPermissions = this.featureAccessService.hasAccess('recurringAssessments.viewFrequency');
    this.viewTags = this.featureAccessService.hasAccess('tags.view');
  }

  setState() {
    if (this.saveState) {
      this.state.origin = 'more-recurring-surveys';
      this.state.state = {
        allTemplates: this.allTemplates,
        filter: this.filter,
        hasNext: this.hasNext,
        index: this.index,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        selectedFrequency: this.selectedFrequency,
        selectedGroup: this.selectedGroup,
        surveyGroups: this.surveyGroups,
        surveysToShow: this.surveysToShow,
        tableColumns: this.tableColumns,
        totalCount: this.totalCount,
      };
    } else {
      this.state.origin = undefined;
      this.state.state = undefined;
    }
  }

  resetPagination(): void {
    this.paginator.pageIndex = 0;
    this.index = 0;
    this.pageIndex = 0;
    this.pageSize = this.paginator.pageSize;
  }

  getInitialFilters() {
    this.filter = getUserLocationsQueryString(this.csaAuth.user);
    this.getSurveysToShow('');
  }
 
  tabChanged(tabChangeEvent: MatTabChangeEvent) {
    this.paginator.pageIndex = 0;
    this.index = 0;
    this.pageIndex = 0;
    this.pageSize = this.paginator.pageSize;
    this.selectedGroup = tabChangeEvent.index;
    this.getSurveysToShow(this.selectedFrequency);
  }

  getNext(event: PageEvent) {
    this.index = event.pageIndex * event.pageSize;
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.getSurveysToShow(this.selectedFrequency);
  }

  getSurveysToShow(frequency) {
    setTimeout(() => {
      this.isLoading = true;
      this.surveysToShow = [];
    }, 0);

    let search = '';
    let status = '';
    let frequencySearch = '';

    if (frequency == '' || frequency == 'All Frequencies') {
      search = '';
      this.selectedFrequency = 'All Frequencies';
    } else {
      this.selectedFrequency = frequency;
      search = `&frequency=${frequency}`;
    }

    if (this.viewFrequencyPermissions) {
      this.surveyGroups = ['Daily', 'Weekly', 'Monthly', 'Closed'];
      if (this.selectedGroup == 3) {
        if (this.isStoreUser) {
          this.tableColumns = ['survey', 'dueDate'];
        } else {
          this.tableColumns = ['survey', 'endDate'];
        }
        status = 'closed';
        frequencySearch = 'recurring';
      } else if (this.selectedGroup == 2) {
        this.tableColumns = ['survey', '%Complete', 'dueDate'];
        status = this.csaAuth.isUserAboveStoreRole() ? 'published' : 'started&status=active';
        frequencySearch = 'recurring&frequency=monthly';
      } else if (this.selectedGroup == 1) {
        this.tableColumns = ['survey', '%Complete', 'dueDate'];
        status = this.csaAuth.isUserAboveStoreRole() ? 'published' : 'started&status=active';
        frequencySearch = 'recurring&frequency=weekly';
      } else if (this.selectedGroup == 0) {
        this.tableColumns = ['survey', '%Complete', 'dueDate'];
        status = this.csaAuth.isUserAboveStoreRole() ? 'published' : 'started&status=active';
        frequencySearch = 'recurring&frequency=daily';
      }
    } else {
      this.surveyGroups = ['Available', 'Upcoming', 'Closed'];
      if (this.selectedGroup == 2) {
        if (this.isStoreUser) {
          this.tableColumns = ['survey', 'dueDate'];
        } else {
          this.tableColumns = ['survey', 'endDate'];
        }
        status = 'closed';
        frequencySearch = `recurring${search}`;
      } else if (this.selectedGroup == 1) {
        this.tableColumns = ['survey', 'nextPublishDate'];
        status = 'active';
        frequencySearch = `recurring${search}`;
      } else if (this.selectedGroup == 0) {
        this.tableColumns = ['survey', '%Complete', 'nextPublishDate'];
        status = 'published';
        frequencySearch = `recurring${search}`;
      }
    }

    this.tableColumns.unshift('tags');

    if (this.isStoreUser) {
      this.surveyService
        .getStoreSurveys(
          `?status=${status}`
        + `&division=${this.csaAuth.user.division
        }&storeID=${this.csaAuth.user.storeID}`,
          `/${this.index}`,
          `/${this.pageSize}`,
          frequencySearch
        ).subscribe(
          (surveysData) => {
            this.surveysToShow = surveysData['surveys'];
            this.hasNext = surveysData['has_next'];
            this.getPagination();
            this.isLoading = false;
          }, 
          (error) => {
            this.isLoading = false;
          }
        );        
    } else {
      this.recurringService
        .getRecurringSurveys(
          this.user['division'] + this.filter,
          status,
          `/${this.index}`,
          `/${this.pageSize}`,
          frequencySearch
        ).subscribe((surveysData) => {
          this.surveysToShow = createTagList(surveysData['surveys'], this.viewTags);  
          this.hasNext = surveysData['has_next'];
          this.getPagination();
          this.isLoading = false;
        }, (error) => {
          this.isLoading = false;
        });
    }
  }

  getPagination() {
    const label = this.elementRef.nativeElement.querySelector(
      '.mat-paginator-range-label'
    );
    if (label) {
      if (this.surveysToShow.length == 0) {
        label.innerHTML = 'No results to show';
        this.totalCount = 0;
      } else {
        const upToCount = this.index + this.surveysToShow.length;

        if (this.hasNext == 'Y') {
          label.innerHTML = `${String(this.index + 1)} to ${upToCount} of Many`;
          this.totalCount = upToCount + 1;
        } else {
          label.innerHTML = `${String(this.index + 1)} to ${upToCount} of ${upToCount}`;
          this.totalCount = upToCount;
        }
      }
    }
  }

  surveyDetails(surveyId, message: string, event) {
    event.stopPropagation();
    this.surveyCompletionData.changeMessage(message);
    const path = !this.csaAuth.isUserAboveStoreRole() ? '/assessment-response-view' : '/recurring-survey-details';
    this.router.navigate([path, surveyId]);
  }
}
