import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

import { faPencilAlt, faPlus, faPlayCircle } from '@fortawesome/free-solid-svg-icons';
import { MatDialog } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import * as moment from 'moment';

import { Subject, takeUntil } from 'rxjs';
import { SurveysService } from 'src/app/surveys/surveys.service';
import { AdhocSurveyService } from 'src/app/adhoc-surveys/adhoc-survey.service';
import { RecurringSurveyService } from 'src/app/recurring-survey/recurring-survey.service';
import { MrrSurveysService } from 'src/app/mrr-surveys/mrr-surveys.service';
import { reassignArrayPropertyValues } from 'src/app/utils/data';
import { splitRoleByCaps } from 'src/app/utils/user-roles/split-role-by-caps';
import { User } from '../../models/user';
import { StateService } from '../../common-services/state.service';
import { StoreSelectionDialogComponent } from '../../surveys/store-selection-dialog/store-selection-dialog.component';
import { MessagesComponent } from '../../surveys/messages/messages.component';
import { CsaSantiserService } from '../../sanitizer/csa-santiser.service';
import { CsaBackEndService } from '../../common-services/csa-back-end.service';
import { CsaAuthService } from '../../auth/csa-auth.service';

@Component({
  selector: 'app-store-landing',
  templateUrl: './store-landing.component.html',
  styleUrls: ['./store-landing.component.scss'],
})
export class StoreLandingComponent implements OnInit {
  @ViewChild('tabGroup') tabGroup;

  faPencilAlt = faPencilAlt;

  faPlus = faPlus;

  faPlayCircle = faPlayCircle;

  actions = [];

  actionsTableColumns: string[] = ['action', 'description', 'updates'];

  mrrActions = [];

  adhocSurveys = [];

  allSurveys = [];

  date = moment().format();

  departments = [];
  
  selectedGroup = 0;

  surveyGroups = ['My Assessments', "Other's Assessments"];

  surveys = [];

  onceOffSurveys = [];

  tableColumns: string[] = ['survey', 'percentComplete', 'status'];

  user: User = {};

  isLoadingOnceOff: boolean = false;

  isLoadingAdhoc: boolean = false;

  isLoadingActions: boolean = false;

  isLoadingMrrActions: boolean = false;

  isLoadingMrrSurveys: boolean = false;

  /* MRR assessment */
  mrrSurveyGroups = ['Available', 'Closed'];

  mrrSelectedGroup = 0;

  mrrSurveys = [];

  mrrTableColumns: string[] = ['survey', 'rating', 'status', 'createNew'];

  isLoadingMRR: boolean = false;

  private onDestroy$ = new Subject<void>(); // used to unsubscribe from observables on component destroy

  /* MRR assessment */

  constructor(
    private router: Router,
    private csaAuth: CsaAuthService,
    private csaBackEnd: CsaBackEndService,
    private dialog: MatDialog,
    private cookieService: CookieService,
    private sanitizer: CsaSantiserService,
    private state: StateService,
    private surveyService: SurveysService,
    private adhocService: AdhocSurveyService,

  ) { }

  ngOnInit() {
    this.user = this.csaAuth.user;

    this.csaAuth.userChanges$().pipe(takeUntil(this.onDestroy$)).subscribe((updatedUser) => {
      this.user = updatedUser;
    });

    if (this.user['storeID'] == '') {
      if (this.cookieService.get('userStoreID')) {
        this.surveyService
          .getStoreList(
            this.sanitizer.escapedAndSanitizedHtmlProperty(
              this.cookieService.get('userStoreID')
            )
          )
          .subscribe(
            (storeData) => {
              if (storeData.length > 0) {
                this.csaAuth.setUserOverride('storeID', storeData[0]['Store_No']);
                this.csaAuth.setUserOverride('storeName', storeData[0]['Location_Name']);
                this.csaAuth.setUserOverride('groupNo', storeData[0]['Group_No']);
                this.csaAuth.setUserOverride('zone', storeData[0]['Zone']);
                this.csaAuth.setUserOverride('state', storeData[0]['State']);

                this.getActions();
                this.getOnceOffSurveys();
                this.getAdhocSurveys();
                this.getMRRassessments();
                this.getMrrActions();
              } else {
                this.changeStore(false);
              }
            },
            (error) => {
              this.changeStore(false);
            }
          );
      } else {
        this.changeStore(false);
      }
    } else {
      this.getActions();
      this.getOnceOffSurveys();
      this.getAdhocSurveys();
      this.getMRRassessments();
      this.getMrrActions();
    }
  }

  ngOnDestroy() {
    this.state.origin = undefined;
    this.state.state = undefined;
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  getActions() {
    setTimeout(() => {
      this.isLoadingActions = true;
      this.actions = [];
    }, 0);

    this.surveyService
      .getActionList(
        '/0',
        '/5',
        '?status=pending'
        + `&division=${this.user['division']
        }&storeID=${this.user['storeID']}`
      )
      .subscribe((message) => {
        this.actions = message['actions'];
        this.getActionDueDates();
        this.isLoadingActions = false;
      }, (error) => {
        this.isLoadingActions = false;
      });
  }

  getMrrActions() {
    setTimeout(() => {
      this.isLoadingMrrActions = true;
      this.mrrActions = [];
    }, 0);

    this.surveyService
      .getActionList(
        '/0',
        '/5',
        '?status=pending'
        + `&division=${this.user['division']
        }&storeID=${this.user['storeID']}&filterName=mrr`
      )
      .subscribe((message) => {
        this.mrrActions = message['actions'];
        this.getMrrActionDueDates();
        this.isLoadingMrrActions = false;
      }, (error) => {
        this.isLoadingMrrActions = false;
      });
  }

  getOnceOffSurveys() {
    setTimeout(() => {
      this.isLoadingOnceOff = true;
      this.onceOffSurveys = [];
    }, 0);

    this.surveyService
      .getStoreSurveys(
        '?status=started&status=active'
        + `&division=${this.user['division']
        }&storeID=${this.user['storeID']}`,
        '/0',
        '/5'
      )
      .subscribe((surveysData) => {
        this.onceOffSurveys = surveysData['surveys'];
        this.getDueDates();
        this.getSurveyStates();
        this.allSurveys = this.onceOffSurveys.slice(0, 5);
        this.isLoadingOnceOff = false;
      }, (error) => {
        this.isLoadingOnceOff = false;
      });
  }

  getAdhocSurveys() {
    setTimeout(() => {
      this.isLoadingAdhoc = true;
      this.adhocSurveys = [];
    }, 0);

    let target;
    if (this.selectedGroup == 1) target = 'other';
    else if (this.selectedGroup == 0) target = 'self';

    this.adhocService
      .getOnTheFlySurveytemplates(
        '',
        this.user['division'],
        'published',
        '/0',
        '/5',
        `adhoc&targetRoles=${target}&storeID=${this.user['storeID']}`
      )
      .subscribe((surveysData) => {
        // Reassign filterName property value from 'eitb' to 'gps'
        this.adhocSurveys = reassignArrayPropertyValues(surveysData['surveys'], 'filterName', 'eitb', 'GPS');             
        this.getAdhocUsers();
        this.isLoadingAdhoc = false;
      }, (error) => {
        this.isLoadingAdhoc = false;
      });
  }

  // mrr assessment
  getMRRassessments() {
    setTimeout(() => {
      this.isLoadingMRR = true;
      this.mrrSurveys = [];
    }, 0);

    let status;
    if (this.mrrSurveyGroups[this.mrrSelectedGroup] == 'Available') {
      if (this.user['role'] == 'Admin' || this.user['role'] == 'StateManager' || this.user['role'] == 'GroupManager' || this.user['role'] == 'OperationManager' || this.user['role'] == 'StoreLossPartner' || this.user['role'] == 'StoreLossManager' || this.user['role'] == 'Super' || this.user['role'] == 'NationalView') { status = 'published'; } else { status = 'active&status=started'; }
    } else if (this.mrrSurveyGroups[this.mrrSelectedGroup] == 'Closed') {
      status = 'closed';
    } else if (this.mrrSurveyGroups[this.mrrSelectedGroup] == 'Upcoming') {
      status = 'active';
    }

    this.surveyService
      .getStoreSurveys(
        `?status=${status
        }&division=${this.user['division']
        }&storeID=${this.user['storeID']}&type=adhoc&filterName=mrr`,
        '/0',
        '/5',
      )
      .subscribe((surveysData) => {
        this.mrrSurveys = surveysData['surveys'];
        this.getMrrStatus();

        // this.getAdhocUsers();

        this.isLoadingMRR = false;
      }, (error) => {
        this.isLoadingMRR = false;
      });
  }

  mrrTabChanged(tabChangeEvent: MatTabChangeEvent) {
    this.mrrSelectedGroup = tabChangeEvent.index;
    this.getMRRassessments();
  }

  // mrr assessment

  tabChanged(tabChangeEvent: MatTabChangeEvent) {
    this.selectedGroup = tabChangeEvent.index;
    this.getAdhocSurveys();
  }

  createOnTheFlySurvey(surveyID) {
    const adhocAssessment = {};
    adhocAssessment['storeID'] = this.user['storeID'];
    adhocAssessment['survey_id'] = surveyID;

    for (let i = 0; i < this.adhocSurveys.length; i++) {
      if (this.adhocSurveys[i]['id'] == surveyID) {
        if (this.adhocSurveys[i]['activeResponseCount'] > 0) {
          const confirmMultipleDialog = this.dialog.open(MessagesComponent, {
            data: {
              heading: 'Active Responses',
              message:
                `You currently have ${this.adhocSurveys[i]['activeResponseCount']
                } Active Responses for this Assessment. Are you sure you would like to create a new one?`,
              closeText: 'Create',
              cancelText: 'See Active Responses',
            },
            backdropClass: 'dialog-backdrop',
          });
          confirmMultipleDialog.afterClosed().subscribe((result) => {
            if (result == 'logout') {
              this.adhocService
                .createOnTheFlySurvey(adhocAssessment)
                .subscribe((reply) => {
                  if (reply['status'] == 200) {
                    const dialogSuccess = this.dialog.open(MessagesComponent, {
                      data: {
                        heading: 'Adhoc Assessment Created',
                        closeText: 'OK',
                        message:
                          'Adhoc Assessment has been created. Please click ok to go to the Adhoc Assessment, and begin completing it',
                      },
                      disableClose: true,
                      backdropClass: 'dialog-backdrop',
                    });
                    dialogSuccess.afterClosed().subscribe((result) => {
                      // window.location.reload();
                      this.router.navigate([
                        '/assessment-response-edit',
                        reply['response_id'],
                      ]);
                    });
                  } else {
                    this.dialog.open(MessagesComponent, {
                      data: {
                        heading: "Couldn't Schedule Adhoc Assessment",
                        message:
                          `Please email ${this.user['supportEmail']
                          } for support`,
                      },
                      disableClose: true,
                      backdropClass: 'dialog-backdrop',
                    });
                  }
                });
            } else {
              this.router.navigate(['/onthefly-survey-details', surveyID]);
            }
          });
        } else {
          this.adhocService
            .createOnTheFlySurvey(adhocAssessment)
            .subscribe((reply) => {
              if (reply['status_message'] == 'Already created') {
                this.dialog.open(MessagesComponent, {
                  data: {
                    heading: 'You Have Already Responded',
                    closeText: 'OK',
                    message:
                      'This Adhoc Assessment only allows one response per store. You have already completed this response, and so no further action is required.',
                  },
                  disableClose: true,
                  backdropClass: 'dialog-backdrop',
                });
              } else if (reply['status'] == 200) {
                const dialogSuccess = this.dialog.open(MessagesComponent, {
                  data: {
                    heading: 'Adhoc Assessment Created',
                    closeText: 'OK',
                    message:
                      'Adhoc Assessment has been created. Please click ok to go to the Adhoc Assessment, and begin completing it',
                  },
                  disableClose: true,
                  backdropClass: 'dialog-backdrop',
                });
                dialogSuccess.afterClosed().subscribe((result) => {
                  // window.location.reload();
                  this.router.navigate([
                    '/assessment-response-edit',
                    reply['response_id'],
                  ]);
                });
              } else {
                this.dialog.open(MessagesComponent, {
                  data: {
                    heading: "Couldn't Schedule Adhoc Assessment",
                    message:
                      `Please email ${this.user['supportEmail']
                      } for support`,
                  },
                  disableClose: true,
                  backdropClass: 'dialog-backdrop',
                });
              }
            });
        }
      }
    }
  }

  changeStore(allowClose) {
    const storeSelectDialog = this.dialog.open(StoreSelectionDialogComponent, {
      backdropClass: 'dialog-backdrop',
      minWidth: '70%',
      maxWidth: '90vw',
      height: 'auto',
      data: {
        allowClose,
        message:
          'You are able to complete Assessments across multiple Stores, please enter the Store number you would like to complete Assessments for.',
      },
    });
    storeSelectDialog.afterClosed().subscribe((result) => {
      if (result) {
        this.csaAuth.setUserOverride('storeID', result['storeNo']);
        this.csaAuth.setUserOverride('storeName', result['storeName']);
        // Cookie expiry is set to 12 hours -- 0.5
        this.cookieService.set(
          'userStoreID',
          this.sanitizer.escapedAndSanitizedHtmlProperty(result['storeNo']),
          0.5
        );
        this.surveyService
          .getStoreList(
            this.sanitizer.escapedAndSanitizedHtmlProperty(
              this.cookieService.get('userStoreID')
            )
          )
          .subscribe(
            (storeData) => {
              if (storeData.length > 0) {
                this.csaAuth.setUserOverride('storeID', storeData[0]['Store_No']);
                this.csaAuth.setUserOverride('storeName', storeData[0]['Location_Name']);
                this.csaAuth.setUserOverride('groupNo', storeData[0]['Group_No']);
                this.csaAuth.setUserOverride('zone', storeData[0]['Zone']);
                this.csaAuth.setUserOverride('state', storeData[0]['State']);
              } else {
                const errorDialog3 = this.dialog.open(MessagesComponent, {
                  data: {
                    heading: 'Error Saving Store Selection',
                    message:
                      'Please try again',
                    closeText: 'Ok',
                  },
                  backdropClass: 'dialog-backdrop',
                });
                errorDialog3.afterClosed().subscribe((result3) => {
                  this.changeStore(true);
                });
              }
            },
            (error) => {
              const errorDialog2 = this.dialog.open(MessagesComponent, {
                data: {
                  heading: 'Error Saving Store Selection',
                  message:
                    'Please try again',
                  closeText: 'Ok',
                },
                backdropClass: 'dialog-backdrop',
              });
              errorDialog2.afterClosed().subscribe((result2) => {
                this.changeStore(true);
              });
            }
          );
        this.getActions();
        this.getOnceOffSurveys();
        this.getAdhocSurveys();
        this.getMRRassessments();
        this.getMrrActions();
      }
    });
  }

  getActionUpdates(actionID) {
    const actionUpdates = [];
    for (let i = 0; i < this.actions.length; i++) {
      if (this.actions[i]['workNotes'] && actionID == this.actions[i]['id']) {
        for (let j = 0; j < this.actions[i]['workNotes'].length; j++) {
          if (
            !(
              this.actions[i]['workNotes'][j]['notes'] == 'Due date updated.'
              || this.actions[i]['workNotes'][j]['notes'] == 'Status updated.'
            )
          ) {
            actionUpdates.push(this.actions[i]['workNotes'][j]['notes']);
          }
        }
      }
    }
    return actionUpdates.length;
  }

  getMrrActionUpdates(actionID) {
    const actionUpdates = [];
    for (let i = 0; i < this.mrrActions.length; i++) {
      if (this.mrrActions[i]['workNotes'] && actionID == this.mrrActions[i]['id']) {
        for (let j = 0; j < this.mrrActions[i]['workNotes'].length; j++) {
          if (
            !(
              this.mrrActions[i]['workNotes'][j]['notes'] == 'Due date updated.'
              || this.mrrActions[i]['workNotes'][j]['notes'] == 'Status updated.'
            )
          ) {
            actionUpdates.push(this.mrrActions[i]['workNotes'][j]['notes']);
          }
        }
      }
    }
    return actionUpdates.length;
  }

  getDueDates() {
    for (let i = 0; i < this.onceOffSurveys.length; i++) {
      const surveyDueDate = this.onceOffSurveys[i].dueDate;
      const m = moment(surveyDueDate, [
        'DD/MM/YYYY',
        'DD MMM YYYY',
        moment.ISO_8601,
      ]);
      const currentDate = moment();
      const isDueInWeek = currentDate.diff(m, 'days') >= -7 && currentDate.diff(m, 'days') <= 0;

      this.onceOffSurveys[i].isDueInWeek = isDueInWeek;
    }
  }

  getActionDueDates() {
    for (let i = 0; i < this.actions.length; i++) {
      const actionDueDate = this.actions[i].dueDate;
      const m = moment(actionDueDate, [
        'DD/MM/YYYY',
        'DD MMM YYYY',
        moment.ISO_8601,
      ]);
      const currentDate = moment();
      const isDueInWeek = currentDate.diff(m, 'days') >= -7 && currentDate.diff(m, 'days') <= 0;

      this.actions[i].overDue = m < currentDate;

      this.actions[i].dueDate = moment(this.actions[i].dueDate, [
        'DD/MM/YYYY',
        'DD MMM YYYY',
        moment.ISO_8601,
      ]).format('DD MMM YYYY');
      this.actions[i].isDueInWeek = isDueInWeek;
    }
  }

  getMrrActionDueDates() {
    for (let i = 0; i < this.mrrActions.length; i++) {
      const actionDueDate = this.mrrActions[i].dueDate;
      const m = moment(actionDueDate, [
        'DD/MM/YYYY',
        'DD MMM YYYY',
        moment.ISO_8601,
      ]);
      const currentDate = moment();
      const isDueInWeek = currentDate.diff(m, 'days') >= -7 && currentDate.diff(m, 'days') <= 0;

      this.mrrActions[i].overDue = m < currentDate;

      this.mrrActions[i].dueDate = moment(this.mrrActions[i].dueDate, [
        'DD/MM/YYYY',
        'DD MMM YYYY',
        moment.ISO_8601,
      ]).format('DD MMM YYYY');
      this.mrrActions[i].isDueInWeek = isDueInWeek;
    }
  }

  getAdhocUsers() {
    for (let i = 0; i < this.adhocSurveys.length; i++) {
      // this.adhocSurveys[i]["nextTargetRoles"] = [];
      // let targetRoles = this.adhocSurveys[i]["targetRoles"].filter(role => role != "Super");
      // targetRoles.forEach((targetRole) => {
      //   if (
      //     targetRole == "Admin" || 
      //     targetRole == 'NationalView' ||
      //     targetRole == 'MultiStore'
      //   ) {
      //     this.adhocSurveys[i]["nextTargetRoles"].push("Operations Managers");
      //   } else if (targetRole == "StateManager") {
      //     this.adhocSurveys[i]["nextTargetRoles"].push("State Managers");
      //   } else if (targetRole == "Admin") {
      //     this.adhocSurveys[i]["nextTargetRoles"].push("Admin Users");
      //   } else if (targetRole == "RegionManager") {
      //     this.adhocSurveys[i]["nextTargetRoles"].push("Region Managers");
      //   } else if (targetRole == "AreaManager") {
      //     this.adhocSurveys[i]["nextTargetRoles"].push("Area Managers");
      //   } else if (targetRole == "StoreLossPartner" || targetRole == "StockLossPartner" ) {
      //     this.adhocSurveys[i]["nextTargetRoles"].push("Store Loss Partners");
      //   }else if (targetRole == "StoreLossManager" ) {
      //     this.adhocSurveys[i]["nextTargetRoles"].push("Store Loss Managers");
      //   }else if (targetRole == "NationalView") {
      //     this.adhocSurveys[i]["nextTargetRoles"].push("National View Users");
      //   }
      // });

      if (
        this.adhocSurveys[i]['status'] == 'closed'
        && moment().isAfter(
          moment(this.adhocSurveys[i]['endDate'], [
            'DD/MM/YYYY',
            'DD MMM YYYY',
            moment.ISO_8601,
          ]).format('YYYY-MM-DD')
        )
      ) {
        const maxEndDate = moment(this.adhocSurveys[i]['maxEndDate'], [
          'DD/MM/YYYY',
          'DD MMM YYYY',
          moment.ISO_8601,
        ]).format('YYYY-MM-DD');
        this.adhocSurveys[i]['daysToEdit'] = moment(maxEndDate).diff(moment(), 'days') + 1;
      }
    }
  }

  // Exposes global function splitRoleByCaps to template
  splitRoleByCaps(value): string {
    return splitRoleByCaps(value, this.user['division']);
  }

  getSurveyStates() {
    for (let i = 0; i < this.onceOffSurveys.length; i++) {
      const status = this.onceOffSurveys[i]['status'];
      if (status == 'active') {
        this.onceOffSurveys[i].surveyState = 'Start';
      } else {
        this.onceOffSurveys[i].surveyState = 'Continue';
      }
    }
  }

  getMrrStatus() {
    for (let i = 0; i < this.mrrSurveys.length; i++) {
      if (this.mrrSurveys[i]['finalReportDate']) {
        this.mrrSurveys[i].mrrStatus = 'Final';
      } else {
        this.mrrSurveys[i].mrrStatus = 'Draft';
      }
    }

    for (let i = 0; i < this.mrrSurveys.length; i++) {
      const status = this.mrrSurveys[i]['status'];
      if (status == 'started') {
        this.mrrSurveys[i].mrrStatusBtn = 'Continue';
      } else if (status == 'active') {
        this.mrrSurveys[i].mrrStatusBtn = 'Start';
      } else {
        this.mrrSurveys[i].mrrStatusBtn = 'Closed';
      }
    }
  }

  getDate(date) {
    date = moment(date, ['DD/MM/YYYY', 'DD MMM YYYY', moment.ISO_8601]).format(
      'DD MMM YYYY'
    );
    return date;
  }
}
