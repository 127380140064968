<div class="page-container">

  <h1>Templates</h1>

  <div class="question-options-row">
    <div class="filter-chips">
      <div class="chipDrop" *ngIf="('adhocAssessments.create' | accessCheck) || ('recurringAssessments.create' | accessCheck) || ('onceOffAssessments.create' | accessCheck)">
        <mat-chip-list aria-label="Filters">
          <mat-chip (click)="createSurvey()" color="primary" selected>
            <fa-icon class="chip-left-icon" [icon]="faPlus"></fa-icon>
            <span>Create New Assessment</span>
          </mat-chip>
        </mat-chip-list>
      </div>
      <div class="chipDrop">
				<mat-chip-list aria-label="Filters">
          <mat-chip (click)="loadTemplates()" color="primary" selected>
            <fa-icon class="chip-left-icon" [icon]="faFileUpload"></fa-icon>
            <span>Load Templates from Sheets</span> 
          </mat-chip>
        </mat-chip-list>
      </div>
      <div class="chipDrop">
				<mat-chip-list aria-label="Filters">
          <mat-chip (click)="openDriveFolder()" color="primary" selected >
            <fa-icon class="chip-left-icon" [icon]="faFolderOpen"></fa-icon>
            <span>Manage Template Google Sheets</span>
          </mat-chip>
        </mat-chip-list>
      </div>
    </div>
  </div>

  <div class="audit-tool-cards-container">
    <div class="audit-tool-card more-list-screen-card">
      <mat-tab-group #tabGroup dynamicHeight="false" mat-align-tabs="center" [(selectedIndex)]="selectedGroup" (selectedTabChange)="tabChanged($event)">
        <mat-tab label="{{surveyGroup}}" *ngFor="let surveyGroup of surveyGroups">

          <table mat-table [dataSource]="allTemplates">
            <ng-container matColumnDef="tags">
              <th mat-header-cell *matHeaderCellDef class="survey-details-link center-align">
                <h4>Tags</h4>
              </th>
              <td mat-cell *matCellDef="let template" class="survey-details-link">
                <div class="center-align">
                  <app-tags-cell-display [tags]="template['tagList']" [contextName]="template['title']"></app-tags-cell-display>
                </div>
              </td>           
            </ng-container>            
            <ng-container matColumnDef="template">
              <th mat-header-cell *matHeaderCellDef class="survey-details-link center-align">
                <h4>Template</h4>
              </th>
              <td mat-cell *matCellDef="let template" class="survey-details-link center-align">
                <h3 style="word-break: break-word;">
                  {{template['title'].slice(0, 90) | unescape}}
                  <span *ngIf="template.title.length > 50">...</span>
                </h3>
              </td>
            </ng-container>

            <ng-container matColumnDef="division">
              <th mat-header-cell *matHeaderCellDef class="survey-details-link center-align">
                <h4>Division</h4>
              </th>
              <td mat-cell *matCellDef="let template" class="survey-details-link center-align">
                <h4>{{template.division}}</h4>
              </td>
            </ng-container>

            <ng-container matColumnDef="questions">
              <th mat-header-cell *matHeaderCellDef class="survey-details-link center-align">
                <h4>Questions</h4>
              </th>
              <td mat-cell *matCellDef="let template" class="survey-details-link center-align">
                <h1>{{template.questions.length}}</h1>
              </td>
            </ng-container>

            <ng-container matColumnDef="assessments" class="hide-on-mobile">
              <th mat-header-cell *matHeaderCellDef class="survey-details-link center-align">
                <h4>Assessments</h4>
              </th>
              <td mat-cell *matCellDef="let template" class="survey-details-link center-align">
                <h2>{{template['surveysPublished']}}</h2>
              </td>
            </ng-container>

            <ng-container matColumnDef="archive">
              <th mat-header-cell *matHeaderCellDef class="survey-details-link center-align" style="max-width: 100px; width: 100%">
                <h4></h4>
              </th>
              <td mat-cell *matCellDef="let template" class="survey-details-link center-align" style="max-width: 100px; width: 100%">
                <mat-chip-list aria-label="archiveChips">
                  <mat-chip  *ngIf="template['status'] === 'active' || !template['status']" (click)="updateStatus(template.id, 'archived', $event)" color="primary" selected>
                    <fa-icon [icon]="faArchive"></fa-icon>
                    <span>&nbsp;&nbsp;Archive</span>
                  </mat-chip>
                  <mat-chip  *ngIf="template['status'] === 'archived'" (click)="updateStatus(template.id, 'active', $event)" color="primary" selected>
                    <fa-icon [icon]="faBoxOpen"></fa-icon>
                    <span>&nbsp;&nbsp;Activate</span>
                  </mat-chip>
                </mat-chip-list>
              </td>
            </ng-container>

            <mat-header-row *matHeaderRowDef="tableColumns"  class="survey-heading-line"></mat-header-row>
            <mat-row *matRowDef="let template; columns: tableColumns;" (click)="saveState = true"  [routerLink]="['/template-details', template.id]" class="survey-line survey-line-link"></mat-row>
          </table>

          <div *ngIf="isLoading" class="loader-container">
            <div class="loading-wapple"></div>
          </div>
    
          <div *ngIf="allTemplates.length == 0 && !isLoading">            
            <h3 class="none-found-message">
              There are currently no Templates available. Please talk to the Dev Team to load surveys.
            </h3>
          </div>
        </mat-tab>
      </mat-tab-group>
      <mat-paginator #paginator
          [pageIndex]=pageIndex
          [pageSize]=pageSize
          [length]="totalCount"
          [pageSizeOptions]="[5, 10, 25, 100]"
          [showFirstLastButtons] = false
          (page)="getNext($event)">
      </mat-paginator>
    </div>
  </div>
</div>