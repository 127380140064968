// TODO (10/1/25 AS): Add unit tests

import {
  Component, HostBinding, Input, OnInit 
} from '@angular/core';
import { TagType } from 'src/app/types/tag';

@Component({
  selector: 'app-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss']
})
export class TagComponent {
  @Input() tagText = '';

  @Input() type: TagType = TagType.Default;

  @HostBinding('class.default') get isDefault() {
    return this.type === TagType.Default;
  }

  @HostBinding('class.custom') get isCustom() {
    return this.type === TagType.Custom;
  }

  @HostBinding('class.cancelled') get isCancelled() {
    return this.type === TagType.Cancelled;
  }

  @HostBinding('class.targeted') get isTargeted() {
    return this.type === TagType.Targeted;
  }

  @HostBinding('class.gps') get isGps() {
    return this.type === TagType.Gps;
  }

  constructor() { }
}
