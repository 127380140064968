import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot, CanActivate, Params, Router, RouterStateSnapshot, UrlTree 
} from '@angular/router';
import { map, Observable, take } from 'rxjs';
import { CsaAuthService } from 'src/app/auth/csa-auth.service';
import { FeatureAccessService } from 'src/app/common-services/feature-access.service';

@Injectable({
  providedIn: 'root'
})
export class SubfeatureRouteGuard implements CanActivate {
  /**
   * A route guard that grants access based on user subfeatures and redirects users to the 
   * applicable path. If the user lacks access, they are navigated 
   * to an unauthorised error page or a login page if no user is authenticated.
   * 
   * @param authService - The authentication service to retrieve user details.
   * @param featureService - The feature access service to check access permissions.
   * @param router - Angular's Router to handle navigation and redirection.
   * @example
   * // Redirect in your routing module:
   * const routes: Routes = [
   *   {
   *     path: '/feature-page',
   *     component: FeaturePageComponent,
   *     canActivate: [SubfeatureRouteGuard],
   *     data: {
   *       subfeature: 'subfeature0',
   *       subfeatureRedirects: [
   *         { subfeature: 'subfeatureA', path: '/pathA', order: 1 },
   *         { subfeature: 'subfeatureB', path: '/pathB', order: 2 }
   *       ]
   *     }
   *   }
   * ];
   * 
   * In order of precedence:
   * - Order 1: If user has access to 'subfeatureA', they will be redirected to '/pathA',
   * - Order 2: If user has access to 'subfeatureB', they will be redirected to '/pathB',
   * - Default: If the user has access to 'subfeature0', they will remain on '/feature-page'.
   * - Otherwise: The user is redirected to '/error/unauthorised' if no access is found.
   */  
  constructor(
    private authService: CsaAuthService,
    private featureService: FeatureAccessService, 
    private router: Router
  ) { }

  /**
   * Determines whether a route can be activated based on the user's subfeature access.
   * A default root subfeature defining the default route must be defined, otherwise the user will 
   * be navigated to an unauthorised page.
   * If the route contains subfeature redirects, the guard will redirect to the highest-order subfeature 
   * for which they have access. If no valid access or redirects are found, the user will be navigated to 
   * an unauthorised page or the login page if unauthenticated.
   *
   * 
   * This subfeature guard expects to be used only on routes that also use the auth guard (CsaAuthGuard).
   * 
   * Our expectation was that the auth guard would be run and complete before the subfeature route guard, but in reality that doesn't actually happen.
   * 
   * Yes, the auth guard is run first, but the code within the observable isn't guaranteed to run before any subsequent guards. 
   
   * There is a fix for this in Angular 15, but at the time of writing we are using Angular 13: https://dev.to/dzinxed/a-new-way-of-ordering-guards-in-angular-2
   * You can read more about this here: https://upmostly.com/angular/running-angular-guards-synchronously.
   *
   * @param route - The activated route snapshot containing route data, parameters, and subfeature information.
   * @param state - The current router state snapshot.
   * @returns {Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree} 
   * An observable or promise that resolves to either true (grants access), 
   * a redirection `UrlTree`, or false if unauthorised.
   * @example
   * // When no user is authenticated, it redirects to the login page:
   * authService.userChanges$.and.returnValue(of(null)); // No user
   * guard.canActivate(route, state).subscribe((result) => {
   *   expect(result).toBe(router.parseUrl('/login'));  // Should redirect to login
   * });
   * @example
   * // If subfeature redirects are defined, redirect to the highest priority:
   * route.data = {
   *   subfeatureRedirects: [
   *     { subfeature: 'feature1', path: '/feature1', order: 2 },
   *     { subfeature: 'feature2', path: '/feature2', order: 1 } // Higher priority
   *   ]
   * };
   * authService.userChanges$.and.returnValue(of(user)); // User is authenticated
   * featureService.hasAccess.and.returnValue(true); // User has access to 'feature2'
   * guard.canActivate(route, state).subscribe((result) => {
   *   expect(result).toBe(router.parseUrl('/feature2')); // Redirects to 'feature2'
   * });
   * @example
   * // If the user has access to the specified subfeature, allow access:
   * route.data = { subfeature: 'requiredFeature' };
   * authService.userChanges$.and.returnValue(of(user));  // Mock user
   * featureService.hasAccess.and.returnValue(true);      // User has access
   * guard.canActivate(route, state).subscribe((result) => {
   *   expect(result).toBe(true);  // Access allowed
   * });
   * @example
   * // If no access to subfeature, it redirects to an unauthorized error page:
   * route.data = { subfeature: 'requiredFeature' };
   * authService.userChanges$.and.returnValue(of(user));  // Mock user
   * featureService.hasAccess.and.returnValue(false);     // No access
   * guard.canActivate(route, state).subscribe((result) => {
   *   expect(result).toBe(router.parseUrl('/error/unauthorised'));  // Redirect to error page
   * });
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authService.userChanges$().pipe(
      take(1),
      map((userData) => {
        if (!userData) {
          // there isn't a signed in user
          return this.router.parseUrl('/login');
        }
        if (!route.data || (!route.data?.subfeature && !route.data?.subfeatures)) {
          // Navigate to an error page when no route data or subfeature definition found.
          console.error('Subfeature Guard Error: Subfeature is not defined.');
          return this.router.parseUrl('/error/unauthorised');
        }
        
        // Handle subfeature redirects based on user's access
        if (route.data.subfeatureRedirects) {
          const subfeatureRedirects = route.data.subfeatureRedirects;
          subfeatureRedirects.sort((a, b) => a.order - b.order); // sort in ascending order
          // Redirect to first path with a valid permission
          for (let i = 0; i < subfeatureRedirects.length; i++) {
            const compositeKey = subfeatureRedirects[i].subfeature;
            if (this.featureService.hasAccess(compositeKey)) {
              return this.router.parseUrl(
                subfeatureRedirects[i].path + this.paramPath(route.params)
              );
            }
          }
        }

        // Grant access to the default subfeature if the user has permission
        if (route.data.subfeature && this.featureService.hasAccess(route.data.subfeature)) {
          return true;          
        }

        // Grant access when the user has permission to any of the listed subfeatures
        if (route.data.subfeatures && this.featureService.hasAccessToAnyFeature(route.data.subfeatures)) {
          return true;  
        }

        // Redirect to unauthorised page if no access is found
        if (route.data.subfeature) {
          console.error(`Subfeature Guard Error: No access found for subfeature: ${route.data.subfeature}`);
        } else if (route.data.subfeatures) {
          console.error(`Subfeature Guard Error: No access found for subfeatures: ${route.data.subfeatures.join(', ')}`);
        }
        return this.router.parseUrl('/error/unauthorised');
      })
    );
  }

  /**
   * Generates a URL path by joining the values of the given parameters.
   *
   * @param {object} params - The parameters object, where each key represents a parameter name
   *                          and the value represents its corresponding value.
   * @returns {string} A string representing the URL path composed of the parameter values,
   *                   joined by slashes (`/`). Returns an empty string if no parameters are provided.
   */
  paramPath(params: Params): string {
    const paramKeyValues = [];
    for (const key in params) {
      paramKeyValues.push(params[key]);
    }
    return paramKeyValues.length > 0 ? `/${paramKeyValues.join('/')}` : '';
  }
}
