import {
  Component, OnInit, ElementRef, AfterViewInit, 
  HostListener, NgZone, ViewChild 
} from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment/moment';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  faPlus, faPencilAlt, faTimes, faSortDown, faFileAlt 
} from '@fortawesome/free-solid-svg-icons';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Subject, takeUntil } from 'rxjs';
import { SurveysService } from 'src/app/surveys/surveys.service';
import { reassignArrayPropertyValues, reassignPropertyValue } from 'src/app/utils/data';
import { FeatureAccessService } from 'src/app/common-services/feature-access.service';
import { deepCopy } from 'src/app/utils/deep-copy/deep-copy';
import { splitRoleByCaps } from 'src/app/utils/user-roles/split-role-by-caps';
import { User } from '../../models/user';
import { MessagesComponent } from '../../surveys/messages/messages.component';
import { CsaAuthService } from '../../auth/csa-auth.service';
import { StoreSelectionDialogComponent } from '../../surveys/store-selection-dialog/store-selection-dialog.component';
import { CsaBackEndService } from '../../common-services/csa-back-end.service';
import { AdhocSurveyService } from '../adhoc-survey.service';

@Component({
  selector: 'app-on-the-fly-survey-details',
  templateUrl: './on-the-fly-survey-details.component.html',
  styleUrls: ['./on-the-fly-survey-details.component.scss'],
})

export class OnTheFlySurveyDetailsComponent implements OnInit {
  surveyID = '';

  faPlus = faPlus;

  faTimes = faTimes;

  faSortDown = faSortDown;

  faPencilAlt = faPencilAlt;

  faFileAlt = faFileAlt;

  // orgArray: any[];
  dateToday = new Date();

  maxStartDate;

  minStartDate = new Date();

  minEndDate;

  count = 10;

  editEndDate = false;

  editStartDate = false;

  editValidity = false;

  index = 0;

  surveyDetails = {};

  user: User;

  // Tags
  editTags = false;

  tagList: string[] = [];

  originalTagList: string[] = [];

  // Permissions
  public viewDetails = false;

  public cancelOrCloseAssessment = false;

  public templatesViewDetails = false;

  public editSurveyDetailsBeforePublishDate = false;

  private onDestroy$ = new Subject<void>(); // used to unsubscribe from observables on component destroy

  constructor(
    private csaAuth: CsaAuthService,
    private featureAccessService: FeatureAccessService,
    private actRoute: ActivatedRoute,
    private dialog: MatDialog,
    private router: Router,
    private surveyService : SurveysService,
    private adhocService : AdhocSurveyService
  ) { }

  ngOnInit(): void {
    this.surveyID = this.actRoute.snapshot.paramMap.get('id');
    this.user = this.csaAuth.user;
    this.getPermissions();
    this.csaAuth.userChanges$().pipe(takeUntil(this.onDestroy$)).subscribe((user) => {
      this.user = user;
    });

    this.getAssessmentDetails();
  }

  getPermissions() {
    this.viewDetails = this.featureAccessService.hasAccess('adhocAssessments.viewDetails');
    this.cancelOrCloseAssessment = this.featureAccessService.hasAccess('adhocAssessments.cancelOrCloseAssessment');
    this.templatesViewDetails = this.featureAccessService.hasAccess('templates.view');
    this.editSurveyDetailsBeforePublishDate = this.featureAccessService.hasAccess('adhocAssessments.editSurveyDetailsBeforePublishDate');
  }

  getAssessmentDetails() {
    this.surveyService
      .getAdminSurveyById(this.surveyID)
      .subscribe((surveysData) => {
        this.surveyDetails = surveysData;
        // Reassign filterName property value from 'eitb' to 'gps'
        reassignPropertyValue(this.surveyDetails, 'filterName', 'eitb', 'GPS');              
        this.maxStartDate = new Date(this.surveyDetails['endDate']);
        this.minEndDate = new Date(this.surveyDetails['startDate']);
        this.tagList = this.surveyDetails['tags'] ?? [];
        this.originalTagList = deepCopy(this.tagList);
        console.log(this);
      });
  }

  cancelTagChanges() {
    this.tagList = deepCopy(this.originalTagList);
  }

  saveTagChanges() {
    const updateData = {
      modifiedBy: this.user['email'],
      id: this.surveyDetails['id'],
      startDate: this.surveyDetails['startDate'],
      endDate: this.surveyDetails['endDate'],
      surveyValidity: Number(this.surveyDetails['surveyValidity']),
      type: 'adhoc',
      status: this.surveyDetails['status'],
      tags: this.tagList,
    };    

    this.surveyService.updateSurvey(updateData).subscribe(
      (data) => {
        this.originalTagList = deepCopy(this.tagList);
        console.log(`Tags Updated for: ${this.surveyDetails['title']}`, this.tagList);
        this.dialog.open(MessagesComponent, {
          data: {
            heading: 'Tags Updated',
            message:
              'Tags have been updated.',
            closeText: 'Ok',
          },
          backdropClass: 'dialog-backdrop',
        });
      },
      (error) => {
        this.dialog.open(MessagesComponent, {
          data: {
            heading: "Couldn't Update Tags",
            message:
              `Please email ${this.user['supportEmail']} for support`,
            closeText: 'Ok',
          },
          backdropClass: 'dialog-backdrop',
        });
      }
    );
  }

  haveTagsChanged(): boolean {
    if (this.tagList.length !== this.originalTagList.length) {
      return true;
    }

    return !this.originalTagList.every((value, index) => value === this.tagList[index]);    
  }

  tagListChanged(tagList: string[]) {
    console.log('tagListChanged', tagList);
    this.tagList = tagList;
  }

  // Exposes global function splitRoleByCaps to template
  splitRoleByCaps(value): string {
    return splitRoleByCaps(value, this.user['division']);
  }

  cancelCloseAssessment() {
    const updateData = {
      modifiedBy: this.user['email'],
      id: this.surveyDetails['id'],
      type: 'adhoc',
      status: this.surveyDetails['status'] === 'active' ? 'cancelled' : 'closed',
    };

    const cancelledClosedText = this.surveyDetails['status'] === 'active' ? 'Cancelled' : 'Closed';
    const cancelCloseText = this.surveyDetails['status'] === 'active' ? 'Cancel' : 'Close';

    const message = this.surveyDetails['status'] === 'active' 
      ? `If you continue, this '${
        this.surveyDetails['title']
      }' assessment will not be published.` 
      : `If you continue, no future '${
        this.surveyDetails['title']
      }' assessments will be sent to users. The currently published assessment will remain active until its due date.`;

    const confirmationCancelClose = this.dialog.open(MessagesComponent, {
      data: {
        heading: 'Are You Sure?',
        message,
        closeText: `${cancelCloseText} Assessment`,
        cancelText: 'Cancel',
      },
      backdropClass: 'dialog-backdrop',
    });

    confirmationCancelClose.afterClosed().subscribe((result) => {
      if (result == 'logout') {
        this.surveyService.updateSurvey(updateData).subscribe(
          (message) => {
            if (message['status'] == 200) {
              const confirmationTwo = this.dialog.open(MessagesComponent, {
                data: {
                  heading: `Assessment ${cancelledClosedText}`,
                  message: `This assessment has now been ${cancelledClosedText.toLowerCase()}. 
                    The page will now reload to update the status.`,
                  closeText: 'Ok',
                },
                backdropClass: 'dialog-backdrop',
              });

              confirmationTwo.afterClosed().subscribe((result) => {
                window.location.reload();
              });
            } else {
              this.dialog.open(MessagesComponent, {
                data: {
                  heading: `Couldn't ${cancelCloseText} Assessment`,
                  message:
                    `Please email ${ 
                      this.user['supportEmail']
                    } for support`,
                  closeText: 'Ok',
                },
                backdropClass: 'dialog-backdrop',
              });
            }
          },
          (error) => {
            this.dialog.open(MessagesComponent, {
              data: {
                heading: `Couldn't ${cancelCloseText} Assessment`,
                message:
                  `Please email ${this.user['supportEmail']} for support`,
                closeText: 'Ok',
              },
              backdropClass: 'dialog-backdrop',
            });
          }
        );
      }
    });
  }

  createOnTheFlySurvey() {
    if (this.user['role'] == 'Store' || this.user['role'] == 'MultiStore') {
      const adhocAssessment = {};
      adhocAssessment['storeID'] = this.user['storeID'];
      adhocAssessment['survey_id'] = this.surveyDetails['id'];

      if (this.surveyDetails['activeResponseCount'] > 0) {
        const confirmMultipleDialog = this.dialog.open(MessagesComponent, {
          data: {
            heading: 'Active Responses',
            message:
              `You currently have ${ 
                this.surveyDetails['activeResponseCount']
              } Active Responses for this Assessment. Are you sure you would like to create a new one?`,
            closeText: 'Create',
            cancelText: 'See Active Responses',
          },
          backdropClass: 'dialog-backdrop',
        });

        confirmMultipleDialog.afterClosed().subscribe((result) => {
          if (result == 'logout') {
            this.adhocService
              .createOnTheFlySurvey(adhocAssessment)
              .subscribe((reply) => {
                if (reply['status'] == 200) {
                  const dialogSuccess = this.dialog.open(MessagesComponent, {
                    data: {
                      heading: 'Adhoc Assessment Created',
                      closeText: 'OK',
                      message:
                        'Adhoc Assessment has been created. Please click ok to go to the Adhoc Assessment, and begin completing it',
                    },
                    disableClose: true,
                    backdropClass: 'dialog-backdrop',
                  });

                  dialogSuccess.afterClosed().subscribe((result) => {
                    this.router.navigate([
                      '/assessment-response-edit',
                      reply['response_id'],
                    ]);
                  });
                } else {
                  this.dialog.open(MessagesComponent, {
                    data: {
                      heading: "Couldn't Schedule Adhoc Assessment",
                      message: `Please email ${this.user['supportEmail']} for support`,
                    },
                    disableClose: true,
                    backdropClass: 'dialog-backdrop',
                  });
                }
              });
          } else {
            this.router.navigate([
              '/onthefly-survey-details',
              this.surveyDetails['id'],
            ]);
          }
        });
      } else {
        this.adhocService
          .createOnTheFlySurvey(adhocAssessment)
          .subscribe((reply) => {
            if (reply['status'] == 200) {
              const dialogSuccess = this.dialog.open(MessagesComponent, {
                data: {
                  heading: 'Adhoc Assessment Created',
                  closeText: 'OK',
                  message:
                    'Adhoc Assessment has been created. Please click ok to go to the Adhoc Assessment, and begin completing it',
                },
                disableClose: true,
                backdropClass: 'dialog-backdrop',
              });

              dialogSuccess.afterClosed().subscribe((result) => {
                this.router.navigate([
                  '/assessment-response-edit',
                  reply['response_id'],
                ]);
              });
            } else {
              this.dialog.open(MessagesComponent, {
                data: {
                  heading: "Couldn't Schedule Adhoc Assessment",
                  message: `Please email ${this.user['supportEmail']} for support`,
                },
                disableClose: true,
                backdropClass: 'dialog-backdrop',
              });
            }
          });
      }
    } else {
      const storeSelectDialog = this.dialog.open(StoreSelectionDialogComponent, {
        backdropClass: 'dialog-backdrop',
        minWidth: '70%',
        maxWidth: '90vw',
        height: 'auto',
        data: {
          allowClose: true,
          message: 'Please select the store you are completing this Adhoc Assessment for.',
        },
      });

      storeSelectDialog.afterClosed().subscribe((result) => {
        if (result['storeNo']) {
          const adhocAssessment = {};
          adhocAssessment['storeID'] = result['storeNo'];
          adhocAssessment['survey_id'] = this.surveyDetails['id'];

          this.surveyService
            .getAboveStoreSurveyID(
              this.surveyDetails['id'],
              `?storeID=${adhocAssessment['storeID']}`
            )
            .subscribe((surveyDetails) => {
              if (surveyDetails['activeResponseCount'] > 0) {
                const confirmMultipleDialog = this.dialog.open(
                  MessagesComponent,
                  {
                    data: {
                      heading: 'Active Responses',
                      message:
                        `You currently have ${ 
                          surveyDetails['activeResponseCount']
                        } Active Responses for this Store & Assessment. Are you sure you would like to create a new one?`,
                      closeText: 'Create',
                      cancelText: 'Cancel',
                    },
                    backdropClass: 'dialog-backdrop',
                  }
                );

                confirmMultipleDialog.afterClosed().subscribe((result) => {
                  console.log(result);
                  if (result == 'logout') {
                    this.adhocService
                      .createOnTheFlySurvey(adhocAssessment)
                      .subscribe((reply) => {
                        if (reply['status'] == 200) {
                          const dialogSuccess = this.dialog.open(
                            MessagesComponent,
                            {
                              data: {
                                heading: 'Adhoc Assessment Created',
                                closeText: 'OK',
                                message:
                                  'Adhoc Assessment has been created. Please click ok to go to the Adhoc Assessment, and begin completing it',
                              },
                              disableClose: true,
                              backdropClass: 'dialog-backdrop',
                            }
                          );
                          dialogSuccess.afterClosed().subscribe((result) => {
                            this.router.navigate([
                              '/assessment-response-edit',
                              reply['response_id'],
                            ]);
                          });
                        } else {
                          this.dialog.open(MessagesComponent, {
                            data: {
                              heading: "Couldn't Schedule Adhoc Assessment",
                              message: `Please email ${this.user['supportEmail']} for support`,
                            },
                            disableClose: true,
                            backdropClass: 'dialog-backdrop',
                          });
                        }
                      });
                  } else {
                    this.router.navigate([
                      '/onthefly-survey-details',
                      this.surveyDetails['id'],
                    ]);
                  }
                });
              } else {
                this.adhocService
                  .createOnTheFlySurvey(adhocAssessment)
                  .subscribe((reply) => {
                    if (reply['status'] == 200) {
                      const dialogSuccess = this.dialog.open(MessagesComponent, {
                        data: {
                          heading: 'Adhoc Assessment Created',
                          closeText: 'OK',
                          message:
                            'Adhoc Assessment has been created. Please click ok to go to the Adhoc Assessment, and begin completing it',
                        },
                        disableClose: true,
                        backdropClass: 'dialog-backdrop',
                      });
                      dialogSuccess.afterClosed().subscribe((result) => {
                        this.router.navigate(['/assessment-response-edit', reply['response_id']]);
                      });
                    } else {
                      this.dialog.open(MessagesComponent, {
                        data: {
                          heading: "Couldn't Schedule Adhoc Assessment",
                          message:
                            `Please email ${ 
                              this.user['supportEmail']
                            } for support`,
                        },
                        disableClose: true,
                        backdropClass: 'dialog-backdrop',
                      });
                    }
                  });
              }
            });
        } else {
          this.dialog.open(MessagesComponent, {
            data: {
              heading: 'Adhoc Assessment Cancelled',
              message:
                'You need to select a store for your Adhoc Assessment before starting.',
            },
            disableClose: true,
            backdropClass: 'dialog-backdrop',
          });
        }
      });
    }
  }

  getDate(date) {
    date = moment(date, ['DD/MM/YYYY', 'DD MMM YYYY', moment.ISO_8601]).format(
      'DD MMM YYYY'
    );
    return date;
  }

  submitForm2(survey, value) {
    const updateData = {
      modifiedBy: this.user['email'],
      id: this.surveyDetails['id'],
      endDate: moment(value.dueDate, [
        'DD/MM/YYYY',
        'DD MMM YYYY',
        moment.ISO_8601,
      ]).format(),
      surveyValidity: Number(this.surveyDetails['surveyValidity']),
      type: 'adhoc',
      status: this.surveyDetails['status'],
    };

    this.surveyService.updateSurvey(updateData).subscribe(
      (message) => {
        if (message['status'] == 200) {
          const confirmationTwo = this.dialog.open(MessagesComponent, {
            data: {
              heading: 'End Date Updated',
              message:
                'The end date has been updated. The page will now reload to update the reminder dates',
              closeText: 'Ok',
            },
            backdropClass: 'dialog-backdrop',
          });

          confirmationTwo.afterClosed().subscribe((result) => {
            window.location.reload();
          });
        } else {
          this.dialog.open(MessagesComponent, {
            data: {
              heading: "Couldn't Update End Date",
              message:
                `Please email ${this.user['supportEmail']} for support`,
              closeText: 'Ok',
            },
            backdropClass: 'dialog-backdrop',
          });
        }
      },
      (error) => {
        this.dialog.open(MessagesComponent, {
          data: {
            heading: "Couldn't Update End Date",
            message:
              `Please email ${this.user['supportEmail']} for support`,
            closeText: 'Ok',
          },
          backdropClass: 'dialog-backdrop',
        });
      }
    );

    this.surveyDetails['dueDate'] = this.getDate(value.dueDate);

    this.editEndDate = false;
    this.maxStartDate = moment(value.dueDate, [
      'DD/MM/YYYY',
      'DD MMM YYYY',
      moment.ISO_8601,
    ]);
    survey.reset();
  }

  submitForm3(survey, value) {
    const updateData = {
      modifiedBy: this.user['email'],
      id: this.surveyDetails['id'],
      startDate: moment(value.publishDate, [
        'DD/MM/YYYY',
        'DD MMM YYYY',
        moment.ISO_8601,
      ]).format(),
      surveyValidity: Number(this.surveyDetails['surveyValidity']),
      type: 'adhoc',
      status: this.surveyDetails['status'],
    };

    this.surveyService.updateSurvey(updateData).subscribe(
      (message) => {
        if (message['status'] == 200) {
          const confirmation = this.dialog.open(MessagesComponent, {
            data: {
              heading: 'Start Date Updated',
              message:
                'The start date has been updated. The page will now reload to update the Assessment Schedule',
              closeText: 'Ok',
            },
            backdropClass: 'dialog-backdrop',
          });

          confirmation.afterClosed().subscribe((result) => {
            window.location.reload();
          });
        } else {
          this.dialog.open(MessagesComponent, {
            data: {
              heading: "Couldn't Update Start Date",
              message:
                `Please email ${this.user['supportEmail']} for support`,
              closeText: 'Ok',
            },
            backdropClass: 'dialog-backdrop',
          });
        }
      },
      (error) => {
        this.dialog.open(MessagesComponent, {
          data: {
            heading: "Couldn't Update Start Date",
            message:
              `Please email ${this.user['supportEmail']} for support`,
            closeText: 'Ok',
          },
          backdropClass: 'dialog-backdrop',
        });
      }
    );

    this.minEndDate = moment(value.publishDate, [
      'DD/MM/YYYY',
      'DD MMM YYYY',
      moment.ISO_8601,
    ]);
    this.editStartDate = false;
    this.surveyDetails['publishDate'] = this.getDate(value.publishDate);

    survey.reset();
  }

  submitForm4(survey, value) {
    const updateData = {
      modifiedBy: this.user['email'],
      id: this.surveyDetails['id'],
      startDate: this.surveyDetails['startDate'],
      endDate: this.surveyDetails['endDate'],
      surveyValidity: Number(value['validity']),
      type: 'adhoc',
      status: this.surveyDetails['status'],
    };

    this.surveyService.updateSurvey(updateData).subscribe(
      (message) => {
        if (message['status'] == 200) {
          const confirmation = this.dialog.open(MessagesComponent, {
            data: {
              heading: 'Days to Complete Updated',
              message:
                'The Days to Complete have been updated. The page will now reload to update the Assessment Schedule',
              closeText: 'Ok',
            },
            backdropClass: 'dialog-backdrop',
          });

          confirmation.afterClosed().subscribe((result) => {
            window.location.reload();
          });
        } else {
          this.dialog.open(MessagesComponent, {
            data: {
              heading: "Couldn't Update Days to Complete",
              message:
                `Please email ${this.user['supportEmail']} for support`,
              closeText: 'Ok',
            },
            backdropClass: 'dialog-backdrop',
          });
        }
      },
      (error) => {
        this.dialog.open(MessagesComponent, {
          data: {
            heading: "Couldn't Update Days to Complete",
            message:
              `Please email ${this.user['supportEmail']} for support`,
            closeText: 'Ok',
          },
          backdropClass: 'dialog-backdrop',
        });
      }
    );

    this.minEndDate = moment(value.publishDate, [
      'DD/MM/YYYY',
      'DD MMM YYYY',
      moment.ISO_8601,
    ]);
    this.editValidity = false;
    this.surveyDetails['publishDate'] = this.getDate(value.publishDate);

    survey.reset();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
