import { Tag, TagType } from 'src/app/types/tag';

/**
 * Adds matching statuses to the `addToTagList` array in each object of the provided data array.
 *
 * This function iterates through the `data` array and compares each object's `status` property with the 
 * provided `statuses` array. If a match is found (case-insensitive), the status is added to the `addToTagList` 
 * array of that object. If the `addToTagList` array does not exist, it is initialized as an empty array.
 *
 * @param {object[]} data - The array of objects to process. Each object should have a `status` property.
 * @param {string[]} statuses - The list of statuses to match against the `status` property of each object.
 * @returns {object[]} The updated array of objects. Each object may include:
 * - `status` {string} - The original status of the object (if present).
 * - `addToTagList` {string[]} - An array containing the matched statuses. If no matches are found, this property remains unchanged or uninitialized.
 * @example
 * const data = [
 *   { status: 'Active', otherProp: 'value1' },
 *   { status: 'Pending', otherProp: 'value2' },
 *   { status: 'Inactive', otherProp: 'value3' }
 * ];
 * const statuses = ['Active', 'Inactive'];
 * 
 * const result = addStatusToAddToTagList(data, statuses);
 * console.log(result);
 * // Output:
 * // [
 * //   { status: 'Active', otherProp: 'value1', addToTagList: ['Active'] },
 * //   { status: 'Pending', otherProp: 'value2' },
 * //   { status: 'Inactive', otherProp: 'value3', addToTagList: ['Inactive'] }
 * // ]
 */
export function addStatusToAddToTagList(
  data: object[],
  statuses: string[]
): object[] {
  data?.forEach((row) => {
    statuses?.forEach((status) => {
      if (row['status'] && status.toLowerCase() === row['status'].toLowerCase()) {
        if (!row['addToTagList']) {
          row['addToTagList'] = [];
        }
        let tagType = TagType.Default;
        switch (status.toLowerCase()) {
          case 'cancelled':
            tagType = TagType.Cancelled;
            break;
          // extend and continue matching statuses here
        }
        const tag = <Tag>{ name: status, type: tagType };
        row['addToTagList'].push(tag);
      }
    });
  });
  return data;
}
