<div class="close-container">
  <span class="info-icon med-grey" (click)="closeMe()">
    <fa-icon [icon]="faTimes"></fa-icon>
  </span>
</div>

<mat-dialog-content>
  <h1 id="dialog-heading">{{ data.heading }}</h1>


  <h4 *ngIf="data.idleMessage">{{ idleState }}</h4>

  <div *ngIf="viewNotesAvailable">
    <div *ngFor="let section of surveyDetails; let i = index">
      <div class="audit-tool-card question-card">
        <div class="p2">
          <div class="question-options-row no-top-margin no-bottom-margin">
            <div class="survey-detail-block">
              <h4>Role:</h4>
              <p>{{ section["role"] | RoleFieldToDisplayString }}</p>
            </div>

            <div class="survey-detail-block">
              <h4>Visit Note added Date:</h4>
              <p>{{ section["addDate"] | date :'dd MMM y' }}</p>
            </div>
          </div>

          <div class="question-options-row no-bottom-margin">
            <div class="survey-detail-block">
              <h4>Email Address:</h4>
              <p>{{ section["visitNotesBy"] }}</p>
            </div>
          </div>

          <div class="question-options-row no-bottom-margin">
            <div class="survey-detail-block">
              <h4>Visit Notes Comments:</h4>
              <div id="textContainer{{i}}" (window:resize)="checkClipping(i)" class="detailed-finding center-align" [ngClass]="{'expansion_case': visitNotesState[i]['showMore']}">
                {{section["notes"]}}</div>
            </div>
            <div class="show-more-less" *ngIf="visitNotesState[i]['exceededMaxNumberOfLines']" (click)="toggleShowMoreLess(i)">{{ visitNotesState[i]['showMore'] ? 'Show more' : 'Show less'}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    *ngIf="canUserAddVisitNotes">
    <h3 id="dialog-message" [innerHTML]="data.message"></h3>

    <form #visitNotes="ngForm" (submit)="saveNotes(visitNotes.value)" (keydown.enter)="$event.preventDefault()">
      <div class="create-action-form">
        <h1 class="create-action-headings left-align">Visit Notes:</h1>
        <mat-form-field appearance="outline" color="primary">
          <mat-label class="" for="notes">visit Notes</mat-label>
          <textarea cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" spellcheck="true" ngModel
            #notes maxLength="3000" matInput class="custom-control-input" name="notes"
            (ngModelChange)="saveNotes($event)"></textarea>
          <mat-hint align="end">{{ notes["value"].length || 0 }}/3000</mat-hint>
        </mat-form-field>
      </div>

      <div class="submit-button-container">
        <div class="survey-link-container">
          <div *ngIf="!visitNotes.valid || !visitNotes.dirty" class="subtle-centered-text">
            <span *ngIf="!visitNotes.dirty">
              <!-- The form has not been changed. -->
            </span>
            <span *ngIf="!visitNotes.valid">
              <!-- Please complete all fields to save. -->
            </span>
          </div>

          <div class="admin-button-container filter-chips">
            <button [disabled]="!visitNotes.valid || !visitNotes.dirty" class="csa-button-solid survey-submit" type="submit"
              (click)="sendVisitNote(visitNotes.value)">
              <span> Submit </span>
            </button>

            <div *ngIf="data.cancelText">
              <button class="csa-button-outline" (click)="closeMe()">
                {{ data.cancelText }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</mat-dialog-content>