import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment/moment';
import { MatDialog } from '@angular/material/dialog';
import {
  faAngleDown, faAngleUp, faExclamationCircle, faPlus 
} from '@fortawesome/free-solid-svg-icons';
import { reassignPropertyValue } from 'src/app/utils/data';
import { CreateSurveyDialogComponent } from '../../surveys/create-survey-dialog/create-survey-dialog.component';
import { SurveysService } from '../surveys.service';

@Component({
  selector: 'app-template-details',
  templateUrl: './template-details.component.html',
  styleUrls: ['./template-details.component.scss']
})
export class TemplateDetailsComponent implements OnInit {
  faPlus = faPlus;

  faAngleDown = faAngleDown;

  faAngleUp = faAngleUp;

  activeSurveys;

  faExclamationCircle = faExclamationCircle;

  allExpandState = true;

  templates = [];

  thisTemplate = {};

  templateID = '';

  openItems = [];

  constructor(
     private actRoute: ActivatedRoute, 
     private dialog: MatDialog,
     private surveyService : SurveysService,
  ) { }

  ngOnInit() {
    this.templateID = this.actRoute.snapshot.paramMap.get('id');

    this.surveyService.getTemplates(`/${this.templateID}`).subscribe((templatesData) => {
      // Convert templatesData.filterName property value to 'gps' when 'found to be 'eitb' before
      // assigning templatesData to thisTemplate.
      this.thisTemplate = reassignPropertyValue(templatesData, 'filterName', 'eitb', 'GPS');
      if (!this.thisTemplate['sectionHeading']) {
        this.thisTemplate['sectionHeading'] = ['All Questions'];
        this.thisTemplate['questions'].forEach((question) => {
          question['sectionHeading'] = 'All Questions';
        });
      }
      this.thisTemplate['questions'].forEach((question) => {
        if (!question['sectionHeading']) {
          question['sectionHeading'] = 'All Questions';
        }
      });
    });
  }

  setOpened(itemIndex) {
    this.openItems.push(itemIndex);
  }

  setClosed(itemIndex) {
    for (let i = 0; i < this.openItems.length; i++) {
      if (this.openItems[i] === itemIndex) {
        this.openItems.splice(i, 1);
      }
    }
  }

  createSurvey() {
    this.dialog.open(CreateSurveyDialogComponent, {
      backdropClass: 'dialog-backdrop',
      minWidth: '70%',
      minHeight: 'calc(100vh - 90px)',
      height: 'auto',
      data: { templateid: this.templateID, templateDivision: this.thisTemplate['division'] } 
    });
  }
}
