<div class="page-container">
	<div class="store-survey">
		<div>
			<div class="survey-page-stat">
				<h1 [ngClass]="{
                  'light-green': surveyDetails['status'] === 'active',
                  'light-blue' : surveyDetails['status'] === 'started'
                }">{{ surveyDetails['completion'] | percent }}</h1>
				<p>Complete</p>
			</div>
		</div>
		<div class="survey-page-heading no-bottom-margin">
			<h2 class="no-bottom-margin">{{surveyDetails['title'] | unescape}}</h2>
			<h1>
				For Store - {{surveyDetails['storeID']}} - {{surveyDetails['storeName']}}
			</h1>
			<div class="question-options-row">
				<div class="filter-chips">
					<div class="chipDrop">
						<mat-chip-list aria-label="Filters">
							<mat-chip color="primary" selected (click)="printSelect.open()">
								<span>
									<fa-icon class="chip-left-icon" [icon]="faFileInvoice"></fa-icon>
									<span>Filter Response</span>
								</span>
							</mat-chip>
							<mat-select #printSelect class="optionsDropDown">
								<mat-option (click)="printResponse('All');">
									All Questions
								</mat-option>
								<mat-option (click)="printResponse('Answered');">
									Answered Questions
								</mat-option>
								<mat-option (click)="printResponse('Unanswered');">
									Unanswered Questions
								</mat-option>
								<mat-option (click)="printResponse('No');">
									Questions Answered 'No'
								</mat-option>
								<mat-option (click)="printResponse('Yes');">
									Questions Answered 'Yes'
								</mat-option>
							</mat-select>
						</mat-chip-list>
					</div>
					<div class="chipDrop" *ngIf="showCancelResponse">
						<mat-chip-list aria-label="Filters">
							<mat-chip color="warn" selected (click)="cancelResponse()">
								<span>
									<fa-icon class="chip-left-icon" [icon]="faTimes"></fa-icon>
									<span>Cancel Response</span>
								</span>
							</mat-chip>
						</mat-chip-list>
					</div>
				</div>
			</div>
		</div>

		<form #surveyForm="ngForm" (submit)="submitForm(surveyForm.value)">
			<a #saveButton (click)="saveForm('All')" class="csa-button-solid csa-floating-button">
        <span *ngIf="saving == true" class="padded-icon">
          <fa-icon [icon]="faCircleNotch" [spin]="true"></fa-icon>
        </span>
        <span *ngIf="saved == true && saving == false" class="padded-icon">
          <fa-icon [icon]="faCheck"></fa-icon>
        </span>
        <span *ngIf="saved == false && saving == false" class="padded-icon">
          <fa-icon [icon]="faSave"></fa-icon>
        </span>
        <span *ngIf="saved == false && saving == false">&nbsp;&nbsp;</span>Save<span *ngIf="saved == true && saving == false">d</span>
      </a>
			<div class="submit-button-container center-align" *ngIf="surveyForm.valid && actionsValid">
				<button class="csa-button-solid survey-submit" type="submit">
					<span>
						<fa-icon [icon]="faPaperPlane"></fa-icon>
					</span> Submit
				</button>		
			</div>
			
			<div class="survey-container">			
				<div class="question-options-row no-top-margin no-bottom-margin">
					<h3 class="light-green medium-text expand-collapse" (click)="allExpandState = !allExpandState">
						<span *ngIf="allExpandState == false">
							&nbsp;&nbsp;Show All
							<fa-icon [icon]="faAngleDown"></fa-icon>
						</span>
						<span *ngIf="allExpandState == true">
							&nbsp;&nbsp;Hide All
							<fa-icon [icon]="faAngleUp"></fa-icon>
						</span>
						&nbsp;&nbsp;
					</h3>
				</div>

				<mat-accordion class="questions-accordion" multi>
					<mat-expansion-panel [expanded]="allExpandState" #panel hideToggle
						*ngFor="let heading of surveyDetails['sectionHeading']; let sectionIndex = index;" (opened)="setOpened(sectionIndex)"
						(closed)="setClosed(sectionIndex)">
						<mat-expansion-panel-header>
							<mat-panel-title>
								<span class="neg-5-margin hide-on-mobile">
									<svg height="3" width="200">
										<line x1="0" y1="0" x2="200" y2="0" style="stroke-width:4" />
									</svg>
								</span>
								&nbsp;&nbsp;<span>{{heading}}</span>&nbsp;&nbsp;<fa-icon *ngIf="openItems.indexOf(sectionIndex) === -1"
									[icon]="faAngleDown">
								</fa-icon>
								<fa-icon *ngIf="openItems.indexOf(sectionIndex) > -1" [icon]="faAngleUp"></fa-icon>&nbsp;&nbsp;
								<span class="neg-5-margin hide-on-mobile">
									<svg height="3" width="200">
										<line x1="0" y1="0" x2="200" y2="0" style="stroke-width:4" />
									</svg>
								</span>
							</mat-panel-title>
						</mat-expansion-panel-header>

						<div>
							<div
								*ngFor="let question of (surveyDetails['questions'] | groupQuestions:{sectionHeading: heading}); let sectionQuestionIndex = index;">
								<div class="audit-tool-card question-card">
									<div class="p2">
										<div class="question-count">
											<span class="large-count">Question {{sectionQuestionIndex+1}}</span> of
											{{(surveyDetails['questions'] | groupQuestions:{sectionHeading: heading}).length}}
										</div>
										<div class="question-title" [innerHTML]="question.title"></div>
										<div *ngIf="question.type === 'boolean' && !question['radioOptions'] " class="question-options-row">
											<div class="question-option">
												<label [ngClass]="{'active' : getSurveyResponses(question['id']) === 'Yes'}"
													class="csa-button-outline custom-control-label" for="{{question['id'] + 'bool1'}}">
													<input required ngModel (change)="saveForm(question['id'])" id="{{question['id'] + 'bool1'}}"
														type="radio" class="custom-control-input" value="Yes"
														name="{{question['id'] + 'response'}}">
													Yes
												</label>
											</div>
											<div class="question-option">
												<label [ngClass]="{'active' : getSurveyResponses(question['id']) === 'No'}"
													class="csa-button-outline custom-control-label" for="{{question['id'] + 'bool2'}}">
													<input required ngModel (change)="saveForm(question['id'])" id="{{question['id'] + 'bool2'}}"
														type="radio" class="custom-control-input" value="No" name="{{question['id'] + 'response'}}">
													No
												</label>
											</div>
										</div>
										<div *ngIf="question.type === 'boolean' && question['radioOptions']" class="question-options-row">
											<div class="question-option">
												<label
													[ngClass]="{'active' : getSurveyResponses(question['id']) == question.radioOptions[0].answer}"
													class="csa-button-outline custom-control-label" for="{{question['id'] + 'bool1'}}">
													<input required ngModel (change)="saveForm(question['id'])" id="{{question['id'] + 'bool1'}}"
														type="radio" class="custom-control-input" value="{{question.radioOptions[0].answer}}"
														name="{{question['id'] + 'response'}}">
													{{question.radioOptions[0].answer}}
												</label>
											</div>
											<div class="question-option">
												<label
													[ngClass]="{'active' : getSurveyResponses(question['id']) == question.radioOptions[1].answer}"
													class="csa-button-outline custom-control-label" for="{{question['id'] + 'bool2'}}">
													<input required ngModel (change)="saveForm(question['id'])" id="{{question['id'] + 'bool2'}}"
														type="radio" class="custom-control-input" value="{{question.radioOptions[1].answer}}"
														name="{{question['id'] + 'response'}}">
													{{question.radioOptions[1].answer}}
												</label>
											</div>
										</div>
										<div *ngIf="question.type === 'boolean-na' && !question['radioOptions']"
											class="question-options-row">
											<div class="question-option">
												<label [ngClass]="{'active' : getSurveyResponses(question['id']) === 'Yes'}"
													class="csa-button-outline custom-control-label" for="{{question['id'] + 'boolna1'}}">
													<input required ngModel (change)="saveForm(question['id'])"
														id="{{question['id'] + 'boolna1'}}" type="radio" class="custom-control-input" value="Yes"
														name="{{question['id'] + 'response'}}">
													Yes
												</label>
											</div>
											<div class="question-option">
												<label [ngClass]="{'active' : getSurveyResponses(question['id']) === 'No'}"
													class="csa-button-outline custom-control-label" for="{{question['id'] + 'boolna2'}}">
													<input required ngModel (change)="saveForm(question['id'])"
														id="{{question['id'] + 'boolna2'}}" type="radio" class="custom-control-input" value="No"
														name="{{question['id'] + 'response'}}">
													No
												</label>
											</div>
											<div class="question-option">
												<label [ngClass]="{'active' : getSurveyResponses(question['id']) === 'NA'}"
													class="csa-button-outline custom-control-label" for="{{question['id'] + 'bool3'}}">
													<input required ngModel (change)="saveForm(question['id'])" id="{{question['id'] + 'bool3'}}"
														type="radio" class="custom-control-input" value="NA" name="{{question['id'] + 'response'}}">
													N/A
												</label>
											</div>
										</div>
										<div *ngIf="question.type === 'boolean-na' && question['radioOptions']" class="question-options-row">
											<div class="question-option">
												<label
													[ngClass]="{'active' : getSurveyResponses(question['id']) == question.radioOptions[0].answer}"
													class="csa-button-outline custom-control-label" for="{{question['id'] + 'boolna1'}}">
													<input required ngModel (change)="saveForm(question['id'])"
														id="{{question['id'] + 'boolna1'}}" type="radio" class="custom-control-input"
														value="{{question.radioOptions[0].answer}}" name="{{question['id'] + 'response'}}">
													{{question.radioOptions[0].answer}}
												</label>
											</div>
											<div class="question-option">
												<label
													[ngClass]="{'active' : getSurveyResponses(question['id']) == question.radioOptions[1].answer}"
													class="csa-button-outline custom-control-label" for="{{question['id'] + 'boolna2'}}">
													<input required ngModel (change)="saveForm(question['id'])"
														id="{{question['id'] + 'boolna2'}}" type="radio" class="custom-control-input"
														value="{{question.radioOptions[1].answer}}" name="{{question['id'] + 'response'}}">
													{{question.radioOptions[1].answer}}
												</label>
											</div>
											<div class="question-option">
												<label
													[ngClass]="{'active' : getSurveyResponses(question['id']) == question.radioOptions[2].answer}"
													class="csa-button-outline custom-control-label" for="{{question['id'] + 'bool3'}}">
													<input required ngModel (change)="saveForm(question['id'])" id="{{question['id'] + 'bool3'}}"
														type="radio" class="custom-control-input" value="{{question.radioOptions[2].answer}}"
														name="{{question['id'] + 'response'}}">
													{{question.radioOptions[2].answer}}
												</label>
											</div>
										</div>
										<div *ngIf="question.type === 'radio' " class="question-options-column">
											<div class="question-option">
												<label
													[ngClass]="{'active' : getSurveyResponses(question['id']) == question.radioOptions[0].answer }"
													class="csa-button-outline custom-control-label" for="{{question['id'] + 'radio1'}}">
													<input required ngModel (change)="saveForm(question['id']);"
														id="{{question['id'] + 'radio1'}}" type="radio" class="custom-control-input"
														value="{{question.radioOptions[0].answer}}" name="{{question['id'] + 'response'}}">
													{{question.radioOptions[0].answer}}
												</label>
											</div>
											<div class="question-option">
												<label
													[ngClass]="{'active' : getSurveyResponses(question['id']) == question.radioOptions[1].answer }"
													class="csa-button-outline custom-control-label" for="{{question['id'] + 'radio2'}}">
													<input required ngModel (change)="saveForm(question['id'])" id="{{question['id'] + 'radio2'}}"
														type="radio" class="custom-control-input" value="{{question.radioOptions[1].answer}}"
														name="{{question['id'] + 'response'}}">
													{{question.radioOptions[1].answer}}
												</label>
											</div>
											<div class="question-option" *ngIf="question.radioOptions[2]">
												<label
													[ngClass]="{'active' : getSurveyResponses(question['id']) == question.radioOptions[2].answer }"
													class="csa-button-outline custom-control-label" for="{{question['id'] + 'radio3'}}">
													<input required ngModel (change)="saveForm(question['id'])" id="{{question['id'] + 'radio3'}}"
														type="radio" class="custom-control-input" value="{{question.radioOptions[2].answer}}"
														name="{{question['id'] + 'response'}}">
													{{question.radioOptions[2].answer}}
												</label>
											</div>
										</div>

										<div *ngIf="question.type === 'freeText' " class="question-options-row">
											<mat-form-field appearance="outline" color="primary">
												<mat-label class="">Free Text</mat-label>
												<textarea required (keyup)="saveUser(question['id'])" spellcheck="true" cdkTextareaAutosize
													#autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" matInput #userSection
													(ngModelChange)="question['response']=$event" [ngModel]="question['response'] | unescape"
													class="custom-control-input" name="{{question['id'] + 'response'}}"></textarea>
												<!--<mat-hint align="end">{{noteSection['value']?.length || 0}}/500</mat-hint>-->
												<mat-error>Please Enter a Text Here</mat-error>
											</mat-form-field>
										</div>

										<h3 class="actions-heading  no-top-margin pb3"
											*ngIf="question['notes'] || question['reviewedFrom'] || question['reviewedTo'] || question['showNotes']">
											Review Period
										</h3>
										<div class="centered-fields"
											*ngIf="question['notes'] || question['reviewedFrom'] || question['reviewedTo'] || question['showNotes']">
											<div class="multiple-field" [style.--numShown]="2">
												<mat-form-field appearance="outline" color="primary">
													<mat-label class="">From</mat-label>
													<input (blur)="findInvalidControls()" [ngModel]="question['reviewedFrom']"
														[max]="surveyForm.value[question['id'] + 'reviewedTo']"
														(ngModelChange)="saveForm(question['id'])" name="{{question['id'] + 'reviewedFrom'}}"
														readonly="true" (keydown)="false" ngModel matInput [matDatepicker]="picker"
														(click)="picker.open()">
													<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
													<mat-datepicker #picker touchUi="true"></mat-datepicker>
												</mat-form-field>
											</div>
											<div class="multiple-field" [style.--numShown]="2">
												<mat-form-field appearance="outline" color="primary">
													<mat-label class="">To</mat-label>
													<input (blur)="findInvalidControls()" [ngModel]="question['reviewedTo']"
														[min]="surveyForm.value[question['id'] + 'reviewedFrom']"
														(ngModelChange)="saveForm(question['id'])" name="{{question['id'] + 'reviewedTo'}}"
														readonly="true" (keydown)="false" ngModel matInput [matDatepicker]="topicker"
														(click)="topicker.open()">
													<mat-datepicker-toggle matSuffix [for]="topicker"></mat-datepicker-toggle>
													<mat-datepicker #topicker touchUi="true"></mat-datepicker>
													<mat-error>Please select a date after "From" Date</mat-error>
												</mat-form-field>
											</div>
										</div>
										<mat-form-field appearance="outline"
											*ngIf="question['notes'] || question['reviewedFrom'] || question['reviewedTo'] || question['showNotes']"
											color="primary">
											<mat-label class="">Notes</mat-label>
											<textarea (keyup)="saveNotes(question['id'])" spellcheck="true" cdkTextareaAutosize
												#autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" matInput #noteSection
												(ngModelChange)="question['notes']=$event" [ngModel]="question['notes'] | unescape"
												class="custom-control-input" name="{{question['id'] + 'note'}}"></textarea>
											<!--<mat-hint align="end">{{noteSection['value']?.length || 0}}/500</mat-hint>-->
											<mat-error>Please Enter a Note</mat-error>
										</mat-form-field>
									</div>

									<div class="actions-section">
										<h3 class="actions-heading  no-top-margin"
											*ngIf="question['actions']?.length > 0 || question['autoSavedAction']?.length > 0">
											Actions</h3>
										<div class="action-line" *ngFor="let action of question['actions']">
											<div class="file-name">{{action['title'] | unescape | slice:0:25}}<span
													*ngIf="action['title']?.length > 25">...</span>
											</div>
											<div *ngIf="!action['actionId']" class="right-align">
												{{ action['dueDate'] | date :'dd MMM y'  }} </div>
											<div *ngIf="action['actionId']" class="right-align">
												<a
													(click)="saveForm('All')" [routerLink]="['/action-details', action['actionId']]">
													<span>View</span>
												</a>
											</div>
										</div>

										<div class="action-line" *ngFor="let autoSavedAction of question['autoSavedAction']">
											<div class="right-margin" *ngIf="autoSavedAction['title']">
												{{autoSavedAction['title']?.slice(0, 15) | unescape}}
												<span *ngIf="autoSavedAction['title']?.length > 15">...</span>
											</div>
											<div class="right-margin"
												*ngIf="!autoSavedAction['title'] && autoSavedAction['issueDescription']">
												{{autoSavedAction['issueDescription']?.slice(0, 15) | unescape}}
												<span *ngIf="autoSavedAction['issueDescription']?.length > 15">...</span>
											</div>
											<div class="right-margin"
												*ngIf="!autoSavedAction['title'] && !autoSavedAction['issueDescription']">
												{{ autoSavedAction['dueDate']| date :'dd MMM y'  }} 
											</div>
											<div class="grey-text medium-text"><i>Unsaved</i></div>
											<div class="right-align">
												<a
													(click)="createAction(surveyDetails['title'], surveyDetails['id'], question['title'], question['id'].split('_').pop(), question['shortTitle'], autoSavedAction['autoSavedActionID'])">
													<fa-icon [icon]="faPencilAlt" [spin]="false"></fa-icon>
												</a>
												&nbsp;&nbsp;&nbsp;
												<a class="red-text"
													(click)="deleteAction(question['id'].split('_').pop(), autoSavedAction['autoSavedActionID'])">
													<fa-icon [icon]="faTrash" [spin]="false"></fa-icon>
												</a>
											</div>
										</div>

										<h3 class="actions-heading  no-top-margin"
											*ngIf="question['photos']?.length > 0 || question['uploading'] == true">
											Evidence
										</h3>
										<div class="action-line" *ngFor="let photo of question['photos']; let i = index">
											<div class="file-name">{{photo['photoName']?.slice(0, 25)}}<span
													*ngIf="photo['photoName']?.length > 25">...</span>
											</div>
											<div class="right-align hide-on-mobile">{{photo['fileSize'] | filesize}}</div>
											<div class="right-align">
												<a (click)="getAllPhotos(question['id'], photo['fileName'], i)">
													View
												</a>												
											</div>
										</div>

										<div class="action-line" *ngIf="question['uploading'] == true">
											<div class="light-blue">Evidence Uploading...</div>
											<div class="light-blue right-align"><span class="padded-icon">
													<fa-icon [icon]="faCircleNotch" [spin]="true"></fa-icon>
												</span></div>
										</div>

										<h3 class="actions-heading no-top-margin" *ngIf="question['pointInTimeReviewDate']">
											Reviewed On</h3>
										<div class="action-line" *ngIf="question['pointInTimeReviewDate']">
											<div class="file-name">Point In Time Review Date:
												{{ question['pointInTimeReviewDate'] | date :'dd MMM y'  }}</div>
										</div>
									</div>

									<div class="p2">
										<h5 class="red-text" *ngIf="!question['actions'] && question.type !== 'freeText' && (getSurveyResponses(question['id']) === 'No' && !question['radioOptions'] ) 
									&& !(question['autoSavedAction'] | keyvalue)?.length">
											Please Create an Action when {{getSurveyResponses(question['id'])}} is Selected
										</h5>

										<h5 class="red-text" *ngIf="!question['actions'] && question.type !== 'freeText' && (question['radioOptions'] && getSurveyResponses(question['id']) == question.radioOptions[0].answer && (question.radioOptions[0].enforceAction) == true )
									&& !(question['autoSavedAction'] | keyvalue)?.length">
											Please Create an Action when {{getSurveyResponses(question['id'])}} is Selected
										</h5>

										<h5 class="red-text" *ngIf="!question['actions'] && question.type !== 'freeText' && (question['radioOptions'] && getSurveyResponses(question['id']) == question.radioOptions[1].answer && (question.radioOptions[1].enforceAction) == true )
									&& !(question['autoSavedAction'] | keyvalue)?.length">
											Please Create an Action when {{getSurveyResponses(question['id'])}} is Selected
										</h5>

										<h5 class="red-text" *ngIf="!question['actions'] && question.type !== 'freeText' && (question['radioOptions'] && (question['radioOptions']?.length > 2) && getSurveyResponses(question['id']) == question.radioOptions[2].answer && (question.radioOptions[2].enforceAction) == true )
									&& !(question['autoSavedAction'] | keyvalue)?.length">
											Please Create an Action when {{getSurveyResponses(question['id'])}} is Selected
										</h5>
									</div>

									<h5 class="med-grey" *ngIf="(question['autoSavedAction'] | keyvalue)?.length > 0">Please
										Complete the Above Action</h5>
									<div class="action-buttons">
										<div class="csa-button-solid action-button note-action" (click)="makeNoteShow(question['id'])">
											<span class="action-icon">
												<fa-icon [icon]="faPencilAlt"></fa-icon>
											</span> Add Note
										</div>
										<div (click)="uploader.click()" [ngClass]="{
												'semi-disabled' : question['uploading'] == true,
												'disabled' : question['photos']?.length >= 10
											}" class="csa-button-solid action-button">
											<span class="action-icon">
												<fa-icon *ngIf="question['uploading'] == true" [icon]="faCircleNotch" [spin]="true"></fa-icon>
												<fa-icon *ngIf="question['uploading'] == false" [icon]="faCamera"></fa-icon>
											</span> Add Evidence
											<input hidden type="file" #uploader (click)="uploader.value = null"
												(change)="uploadPhoto($event, question['id'], surveyDetails['id'])" />
										</div>
										<div [ngClass]="{
																	'red-background': !question['actions'] && question.type !== 'freeText' && ((getSurveyResponses(question['id']) === 'No' && !question['radioOptions']) 
																	|| ((question['radioOptions']) && ( ((getSurveyResponses(question['id']) == question.radioOptions[0].answer ) && (question.radioOptions[0].enforceAction) == true) 
																	|| ((getSurveyResponses(question['id']) == question.radioOptions[1].answer ) && (question.radioOptions[1].enforceAction) == true)
																	|| ((question['radioOptions']?.length > 2) && getSurveyResponses(question['id']) == question.radioOptions[2].answer && (question.radioOptions[2].enforceAction) == true ))))
																	&& (question['autoSavedAction'] | keyvalue)?.length == 0 && question['actions']?.length < 5,
																	'disabled' : question['actions']?.length >= 5 || (question['autoSavedAction'] | keyvalue)?.length > 0
																}" class="csa-button-solid action-button action-action" (click)="createAction(surveyDetails['title'], surveyDetails['id'], question['title'], question['id'].split('_').pop(),
																question['shortTitle'], 'new')">
											<span class="action-icon">
												<fa-icon [icon]="faCalendarCheck"></fa-icon>
											</span> Add Actions
										</div>
									</div>
								</div>
							</div>
						</div>
					</mat-expansion-panel>
				</mat-accordion>
			</div>

			<div class="submit-button-container center-align">
				<div class="medium-text" *ngIf="outstandingQuestions >= 1"><span class="subtle-centered-text">You have
					</span> <span class="med-grey">{{outstandingQuestions}}</span> <span class="subtle-centered-text">
						Unanswered Question<span *ngIf="outstandingQuestions > 1">s</span></span></div>
				<div class="medium-text" *ngIf="outstandingActions >= 1"><span class="subtle-centered-text">You have </span>
					<span class="med-grey">{{outstandingActions}}</span> <span class="subtle-centered-text"> Question which
						Require<span *ngIf="outstandingActions > 1">s</span> an Action</span>
				</div>
        
				<div class="medium-text" *ngIf="incompleteActions >= 1"><span class="subtle-centered-text">You have </span>
					<span class="med-grey">{{incompleteActions}}</span> <span class="subtle-centered-text"> Unsaved Action<span
							*ngIf="incompleteActions > 1">s</span>,
						please save or delete this to continue</span>
				</div>

				<div class="medium-text" *ngFor="let field of invalid"><span class="subtle-centered-text">Question
					</span><span class="med-grey">{{field}}</span> <span class="subtle-centered-text"> Has an Invalid
					</span><span class="med-grey"> Reviewed To Date</span> </div>

				<button [disabled]="!surveyForm.valid || !actionsValid" class="csa-button-solid survey-submit"
					type="submit"><span>
						<fa-icon [icon]="faPaperPlane"></fa-icon>
					</span> Submit</button>
			</div>
		</form>
	</div>

	<div class="print-survey" id="printSurvey" #printSurvey>
		<h3 style="text-align: center">{{surveyDetails['title'] | unescape}}</h3>
		<span style="text-align: center">
			{{surveyDetails['storeID']}} - {{surveyDetails['storeName']}}
		</span>
		<br>

		<div *ngFor="let heading of surveyDetails['sectionHeading']">
			<ng-template #questionResponses [ngTemplateOutlet]="questionResponses" let-responses="responses" let-questions="questions"
			[ngTemplateOutletContext]="{ 
				responses: (surveyDetails['responses'] | groupQuestions:{sectionHeading: heading}),
				questions: (surveyDetails['questions'] | groupQuestions:{sectionHeading: heading})
			}">
        <div
					*ngFor="let question of questions; let questionIndex = index;">
					<div *ngIf="
						printVersion == 'All Questions' || 
						(printVersion == 'Answered Questions' && responses[questionIndex].response != '') || 
						(printVersion == 'Unanswered Questions' && responses[questionIndex].response == '') ||
						(printVersion == 'Questions Answered \'No\'' && responses[questionIndex].response == 'No' && question.type != 'freeText') ||
						(printVersion == 'Questions Answered \'Yes\'' && responses[questionIndex].response == 'Yes' && question.type != 'freeText')
					">
						<span class="actions-heading pdf-pagebreak-before">
							<u><b>{{heading}}</b> - Question {{questionIndex+1}} of {{questions.length}}</u>
						</span>
						<br>
						<div [innerHTML]="(question['text'] ? question['text'] : question['title'])"></div> 
						<br>		

						<table data-pdfmake="{&quot;layout&quot;:&quot;noBorders&quot;, &quot;widths&quot;:[253, 253]}">
							<tr>
								<td>
									<span class="actions-heading no-top-margin">
										<i>Response:</i>
									</span>

									<div *ngIf="question.type !== 'freeText'">
										<br>
										<div *ngIf="question.type === 'boolean' && !question['radioOptions']">
											<div>
												<input id="{{question['id'] + 'bool1'}}" type="checkbox" [checked]="responses[questionIndex].response === 'Yes'" />
												<label [ngClass]="{'active' : responses[questionIndex].response === 'Yes'}" for="{{question['id'] + 'bool1'}}">
													{{responses[questionIndex].response === 'Yes' ? '(x)' : '(&nbsp;&nbsp;)'}} Yes
												</label>
											</div>
											<div>
												<input id="{{question['id'] + 'bool2'}}" type="checkbox" />
												<label [ngClass]="{'active' : responses[questionIndex].response === 'No'}" for="{{question['id'] + 'bool2'}}">
													{{responses[questionIndex].response === 'No' ? '(x)' : '(&nbsp;&nbsp;)'}} No
												</label>
											</div>
										</div>
										<div *ngIf="question.type === 'boolean' && question['radioOptions']">
											<div>
												<input id="{{question['id'] + 'bool1'}}" type="checkbox" [checked]="responses[questionIndex].response == question.radioOptions[0].answer" />
												<label [ngClass]="{'active' : responses[questionIndex].response == question.radioOptions[0].answer}" for="{{question['id'] + 'bool1'}}">
													{{responses[questionIndex].response == question.radioOptions[0].answer ? '(x)' : '(&nbsp;&nbsp;)'}} {{question.radioOptions[0].answer}}
												</label>
											</div>
											<div>
												<input id="{{question['id'] + 'bool2'}}" type="checkbox" [checked]="responses[questionIndex].response == question.radioOptions[1].answer" />
												<label [ngClass]="{'active' : responses[questionIndex].response == question.radioOptions[1].answer}" for="{{question['id'] + 'bool2'}}">
													{{responses[questionIndex].response == question.radioOptions[1].answer ? '(x)' : '(&nbsp;&nbsp;)'}} {{question.radioOptions[1].answer}}
												</label>
											</div>
										</div>
										<div *ngIf="question.type === 'boolean-na' && !question['radioOptions']">
											<div>
												<input id="{{question['id'] + 'boolna1'}}" type="checkbox" [checked]="responses[questionIndex].response === 'Yes'" />
												<label [ngClass]="{'active' : responses[questionIndex].response === 'Yes'}" for="{{question['id'] + 'boolna1'}}">
													{{responses[questionIndex].response === 'Yes' ? '(x)' : '(&nbsp;&nbsp;)'}} Yes
												</label>
											</div>
											<div>
												<input id="{{question['id'] + 'boolna2'}}" type="checkbox" [checked]="responses[questionIndex].response === 'No'" />
												<label [ngClass]="{'active' : responses[questionIndex].response === 'No'}" for="{{question['id'] + 'boolna2'}}">
													{{responses[questionIndex].response === 'No' ? '(x)' : '(&nbsp;&nbsp;)'}} No
												</label>
											</div>
											<div>
												<input id="{{question['id'] + 'bool3'}}" type="checkbox" [checked]="responses[questionIndex].response === 'NA'" />
												<label [ngClass]="{'active' : responses[questionIndex].response === 'NA'}" for="{{question['id'] + 'bool3'}}">
													{{responses[questionIndex].response === 'NA' ? '(x)' : '(&nbsp;&nbsp;)'}} N/A
												</label>
											</div>
										</div>
										<div *ngIf="question.type === 'boolean-na' && question['radioOptions']">
											<div>
												<input id="{{question['id'] + 'boolna1'}}" type="checkbox" [checked]="responses[questionIndex].response == question.radioOptions[0].answer" />
												<label [ngClass]="{'active' : responses[questionIndex].response == question.radioOptions[0].answer}" for="{{question['id'] + 'boolna1'}}">
													{{responses[questionIndex].response == question.radioOptions[0].answer ? '(x)' : '(&nbsp;&nbsp;)'}} {{question.radioOptions[0].answer}}
												</label>
											</div>
											<div>
												<input id="{{question['id'] + 'boolna2'}}" type="checkbox" [checked]="responses[questionIndex].response == question.radioOptions[1].answer" />
												<label [ngClass]="{'active' : responses[questionIndex].response == question.radioOptions[1].answer}" for="{{question['id'] + 'boolna2'}}">
													{{responses[questionIndex].response == question.radioOptions[1].answer ? '(x)' : '(&nbsp;&nbsp;)'}} {{question.radioOptions[1].answer}}
												</label>
											</div>
											<div>
												<input id="{{question['id'] + 'bool3'}}" type="checkbox" [checked]="responses[questionIndex].response == question.radioOptions[2].answer" />
												<label [ngClass]="{'active' : responses[questionIndex].response == question.radioOptions[2].answer}" for="{{question['id'] + 'bool3'}}">
													{{responses[questionIndex].response == question.radioOptions[2].answer ? '(x)' : '(&nbsp;&nbsp;)'}} {{question.radioOptions[2].answer}}
												</label>
											</div>
										</div>
										<div *ngIf="question.type === 'radio'">
											<div>
												<input id="{{question['id'] + 'radio1'}}" type="checkbox" [checked]="responses[questionIndex].response == question.radioOptions[0].answer" />
												<label [ngClass]="{'active' : responses[questionIndex].response == question.radioOptions[0].answer }" for="{{question['id'] + 'radio1'}}">
													{{responses[questionIndex].response == question.radioOptions[0].answer ? '(x)' : '(&nbsp;&nbsp;)'}} {{question.radioOptions[0].answer}}
												</label>
											</div>
											<div>
												<input id="{{question['id'] + 'radio2'}}" type="checkbox" [checked]="responses[questionIndex].response == question.radioOptions[1].answer" />
												<label [ngClass]="{'active' : responses[questionIndex].response == question.radioOptions[1].answer }" for="{{question['id'] + 'radio2'}}">
													{{responses[questionIndex].response == question.radioOptions[1].answer ? '(x)' : '(&nbsp;&nbsp;)'}} {{question.radioOptions[1].answer}}
												</label>
											</div>
											<div>
												<input id="{{question['id'] + 'radio3'}}" type="checkbox" [checked]="responses[questionIndex].response == question.radioOptions[2].answer" />
												<label [ngClass]="{'active' : responses[questionIndex].response == question.radioOptions[2].answer }" for="{{question['id'] + 'radio3'}}">
													{{responses[questionIndex].response == question.radioOptions[2].answer ? '(x)' : '(&nbsp;&nbsp;)'}} {{question.radioOptions[2].answer}}
												</label>
											</div>
										</div>
										<br>
									</div>
									<div *ngIf="question['type'] === 'freeText'">
										<br>
										<hr data-pdfmake="{&quot;width&quot;:253, &quot;thickness&quot;:0.5}">
										<hr data-pdfmake="{&quot;width&quot;:253, &quot;thickness&quot;:0.5}">
										<div *ngIf="responses[questionIndex].response">
											{{responses[questionIndex].response | unescape}}
											<br>
										</div>
										<br>
									</div>
								</td>
								<td>
									<div class="actions-heading no-top-margin">
										<i>Notes:</i>
									</div>
									<div *ngIf="question['notes']">
										<br>
										{{question['notes'] | unescape}}
									</div>
									<br>
									<hr data-pdfmake="{&quot;width&quot;:253, &quot;thickness&quot;:0.5}">
									<hr data-pdfmake="{&quot;width&quot;:253, &quot;thickness&quot;:0.5}">
								</td>
							</tr>
							<tr>
								<td>
									<div class="actions-heading no-top-margin">
										<i>Actions:</i>
									</div>
									<div *ngIf="question['actions']">
										<br>
										<ul class="action-line" *ngFor="let action of question['actions']">
											<li class="file-name">
												{{action['title'] | unescape}}
											</li>
										</ul>
									</div>
									<br>
									<hr data-pdfmake="{&quot;width&quot;:506, &quot;thickness&quot;:0.5}">
									<hr data-pdfmake="{&quot;width&quot;:506, &quot;thickness&quot;:0.5}">
									<div *ngIf="
										!question['actions'] && question.type != 'freeText' && !(question['autoSavedAction'] | keyvalue)?.length && 
										(
											(responses[questionIndex].response == 'No' && question['radioOptions']) || 
											(question['radioOptions'] && responses[questionIndex].response == question.radioOptions[0].answer && question.radioOptions[0].enforceAction) || 
											(question['radioOptions'] && responses[questionIndex].response == question.radioOptions[1].answer && question.radioOptions[1].enforceAction) || 
											(question['radioOptions'] && question['radioOptions']?.length > 2 && responses[questionIndex].response == question.radioOptions[2].answer && question.radioOptions[2].enforceAction)
										)"
									>
										<i>Please create an action when <u>{{responses[questionIndex].response}}</u> is selected</i>
									</div>
								</td>
								<td></td>
							</tr>
							<tr>
								<td>
									<div class="no-top-margin">
										<div>
											<i class="actions-heading no-top-margin">Reviewed On:</i><span class="action-line">  &nbsp;&nbsp;{{question['pointInTimeReviewDate'] ? (question['pointInTimeReviewDate']| date :'dd MMM y') : "________________________________"}}</span>
										</div>
									</div>
								</td>
								<td></td>
							</tr>
						</table>
						<br>
						<br>
					</div>
				</div>
			</ng-template>
		</div>
	</div>
</div>