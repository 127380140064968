<div class="page-container">
  <h1>Material Risk Review Actions</h1>
    <div class="audit-tool-cards-container">
    <div class="audit-tool-card more-list-screen-card">
      <mat-tab-group #tabGroup dynamicHeight="false" mat-align-tabs="center" [(selectedIndex)]="selectedGroup" (selectedTabChange)="tabChanged($event)">
        <mat-tab label="{{actionGroup}}" *ngFor="let actionGroup of actionGroups">

          <table mat-table [dataSource]="actionsToShow">
            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef class="survey-details-link center-align">
                <h4>Action</h4>
              </th>
              <td mat-cell *matCellDef="let action" class="survey-details-link center-align">
                <h4> {{action['title'].slice(0, 35) | unescape}}<span *ngIf="(action.title.length > 35)">...</span></h4>
                <p [ngClass]="{
                'orange-text' : action['isDueInWeek'] == true && action['status'] === 'pending',
                'red-text' : action['overDue'] == true && action['status'] === 'pending'
                }">Due:  {{getDate(action.dueDate)}}</p>
              </td>
            </ng-container>


            <ng-container matColumnDef="assessment">
              <th mat-header-cell *matHeaderCellDef class="survey-details-link center-align">
                <h4>Assessment</h4>
              </th>
              <td mat-cell *matCellDef="let action" class="survey-details-link center-align"
                [routerLink]="['/mrr-action-details', action.id]">
                <div class="survey-stat template-stat">
                  <div>{{action['surveyTitle'] | unescape}}</div>
                </div>
              </td>
            </ng-container>


            <ng-container matColumnDef="description">
              <th mat-header-cell *matHeaderCellDef class="survey-details-link center-align">
                <h4>Description</h4>
              </th>
              <td mat-cell *matCellDef="let action" class="survey-details-link center-align">
                  <p> {{action['description'].slice(0, 100) | unescape}}<span *ngIf="(action.title.length > 100)">...</span></p>
              </td>
            </ng-container>

            <ng-container matColumnDef="updates">
              <th mat-header-cell *matHeaderCellDef class="survey-details-link center-align">
                <h4>Updates</h4>
              </th>
              <td mat-cell *matCellDef="let action" class="survey-details-link center-align">
                <div class="center-align survey-stat">
                  <h1>{{getActionUpdates(action['id'])}}</h1>
                </div>
              </td>
            </ng-container>

            <mat-header-row *matHeaderRowDef="tableColumns"  class="survey-heading-line"></mat-header-row>
            <mat-row *matRowDef="let action; columns: tableColumns;" (click)="saveState = true" [routerLink]="['/mrr-action-details', action.id]" class="survey-line survey-line-link"></mat-row>
          </table>

          <div *ngIf="isLoading" class="loader-container">
            <div class="loading-wapple"></div>
          </div>

          <div *ngIf="actionsToShow.length == 0 && !isLoading">
            <h3 class="none-found-message">
              There are no actions in this section. You can create Actions while completing surveys.
            </h3>
          </div>

          <mat-paginator #paginator
              [pageIndex]=pageIndex
              [pageSize]=pageSize
              [length]="totalCount"
              [pageSizeOptions]="[5, 10, 25, 100]"
              [showFirstLastButtons]=false
              (page)="getNext($event)">
          </mat-paginator>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
