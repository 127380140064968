import { Tag } from 'src/app/types/tag';

/**
 * Adds a custom tag to the `addToTagList` property of objects in the provided array
 * if they match the specified property name and value.
 *
 * @param {object[]} data - The array of objects to be processed.
 * @param {object} customTag - An object defining the criteria for adding the tag.
 * @param {string} customTag.propertyName - The name of the property to match in each object.
 * @param {*} customTag.propertyValueToMatch - The value to match for the specified property.
 * @param {string} customTag.tagType - The type of the tag to be added.
 * @returns {object[]} The updated array of objects.
 * @example
 * const data = [
 *   { id: 1, category: 'fruit', addToTagList: [] },
 *   { id: 2, category: 'vegetable' },
 * ];
 * const customTag = { propertyName: 'category', propertyValueToMatch: 'fruit', tagType: 'food' };
 * const result = addCustomTagToAddToTagList(data, customTag);
 * // Result:
 * // [
 * //   { id: 1, category: 'fruit', addToTagList: [{ name: 'fruit', type: 'food' }] },
 * //   { id: 2, category: 'vegetable' }
 * // ]
 */
export function addCustomTagToAddToTagList(
  data: object[],
  customTag: object
): object[] {
  const propertyName = customTag['propertyName'];
  const propertyValueToMatch = customTag['propertyValueToMatch'];
  const tagType = customTag['tagType'];
  data?.forEach((row) => {
    if (row[propertyName] && row[propertyName] === propertyValueToMatch) {
      if (!row['addToTagList']) {
        row['addToTagList'] = [];
      }
      row['addToTagList'].push(<Tag>{ name: row[propertyName], type: tagType });
    }
  });
  return data;
}
