<label class="core-text-field search-input-wrapper" 
    cdkOverlayOrigin
    #trigger="cdkOverlayOrigin"
    [ngClass]="{'error': showErrorMessage}"
>
    <div class="core-text-field-wrapper trailing">
        <input
            id="textSearchTerm"
            #textSearchTerm
            type="text" 
            class="core-text-field" 
            [placeholder]="placeholder"
            aria-autocomplete="list"
            autocomplete="off"
            [attr.aria-label]="accessibilityLabel"
            aria-activedescendant
            aria-owns="search-results"
            aria-haspopup="true"
            [attr.aria-expanded]="openOverlay"
            [ngModel]="searchTerm" 
            (input)="onSearchTermInput($event)"
            (blur)="onSearchTermBlur($event)"
            (focus)="onSearchTermFocus()"
            (keyup.enter)="onEnter()"
            
        />
        <app-icon id="searchTermIcon" iconName="search" *ngIf="!searchTerm && !selectSearchTextOnEnter"></app-icon>
        <app-icon id="clearSearchTermIcon" iconName="close" *ngIf="searchTerm && !selectSearchTextOnEnter" class="clear" (click)="clearSearchTerm()"></app-icon>
        <app-icon id="addSearchTermIconDisabled" iconName="addToList" color="{{addToListColorDisabled}}"*ngIf="!searchTerm && selectSearchTextOnEnter"></app-icon>
        <app-icon id="addSearchTermIconEnabled" iconName="addToList" color="{{addToListColorEnabled}}" *ngIf="searchTerm && selectSearchTextOnEnter" class="add" (click)="addSearchTerm()"></app-icon>
    </div> 
    <div class="core-help-text" *ngIf="showErrorMessage">
        <span>
            <app-icon iconName="errorFill"></app-icon>
            {{errorMessage}}
        </span>
    </div>      
</label>
<ng-template
    *ngIf="!showErrorMessage"
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="openOverlay"
    [cdkConnectedOverlayWidth]="containerWidth"
    [cdkConnectedOverlayHasBackdrop]="true"
    [cdkConnectedOverlayBackdropClass]="'dialog-backdrop-search-items'"
    (backdropClick)="backdropClick($event)"
>
<div id="search-results" class="autocomplete-dropdown-wrapper">
    <ul #searchResultList class="autocomplete-dropdown" tabindex="0" role="listbox">
        <li 
            #searchResultListItem
            *ngFor="let searchResultItem of searchResults; let i=index"
            class="autocomplete-item"
            (click)="selectItem(searchResultItem)"
            (keyup.enter)="selectItem(searchResultItem)"
            (keydown)="keydown($event, i)"
            tabindex="-1"
        >{{ !!searchResultsPropertyName ? searchResultItem[searchResultsPropertyName] : searchResultItem }}</li>
    </ul>
</div>
</ng-template>
