import {
  Component,
  Inject,
  OnInit,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
  ViewChildren,
  QueryList,
  AfterViewInit,
} from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import {
  faArrowLeft,
  faArrowRight,
  faInfoCircle,
  faAngleDown,
  faAngleUp,
  faTimes,
  faTrash,
  faChevronCircleLeft,
  faChevronCircleRight,
  faRedo,
  faDownload,
  faEye,
  faCircleNotch,
} from '@fortawesome/free-solid-svg-icons';

import { SwiperComponent } from 'swiper/angular';
import SwiperCore, { Navigation } from 'swiper';
import 'swiper/scss';
import 'swiper/scss/navigation';
import * as moment from 'moment';
import { saveAs } from 'file-saver';
import * as JSZip from 'jszip';
import { MessagesComponent } from '../messages/messages.component';
import { CsaAuthService } from '../../auth/csa-auth.service';
import { User } from '../../models/user';
import { SurveysService } from '../surveys.service';

SwiperCore.use([Navigation]);

@Component({
  selector: 'app-photo-carousel',
  templateUrl: './photo-carousel.component.html',
  styleUrls: ['./photo-carousel.component.scss'],
})
export class PhotoCarouselComponent implements OnInit, AfterViewInit {
  faArrowLeft = faArrowLeft;

  faArrowRight = faArrowRight;

  faChevronLeft = faChevronCircleLeft;

  faChevronRight = faChevronCircleRight;

  faAngleDown = faAngleDown;

  faAngleUp = faAngleUp;

  faCircleNotch = faCircleNotch;

  faDownload = faDownload;

  faEye = faEye;

  faInfoCircle = faInfoCircle;

  faRedo = faRedo;

  faTimes = faTimes;

  faTrash = faTrash;

  evidences = [];

  evidenceIndex = 0;

  previousEvidenceIndex = 0;

  skippedEvidenceMessage = '';

  sectionName = '';

  initial: boolean = true;

  isDownloading: boolean = false;

  photoIndex = -1;

  showInfo: boolean = false;

  user: User;

  loadingSwiper: boolean = true;

  isDetailedFindingExpanded: boolean = false;

  allExpandState: boolean = true; // expansion panel open and close param

  @ViewChildren('photoImg') elReference: QueryList<ElementRef>;

  @ViewChild('photoContainer', { read: ElementRef, static: false })
    container: any;

  @ViewChild('totalSwiper', { static: false }) totalSwiper?: SwiperComponent;

  @ViewChild('imageSwiper', { static: false }) imageSwiper?: SwiperComponent;

  private zip: any = new JSZip();

  constructor(
    private dialog: MatDialog,
    private csaAuth: CsaAuthService,
    private cd: ChangeDetectorRef,
    private dialogRef: MatDialogRef<PhotoCarouselComponent>,
    private surveyService: SurveysService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    this.user = this.csaAuth.user;
    this.evidences = this.data.evidence;
    this.evidenceIndex = this.data.evidenceIndex;
    this.sectionName = this.evidences[this.evidenceIndex]['sectionHeading'];
    this.photoIndex = this.data.photoIndex; // this index is relative to its question/finding/action
  }  

  // Reset image swiper and load new images if necessary
  onTotalSlideChange([swiper]) {
    if (this.evidenceIndex === swiper.activeIndex) {
      console.log(`onTotalSlideChange - evidenceIndex NOT CHANGED: ${this.evidenceIndex}`);
      return;
    }
    this.previousEvidenceIndex = this.evidenceIndex;
    this.evidenceIndex = swiper.activeIndex;
    this.skippedEvidenceMessage = this.getSkippedEvidenceMessage(this.data.type, this.previousEvidenceIndex, this.evidenceIndex, this.evidences);
    this.sectionName = this.evidences[this.evidenceIndex]['sectionHeading'];
    if (this.photoIndex == 0) {
      this.generateURL();
    } else {
      this.photoIndex = 0;
      this.imageSwiper?.swiperRef.slideTo(0);
      this.updateSwiper();
    }
  }

  getEvidenceTypeName(type: string): string {
    switch (type) {
      case 'survey':
        return 'Question';
      case 'mrrFindings':
        return 'Finding';
      case 'action':
        return 'Action';
    }
    return '';
  }

  getSkippedEvidenceMessage(type: string, previousIndex: number, currentIndex: number, evidences: object[]): string {
    const currentEvidencePosition = evidences[currentIndex]['totalIndex'];
    const previousEvidencePosition = evidences[previousIndex]['totalIndex'];
    const isSkipped = Math.abs(currentEvidencePosition - previousEvidencePosition) > 1;
    const navForward = currentEvidencePosition - previousEvidencePosition > 0;
    if (!isSkipped) {
      return '';
    }
    const evidenceTypeName = this.getEvidenceTypeName(type);
    if (evidenceTypeName === '') {
      return '';
    }
    const skippedPositions = [];
    if (navForward) {
      for (let i = previousEvidencePosition + 1; i < currentEvidencePosition; i++) {
        skippedPositions.push(i);
      }
    } else {
      for (let i = previousEvidencePosition - 1; i > currentEvidencePosition; i--) {
        skippedPositions.unshift(i);
      }
    }

    return evidenceTypeName ? `${evidenceTypeName} ${skippedPositions.join(', ')} is skipped since there is no evidence.` : '';
  }

  // Update photoIndex and load new images if needed
  onImageSlideChange([swiper]) {
    this.photoIndex = swiper.activeIndex;
    this.generateURL();
  }

  /**
   * Function 'updateSwiper' updating height as per the height of detailed finding expansion panel.
   *
   */
  swiperUpdating() {
    this.updateSwiper();
    if (this.isDetailedFindingExpanded) {
      this.isDetailedFindingExpanded = false;
    } else {
      this.isDetailedFindingExpanded = true;
    }

    this.imageSwiper.swiperRef.updateAutoHeight(30000);
  }

  ngAfterViewInit(): void {
    this.generateURL();
  }

  // Initialise image swiper
  onImageSwiper() {
    this.imageSwiper?.swiperRef.init();
    this.cd.detectChanges();
    this.updateSwiper();
  }

  // Initialise total swiper
  onTotalSwiper() {
    this.totalSwiper?.swiperRef.init();
    this.cd.detectChanges();
  }

  // Update autoheight of both swipers. This should be done automatically, but this function will double-check that the autoheight gets updated after a delay.
  updateSwiper() {
    setTimeout(() => {
      if (this.loadingSwiper) {
        this.loadingSwiper = false;
      } else {
        this.imageSwiper?.swiperRef.updateAutoHeight(200);
        this.imageSwiper?.swiperRef.update();
      }
      this.cd.detectChanges();
    }, 250);
  }

  checkImage(url) {
    const http = new XMLHttpRequest();
    http.open;
    http.open('GET', url, false);
    http.send();
    return http.status != 404;
  }

  // Lazy load images within a window of 10 images. E.g. if on more-evidence and 100 images are shown in the table, this wil only load images in the range i-5 < i < i+5 where i = the selected index. If the image URL is not retrieved, the "Image Not Found" message will be displayed in its place. Call updateSwiper() once the image for the current index has been retrieved.
  generateURL() {
    const i = this.photoIndex - 5 < 0 ? 0 : this.photoIndex - 5;
    const j = this.photoIndex + 5 > this.evidences[this.evidenceIndex].photos.length
      ? this.evidences[this.evidenceIndex].photos.length
      : this.photoIndex + 5;

    Promise.all(
      this.evidences[this.evidenceIndex].photos.slice(i, j).map((photo) => new Promise((resolve, reject) => {
        if (!photo['src']) {
          this.surveyService
            .getPhotos(`?fileNames=${photo['fileName']}`)
            .subscribe(
              (photoData) => {
                if (Object.keys(photoData).length == 0) {
                  photo['src'] = 'Image not found';
                  resolve(photo);
                } else {
                  photo['src'] = photoData[photo['fileName']];
                  resolve(photo);
                }
              },
              (error) => {
                photo['src'] = 'Image not found';
                resolve(photo);
              }
            );
        } else {
          resolve(photo);
        }
      }))
    ).then(() => {
      this.updateSwiper();
    });
  }

  // Download all photos for current question/update. To do this, we need to ensure that all photos have the 'src' value already generated.
  downloadPhotosZip() {
    this.isDownloading = true;

    // Generate 'src' value for all photos on current question/update
    Promise.all(
      this.evidences[this.evidenceIndex].photos.map((photo) => new Promise((resolve, reject) => {
        this.surveyService
          .getPhotos(`?fileNames=${photo['fileName']}`)
          .subscribe((photoData) => {
            if (Object.keys(photoData).length == 0) {
              photo['src'] = 'Image not found';
              resolve(photo);
            } else {
              photo['src'] = photoData[photo['fileName']];
              resolve(photo);
            }
          });
      }))
    ).then(() => {
      Promise.all(
        this.evidences[this.evidenceIndex].photos.map((photo) => this.urlToPromiseOne(photo))
      )
        .then(() => {
          this.zip
            .generateAsync({
              type: 'blob',
            })
            .then((content) => {
              saveAs(content, 'evidence.zip');
            });
          this.isDownloading = false;
        })
        .catch((error) => {
          this.isDownloading = false;
          this.dialog.open(MessagesComponent, {
            data: {
              heading: 'Cannot Download Evidence',
              message: `Please email ${this.user['supportEmail']} for support.`,
            },
            disableClose: true,
            backdropClass: 'dialog-backdrop',
          });
        });
    });
  }

  // Download image and store it in a zip folder.
  urlToPromiseOne(photo) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.onload = (event) => {
        this.zip.file(photo.fileName, xhr.response);
        resolve(this.zip);
      };
      xhr.onerror = function (event) {
        reject(new Error("can't load image"));
      };
      xhr.open('GET', photo.src);
      xhr.send();
    });
  }

  // Download all images. Ensure that all images have had their 'src' value generated.
  downloadAllZip() {
    this.isDownloading = true;

    // Generate 'src' value for all photos on all questions/updates
    Promise.all(
      this.evidences.map((evidence) => Promise.all(
        evidence.photos.map((photo) => new Promise((resolve, reject) => {
          this.surveyService
            .getPhotos(`?fileNames=${photo['fileName']}`)
            .subscribe((photoData) => {
              if (Object.keys(photoData).length == 0) {
                photo['src'] = 'Image not found';
                resolve(photo);
              } else {
                photo['src'] = photoData[photo['fileName']];
                resolve(photo);
              }
            });
        }))
      ))
    ).then(() => {
      Promise.all(
        this.evidences.map((evidence) => Promise.all(
          evidence.photos.map((photo) => this.urlToPromiseTwo(photo, evidence.totalIndex))
        ))
      )
        .then(() => {
          this.zip
            .generateAsync({
              type: 'blob',
            })
            .then((content) => {
              saveAs(content, 'evidence.zip');
            });
          this.isDownloading = false;
        })
        .catch((error) => {
          this.isDownloading = false;
          this.dialog.open(MessagesComponent, {
            data: {
              heading: 'Cannot Download Evidence',
              message: `Please email ${this.user['supportEmail']} for support.`,
            },
            disableClose: true,
            backdropClass: 'dialog-backdrop',
          });
        });
    });
  }

  urlToPromiseTwo(photo, index) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.onload = (event) => {
        this.zip.folder(index).file(photo.fileName, xhr.response);
        resolve(this.zip);
      };
      xhr.onerror = function (event) {
        reject(new Error(`can't load ${photo.src}`));
      };
      xhr.open('GET', photo.src);
      xhr.send();
    });
  }

  // Download current photo
  downloadPhoto(photo) {
    this.isDownloading = true;

    new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.onload = (event) => {
        saveAs(xhr.response, photo.fileName);
        resolve('downloaded');
      };
      xhr.onerror = (error) => {
        reject(new Error('could not download'));
      };
      xhr.open('GET', photo.src);
      xhr.send();
    })
      .then(() => {
        this.isDownloading = false;
      })
      .catch(() => {
        this.isDownloading = false;
        this.dialog.open(MessagesComponent, {
          data: {
            heading: 'Cannot Download Evidence',
            message: `Please email ${this.user['supportEmail']} for support.`,
          },
          disableClose: true,
          backdropClass: 'dialog-backdrop',
        });
      });
  }

  closeMe() {
    this.dialogRef.close({ status: 'success' });
  }

  deletePhoto() {
    const message = this.dialog.open(MessagesComponent, {
      data: {
        heading: 'Are You Sure?',
        message:
          'Are you sure you want to delete this evidence from file? This cannot be un-done.',
        closeText: 'Delete',
        cancelText: 'Cancel',
        maxWidth: '90vw',
      },
      backdropClass: 'dialog-backdrop',
    });

    message.afterClosed().subscribe((result) => {
      if (result == 'logout') {
        if (this.data.type == 'survey') {
          this.dialogRef.close({
            status: 'delete',
            questionID: this.evidences[this.evidenceIndex]['questionID'],
            fileName:
              this.evidences[this.evidenceIndex].photos[this.photoIndex][
                'fileName'
              ],
            src: this.evidences[this.evidenceIndex].photos[this.photoIndex][
              'src'
            ],
          });
        } else if (this.data.type == 'mrrFindings') {
          this.dialogRef.close({
            status: 'delete',
            questionID: this.evidences[this.evidenceIndex]['questionID'],
            fileName:
              this.evidences[this.evidenceIndex].photos[this.photoIndex][
                'fileName'
              ],
            src: this.evidences[this.evidenceIndex].photos[this.photoIndex][
              'src'
            ],
          });
        } else if (this.data.type == 'action') {
          this.dialogRef.close({
            status: 'delete',
            questionID: this.evidences[this.evidenceIndex]['questionID'],
            fileName:
              this.evidences[this.evidenceIndex].photos[this.photoIndex][
                'fileName'
              ],
            src: this.evidences[this.evidenceIndex].photos[this.photoIndex][
              'src'
            ],
          });
        }
      }
    });
  }

  checkName(name) {
    const check = name.toLowerCase().match(/\.(pdf)/g);
    if (check == null) {
      return true;
    }
    return false;
  }

  // Modify current photo properties to ensure rotated image fits within the container correctly.
  rotatePhoto() {
    const photo = this.evidences[this.evidenceIndex].photos[this.photoIndex];

    const [el] = this.elReference
      .toArray()
      .filter((r) => r.nativeElement.id == photo['fileName']);

    if (!photo['width'] && !photo['height']) {
      photo['width'] = el.nativeElement.offsetWidth;
      photo['height'] = el.nativeElement.offsetHeight;
    }

    photo['maxHeight'] = photo['height'];
    photo['maxWidth'] = photo['width'];
    photo['marginTop'] = 0;
    photo['marginBottom'] = 0;
    if (photo['degree']) {
      photo['degree'] += 90;
    } else {
      photo['degree'] = 90;
    }

    if ((photo['degree'] / 90) % 2 != 0) {
      if (photo['width'] > photo['height']) {
        photo['marginTop'] = (photo['maxWidth'] - photo['maxHeight']) / 2;
        photo['marginBottom'] = photo['marginTop'];
      } else if (photo['width'] < photo['height']) {
        photo['maxHeight'] = this.container.nativeElement.offsetWidth;

        if (photo['maxHeight'] > photo['height']) {
          photo['maxHeight'] = photo['height'];
          photo['maxWidth'] = photo['width'];
        } else {
          photo['maxWidth'] = photo['width'] * (photo['maxHeight'] / photo['height']);
        }

        photo['marginTop'] = (-1 * (photo['maxHeight'] - photo['maxWidth'])) / 2;
        photo['marginBottom'] = photo['marginTop'];
      }
    }

    this.evidences[this.evidenceIndex].photos[this.photoIndex] = photo;
    this.updateSwiper();
  }

  viewResponseAction() {
    const current = this.evidences[this.evidenceIndex].photos[this.photoIndex];
    if (this.data.type == 'assessments-table') {
      this.dialogRef.close({
        status: 'view',
        type: this.data.type,
        id: current['responseID'],
      });
    } else if (this.data.type == 'actions-table') {
      this.dialogRef.close({
        status: 'view',
        type: this.data.type,
        id: current['actionID'],
      });
    }
  }

  expandDetailedFindings() {
    this.isDetailedFindingExpanded = true;
  }

  closeDetailedFindings() {
    this.isDetailedFindingExpanded = false;
  }
}
