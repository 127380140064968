/**
 * Formats the given role name by splitting it into words based on camel casing and modifying it based on the user division.
 * If the role is not a legacy role, the function returns the original role name.
 *
 * @param {string} roleName - The role name to be formatted with camel casing.
 * @param {string} userDivision - The user division value, used to modify role names when the division is 'DC'.
 * @returns {string} - The formatted role name.
 * @example
 * // Example 1: Legacy role name formatting
 * const legacyRoleName = 'NationalView';
 * const formattedRegularRole = splitRoleByCaps(legacyRoleName, 'Supermarkets');
 * console.log(formattedRegularRole);
 * // Output: "National View Users".
 * 
 * // Example 2: Custom role name formatting
 * const roleName = 'Store XYZ';
 * const formattedRole = splitRoleByCaps(roleName, 'Supermarkets');
 * console.log(formattedRole);
 * // Output: "Store XYZ"
 * 
 * // Example 3: Legacy role name formatting
 * const legacyRoleName = 'OperationManager';
 * const formattedRole = splitRoleByCaps(legacyRoleName, 'Supermarkets');
 * console.log(formattedRole);
 * // Output: "Zone Operation Managers"
 */
export function splitRoleByCaps(roleName: string, userDivision: string): string {
  // Check if the role is a legacy role for the given division
  if (!isLegacyRole(userDivision, roleName)) {
    return roleName; // Return the original role name if it's not a legacy role
  }
  
  if (
    roleName == 'Super'
        || roleName == 'Admin'
        || roleName == 'NationalView'
        || roleName == 'MultiStore'
  ) {
    roleName += 'Users';
  } else {
    if (roleName == 'OperationManager') {
      roleName = 'ZoneOperationManager';
    }
    if (roleName.substr(-1) != 's') {
      roleName += 's';
    }
  }
  
  return roleName.match(/[A-Z][a-z]+|[0-9]+/g).join(' ');
}

/**
 * Returns an object representing various legacy roles for different divisions.
 *
 * Each division contains an array of roles that are specific its division.
 *
 * @returns {object} An object where keys are division names and values are objects containing arrays of roles.
 * @example
 * const roles = legacyRoles();
 * console.log(roles.Supermarkets.roles);
 * // Output: ['MultiStore', 'StoreLossPartner', 'StoreLossManager', 'GroupManager', 'OperationManager', 'StateManager', 'NationalView', 'Admin', 'Super', 'Support']
 */
export function legacyRoles(): object {
  return {
    Supermarkets: { 
      roles: [
        'MultiStore', 
        'StoreLossPartner', 
        'StoreLossManager',
        'GroupManager',
        'OperationManager',
        'StateManager',
        'NationalView',
        'Admin',
        'Super',
        'Support',
      ]
    },
    Metro: { 
      roles: [
        'MultiStore',
        'GroupManager',
        'StateManager',
        'NationalView',
        'Admin',
        'Super',
        'Support',
      ]
    },
    CFC: { 
      roles: [
        'MultiStore',
        'StateManager',
        'NationalView',
        'Admin',
        'Super',
        'Support',
      ]
    },
    Bigw: { 
      roles: [
        'MultiStore',
        'AreaManager',
        'RegionManager',
        'NationalView',
        'Admin',
        'Super',
        'Support',
      ]
    },
    'Woolworths NZ Stores': { 
      roles: [
        'MultiStore',
        'GroupManager',
        'OperationManager',
        'NationalView',
        'Admin',
        'Super',
        'Support',
      ]
    },
    'Woolworths NZ eStores': { 
      roles: [
        'MultiStore',
        'GroupManager',
        'OperationManager',
        'NationalView',
        'Admin',
        'Super',
        'Support',
      ]
    },
    'Woolworths NZ Primary Connect': { 
      roles: [
        'MultiStore',
        'NationalView',
        'Admin',
        'Super',
        'Support',
      ]
    }
  };
}

/**
 * Checks if a given role name is a legacy role within a specified division.
 * 
 * @param {string} division - The name of the division to check.
 * @param {string} roleName - The name of the role to check.
 * @returns {boolean} - Returns true if the role name is found in the division's legacy roles, otherwise false.
 */
export function isLegacyRole(division: string, roleName: string): boolean {
  const legacyRoleNames = legacyRoles();
  const roles = legacyRoleNames[division]?.roles;
  if (roles && Array.isArray(roles)) {
    return roles.includes(roleName);
  }
  return false;
}
