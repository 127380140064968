/**
 * Checks if a given role id exists in the targetRoles array (case-sensitive)..
 *
 * @param {string[]} targetRoleIds - The array of role id strings to search within.
 * @param {string} roleId - The role id to check for in the array.
 * @returns {boolean} - Returns true if the role id is found, otherwise false.
 * @example
 * isTargetRole(["1", "2", "3"], "3"); // true
 * @example
 * isTargetRole(["1", "2", "3"], "4"); // false
 * @example
 * isTargetRole([], "1"); // false
 */
export function isTargetRoleId(targetRoleIds: string[], roleId: string): boolean {
  return targetRoleIds?.indexOf(roleId) > -1;
}
