<div class="page-container">

    <h1>Developer Handbook</h1>
    <br>
    <!-- introduction -->
    <app-accordion [headingLevel]="2">
        <ng-container accordion-header>
            Introduction
        </ng-container>
        <ng-container accordion-body>
            The developer handbook documents all components, styles and patterns for this application. It is the duty of
            developers to understand and maintain the developer handbook.
            Whenever a component is updated or added, the documentation for that component in the developer handbook
            must be
            modified in the same PR.
        </ng-container>
    </app-accordion>

    <!-- design tokens -->
    <app-accordion [headingLevel]="2">
        <ng-container accordion-header>
            Design tokens (fonts/colors/margins etc)
        </ng-container>
        <ng-container accordion-body>
            <p>
                The core design system provides us with design tokens (basically a collection of css variables). You can
                take a look at the css variables available in :root to get an idea of what design tokens are available.
            </p>

            <p>
                We should almost always use design tokens for colors. The figma designs will typically tell you which
                design tokens to use.
            </p>

            <p>
                Since our application supports both Woolowrths and Big W styles, we expect that the design tokens we use
                will exist in both design systems - but sometime's they dont. For example --color-example might exist in
                Woolworths design system, but not in Big W. In this case, create a "proxy" design token by adding a css
                variable to theme.scss (both the BigW and Woolworths themes). And have those variables point to a design
                token in the respective design system.
            </p>
        </ng-container>
    </app-accordion>

    <!-- navigation -->
    <app-accordion [headingLevel]="2">
        <ng-container accordion-header>
            Navigation
        </ng-container>
        <ng-container accordion-body>
            <p>
                The "navigation frame" is automatically applied to the entire application, which includes the header bar
                and the side menu.
            </p>

            <p>
                The navigation can be hidden on specific routes by passing <code>hideNav: true</code> in the route data.
            </p>

            <p>
                When adding a new route, you must provide a <code>breadcrumbKey</code> in the route data and add the
                route with the corrosponding key to the routes in <code>navigation.service.ts</code>. This is to ensure
                the breadcrumbs are displayed.
            </p>

            <p>When adding a details page, such as 'user details (user-details/:usedId)' - which includes a route param,
                it is obviously not possible to provide a static route for the breadcrumbs. In this case, leave the
                route empty.</p>
        </ng-container>
    </app-accordion>

    <!-- typography -->
    <app-accordion [headingLevel]="2">
        <ng-container accordion-header>
            Typography
        </ng-container>
        <ng-container accordion-body>
            <p>
                Headings and body font will automatically be styled to the design system specifications. But, we also
                provide utility classes
                if you need to override the styling of any headings or apply styling to any other text. Some font styles
                change size on smaller screens.
            </p>

            <br>

            <p>
                See the examples below (this list is not exhaustive), the text specifies the name of the class.
            </p>

            <br>

            <div class="core-display-lg">
                core-display-lg
            </div>

            <div class="core-display-sm">
                core-display-sm
            </div>

            <div class="core-headline-lg">
                core-headline-lg
            </div>

            <div class="core-headline-md">
                core-headline-md
            </div>

            <div class="core-headline-sm">
                core-headline-sm
            </div>

            <div class="core-headline-light-lg">
                core-headline-light-lg
            </div>

            <div class="core-headline-light-md">
                core-headline-light-md
            </div>

            <div class="core-headline-light-sm">
                core-headline-light-sm
            </div>


            <div class="core-body-md">
                core-body-md
            </div>

            <div class="core-body-md-medium">
                core-body-md-medium
            </div>


            <div class="core-body-sm">
                core-body-sm
            </div>

            <div class="core-body-sm-medium">
                core-body-sm-medium
            </div>
        </ng-container>
    </app-accordion>



    <!-- icon component -->
    <app-accordion [headingLevel]="2">
        <ng-container accordion-header>
            Icons
        </ng-container>
        <ng-container accordion-body>
            The icon component contains all of the application's supported icons. To use it, just provide it the icon's
            name and a color. Optionally, provide a
            label for the icon and a size.
            <app-icon iconName="roundCheck"></app-icon>
        </ng-container>
    </app-accordion>

    <!-- button component -->
    <app-accordion [headingLevel]="2">
        <ng-container accordion-header>
            Buttons
        </ng-container>
        <ng-container accordion-body>
            <button class="button">
                <app-icon iconName="roundCheck" color="#fff"></app-icon>
                Primary button with icon
            </button>

            <br>
            <br>

            <button class="button secondary">
                <app-icon iconName="roundCheck" color="#fff"></app-icon>
                Secondary Button
            </button>

            <br>
            <br>

            <button class="button secondary small">
                <app-icon iconName="roundCheck" color="#fff"></app-icon>
                Small Secondary Button
            </button>

            <br>
            <br>

            <button class="button small">
                <app-icon iconName="roundCheck" color="#fff"></app-icon>
                Small Primary Button
            </button>

            <br>
            <br>

            <p>
                Small icon only button
            </p>
            <button class="button small iconOnly">
                <app-icon iconName="roundCheck" color="#fff"></app-icon>
            </button>

            <br>
            <br>

            <p>medium icon only button</p>

            <button class="button iconOnly">
                <app-icon iconName="roundCheck" color="#fff"></app-icon>
            </button>

            <br>
            <br>

            <button class="button fullWidth">
                <app-icon iconName="roundCheck" color="#fff"></app-icon>
                full width button with icon
            </button>

            <br>
            <br>

            <button class="button fullWidth small">
                <app-icon iconName="roundCheck" color="#fff"></app-icon>
                small full width button with icon
            </button>


            <br>
            <br>

            <button class="button fullWidth iconLast">
                <app-icon iconName="roundCheck" color="#fff"></app-icon>
                icon after copy
            </button>

            <br>
            <br>

            <button class="button tertiary">
                <app-icon iconName="roundCheck" color="#fff"></app-icon>
                Tertiary style
            </button>

            <br>
            <br>

            <button class="button destructive">
                <app-icon iconName="roundCheck" color="#fff"></app-icon>
                Destructive style
            </button>



        </ng-container>
    </app-accordion>


    <!-- tabs component -->
    <app-accordion [headingLevel]="2">
        <ng-container accordion-header>
            Tabs Component
        </ng-container>
        <ng-container accordion-body>
            <p>We are using the <a href="https://v13.material.angular.io/components/tabs/overview">angular material tabs
                    component</a>.</p>
            <br>
            <br>
            <mat-tab-group>
                <mat-tab label="Active"> Content 1 </mat-tab>
                <mat-tab label="Upcoming"> Content 2 </mat-tab>
            </mat-tab-group>
        </ng-container>
    </app-accordion>


    <!-- table styling -->
    <app-accordion [headingLevel]="2">
        <ng-container accordion-header>
            Tables
        </ng-container>
        <ng-container accordion-body>
            <p>Simply apply the <code>.table</code> class to a table to have it styled in the iVerify style.</p>
            <br>
            <br>

            <table class="table">
                <tr>
                    <th>
                        Col 1
                    </th>

                    <th>
                        Col 2
                    </th>

                    <th>
                        col 3
                    </th>
                </tr>
                <tr>
                    <td>cel 1</td>
                    <td>cel 2</td>
                    <td>cel 3</td>
                </tr>
            </table>
            <br>
            <br>
            <p>You can also use the <code>app-table-empty-state</code> component to provide an empty state for the
                table. Just use it directly underneath your table and set the ngIf conditions to make it appear</p>
            <br>
            <br>
            <table class="table">
                <tr>
                    <th>
                        Col 1
                    </th>

                    <th>
                        Col 2
                    </th>

                    <th>
                        col 3
                    </th>
                </tr>
            </table>
            <app-table-empty-state
                emptyStateText="No results found. You can also pass empty state text to the component."></app-table-empty-state>


        </ng-container>
    </app-accordion>

    <!-- panel styling -->
    <app-accordion [headingLevel]="2">
        <ng-container accordion-header>
            Panels
        </ng-container>
        <ng-container accordion-body>
            <div class="panel">
                This is a panel. It is one of the main building blocks of the iVerify UI. It's just a div (or
                potentially some other element with the .panel class)
            </div>

            <br>
            <br>

            <h4>You can also use the panel header for a consistent header across panels:</h4>

            <br>

            <div class="panel">
                <app-panel-header class="panel-header-management" heading="Sample Heading">

                    <!-- actions via content projection -->
                    <div class="actionButtons">
                        <button class="button small">
                            <app-icon iconName="add" color="#fff"></app-icon>
                            Sample Button
                        </button>
                    </div>
                </app-panel-header>

                <p>
                    The panel header accepts actions via content projection.
                </p>

                <br>
                <p>
                    The actions are hidden on smaller screens, but you can keep them always visible by passing alwaysShowActions=true
                </p>
                <br>

                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                    labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
                    nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                    sunt in culpa qui officia deserunt mollit anim id est laborum.
                </p>
            </div>
        </ng-container>
    </app-accordion>

    <!-- sticky footer -->
    <app-accordion [headingLevel]="2">
        <ng-container accordion-header>
            Sticky Footer
        </ng-container>
        <ng-container accordion-body>
            <p>Use the sticky-footer global classes (sticky-footer-wrapper, sticky-footer-content) here as an example of how to anchor the footer and force the content to be scrollable.</p>
            <p>Override property details of these classes to suit your requirements.</p>
            <br>
            <br>
            <div class="sticky-footer-wrapper">
                <div class="sticky-footer-heading-example">Wrapper</div>
                <div class="sticky-footer-content">
                    <div class="sticky-footer-content-example">Scrollable Content</div>
                </div>
                <div class="sticky-footer-example">
                    <div>Footer Content</div>
                </div>  
            </div>
        </ng-container>
    </app-accordion>     

    <!-- percentage bar -->
    <app-accordion [headingLevel]="2">
        <ng-container accordion-header>
            Percentage Bar
        </ng-container>
        <ng-container accordion-body>
            <app-percentage-bar [percentage]="30"></app-percentage-bar>

            <app-percentage-bar [showPercentage]="false" [percentage]="90"></app-percentage-bar>

            <app-percentage-bar [showPercentage]="true" [percentage]="20"></app-percentage-bar>
        </ng-container>
    </app-accordion>

    <!-- tags -->
    <app-accordion [headingLevel]="2">
        <ng-container accordion-header>
            Tags
        </ng-container>
        <ng-container accordion-body>
            <p>
                Tags can be created using the <code>app-tag</code> component, just provide it the text for the tag using
                the tagText input. See examples below:
            </p>

            <br>
            <br>

            <app-tag tagText="hello world"></app-tag>
            <app-tag tagText="another tag"></app-tag>
            <app-tag tagText="cool"></app-tag>
            <app-tag tagText="calm"></app-tag>
            <app-tag tagText="collected"></app-tag>
        </ng-container>
    </app-accordion>

    <!-- select dropdown -->
    <!-- <app-accordion [headingLevel]="2">
        <ng-container accordion-header>
            Select Dropdown
        </ng-container>
        <ng-container accordion-body>

            <p>Apply the .select class and use the following structure.</p>

            <label class="select">
                <span class="label hidden">Label title</span>
                <select>
                    <option value="1">Option 1</option>
                    <option value="2">Option 2</option>
                    <option value="3">Option 3</option>
                    <option value="4">Option 4</option>
                    <option value="5">Option 5</option>
                </select>
                <div class="selectArrow" aria-hidden="true">
                </div>
            </label>
        </ng-container>
    </app-accordion> -->

    <!-- select dropdown -->
    <app-accordion [headingLevel]="2">
        <ng-container accordion-header>
            Select Dropdown
        </ng-container>
        <ng-container accordion-body>

            <div class="select-container">
                <label for="select" class="core-form-label">Apply the .select-wrapper class and use the following
                    structure</label>
                <div class="select-wrapper">
                    <select class="core-select" id="select" required="">
                        <option value="">Choose an option</option>
                        <option value="one">one</option>
                        <option value="two">two</option>
                    </select>
                    <div class="selectArrow" aria-hidden="true">
                    </div>
                </div>
            </div>

        </ng-container>
    </app-accordion>

    <!-- text input -->
    <app-accordion [headingLevel]="2">
        <ng-container accordion-header>
            Text Input
        </ng-container>
        <ng-container accordion-body>


            <label class="core-text-field">
                <span class="core-text-field-label-text">
                    Text field label
                </span>
                <div>
                    <div class="core-text-field-wrapper">
                        <input id="textField" type="text" class="core-text-field" />
                    </div>
                </div>
            </label>


            <label class="core-text-field">
                <span class="core-text-field-label-text">
                    Text field label
                </span>
                <div>
                    <div class="core-text-field-wrapper">
                        <input id="textField" type="text" class="core-text-field" />
                    </div>
                </div>
                <div class="core-help-text">
                    <span>
                        help text 1
                    </span>
                </div>
            </label>

            <label class="core-text-field">
                <span class="core-text-field-label-text">
                    Input With Leading Icon
                </span>
                <div>
                    <div class="core-text-field-wrapper leading">
                        <input id="textField" type="text" class="core-text-field" />
                        <app-icon iconName="errorFill"></app-icon>
                    </div>
                </div>
            </label>

            <label class="core-text-field">
                <span class="core-text-field-label-text">
                    Input With Trailing Icon
                </span>
                <div>
                    <div class="core-text-field-wrapper trailing">
                        <input id="textField" type="text" class="core-text-field" />
                        <app-icon iconName="errorFill"></app-icon>
                    </div>
                </div>
            </label>

            <label class="core-text-field">
                <span class="core-text-field-label-text">
                    Text field label
                </span>
                <div>
                    <div class="core-text-field-wrapper">
                        <input id="textField" type="text" class="core-text-field" />
                    </div>
                </div>
                <div class="core-help-text">
                    <span>
                        <app-icon iconName="errorFill"></app-icon>
                        help text 1
                    </span>
                </div>
            </label>

            <label class="core-text-field error">
                <span class="core-text-field-label-text">
                    Text field label
                </span>
                <div>
                    <div class="core-text-field-wrapper">
                        <input id="textField" type="text" class="core-text-field" />
                    </div>
                </div>
                <div class="core-help-text">
                    <span>
                        <app-icon iconName="errorFill"></app-icon>
                        help text 1
                    </span>
                </div>
            </label>


            <label class="core-text-field disabled">
                <span class="core-text-field-label-text">
                    Text field label
                </span>
                <div>
                    <div class="core-text-field-wrapper">
                        <input id="textField" type="text" class="core-text-field" readonly />
                    </div>
                </div>
            </label>

            <label class="core-text-field">
                <span class="core-text-field-label-text">
                    Text field label
                </span>
                <div>
                    <div class="core-text-field-wrapper">
                        <input id="textField" [(ngModel)]="textFieldCounterExampleValue" type="text"
                            class="core-text-field" />
                    </div>
                </div>
                <div class="core-help-text">
                    <app-text-field-counter maxLength="25"
                        [fieldContent]="textFieldCounterExampleValue"></app-text-field-counter>
                    <span>
                        <app-icon iconName="errorFill"></app-icon>
                        help text 1
                    </span>
                </div>
            </label>


        </ng-container>
    </app-accordion>

    <!-- dialog -->
    <app-accordion [headingLevel]="2">
        <ng-container accordion-header>
            Dialogs
        </ng-container>
        <ng-container accordion-body>

            <p>Along with the title and message properties that make up the dialog you can override the default button
                text.</p>
            <div class="dialog-buttons">
                <button class="button secondary" aria-label="Success Dialog" (click)="successDialog()">
                    Success
                </button>
                <button class="button secondary" aria-label="Success Dialog" (click)="errorDialog()">
                    Error
                </button>
                <button class="button secondary" aria-label="Success Dialog" (click)="informationDialog()">
                    Information
                </button>
                <button class="button secondary" aria-label="Success Dialog" (click)="confirmationDialog()">
                    Confirmation
                </button>
            </div>

        </ng-container>
    </app-accordion>

    <!-- chip -->
    <app-accordion [headingLevel]="2">
        <ng-container accordion-header>
            Chips
        </ng-container>
        <ng-container accordion-body>
            <div class="chip-buttons">
                <button class="chip-input">
                    chip 1
                </button>
                <button class="chip-input">
                    chip 2 with icon
                    <app-icon iconName="roundXMark"></app-icon>
                </button>
            </div>

        </ng-container>
    </app-accordion>

    <!-- dialog -->
    <app-accordion [headingLevel]="2">
        <ng-container accordion-header>
            Switch
        </ng-container>
        <ng-container accordion-body>

            <button aria-pressed="true" class="core-switch core-switch-selected">
                <span class="core-switch-label">Enabled</span>
                <span class="core-switch-thumb"></span>
            </button>
            <button aria-pressed="true" class="core-switch core-switch-small core-switch-selected">
                <span class="core-switch-label">Small Enabled</span>
                <span class="core-switch-thumb"></span>
            </button>

        </ng-container>
    </app-accordion>

    <!-- autocomplete dropdown -->
    <app-accordion [headingLevel]="2">
        <ng-container accordion-header>
            Autocomplete Dropdown
        </ng-container>
        <ng-container accordion-body>
            <div class="autocomplete-dropdown">
                <p>Search result items must have a name property.</p>
                <app-autocomplete-dropdown [searchTerm]="''" [searchResults]="searchResults"
                    [searchResultsPropertyName]="'name'"
                    [errorMessage]="'This tag already exists'" [showErrorMessage]="true"
                    [placeholder]="'Search xyz'" [accessibilityLabel]="'Search xyz'"
                    (searchTermChanged)="searchTermChanged($event)" (selectedItem)="selectedItem($event)">
                </app-autocomplete-dropdown>
            </div>

        </ng-container>
    </app-accordion>

    <!-- alert -->
    <app-accordion [headingLevel]="2">
        <ng-container accordion-header>
            Alerts
        </ng-container>
        <ng-container accordion-body>
            <div role="alert" class="core-alert">
                <div class="core-alert-container no-results-container">
                    <p class="no-results-description">This is a default alert</p>
                </div>
            </div>
            <div role="alert" class="core-alert core-alert-warning">
                <div class="core-alert-container no-results-container">
                    <p class="no-results-description">This is a warning alert</p>
                </div>
            </div>
            <div role="alert" class="core-alert core-alert-error">
                <div class="core-alert-container no-results-container">
                    <div class="core-alert-title">Alert title</div>
                    <p class="no-results-description">This is an error alert with a title</p>
                </div>
            </div>
            <div role="alert" class="core-alert core-alert-success">
                <div class="core-alert-container no-results-container">
                    <div class="core-alert-title">Alert title</div>
                    <p class="no-results-description">This is a success alert with a title</p>
                </div>
            </div>
        </ng-container>
    </app-accordion>


    <!-- location selection dropdown -->
    <app-accordion [headingLevel]="2">
        <ng-container accordion-header>
            Location Selection
        </ng-container>
        <ng-container accordion-body>
            <div class="autocomplete-dropdown">
                <p>Setup the location selection component by passing in the organisation structure along with its
                    division and initial state.</p>
                <p>Changing the role level property [roleLevel]="state" triggers a refresh and clears all location values. 
                    It defines the scope of applicable locations to enter.</p>
                <p>Each location is defined by its previous location and does not appear until it has a value selected.
                </p>
                <p>Example: [roleLevel]="state" shows state, [roleLevel]="zone" shows state & zone, [roleLevel]="groupID
                    shows state, zone & group.</p>
                <p>Note: This depends on a valid organisation structure that matches the passed in role levels.</p>
                <form [formGroup]="form">
                    <app-location-selection
                        [userOrgStructure]="{division: 'state', state: 'zone', zone: 'groupID', groupID: 'storeID'}"
                        [division]="'Supermarkets'"
                        [initialState]="{roleLevel: 'Group', selectedLocations :{state: 'NSW/ACT', zone: '3', groupID: '434'}}">
                    </app-location-selection>
                </form>
            </div>

        </ng-container>
    </app-accordion>

    <!-- toggle button -->
    <app-accordion [headingLevel]="2">
        <ng-container accordion-header>
            Toggle Button
        </ng-container>
        <ng-container accordion-body>
            <div class="autocomplete-dropdown">
                <p>Setup the toggle button component by passing in the left and right button captions and initial state (left/right).</p>
                <p>Changes to the button state can be tracked via the toggleChange output event.
                </p>
                <app-toggle-button
                    [leftCaption]="'Left Caption'" 
                    [rightCaption]="'Right Caption'"
                    [initialState]="'right'"
                    (toggleChange)="onToggleButtonChange($event)"                
                >
                </app-toggle-button>
                <app-location-selection
                    [userOrgStructure]="{division: 'state', state: 'zone', zone: 'groupID', groupID: 'storeID'}"
                    [division]="'Supermarkets'"
                    [initialState]="{roleLevel: 'Group', selectedLocations :{state: 'NSW/ACT', zone: '3', groupID: '434'}}"></app-location-selection>
            </div>
        </ng-container>
    </app-accordion>

    <!-- Access check pipe -->
    <style>
        .tag {
            display: inline-block;
            background-color: #f0f0f0;
            padding: 2px 6px;
            border-radius: 4px;
            margin: 2px;
            font-family: Arial, sans-serif;
        }
        .tag-name {
            color: #1a73e8;
        }
        .attribute {
            color: #d23669;
        }
        .directive {
            color: #4caf50;
        }
    </style>    <app-accordion [headingLevel]="2">
        <ng-container accordion-header>
            Access Check Pipe
        </ng-container>
        <ng-container accordion-body>
            <div class="autocomplete-dropdown">
                <p>A pipe (accessCheck) that checks user access for a given feature using the FeatureAccessService.</p>
                <p>This pipe accepts a composite 'feature.subfeature' key representing the feature to be checked.</p>
                <p>It returns a boolean indicating whether the user has access to the 'feature.subfeature' key.</p>
                <p>Example:</p>
                <div class="tag">&lt;<span class="tag-name">div</span> <span class="attribute">&nbsp;*</span><span class="attribute">ngIf</span>="<span class="directive">'actionDetails.viewEvidence' | accessCheck</span>"&gt;</div>
                <div class="tag">&lt;/<span class="tag-name">div</span>&gt;</div>
            </div>

        </ng-container>
    </app-accordion>
</div>