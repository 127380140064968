import {
  Component, ElementRef, OnInit, ViewChild 
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment/moment';
import { MatDialog } from '@angular/material/dialog';

import {
  faAngleDown,
  faAngleUp,
  faPlayCircle,
  faFileInvoice
} from '@fortawesome/free-solid-svg-icons';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import htmlToPdfmake from 'html-to-pdfmake';
import { FeatureAccessService } from 'src/app/common-services/feature-access.service';
import { isActionMarkAsCompleteCheckboxEnabled } from 'src/app/utils/is-action-mark-as-complete-checkbox-enabled/is-action-mark-as-complete-checkbox-enabled';
import { splitRoleByCaps } from 'src/app/utils/user-roles/split-role-by-caps';
import { PhotoCarouselComponent } from '../photo-carousel/photo-carousel.component';
import { PhotoPreviewComponent } from '../photo-preview/photo-preview.component';
import { CsaAuthService } from '../../auth/csa-auth.service';
import { MessagesComponent } from '../messages/messages.component';
import { LoaderService } from '../../common-services/loader.service';
import { SurveysService } from '../surveys.service';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-assessment-response-view',
  templateUrl: './assessment-response-view.component.html',
  styleUrls: ['./assessment-response-view.component.scss'],
})
export class AssessmentResponseViewComponent implements OnInit {
  @ViewChild('printSurvey') printSurvey: ElementRef;

  faAngleDown = faAngleDown;

  faAngleUp = faAngleUp;

  faPlayCircle = faPlayCircle;

  faFileInvoice = faFileInvoice;

  allExpandState = true;

  amberResponses = 0;

  canEdit = true;

  greenResponses = 0;

  naResponses = 0;

  noResponses = 0;

  openItems = [];

  redResponses = 0;

  printVersion = '';

  user = {};

  surveyDetails = {};

  surveyID = '';

  yesResponses = 0;

  editResponseForOnceOff = false;

  editResponseForRecurring = false;

  viewResponseForOnceOff = false;

  viewResponseForAdhoc = false;

  viewResponseForRecurring = false;

  constructor(
    private csaAuth: CsaAuthService,
    private actRoute: ActivatedRoute,
    private dialog: MatDialog,
    private router: Router,
    private loader: LoaderService,
    private surveyService: SurveysService,
    private featureAccessService: FeatureAccessService,
  ) {}

  ngOnInit(): void {
    this.getPermissions();
    this.surveyID = this.actRoute.snapshot.paramMap.get('id');
    this.user = this.csaAuth.user;
    this.surveyService.getSurveyById(this.surveyID).subscribe(
      (surveysData) => {
        console.log(surveysData);
        if (surveysData['status'] == 500) {
          this.router.navigate(['/error']);
        }
        this.checkAccess(surveysData['type']);
        this.surveyDetails = surveysData;
        this.getSurveyState();
        this.getNotes();
        this.getResponseCounts();
        this.getEditActionState();
        switch (this.surveyDetails['type']) {
          case 'adhoc':
            if (
              this.surveyDetails['targetRoles'].indexOf(this.user['role']) == -1
            ) {
              this.canEdit = false;
            }
            break;
          case 'onceoff':
            this.canEdit = this.editResponseForOnceOff;
            break;
          case 'recurring':
            this.canEdit = this.editResponseForRecurring;
            break;
        }
        if (!this.surveyDetails['sectionHeading']) {
          this.surveyDetails['sectionHeading'] = ['All Questions'];
          this.surveyDetails['questions'].forEach((question) => {
            question['sectionHeading'] = 'All Questions';
          });
        }
        this.surveyDetails['questions'].forEach((question) => {
          if (!question['sectionHeading']) {
            question['sectionHeading'] = 'All Questions';
          }
        });
      },
      (error) => {
        this.router.navigate(['/error', 'unauthorised']);
      }
    );
  }

  checkAccess(surveyType: string) {
    switch (surveyType) {
      case 'adhoc':
        if (!this.viewResponseForAdhoc) {
          this.router.navigate(['/error', 'unauthorised']);
        }
        break;
      case 'onceoff':
        if (!this.viewResponseForOnceOff) {
          this.router.navigate(['/error', 'unauthorised']);
        }
        break;
      case 'recurring':
        if (!this.viewResponseForRecurring) {
          this.router.navigate(['/error', 'unauthorised']);
        }
        break;
    }    
  }

  getPermissions() {
    this.viewResponseForOnceOff = this.featureAccessService.hasAccess('onceOffAssessments.viewResponse');
    this.viewResponseForAdhoc = this.featureAccessService.hasAccess('adhocAssessments.viewResponse');
    this.viewResponseForRecurring = this.featureAccessService.hasAccess('recurringAssessments.viewResponse');
    this.editResponseForOnceOff = this.featureAccessService.hasAccess('onceOffAssessments.editResponse');
    this.editResponseForRecurring = this.featureAccessService.hasAccess('recurringAssessments.editResponse');    
  }

  printResponse(value) {
    let showPrint = true;
    switch (value) {
      case 'Answered':
        if (!this.surveyDetails['responses'].find(
          (({ response }) => response !== '')
        )) {
          showPrint = false;
        }
        this.printVersion = 'Answered Questions';
        break;
      case 'Unanswered':
        if (!this.surveyDetails['responses'].find(
          (({ response }) => response === '')
        )) {
          showPrint = false;
        }
        this.printVersion = 'Unanswered Questions';
        break;
      case 'Yes':
      case 'No':
        if (!this.surveyDetails['responses'].find(
          (({ response }) => response === value)
        )) {
          showPrint = false;
        }
        this.printVersion = `Questions Answered '${value}'`;
        break;
      default:
        this.printVersion = 'All Questions';
        break;
    }

    if (!showPrint) {
      this.dialog.open(MessagesComponent, {
        data: {
          heading: 'Cannot Display',
          message: `<i>${this.printVersion}</i> has no matches. Please select a different filter option.`
        },
        disableClose: true,
        backdropClass: 'dialog-backdrop',
      });
    } else {
      setTimeout(() => {
        this.htmlToPDF();
      }, 500);
    }
  }

  htmlToPDF() {
    const html = htmlToPdfmake(this.printSurvey.nativeElement.innerHTML);
    const documentDefinition = { 
      content: [html], 
      styles: {
        active: {
          bold: true
        },
        'actions-heading': {
          paddingTop: 10,
          marginBottom: 0,
          textAlign: 'left'
        },
        'no-top-margin': {
          marginTop: 0
        },
        'action-line': {
          display: 'inline'
        },
        'todo-text': {
          whiteSpace: 'pre-wrap',
          fontStyle: { italics: true }
        }
      }
    };
    pdfMake.createPdf(documentDefinition).open();  
  }

  getResponseCounts() {
    this.yesResponses = 0;
    this.noResponses = 0;
    this.naResponses = 0;
    this.redResponses = 0;
    this.amberResponses = 0;
    this.greenResponses = 0;
    this.surveyDetails['responses'].forEach((question) => {
      switch (question['response']) {
        case 'Yes':
          this.yesResponses++;
          break;
        case 'No':
          this.noResponses++;
          break;
        case 'NA':
          this.naResponses++;
          break;
        case 'Red (Non Compliant)':
          this.redResponses++;
          break;
        case 'Amber (Partial Break Down)':
          this.amberResponses++;
          break;
        case 'Green (Compliant)':
          this.greenResponses++;
          break;
      }
    });
  }

  getEditActionState() {
    for (let i = 0; i < this.surveyDetails['questions'].length; i++) {
      for (let j = 0; j < this.surveyDetails['questions'][i]['actions']?.length; j++) {
        const action = this.surveyDetails['questions'][i]['actions'][j];
        action['checkEnabled'] = isActionMarkAsCompleteCheckboxEnabled(action);
      }
    }    
  }
  
  setOpened(itemIndex) {
    this.openItems.push(itemIndex);
  }

  setClosed(itemIndex) {
    for (let i = 0; i < this.openItems.length; i++) {
      if (this.openItems[i] === itemIndex) {
        this.openItems.splice(i, 1);
      }
    }
  }

  // Exposes global function splitRoleByCaps to template
  splitRoleByCaps(value): string {
    return splitRoleByCaps(value, this.user['division']);
  }

  viewPhoto(photoName, question, fileName) {
    let photo = '';

    if (fileName) {
      photo = fileName;
    } else {
      photo = `${this.surveyDetails['id']
      }-${
        question
      }-${
        this.user['storeID']
      }-${
        photoName}`;
    }

    const query = `?fileNames=${photo}`;
    this.loader.show('');

    this.surveyService.getPhotos(query).subscribe(
      (photoData) => {
        if (Object.keys(photoData).length == 0) {
          this.loader.hide('');
          const messageDialog = this.dialog.open(MessagesComponent, {
            data: {
              heading: 'File cannot be viewed, please retry',
              message:
              `Please email ${this.user['supportEmail']} for support`,
              closeText: 'Ok',
              retryText: 'Retry'
            },
            backdropClass: 'dialog-backdrop',
          });
          messageDialog.afterClosed().subscribe((result) => {
            if (result == 'retry') {
              this.viewPhoto(photoName, question, fileName);
            }
          });
        } else {
          const photoDialog = this.dialog.open(PhotoPreviewComponent, {
            data: {
              photoURL: photoData[photo],
              photoName,
              showRemove: false,
              documentType: 'Evidence'
            },
            backdropClass: 'dialog-backdrop',
            minWidth: '70%',
            maxWidth: '90vw',
            height: 'auto',
          });
        }
      },
      (error) => {
        this.loader.hide('');
        const messageDialog = this.dialog.open(MessagesComponent, {
          data: {
            heading: 'File cannot be viewed, please retry',
            message:
            `Please email ${this.user['supportEmail']} for support`,
            closeText: 'Ok',
            retryText: 'Retry'
          },
          backdropClass: 'dialog-backdrop',
        });
        messageDialog.afterClosed().subscribe((result) => {
          if (result == 'retry') {
            this.viewPhoto(photoName, question, fileName);
          }
        });
      }
    );
  }

  getAllPhotos(questionID: string, fileName?: string, photoIndex = 0) {
    const evidence = [];
    let questionIndex = 0;

    for (let i = 0; i < this.surveyDetails['responses'].length; i++) {      
      const response = this.surveyDetails['responses'][i];
      if (response['photos'] && response['photos'].length > 0) {
        const responsePhotos = {
          photos: [],
          questionTitle: this.surveyDetails['questions'][i]['title'],
          questionID,
          sectionHeading: this.surveyDetails['questions'][i]['sectionHeading'],
          totalIndex: i + 1,
          response: response['response']
        };

        if (questionID == response['question_id']) {
          questionIndex = evidence.length;
        }

        for (let j = 0; j < response['photos'].length; j++) {
          if (!response['photos'][j]['fileName']) {
            response['photos'][j]['fileName'] = `${this.surveyDetails['id']}-${response['question_id']}-${this.user['storeID']}-${response['photos'][j]['photoName']}`;
          }

          if (fileName == response['photos'][j]['fileName'].replace(/\s/g, '-').replace(/\.[^.]*$/, '')) {
            photoIndex = j;
          }

          responsePhotos.photos.push({
            photoName: response['photos'][j]['photoName'],
            fileName: response['photos'][j]['fileName']
          });
        }

        evidence.push(responsePhotos);
      }
      this.surveyDetails['responses'][i] = response;
    }
  
    const photoDialog = this.dialog.open(PhotoCarouselComponent, {
      data: {
        evidence,
        evidenceIndex: questionIndex,
        photoIndex,
        totalNumber: this.surveyDetails['questions'].length,
        showRemove: false,
        documentType: 'Evidence',
        type: 'survey'
      },
      backdropClass: 'dialog-backdrop',
      minWidth: '70%',
      maxWidth: '90vw',
      height: 'auto'
    });
  }

  getNotes() {
    for (let i = 0; i < this.surveyDetails['questions'].length; i++) {
      this.surveyDetails['questions'][i]['uploading'] = false;
      for (let j = 0; j < this.surveyDetails['responses'].length; j++) {
        if (
          this.surveyDetails['responses'][j]['question_id']
          == this.surveyDetails['questions'][i]['id']
        ) {
          this.surveyDetails['questions'][i]['notes'] = this.surveyDetails['responses'][j]['notes'];
          this.surveyDetails['questions'][i]['response'] = this.surveyDetails['responses'][j]['response'];
          this.surveyDetails['questions'][i]['actions'] = this.surveyDetails['responses'][j]['actions'];
          this.surveyDetails['questions'][i]['photos'] = this.surveyDetails['responses'][j]['photos'];
          this.surveyDetails['questions'][i]['pointInTimeReviewDate'] = this.surveyDetails['responses'][i]['pointInTimeReviewDate'];
          this.surveyDetails['questions'][i]['reviewedFrom'] = this.surveyDetails['responses'][i]['reviewedFrom'];
          this.surveyDetails['questions'][i]['reviewedTo'] = this.surveyDetails['responses'][i]['reviewedTo'];
        }
      }
    }
  }

  getSurveyState() {
    if (this.surveyDetails['status'] == 'active') {
      this.surveyDetails['surveyState'] = 'Start';
    } else if (this.surveyDetails['status'] == 'started') {
      this.surveyDetails['surveyState'] = 'Continue';
    } else if (this.surveyDetails['status'] == 'submitted') {
      this.surveyDetails['surveyState'] = 'Submitted';
    } else if (this.surveyDetails['status'] == 'closed') {
      this.surveyDetails['surveyState'] = 'Closed';
    }
  }
}
