<div mat-dialog-title>
  <div class="title">{{title}}</div>
  <button class="close-button" (click)="closeMe()">
    <app-icon iconName="dialogClose"></app-icon>
  </button>
</div>

<div mat-dialog-content>
  <div *ngIf="generateTages?.length > 0" class="heading">Generated tags</div>
  <div *ngIf="generateTages?.length > 0" class="tags">
    <app-tag
      *ngFor="let tag of generateTages"
      tagText="{{tag.name}}"
      type="{{tag.type}}"
    >
    </app-tag>
  </div>

  <div *ngIf="userAddedTages?.length > 0" class="heading">User added tags</div>
  <div *ngIf="userAddedTages?.length > 0" class="tags">
    <app-tag
      *ngFor="let tag of userAddedTages"
      tagText="{{tag.name}}"
      type="{{tag.type}}"
    >
    </app-tag>
  </div>
</div>
