import { Tag, TagType } from 'src/app/types/tag';

/**
 * Adds an `addToTagList` property with the value `'Targeted'` to each object in the array 
 * if the object contains a `targetStores` property.
 *
 * @param {object[]} data - An array of objects to be processed. Each object may optionally have a `targetStores` property.
 * @returns {object[]} The modified array of objects with updated `addToTagList` properties.
 * @example
 * const data = [
 *   { targetStores: true, addToTagList: ['ExistingTag'] },
 *   { targetStores: true },
 *   { someOtherProperty: 42 }
 * ];
 * 
 * const result = addTargetedToAddToTagList(data);
 * console.log(result);
 * // Output:
 * // [
 * //   { targetStores: true, addToTagList: ['ExistingTag', 'Targeted'] },
 * //   { targetStores: true, addToTagList: ['Targeted'] },
 * //   { someOtherProperty: 42 }
 * // ]
 */
export function addTargetedToAddToTagList(
  data: object[]
): object[] {
  data?.forEach((row) => {
    if (row['targetStores']) {
      if (!row['addToTagList']) {
        row['addToTagList'] = [];
      }
      row['addToTagList'].push(<Tag>{ name: 'Targeted', type: TagType.Targeted });
    }
  });
  return data;
}
