import { User } from 'src/app/models/user';
/**
 * Determines if a given user is associated with a store.
 *
 * @param {User} user - The user object to evaluate.
 * @returns {boolean} `true` if the user is associated with a store (i.e., has a `storeID`), otherwise `false`.
 */
export function isStoreLevelUser(user: User): boolean {
  return !!user?.storeID; // true when user is associated with a store
}
