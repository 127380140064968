import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  faArchive,
  faBoxOpen,
  faFileUpload,
  faFolderOpen,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import * as moment from 'moment';
import { reassignArrayPropertyValues } from 'src/app/utils/data';
import { addCustomTagToAddToTagList } from 'src/app/utils/add-custom-property-to-add-totarget-list/add-custom-property-to-add-totarget-list';
import { TagType } from 'src/app/types/tag';
import { createTagList } from 'src/app/utils/create-tag-list/create-tag-list';
import { CreateSurveyDialogComponent } from '../create-survey-dialog/create-survey-dialog.component';
import { CsaAuthService } from '../../auth/csa-auth.service';
import { LoadTemplateDialogComponent } from '../load-template-dialog/load-template-dialog.component';
import { MessagesComponent } from '../messages/messages.component';
import { User } from '../../models/user';
import { SurveysService } from '../surveys.service';

@Component({
  selector: 'app-more-templates',
  templateUrl: './more-templates.component.html',
  styleUrls: ['./more-templates.component.scss'],
})
export class MoreTemplatesComponent implements OnInit {
  @ViewChild(MatTabGroup) tabGroup: MatTabGroup;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  faArchive = faArchive;

  faFileUpload = faFileUpload;

  faFolderOpen = faFolderOpen;

  faPlus = faPlus;

  faBoxOpen = faBoxOpen;

  allTemplates: object[] = [];

  date = moment();

  driveUrl: string;

  hasNext: string;

  index: number = 0;

  pageIndex: number = 0;

  pageSize: number = 10;

  saveState: boolean = false;

  selectedGroup: number = 0;

  surveyGroups: string[] = ['Active', 'Archived'];

  tableColumns: string[] = [
    'tags',
    'template',
    'division',
    'questions',
    'assessments',
    'archive',
  ];

  totalCount: number = 0;

  user: User;

  isLoading: boolean = false;

  canUserCreateSurvey = false;

  constructor(
    private csaAuth: CsaAuthService,
    private dialog: MatDialog,
    private elementRef: ElementRef,
    private surveyService : SurveysService,
  ) {}

  ngOnInit(): void {
    this.user = this.csaAuth.user;
    this.getAllTemplates();
  }

  openDriveFolder() {
    if (!this.driveUrl) {
      this.surveyService.getTemplateDrive().subscribe((result) => {
        if (!result['targetUrl'] && result['status'] == 200) {
          this.dialog.open(MessagesComponent, {
            data: {
              heading: "Couldn't Locate Folder",
              message:
                `${result['status_message'] 
                } Please email ${ 
                  this.user['supportEmail'] 
                } for support.`,
              closeText: 'Ok',
            },
            backdropClass: 'dialog-backdrop',
          });
        } else if (
          result['status'] == 200
          && result['status_message'] == 'OK'
        ) {
          this.driveUrl = result['targetUrl'];
          window.open(this.driveUrl, '_blank');
        } else {
          this.dialog.open(MessagesComponent, {
            data: {
              heading: 'An Error Has Occured',
              message:
                `Please email ${this.user['supportEmail']} for support.`,
              closeText: 'Ok',
            },
            backdropClass: 'dialog-backdrop',
          });
        }
      });
    } else {
      window.open(this.driveUrl, '_blank');
    }
  }

  createSurvey() {
    this.dialog.open(CreateSurveyDialogComponent, {
      backdropClass: 'dialog-backdrop',
      minWidth: '70%',
      minHeight: 'calc(100vh - 90px)',
      height: 'auto',
      data: { templateid: '', templateDivision: this.user['division'] },
    });
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent) {
    this.paginator.pageIndex = 0;
    this.pageIndex = 0;
    this.index = 0;
    this.pageSize = this.paginator.pageSize;
    this.selectedGroup = tabChangeEvent.index;
    this.getAllTemplates();
  }

  getNext(event: PageEvent) {
    this.index = event.pageIndex * event.pageSize;
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.getAllTemplates();
  }

  loadTemplates() {
    this.surveyService.uploadTemplates('validate').subscribe(
      (result) => {
        if (result['result']) {
          const loadDialog = this.dialog.open(LoadTemplateDialogComponent, {
            backdropClass: 'dialog-backdrop',
            minWidth: '40%',
            height: 'auto',
            data: result['result'],
          });

          loadDialog.afterClosed().subscribe((result) => {
            this.getAllTemplates();
          });
        } else if (result['status'] == 500) {
          console.log(result);
          this.dialog.open(MessagesComponent, {
            data: {
              heading: "Couldn't Validate Templates",
              message:
                `Please email ${this.user['supportEmail']} for support`,
              closeText: 'Ok',
            },
            backdropClass: 'dialog-backdrop',
          });
        } else {
          console.log(result);
          this.dialog.open(MessagesComponent, {
            data: {
              heading: 'No Templates Found',
              message:
                'There are no files in the drive folder, please add a template file to be uploaded.',
              closeText: 'Ok',
            },
            backdropClass: 'dialog-backdrop',
          });
        }
      },
      (error) => {
        console.log(error);
        this.dialog.open(MessagesComponent, {
          data: {
            heading: "Couldn't Validate Templates",
            message:
              `Please email ${this.user['supportEmail']} for support`,
            closeText: 'Ok',
          },
          backdropClass: 'dialog-backdrop',
        });
      }
    );
  }

  getPagination() {
    const label = this.elementRef.nativeElement.querySelector(
      '.mat-paginator-range-label'
    );
    if (label) {
      if (this.allTemplates.length == 0) {
        label.innerHTML = 'No results to show';
        this.totalCount = 0;
      } else {
        const upToCount = this.index + this.allTemplates.length;

        if (this.hasNext == 'Y') {
          label.innerHTML = `${String(this.index + 1)} to ${upToCount} of Many`;
          this.totalCount = upToCount + 1;
        } else {
          label.innerHTML = `${String(this.index + 1)} to ${upToCount} of ${upToCount}`;
          this.totalCount = upToCount;
        }
      }
    }
  }

  updateStatus(templateID, status, event) {
    event.stopPropagation();
    const request = {};
    request['id'] = templateID;
    request['status'] = status;

    this.surveyService.updateTemplate(request).subscribe(
      (message) => {
        if (message['status'] == 200) {
          setTimeout(() => this.getAllTemplates(), 10);

          const dataObject = {};
          if (status == 'archived') {
            dataObject['heading'] = 'Template Archived';
            dataObject['message'] = 'This Template has been Archived';
            dataObject['closeText'] = 'Ok';
          } else if (status == 'active') {
            dataObject['heading'] = 'Template Re-Activated';
            dataObject['message'] = 'This Template has been Re-Activated';
            dataObject['closeText'] = 'Ok';
          }

          const confirmation = this.dialog.open(MessagesComponent, {
            data: dataObject,
            backdropClass: 'dialog-backdrop',
          });
        } else {
          console.log(message);
          this.dialog.open(MessagesComponent, {
            data: {
              heading: "Couldn't Update Template",
              message:
                `Please email ${this.user['supportEmail']} for support`,
              closeText: 'Ok',
            },
            backdropClass: 'dialog-backdrop',
          });
        }
      },
      (error) => {
        console.log(error);
        this.dialog.open(MessagesComponent, {
          data: {
            heading: "Couldn't Update Template",
            message:
              `Please email ${this.user['supportEmail']} for support`,
            closeText: 'Ok',
          },
          backdropClass: 'dialog-backdrop',
        });
      }
    );
  }

  getAllTemplates() {
    setTimeout(() => {
      this.isLoading = true;
      this.allTemplates = [];
    }, 0);

    const query = `/list/${
      this.user['division']
    }/${
      this.index
    }/${
      this.pageSize
    }${this.selectedGroup == 1 ? '?status=archived' : ''}`;

    this.surveyService
      .getTemplates(query)
      .subscribe((templateData) => {
        this.allTemplates = reassignArrayPropertyValues(templateData['templates'], 'filterName', 'eitb', 'GPS');
        this.allTemplates = addCustomTagToAddToTagList(this.allTemplates, { propertyName: 'filterName', propertyValueToMatch: 'GPS', tagType: TagType.Gps });
        this.allTemplates = createTagList(this.allTemplates, true, ['addToTagList']);
        this.hasNext = templateData['has_next'];
        this.getPagination();
        this.isLoading = false;
      }, (error) => {
        this.isLoading = false;
      });
  }
}
