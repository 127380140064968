import { isTargetRoleId } from '../is-target-role-id/is-target-role-id';
import { isTargetRoleName } from '../is-target-role-name/is-target-role-name';

/**
 * Determines whether a given role matches any of the target roles, either by Id or by name.
 *
 * @param {string[]} targetRoleIds - An array of target role Ids to check against.
 * @param {string | number} roleId - The role Id to check.
 * @param {string[]} targetRoleNames - An array of target role names to check against.
 * @param {string} roleName - The role name to check.
 * @returns {boolean} - Returns `true` if the role matches a target role by Id or name, otherwise `false`.
 */
export function isTargetRole(targetRoleIds: string[], roleId: string | number, targetRoleNames: string[], roleName: string): boolean {
  if (targetRoleIds) {
    if (typeof roleId === 'string') {
      return isTargetRoleId(targetRoleIds, roleId);
    } 
    const roleIdString = roleId ? String(roleId) : '';
    return isTargetRoleId(targetRoleIds, roleIdString);
  }
  if (targetRoleNames) {
    return isTargetRoleName(targetRoleNames, roleName);
  }
  return false;
}
