/**
 * Checks if a given string contains any special characters.
 *
 * A special character is defined as any character that is not
 * a letter (a-z or A-Z), a digit (0-9), or a space.
 *
 * @param {string} value - The string to check for special characters.
 * @returns {boolean} - Returns `true` if the string contains special characters, otherwise `false`.
 */
export function isSpecialCharacter(value: string): boolean {
  const re = /^[a-zA-Z0-9 ]+$/;
  return !!((value && !re.test(value)));
}
