import {
  Component, EventEmitter, Input, OnInit, Output 
} from '@angular/core';

@Component({
  selector: 'app-toggle-button',
  templateUrl: './toggle-button.component.html',
  styleUrls: ['./toggle-button.component.scss']
})
export class ToggleButtonComponent implements OnInit {
  @Input() leftCaption: string = 'Button 1';
  
  @Input() rightCaption: string = 'Button 2';

  @Input() initialState: 'left' | 'right' = 'left';

  @Output() toggleChange = new EventEmitter<'left' | 'right'>();

  selectedButton: 'left' | 'right' = 'left';

  constructor() { }

  ngOnInit(): void {
    this.selectedButton = this.initialState;
  }

  selectButton(button: 'left' | 'right'): void {
    this.selectedButton = button;
    this.toggleChange.emit(this.selectedButton);
  }
}
