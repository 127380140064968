<div mat-dialog-title>
  <div class="close-container">
    <span class="info-icon med-grey" (click)="closeMe()">
      <fa-icon [icon]="faTimes"></fa-icon>
    </span>
  </div>
</div>

<div mat-dialog-content>
  <div class="create-survey-form" *ngIf="!(('adhocAssessments.create' | accessCheck) || ('recurringAssessments.create' | accessCheck) || ('onceOffAssessments.create' | accessCheck))">
    <h2>New Assessment</h2>
    <h1 class="create-survey-headings">Your role does not have permission to view this dialog.</h1>
  </div>
  <form #createSurveyForm="ngForm"
    *ngIf="('adhocAssessments.create' | accessCheck) || ('recurringAssessments.create' | accessCheck) || ('onceOffAssessments.create' | accessCheck)"
    (submit)="sendSurvey(createSurveyForm, createSurveyForm.value); submitButton.disabled = true;"
    (keydown.enter)="$event.preventDefault()">

    <div class="create-survey-form">
      <h2>New Assessment</h2>

      <h1 class="create-survey-headings left-align">Assessment Title:</h1>
      <mat-form-field appearance="outline" color="primary">
        <mat-label class="" for="title">The name of the Assessment</mat-label>
        <input spellcheck="true" ngModel #title maxLength="50" pattern="[a-zA-Z0-9 ]+" matInput
          class="custom-control-input" name="title" required>
        <mat-hint align="end">{{title['value'].length || 0}}/50</mat-hint>
        <mat-error>Please enter an Assessment Name Without Special Characters</mat-error>
      </mat-form-field>

      <div *ngIf="user['domain'] !== 'Bigw'">
        <h1 class="create-survey-headings left-align">Division:</h1>
        <mat-form-field appearance="outline" color="primary">
          <mat-label class="" for="division">Division</mat-label>
          <mat-select disabled #division matInput [(ngModel)]="data.templateDivision" class="custom-control-input"
            name="division" required>
            <mat-option *ngFor="let division of divisions" [value]="division">
              {{division}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <h1 class="create-survey-headings left-align">Template:</h1>
      <mat-form-field appearance="outline" color="primary">
        <mat-label for="template">Select a Template</mat-label>
        <mat-select #template matInput [(ngModel)]="selectedTemplate"
          class="custom-control-input" name="template" required>
          <ng-container *ngIf="templateList">
            <mat-option *ngFor="let template of templateList" [value]="template">
              {{template['title'] | unescape}}
            </mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>

      <h1 class="create-survey-headings left-align" *ngIf="'tags.edit' | accessCheck">Tags:</h1>
      <app-tags-autocomplete-dropdown *ngIf="'tags.edit' | accessCheck"
        (tagListChanged)="tagListChanged($event)"
      >
      </app-tags-autocomplete-dropdown>

      <div>
        <mat-error *ngIf="selectedTemplate['filterName'] && selectedTemplate['filterName'] === 'eitb'">You are scheduling
          GPS assessment</mat-error>
      </div>

      <h1 class="create-survey-headings left-align padding-top">Assessment Type:</h1>
      <mat-radio-group ngModel name="surveyType" class="radio-buttons" required *ngIf="!selectedTemplate['filterName']">
        <div *ngIf="('onceOffAssessments.create' | accessCheck)">
          <mat-radio-button color="primary" value="onceOff">Once Off Published Assessment</mat-radio-button>
        </div>
        <div *ngIf="('adhocAssessments.create' | accessCheck)">
          <mat-radio-button color="primary" value="adHoc">Adhoc Assessment for Users to select on demand
          </mat-radio-button>
        </div>
        <div *ngIf="('recurringAssessments.create' | accessCheck)">
          <mat-radio-button color="primary" value="recurring">Recurring Published Assessments</mat-radio-button>
        </div>
      </mat-radio-group>
      <mat-radio-group ngModel name="surveyType" class="radio-buttons" required 
        *ngIf="selectedTemplate['filterName'] && selectedTemplate['filterName'] === 'eitb'">
        <div *ngIf="('adhocAssessments.create' | accessCheck)">
          <mat-radio-button color="primary" value="adHoc">Adhoc Assessment for Users to select on demand
          </mat-radio-button>
        </div>
        <div *ngIf="!('adhocAssessments.create' | accessCheck)">
          You do not have permission to create an assessment using this template.
        </div>
      </mat-radio-group>

      <div *ngIf="createSurveyForm.value.surveyType === 'onceOff'">
        <h1 class="create-survey-headings left-align padding-top">Send Assessment To:</h1>
        <div class="switch-container">
          <p>All Stores &nbsp; <mat-slide-toggle color="primary" #targeted
              (change)="selectedStores = []; selectedGroupings = [];" ngModel name="targeted">
            </mat-slide-toggle> &nbsp; Targeted Stores</p>
        </div>

        <div *ngIf="createSurveyForm.value.targeted == true">
          <h1 class="create-survey-headings left-align padding-top">Target Level:</h1>
          <div class="switch-container">
            <p>State/Zone/Group &nbsp; <mat-slide-toggle color="primary" #targetLevel
                (change)="selectedStores = []; selectedGroupings = [];" ngModel name="targetLevel">
              </mat-slide-toggle> &nbsp; Individual Stores</p>
          </div>
          <div *ngIf="createSurveyForm.value.targetLevel == false">
            <h1 class="create-survey-headings left-align padding-top">Select Audience:</h1>
            <div class="search_table">

              <mat-accordion>
                <mat-expansion-panel expanded="true">
                  <mat-expansion-panel-header>
                    <span
                      *ngIf="numSelected['division'] == 0 || numSelected['division'] == getKeysLength(locationsDrilldown); else numTopLevel">
                      All {{user.orgStruct['division'].replace("ID", "") | titlecase}}s
                    </span>
                    <ng-template #numTopLevel>
                      {{ numSelected['division'] }} Selected {{user.orgStruct['division'].replace("ID", "") | titlecase}}<span
                        *ngIf="numSelected['division'] > 1">s</span>
                    </ng-template>
                  </mat-expansion-panel-header>
                  
                  <div class="filter-rows" *ngFor="let location of locationsDrilldown | keyvalue; let i = index;">
                    <ng-template #initialblock [ngTemplateOutlet]="initialblock" let-selection="selection" let-level="level" 
                      [ngTemplateOutletContext]="{ 
                        level: user.orgStruct['division'],
                        selection: createSelection(location.key)
                      }">
                      <div class="filter-cell" *ngIf="level !== 'storeID'"
                        [ngClass]="{'selected quart-width' : createSurveyForm.value[getValueName(selection)] == true}">
                        <mat-checkbox name="{{ getValueName(selection) }}" ngModel color="primary"
                          (change)="
                            updateNumSelected('division', $event.checked); 
                            getNextLevel(level, selection, $event.checked)
                          ">
                          {{location.key}}                         
                        </mat-checkbox>
                      </div>
                      <div class="filter-cell" *ngIf="level === 'storeID'"
                        [ngClass]="{'selected': createSurveyForm.value[getValueName(selection)] == true}">
                        <mat-checkbox color="primary" ngModel
                          name="{{ getValueName(selection) }}"
                          (change)="
                            updateNumSelected('division', $event.checked); 
                            selection[level] = location.key; 
                            addGroup(level, selection, $event.checked)
                          ">
                          {{location.key}} - {{location.value | titlecase}}
                        </mat-checkbox>
                      </div>

                      <div class="filter-cell no-border" *ngIf="level !== 'storeID'">
                        <ng-template
                          *ngTemplateOutlet="nextLevelBlock; context: {
                            level: user.orgStruct[level],
                            topSelection: selection,
                            topLocation: location.value,
                            valueName: getValueName(selection)
                          }">
                        </ng-template>

                        <ng-template #nextLevelBlock let-topSelection="topSelection" let-level="level" let-topLocation="topLocation" let-valueName="valueName">
                          <mat-accordion *ngIf="createSurveyForm.value[getValueName(selection)] == true">
                            <mat-expansion-panel>
                              <mat-expansion-panel-header>
                                <span
                                  *ngIf="numSelected[valueName] == 0 || numSelected[valueName] == getKeysLength(topLocation); else numSelectedLevel">
                                  All {{level.replace("ID", "") | titlecase}}s
                                </span>
                                <ng-template #numSelectedLevel>
                                  {{ numSelected[valueName] }} Selected {{level.replace("ID", "") | titlecase}}<span
                                    *ngIf="numSelected[valueName] > 1">s</span>
                                </ng-template>
                              </mat-expansion-panel-header>
                              
                              <div class="filter-rows" *ngFor="let location of topLocation | keyvalue; let j = index">
                                <ng-template #currentLevelBlock [ngTemplateOutlet]="currentLevelBlock" let-selection="selection" 
                                [ngTemplateOutletContext]="{ 
                                  selection: topSelection
                                }">
                                  <div class="filter-cell" *ngIf="level !== 'storeID'" 
                                    [ngClass]="{'selected quart-width' : createSurveyForm.value[valueName+location.key] == true}">
                                    <mat-checkbox color="primary" ngModel 
                                      name="{{ getValueName(selection)+location.key }}"
                                      (change)="
                                        updateNumSelected(valueName, $event.checked); 
                                        selection[level] = location.key; 
                                        getNextLevel(level, selection, $event.checked)
                                      ">
                                      {{location.key}}
                                    </mat-checkbox>
                                  </div>
                                  <div class="filter-cell" *ngIf="level === 'storeID'"
                                  [ngClass]="{'selected': createSurveyForm.value[valueName+location.key] == true}">
                                    <mat-checkbox color="primary" ngModel
                                      name="{{ valueName+location.key }}"
                                      (change)="
                                        updateNumSelected(valueName, $event.checked); 
                                        selection[level] = location.key; 
                                        addGroup(level, selection, $event.checked)
                                      ">
                                      {{location.key}} - {{location.value | titlecase}}
                                    </mat-checkbox>
                                  </div>
                                  <div class="filter-cell no-border" *ngIf="level !== 'storeID' && createSurveyForm.value[valueName+location.key] == true">
                                    <ng-template
                                      *ngTemplateOutlet="nextLevelBlock; context: {
                                        level: user.orgStruct[level],
                                        topSelection: createSelection(location.key, level, selection),
                                        topLocation: location.value,
                                        valueName: getValueName(createSelection(location.key, level, selection))
                                      }">
                                    </ng-template>
                                  </div>
                                </ng-template>
                              </div>
                            </mat-expansion-panel>
                          </mat-accordion>
                        </ng-template>
                      </div>
                    </ng-template>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </div>
        </div>

        <div *ngIf="!createSurveyForm.value.targetLevel">
          <h1 class="create-survey-headings left-align padding-top">Exclude Stores:</h1>
          <mat-form-field appearance="outline" color="primary">
            <mat-label class="" for="excludeStoreSelect" *ngIf="user['division'] !== 'DC'">Search for Stores
            </mat-label>
            <mat-label class="" for="excludeStoreSelect" *ngIf="user['division'] === 'DC'">Search for DCs</mat-label>
            <mat-select matInput ngModel class="custom-control-input" name="excludeStoreSelect"
              (selectionChange)="setStore($event.value, false)" #excludeStoreSelect>
              <mat-option class="">
                <ngx-mat-select-search [clearSearchInput]="false" [searching]="searching"
                  placeholderLabel="Search by Store Name or Number.." [noEntriesFoundLabel]="noEntries"
                  name="searchValue" #searchValue ngModel (ngModelChange)="searchForStores($event)">
                </ngx-mat-select-search>
              </mat-option>
              <mat-option [disabled]="searching" *ngFor="let returnedStore of storesReturned" [value]="returnedStore">
                {{returnedStore['Store_No']}} - {{returnedStore['Location_Name']}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div class="todo-text" *ngIf="storeExcluded">
            This store has already been excluded.
          </div>
          <div class="search_table half-width m1" *ngIf="excludedStores.length > 0">

            <div class="headings">
              <div>Excluded Stores</div>
            </div>
            <div class="filter-rows" *ngFor="let excludedStore of excludedStores; let i = index">
              <div class="filter-cell">
                {{excludedStore['Store_No']}} - {{excludedStore['Location_Name']}}
              </div>
              <div class="filter-cell right-align">
                <a (click)="removeStore(excludedStore, false)">
                  <fa-icon [icon]="faTimes"></fa-icon>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="createSurveyForm.value.targetLevel == true">
          <h1 class="create-survey-headings left-align padding-top">Select Stores:</h1>
          <mat-form-field appearance="outline" color="primary">
            <mat-label class="" for="storeSelect" *ngIf="user['division'] !== 'DC'">Search for Stores</mat-label>
            <mat-label class="" for="storeSelect" *ngIf="user['division'] === 'DC'">Search for DCs</mat-label>
            <mat-select matInput ngModel class="custom-control-input" name="storeSelect"
              (selectionChange)="setStore($event.value, true)" #storeSelect>
              <mat-option class="">
                <ngx-mat-select-search [clearSearchInput]="false" [searching]="searching"
                  placeholderLabel="Search by Store Name or Number.." [noEntriesFoundLabel]="noEntries"
                  name="searchValue" #searchValue ngModel (ngModelChange)="searchForStores($event)">
                </ngx-mat-select-search>
              </mat-option>
              <mat-option [disabled]="searching" *ngFor="let returnedStore of storesReturned" [value]="returnedStore">
                {{returnedStore['Store_No']}} - {{returnedStore['Location_Name']}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div class="todo-text" *ngIf="storeAdded">
            This store has already been added.
          </div>

          <div class="search_table half-width m1" *ngIf="selectedStores.length > 0">

            <div class="headings">
              <div>Selected Stores</div>
            </div>
            <div class="filter-rows" *ngFor="let selectedStore of selectedStores; let i = index">
              <div class="filter-cell">
                {{selectedStore['Store_No']}} - {{selectedStore['Location_Name']}}
              </div>
              <div class="filter-cell right-align">
                <a (click)="removeStore(selectedStore, true)">
                  <fa-icon [icon]="faTimes"></fa-icon>
                </a>
              </div>
            </div>
          </div>
        </div>

        <h1 class="create-survey-headings left-align">Dates:</h1>
        <div class="centered-fields">
          <div class="multiple-field" [style.--numShown]="2">
            <mat-form-field appearance="outline" color="primary">
              <mat-label>Publish Date</mat-label>
              <input name="publishDate" (keydown)="false" [min]="minDate" ngModel matInput [matDatepicker]="picker"
                (click)="picker.open()" required>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
              <mat-error>Please select a date from tomorrow onwards</mat-error>
            </mat-form-field>
          </div>
          <div class="multiple-field" [style.--numShown]="2">
            <mat-form-field appearance="outline" color="primary">
              <mat-label>Due Date</mat-label>
              <input name="closeDate" (keydown)="false" [min]="createSurveyForm.value.publishDate" ngModel matInput
                [matDatepicker]="closepicker" (click)="closepicker.open()" required>
              <mat-datepicker-toggle matSuffix [for]="closepicker"></mat-datepicker-toggle>
              <mat-datepicker #closepicker></mat-datepicker>
              <mat-error>Please select a date after the selected Publish Date</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div *ngIf="createSurveyForm.value.surveyType === 'recurring'">
        <h1 class="create-survey-headings left-align padding-top">Schedule:</h1>
        <div>Publish Assessment Every:</div>
        <mat-form-field appearance="outline" color="primary">
          <mat-label class="" for="frequency">Select</mat-label>
          <mat-select #frequency matInput ngModel (ngModelChange)="updateDayfilter($event)" class="custom-control-input"
            name="frequency" required>
            <ng-container>
              <mat-option *ngFor="let frequency of frequencies" [value]="frequency">
                {{frequency}}
              </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
        <div *ngIf="createSurveyForm.value.frequency === 'Day'">Publish Assessments On:</div>
        <div *ngIf="createSurveyForm.value.frequency === 'Day'">
          <mat-checkbox [ngModel]="allDays" [indeterminate]="anyComplete" color="primary" name="everyDay"
            (ngModelChange)="setAll()">
            {{daySelection.name}}
          </mat-checkbox>
        </div>
        <div *ngIf="createSurveyForm.value.frequency === 'Day'" class="flex-row">
          <div *ngFor="let day of daySelection.days">
            <mat-checkbox [ngModel]="day.completed" color="primary" [name]="day.name" class="checkbox-spacing"
              (ngModelChange)="updateAllComplete(day.name)">
              {{day.name}}
            </mat-checkbox>
          </div>
        </div>
        <div *ngIf="createSurveyForm.value.frequency === 'Week'">Publish Assessments On:</div>
        <mat-radio-group ngModel name="weeklyPublishDay" *ngIf="createSurveyForm.value.frequency === 'Week'"
          (ngModelChange)="updateDayfilter($event)">
          <mat-radio-button color="primary" value="Monday">Monday</mat-radio-button>
          <mat-radio-button color="primary" value="Tuesday">Tuesday</mat-radio-button>
          <mat-radio-button color="primary" value="Wednesday">Wednesday</mat-radio-button>
          <mat-radio-button color="primary" value="Thursday">Thursday</mat-radio-button>
          <mat-radio-button color="primary" value="Friday">Friday</mat-radio-button>
          <mat-radio-button color="primary" value="Saturday">Saturday</mat-radio-button>
          <mat-radio-button color="primary" value="Sunday">Sunday</mat-radio-button>
        </mat-radio-group>

        <div *ngIf="createSurveyForm.value.frequency === 'Week'" class="margin-top">
          <div>Give the user:</div>
          <div class="switch-container">
            5 days &nbsp; <mat-slide-toggle color="primary" #validity ngModel name="validity"></mat-slide-toggle> &nbsp;
            7 days To complete the Assessment
          </div>
        </div>

        <div *ngIf="createSurveyForm.value.frequency === 'Week' || createSurveyForm.value.frequency === 'Month'"
          class="margin-top">
          <div>Enable Emails?</div>
          <div class="switch-container">
            Email Alerts Off &nbsp; <mat-slide-toggle color="primary" #alertsOn ngModel name="alertsOn">
            </mat-slide-toggle> &nbsp; Email Alerts On
          </div>
        </div>

        <div *ngIf="createSurveyForm.value.frequency" class="margin-top">Publish This Assessment On:</div>
        <div *ngIf="createSurveyForm.value.frequency" class="centered-fields">
          <div class="multiple-field" [style.--numShown]="2">
            <mat-form-field appearance="outline" color="primary">
              <mat-label>First Publish Day:</mat-label>
              <input name="beginRecurring" [matDatepickerFilter]="dayFilter" (keydown)="false" [min]="minDate" ngModel
                matInput [matDatepicker]="beginRecurringpicker" (click)="beginRecurringpicker.open()" required>
              <mat-datepicker-toggle matSuffix [for]="beginRecurringpicker"></mat-datepicker-toggle>
              <mat-datepicker #beginRecurringpicker></mat-datepicker>
              <mat-error>Please select a date from today onwards</mat-error>
            </mat-form-field>
          </div>
          <div class="multiple-field" [style.--numShown]="2">
            <mat-form-field appearance="outline" color="primary">
              <mat-label>Final Publish Day:</mat-label>
              <input name="endRecurring" (keydown)="false" [matDatepickerFilter]="dayFilter"
                [min]="createSurveyForm.value.beginRecurring" ngModel matInput [matDatepicker]="endRecurringpicker"
                (click)="endRecurringpicker.open()" required>
              <mat-datepicker-toggle matSuffix [for]="endRecurringpicker"></mat-datepicker-toggle>
              <mat-datepicker #endRecurringpicker></mat-datepicker>
              <mat-error>Please select a date after the selected Begin Scheduling Date</mat-error>
            </mat-form-field>
          </div>
        </div>

        <div *ngIf="createSurveyForm.valid" class="margin-top">
          <span class="medium-text dark-green">{{createSurveyForm.value.title}} </span>
          <span *ngIf="createSurveyForm.value.frequency === 'Month'">will be published on the </span>
          <span *ngIf="createSurveyForm.value.frequency === 'Month'" class="medium-text dark-green">First Day </span>
          <span *ngIf="createSurveyForm.value.frequency === 'Month'">of every </span>
          <span *ngIf="createSurveyForm.value.frequency === 'Week' || createSurveyForm.value.frequency === 'Day'">will be
            published every </span>
          <span *ngIf="createSurveyForm.value.frequency"
            class="medium-text dark-green">{{createSurveyForm.value.frequency}}, </span>
          <span *ngIf="createSurveyForm.value.frequency === 'Week' || createSurveyForm.value.frequency === 'Day'">on
          </span>
          <span *ngIf="createSurveyForm.value.frequency === 'Week'"
            class="medium-text dark-green">{{createSurveyForm.value.weeklyPublishDay}}s </span>
          <span *ngIf="createSurveyForm.value.frequency === 'Day'" class="medium-text dark-green">
            <span *ngIf="createSurveyForm.value.Monday">Mondays, </span>
            <span *ngIf="createSurveyForm.value.Tuesday">Tuesdays, </span>
            <span *ngIf="createSurveyForm.value.Wednesday">Wednesdays, </span>
            <span *ngIf="createSurveyForm.value.Thursday">Thursdays, </span>
            <span *ngIf="createSurveyForm.value.Friday">Fridays, </span>
            <span *ngIf="createSurveyForm.value.Saturday">Saturdays, </span>
            <span *ngIf="createSurveyForm.value.Sunday">Sundays, </span>
          </span>
          <span *ngIf="createSurveyForm.value.frequency">and the user will have </span>
          <span *ngIf="createSurveyForm.value.frequency === 'Day'" class="medium-text dark-green">1 day </span>
          <span *ngIf="createSurveyForm.value.frequency === 'Week' && createSurveyForm.value.validity == true"
            class="medium-text dark-green">7 days </span>
          <span *ngIf="createSurveyForm.value.frequency === 'Week' && createSurveyForm.value.validity == false"
            class="medium-text dark-green">5 days </span>
          <span *ngIf="createSurveyForm.value.frequency === 'Month'" class="medium-text dark-green">the Month </span>
          <span *ngIf="createSurveyForm.value.frequency">to complete each Assessment once it is published. </span>
          <span
            *ngIf="createSurveyForm.value.frequency === 'Month' || createSurveyForm.value.frequency === 'Week'">Publish
            and Reminder emails </span>
          <span class="medium-text dark-green"
            *ngIf="(createSurveyForm.value.frequency === 'Month' || createSurveyForm.value.frequency === 'Week') && createSurveyForm.value.alertsOn">will
          </span>
          <span class="medium-text dark-green"
            *ngIf="(createSurveyForm.value.frequency === 'Month' || createSurveyForm.value.frequency === 'Week') && !createSurveyForm.value.alertsOn">will
            not </span>
          <span *ngIf="createSurveyForm.value.frequency === 'Month' || createSurveyForm.value.frequency === 'Week'">be
            sent for these Assessments. </span>
        </div>

      </div>

      <div *ngIf="createSurveyForm.value.surveyType === 'adHoc'">
        <h1 class="create-survey-headings left-align padding-top">Available Dates:</h1>
        <div>Make the Adhoc Assessment available for users to pick between these dates:</div>
        <div class="centered-fields">
          <div class="multiple-field" [style.--numShown]="2">
            <mat-form-field appearance="outline" color="primary">
              <mat-label>From</mat-label>
              <input name="adhocFrom" (keydown)="false" [min]="today" ngModel matInput [matDatepicker]="adhocFrompicker"
                (click)="adhocFrompicker.open()" required>
              <mat-datepicker-toggle matSuffix [for]="adhocFrompicker"></mat-datepicker-toggle>
              <mat-datepicker #adhocFrompicker></mat-datepicker>
              <mat-error>Please select a date from today onwards</mat-error>
            </mat-form-field>
          </div>
          <div class="multiple-field" [style.--numShown]="2">  
            <mat-form-field appearance="outline" color="primary">
              <mat-label>To</mat-label>
              <input name="adhocTo" (keydown)="false" [min]="createSurveyForm.value.adhocFrom" ngModel matInput
                [matDatepicker]="adhocTopicker" (click)="adhocTopicker.open()" required>
              <mat-datepicker-toggle matSuffix [for]="adhocTopicker"></mat-datepicker-toggle>
              <mat-datepicker #adhocTopicker></mat-datepicker>
              <mat-error>Please select a date after the selected From Date</mat-error>
            </mat-form-field> 
          </div>
        </div>

        <h1 class="create-survey-headings left-align">Assessment Validity:</h1>
        <div>Give the user this many days to complete each Adhoc Assessment, once it has been created:</div>
        <mat-form-field appearance="outline" color="primary" *ngIf="!createSurveyForm.value.template['filterName']">
          <mat-label class="" for="adhocValidity">Number of Days</mat-label>
          <input type="number" max="35" min="1" ngModel #adhocValidity matInput class="custom-control-input"
            name="adhocValidity" required>
          <mat-error>Please the maximum days allowed to complete each Adhoc Assessment</mat-error>
          <mat-hint style="color: var(--red-color);"
            *ngIf="(createSurveyForm.value.adhocValidity !== '') && (createSurveyForm.value.adhocValidity === 0 || createSurveyForm.value.adhocValidity < 1 || createSurveyForm.value.adhocValidity > 35)">
            Please enter a number between 1 and 35</mat-hint>
        </mat-form-field>
        <mat-form-field appearance="outline" color="primary"
          *ngIf="createSurveyForm.value.template['filterName'] && createSurveyForm.value.template['filterName'] === 'eitb'">
          <mat-label class="" for="adhocValidity">Number of Days</mat-label>
          <input type="number" max="35" min="1" [(ngModel)]="adhocValidity" matInput class="custom-control-input"
            name="adhocValidity" required>
          <mat-error>Please the maximum days allowed to complete each Adhoc Assessment</mat-error>
          <mat-hint style="color: var(--red-color);"
            *ngIf="(createSurveyForm.value.adhocValidity !== '') && (createSurveyForm.value.adhocValidity === 0 || createSurveyForm.value.adhocValidity < 1 || createSurveyForm.value.adhocValidity > 35)">
            Please enter a number between 1 and 35</mat-hint>
        </mat-form-field>

        <h1 class="create-survey-headings left-align padding-top">Available To:</h1>
        <mat-form-field appearance="outline" color="primary">
          <mat-label class="" for="targetRole">
            Select at least one user role authorized to complete this Adhoc Assessment. You can select multiple roles if needed.
          </mat-label>
          <mat-select ngModel color="primary" name="targetRole" class="custom-control-input" required multiple>
            <mat-option *ngFor="let role of roleList" [value]="role">
							{{splitRoleByCaps(role.name) | unescape}}
						</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="submit-button-container center-align pt2">
        <div
          *ngIf="excludedStores.length == 0 && selectedStores.length == 0 && selectedGroupings.length == 0 && createSurveyForm.value.targeted == true"
          class="subtle-centered-text">
          Please select which stores to target, or select "All Stores" in "Send Assessment To:" section.
        </div>
        <div
          *ngIf="createSurveyForm.value.adhocValidity === 0 || createSurveyForm.value.adhocValidity < 1 || createSurveyForm.value.adhocValidity > 35 || !createSurveyForm.valid"
          class="subtle-centered-text">
          Please complete all fields correctly to send.
        </div>
        <button #submitButton
          [disabled]="(excludedStores.length == 0 && selectedStores.length == 0 && selectedGroupings.length == 0 && createSurveyForm.value.targeted == true) || createSurveyForm.value.adhocValidity === 0 || createSurveyForm.value.adhocValidity < 1 || createSurveyForm.value.adhocValidity > 35 || !createSurveyForm.valid"
          class="csa-button-solid survey-submit" type="submit">
          <span>
            <fa-icon [icon]="faPaperPlane"></fa-icon>
            <span> Save Assessment</span>
          </span>
        </button>
      </div>
    </div>
  </form>
</div>