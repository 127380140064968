import {
  Component, OnInit
} from '@angular/core';
import { CsaAuthService } from '../csa-auth.service';

@Component({
  selector: 'app-logout',
  template: '',
})
export class LogoutComponent implements OnInit {
  constructor(private auth: CsaAuthService) { }

  ngOnInit(): void {
    this.auth.logout();
  }
}
