import {
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { TitleCasePipe } from '@angular/common';
import {
  AbstractControl, 
  FormGroup, 
  Validators, 
  FormControl 
} from '@angular/forms';
import {
  faSave, faPaperPlane, faTimes, faSortDown 
} from '@fortawesome/free-solid-svg-icons';
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import * as moment from 'moment';
import { SurveysService } from 'src/app/surveys/surveys.service';
import { DrillDownFilterPipe } from '../../custom-pipes/drill-down-filter.pipe';
import { User } from '../../models/user';
import { CsaAuthService } from '../../auth/csa-auth.service';
import { MessagesComponent } from '../../surveys/messages/messages.component';
import { CsaBackEndService } from '../../common-services/csa-back-end.service';

@Component({
  selector: 'app-create-mrr-dialog',
  templateUrl: './create-mrr-dialog.component.html',
  styleUrls: ['./create-mrr-dialog.component.scss'],
  providers: [
    DrillDownFilterPipe,
    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class CreateMRRDialogComponent implements OnInit {
  compState = (val1, val2) => val1 == val2;

  mrrForm: FormGroup;

  faTimes = faTimes;

  faSave = faSave;

  faSortDown = faSortDown;

  faPaperPlane = faPaperPlane;

  divisions: string[] = [];

  sectionToAdd: string = '';

  sectionsToShow: string[] = [];

  selectedSections: string[] = [];

  survey = {};

  titleCasePipe = new TitleCasePipe();

  today = moment();

  user: User;

  assessmentTitle: string;

  selectSectionPlaceholder: string = 'Select Section To Add';

  tagList = [];

  constructor(
    private csaAuth: CsaAuthService,
    private dialog: MatDialog,
    private csaBackEnd: CsaBackEndService,
    private dialogRef: MatDialogRef<CreateMRRDialogComponent>,
    private surveyService : SurveysService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  public closeMe() {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.user = this.csaAuth.user;

    this.mrrForm = new FormGroup({
      title: new FormControl('', [
        Validators.required,
        this.specialCharactersValidator.bind(this),
        this.emptyStringValidator.bind(this)
      ]),
      division: new FormControl({ value: this.user['division'], disabled: true }, [
        Validators.required,
      ]),
      reference: new FormControl('', [this.emptyStringValidator.bind(this)]),
      section: new FormControl('', {
        validators: [
          this.specialCharactersValidator.bind(this),
          this.duplicateSectionsValidator.bind(this),
          this.emptyStringValidator.bind(this)
        ],
        updateOn: 'change'
      }),
      startDate: new FormControl('', [Validators.required]),
      endDate: new FormControl('', [Validators.required]),
    });

    this.surveyService
      .getNextLevel(`?domain=${this.user['domain']}`)
      .subscribe((divisionList) => {
        this.divisions = divisionList['division'];
      });
  }

  tagListChanged(tagList: string[]) {
    console.log('tagListChanged', tagList);
    this.tagList = tagList;
  }  

  duplicateSectionsValidator(control: AbstractControl): {[key: string]: any} | null {
    if (control.value && this.selectedSections.includes(control.value.replace(/\s+/g, ' ').trim())) {
      return { duplicate: true };
    }
    return null;
  }

  emptyStringValidator(control: AbstractControl): {[key: string]: any} | null {
    if (control.value && control.value.trim().length === 0) {
      return { whitespace: true };
    }
    return null;
  }

  specialCharactersValidator(control: AbstractControl): {[key: string]: any} | null {
    const re = /^[a-zA-Z0-9 ]+$/;
    if (control.value && !re.test(control.value)) {
      return { special: true };
    }
    return null;
  }

  addSection(value) {
    const re = /^[a-zA-Z0-9 ]+$/;
    if (value && re.test(value)) {
      value = value.replace(/\s+/g, ' ').trim();
      if (value.trim().length > 0 && !this.selectedSections.includes(value) && this.selectedSections.length < 30) {
        this.selectedSections.push(value);
        this.mrrForm.controls.section.setValue('');
      }
    }
  }

  removeSection(index) {
    this.selectedSections.splice(index, 1); 
  }

  sendSurvey(survey, value) {
    this.survey['title'] = value.title;
    this.survey['filterName'] = 'mrr';
    this.survey['type'] = 'adhoc';
    this.survey['sectionHeading'] = this.selectedSections;
    this.survey['tags'] = this.tagList;
    this.survey['startDate'] = moment(value.startDate, [
      'DD/MM/YYYY',
      'DD MMM YYYY',
      moment.ISO_8601,
    ]).format();
    this.survey['endDate'] = moment(value.endDate, [
      'DD/MM/YYYY',
      'DD MMM YYYY',
      moment.ISO_8601,
    ]).format();

    const requestJSONPayload = this.csaBackEnd.attachFirewallBypassPrefixes(this.survey, ['title']);

    this.surveyService.createSurvey(requestJSONPayload).subscribe((reply) => {
      if (reply['status'] == 200) {
        this.dialogRef.close();
        const dialogSuccess = this.dialog.open(MessagesComponent, {
          data: {
            heading: 'Material Risk Review Assessment Scheduled',
            message:
              `${this.survey['title'] 
              } has been made available to the requested users. The page will now reload to update your Audit Lists`,
          },
          disableClose: true,
          backdropClass: 'dialog-backdrop',
        });
        dialogSuccess.afterClosed().subscribe((result) => {
          window.location.reload();
        });
      } else {
        this.dialogRef.close();
        this.dialog.open(MessagesComponent, {
          data: {
            heading: "Couldn't Schedule Audit",
            message:
              `Please email ${this.user['supportEmail']} for support`,
          },
          disableClose: true,
          backdropClass: 'dialog-backdrop',
        });
      }
    });
  }
}
