import {
  Component, OnInit, ViewChild, ElementRef, 
  OnDestroy
} from '@angular/core';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import * as moment from 'moment';
import { getUserLocationsQueryString } from 'src/app/utils/user-roles/get-location-query-string';
import { createTagList } from 'src/app/utils/create-tag-list/create-tag-list';
import { addTargetedToAddToTagList } from 'src/app/utils/add-targeted-to-add-to-target-list/add-targeted-to-add-to-target-list';
import { FeatureAccessService } from 'src/app/common-services/feature-access.service';
import { CsaAuthService } from '../../auth/csa-auth.service';
import { LoaderService } from '../../common-services/loader.service';
import { StateService } from '../../common-services/state.service';
import { User } from '../../models/user';
import { SurveysService } from '../surveys.service';

@Component({
  selector: 'app-above-store-more-surveys',
  templateUrl: './above-store-more-surveys.component.html',
  styleUrls: ['./above-store-more-surveys.component.scss'],
})
export class AboveStoreMoreSurveysComponent implements OnInit, OnDestroy {
  @ViewChild(MatTabGroup) tabGroup: MatTabGroup;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  faPlus = faPlus;

  date = moment();

  filter: string = '';

  hasNext: string;

  index: number = 0;

  pageIndex: number = 0;

  pageSize: number = 10;

  selectedGroup: number = 0;

  surveyGroups: string[] = ['Active', 'Closed'];

  surveysToShow: any[] = [];

  tableColumns: string[];

  totalCount: number = 0;

  user: User = {};

  saveState: boolean = false;

  isLoading: boolean = false;
  
  public viewTags = false;

  constructor(
    private elementRef: ElementRef,
    private surveyCompletionData: LoaderService,
    private csaAuth: CsaAuthService,
    private state: StateService,
    private surveyService : SurveysService,
    private featureAccessService: FeatureAccessService,
  ) {}

  ngOnInit(): void {
    this.getPermissions();   
    this.user = this.csaAuth.user;
    if (this.state.state && this.state.origin == 'above-store-more-surveys') {
      for (const key in this.state.state) {
        this[key] = this.state.state[key];
      }
    }
    this.tableColumns = ['tags', 'survey', 'dueDate', '%Complete', 'actions'];
    this.getInitialFilters();
  }

  ngOnDestroy() {
    this.setState();
  }

  setState() {
    if (this.saveState) {
      this.state.origin = 'above-store-more-surveys';
      this.state.state = {
        filter: this.filter,
        hasNext: this.hasNext,
        index: this.index,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        selectedGroup: this.selectedGroup,
        surveysToShow: this.surveysToShow,
        tableColumns: this.tableColumns,
        totalCount: this.totalCount,
        user: this.user
      };
    } else {
      this.state.origin = undefined;
      this.state.state = undefined;
    }
  }

  getPermissions() {
    this.viewTags = this.featureAccessService.hasAccess('tags.view');
  }

  surveyDetails(message: string) {
    this.surveyCompletionData.changeMessage(message);
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent) {
    this.paginator.pageIndex = 0;
    this.pageIndex = 0;
    this.index = 0;
    this.pageSize = this.paginator.pageSize;
    this.selectedGroup = tabChangeEvent.index;
    this.getSurveysToShow();
  }

  getNext(event: PageEvent) {
    this.index = event.pageIndex * event.pageSize;
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.getSurveysToShow();
  }

  getInitialFilters() {
    this.filter = getUserLocationsQueryString(this.csaAuth.user);
    this.getSurveysToShow();
  }

  getSurveysToShow() {
    setTimeout(() => {
      this.isLoading = true;
      this.surveysToShow = [];
    }, 0);
    
    if (this.selectedGroup == 1) {
      this.surveyService
        .getSurveys(
          '/above-store',
          `${this.user['division']}&status=closed${this.filter}`,
          '',
          `/${this.index}`,
          `/${this.pageSize}`
        )
        .subscribe(
          (surveysData) => {
            this.surveysToShow = addTargetedToAddToTagList(surveysData['surveys']);
            this.surveysToShow = createTagList(this.surveysToShow, this.viewTags, ['addToTagList']);
            this.hasNext = surveysData['has_next'];
            this.getPagination();
            this.isLoading = false;
          },
          (error) => {
            this.isLoading = false;
          }
        );
    } else if (this.selectedGroup == 0) {
      this.surveyService
        .getSurveys(
          '/above-store',
          `${this.user['division']}&status=published${this.filter}`,
          '',
          `/${this.index}`,
          `/${this.pageSize}`
        )
        .subscribe(
          (surveysData) => {
            this.surveysToShow = addTargetedToAddToTagList(surveysData['surveys']);
            this.surveysToShow = createTagList(this.surveysToShow, this.viewTags, ['addToTagList']);
            this.hasNext = surveysData['has_next'];
            this.getPagination();
            this.isLoading = false;
          },
          (error) => {
            this.isLoading = false;
          }
        );
    }
  }
  
  getPagination() {
    const label = this.elementRef.nativeElement.querySelector(
      '.mat-paginator-range-label'
    );
    if (label) {
      if (this.surveysToShow.length == 0) {
        label.innerHTML = 'No results to show';
        this.totalCount = 0;
      } else {
        const upToCount = this.index + this.surveysToShow.length;

        if (this.hasNext == 'Y') {
          label.innerHTML = `${String(this.index + 1)} to ${upToCount} of Many`;
          this.totalCount = upToCount + 1;
        } else {
          label.innerHTML = `${String(this.index + 1)} to ${upToCount} of ${upToCount}`;
          this.totalCount = upToCount;
        }
      }
    }
  }
}
