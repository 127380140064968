import { User } from 'src/app/models/user';

/**
 * Constructs a query string based on a user's organization structure and properties.
 * This function dynamically builds a query string representing the user's location within
 * an organizational structure, such as division, department, and group. The query string 
 * supports custom user property names based on the organization structure's next key 
 * (e.g., 'groupID' is replaced with 'groupNo').
 * 
 * @param {User} user - The user object containing organization structure and other properties.
 *                    The user object must have an 'orgStruct' property which defines the organization hierarchy.
 *                    Additionally, the user object should contain properties that correspond to the keys
 *                    in the organization structure (e.g., division, groupID) which are used to generate the query string.
 * @returns {string} A query string constructed from the user's organization structure.Each key-value pair in the organization
 *                   structure is converted into a query parameter, with adjustments made for specific property names
 *                   (e.g., 'groupID' becomes 'groupNo' in the query string). If a user property corresponding to
 *                   a key in the organization structure is not found, that key-value pair is skipped in the query string.
 * @example
 * // Assuming a user object with the following structure:
 * // {
 * //   orgStruct: {division: 'state', groupID: 'storeID', state: 'zone', zone: 'groupID'},
 * //   division: 'Supermarkets',
 * //   state: 'NSW/ACT',
 * //   zone: '2',
 * //   groupNo: '422'
 * //   storeID: '1137'
 * // };
 * getUserLocationQueryString(user);
 * // Returns 'division=Supermarkets&state=NSW/ACT&zone=2&groupID=422&storeID=1137'
 */
// TODO (22/1/25 AS): This is to be replaced with the multi location implementation which will pass location API context via headers.
export function getUserLocationsQueryString(user: User): string {
  const userOrgStructure = user['orgStruct'];
  if (!userOrgStructure) {
    return '';
  }
  let queryString = '';
  let key = 'division'; // start with the top level organization node
  while (userOrgStructure[key]) {
    const nextKey = user['orgStruct'][key];
    const userPropertyName = nextKey === 'groupID' ? 'groupNo' : nextKey;
    if (user[`${userPropertyName}`]) {
      queryString += `&${nextKey}=${user[`${userPropertyName}`]}`;
    }
    key = nextKey;
  }
  return queryString;
}
