import { Tag, TagType } from 'src/app/types/tag';
import { deepCopy } from '../deep-copy/deep-copy';

// TODO (10/1/25 AS): Add unit tests

/**
 * Generates a list of objects with a `tagList` property based on the input list. 
 * The `tagList` combines custom tags and additional tags extracted from specified fields.
 *
 * @param {any[]} list - The input array of objects to process.
 * @param {boolean} [includeCustomTags=true] - Determines whether to include custom tags in the `tagList`.
 * @param {string[]} [additionalRowFieldsToIncludeInTagList=[]] - Specifies the additional fields from each row to include in the `tagList`.
 * @returns {object[]} - A new array of objects with the `tagList` property added or updated.
 * @example
 * const list = [
 *   { id: 1, tags: ['tag1', 'tag2'], customField: [{name: 'test1', type: Gps}],
 *   { id: 2, customField: [{name: 'test2', type: Gps}] },
 * ];
 * const result = createTagList(list, true, ['customField']);
 * // result = [
 * //   { id: 1, tags: ['tag1', 'tag2'], customField:  [{name: 'test1', type: Gps}], tagList: [{ name: 'tag1', type: 'Custom' }, { name: 'tag2', type: 'Custom' }, {name: 'test1', type: Gps}] },
 * //   { id: 2, customField:  [{name: 'test2', type: Gps}], tagList: [{name: 'test2', type: Gps}],
 * // ];
 */
export function createTagList(list: any[], includeCustomTags = true, additionalRowFieldsToIncludeInTagList: string[] = []): object[] {
  const alteredData = deepCopy(list);
  alteredData?.forEach((row) => {
    const customTags = row['tags'] ? [...row['tags'].map((tag: string) => (<Tag>{ name: tag, type: TagType.Custom }))] : [];
    const AdditionalTags = [];
    additionalRowFieldsToIncludeInTagList.forEach((fieldName) => {
      if (row[fieldName]) {
        AdditionalTags.push(...row[fieldName]);
      }
    });
    if (includeCustomTags && customTags.length > 0) {
      row['tagList'] = [...AdditionalTags, ...customTags];
    } else {
      row['tagList'] = [...AdditionalTags];
    }
  });
  return alteredData;  
}
